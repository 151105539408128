import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildBudgetSpecialProjectSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'budgetSpecialProject',
    headerName: 'Budget Special Project',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'referenceNumber',
        headerName: 'Reference Number',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: '_count.costCodes',
        headerName: 'Cost Codes',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: '_count.serviceCompletionItems',
        headerName: 'Service Completion Items',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'owner.name',
        headerName: 'Owner',
        sortable: true,
      },
    ],
  },
];
