import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Alert, Button, TextField } from '@mui/material';
import { useAuth } from 'app/providers/auth';
import { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const defaultValues = {
  emailAddress: '',
  method: 'USER_EMAIL_ADDRESS',
  phoneNumber: '',
};

const buildSchema = () =>
  yup.object().shape({
    emailAddress: yup.mixed().when('method', {
      is: 'USER_EMAIL_ADDRESS',
      then: yup
        .string()
        .email('You Must Enter A Valid Email Address')
        .required('You Must Enter An Email Address'),
    }),
    method: yup.string().oneOf(['USER_EMAIL_ADDRESS', 'USER_PHONE_NUMBER']).required(),
    phoneNumber: yup.mixed().when('method', {
      is: 'USER_PHONE_NUMBER',
      then: yup
        .string()
        .matches(/^[0-9]{10}$/, 'You Must Enter A Valid Phone Number')
        .required('You Must Enter A Phone Number'),
    }),
  });

const SignInScreenInitializeForm = ({ onSignInInitialized }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { jwtService } = useAuth();
  const [loading, setLoading] = useState(false);
  const schema = useMemo(() => buildSchema(), []);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onSubmit',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const onSubmit = useCallback(
    async (data) => {
      setErrorMessage(null);
      setLoading(true);

      try {
        const { key } = await jwtService.initializeSignIn({
          emailAddress: data.emailAddress ?? '',
          method: data.method,
          phoneNumber: data.phoneNumber ?? '',
        });

        if (key) {
          if (typeof onSignInInitialized === 'function') {
            onSignInInitialized({ key });
          }

          reset(defaultValues);
        } else {
          setErrorMessage('Sign In Failed!');
        }
      } catch (err) {
        setErrorMessage(err.response?.data?.error ?? 'An unknown error occurred!');
      } finally {
        setLoading(false);
      }
    },
    [jwtService, onSignInInitialized, reset]
  );

  return (
    <FormProvider
      {...{
        clearErrors,
        control,
        formState,
        handleSubmit,
        reset,
        setValue,
        watch,
        ...methods,
      }}
    >
      <form
        className="w-full pt-20"
        id="sign-in-screen-initialize-form"
        name="sign-in-screen-initialize-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        {errorMessage && (
          <Alert className="mb-24" severity="error" variant="outlined">
            {errorMessage}
          </Alert>
        )}

        {watchFields?.method === 'USER_EMAIL_ADDRESS' && (
          <>
            <Controller
              control={control}
              name="emailAddress"
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  className="mb-24"
                  error={!!errors?.emailAddress}
                  fullWidth
                  helperText={errors?.emailAddress?.message}
                  placeholder="Enter Your Work Email Address..."
                  required
                  type="email"
                  variant="outlined"
                />
              )}
            />
          </>
        )}

        {/* ROADMAP: Implement */}
        {watchFields?.method === 'USER_PHONE_NUMBER' && <></>}

        <div className="mt-16">
          <Button
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid || loading}
            form="sign-in-screen-initialize-form"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Sign In
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default SignInScreenInitializeForm;
