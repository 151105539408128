import { Paper, Typography } from '@mui/material';
import { jwtService } from 'app/providers/auth/services';
import { useEffect } from 'react';

const SignOutScreen = () => {
  useEffect(() => {
    setTimeout(() => {
      jwtService.revokeSession();
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-48 mx-auto" src="assets/images/logo/logo.svg" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight text-center">
            You Have Signed Out!
          </Typography>
        </div>
      </Paper>
    </div>
  );
};

export default SignOutScreen;
