import _ from '@lodash';
import {
  jwtService,
  JWT_SERVICE_EVENTS,
  JWT_SERVICE_TOKEN_TYPES,
} from 'app/providers/auth/services';
import { selectUser } from 'app/store/userSlice';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';

// TODO:
const { REACT_APP_SOCKET_IO_HOST = 'https://dev.ultraviolet-interchange.hyvery.io' } = process.env;

const SocketIoContext = createContext();

const SocketIoProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const user = useSelector(selectUser);

  const userSocket = useMemo(
    () =>
      accessToken && user?.id
        ? io(`${REACT_APP_SOCKET_IO_HOST}/users/${user.id}`, {
            autoConnect: false,
            auth: {
              token: accessToken,
            },
            transports: ['websocket'],
          })
        : null,
    [accessToken, user?.id]
  );

  useEffect(() => {
    jwtService.on(
      [
        JWT_SERVICE_EVENTS.ON_COMPLETE_SIGN_IN,
        JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION,
        JWT_SERVICE_EVENTS.ON_REFRESH_SESSION,
      ],
      ({ data }) => {
        if (_.get(data, JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN)) {
          setAccessToken(_.get(data, JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN));
        }
      }
    );

    jwtService.on(
      [JWT_SERVICE_EVENTS.ON_CLIENT_RESPONSE_ERROR, JWT_SERVICE_EVENTS.ON_REVOKE_SESSION],
      () => setAccessToken(null)
    );
  }, []);

  useEffect(() => {
    if (userSocket) {
      userSocket.connect();
    }

    return () => {
      userSocket?.disconnect();
    }
  }, [userSocket]);

  return (
    <SocketIoContext.Provider
      value={{
        userSocket,
      }}
    >
      {children}
    </SocketIoContext.Provider>
  );
};

export const __SocketIoContext = SocketIoContext;
export default SocketIoProvider;
