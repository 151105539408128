import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { IconButton, Tooltip } from '@mui/material';
import { useMeilisearch } from 'app/providers/meilisearch';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { convertDateRangeToNumericFilter } from 'app/shared-components/EntitySearch/EntitySearchDateRangeMenu';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useState } from 'react';
import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch';
import { useDispatch } from 'react-redux';

const EntitySearchHeaderCsvButton = ({ className, dateRangeAttribute, fileName }) => {
  const { axiosSearchClient } = useMeilisearch();
  const currentRefinements = useCurrentRefinements();
  const dispatch = useDispatch();
  const { gridReady, gridRef, indexName, initialColumnDefs } = useEntitySearchApp();
  const [processing, setProcessing] = useState(false);
  const { uiState } = useInstantSearch();

  const handleDownloadCsv = async () => {
    try {
      setProcessing(true);

      if (gridReady && gridRef.current) {
        const columns = gridRef.current.api.getAllGridColumns();
        const {
          data: { hits },
        } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
          filter: [
            ...(currentRefinements?.items?.map((currentRefinement) => {
              let _filter = [];

              _filter = [..._filter, ...(uiState[indexName]?.configure?.filters || [])];

              if (currentRefinement.indexName?.indexOf(indexName) !== -1) {
                currentRefinement.refinements?.forEach((refinement) => {
                  if (refinement.attribute === dateRangeAttribute) {
                    const [dateRangeType, dateRangeString] = refinement.value?.split(':');

                    const numericFilter = convertDateRangeToNumericFilter({
                      attribute: refinement.attribute,
                      dateRangeAttribute,
                      dateRangeString,
                      dateRangeType,
                    });

                    _filter = [..._filter, numericFilter?.join(' AND ')];
                  } else {
                    _filter = [..._filter, `"${refinement.attribute}"="${refinement.value}"`];
                  }
                });
              }

              return _filter?.filter((el) => el) ?? [];
            }) ?? []),
          ].filter((el) => el),
          limit: 2000,
          offset: 0,
          q: uiState[indexName]?.query,
          sort: [uiState[indexName]?.sortBy?.split(':').slice(1).join(':')].filter((el) => el),
        });

        if (columns && hits) {
          const filteredColumns = columns.filter(
            (column) =>
              column.isVisible() &&
              !['active', 'select'].includes(column.getColDef()?.headerName?.toLowerCase())
          );

          const csvString = [
            filteredColumns.map((column) => column.getColDef()?.headerName),

            ...hits.map((hit) =>
              filteredColumns.map((column) => {
                const columnDef = column.getColDef();

                let initialColumnDef;

                initialColumnDefs.forEach(({ children }) => {
                  children.forEach((initialColumn) => {
                    if (initialColumn?.field === columnDef?.field) {
                      initialColumnDef = initialColumn;
                    }
                  });
                });

                let value;

                if (columnDef && initialColumnDef) {
                  value =
                    typeof initialColumnDef.valueGetter === 'function'
                      ? initialColumnDef.valueGetter({
                          data: hit,
                        })
                      : _.get(hit, column.getColDef()?.field);

                  value =
                    typeof initialColumnDef.valueFormatter === 'function'
                      ? initialColumnDef.valueFormatter({ value })
                      : value;
                }

                return `"${value || ''}"`;
              })
            ),
          ]
            .map((row) => row.join(','))
            .join('\n');

          const blob = new Blob([csvString], { type: 'text/csv' });

          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName || `${indexName}-${moment().unix()}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      }
    } catch (err) {
      dispatch(
        showMessage({
          message: 'Failed Generating / Downloading CSV',
          variant: 'error',
        })
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className={className}>
      <Tooltip title="Download CSV">
        <IconButton
          color="default"
          disabled={processing}
          sx={{ width: 40, height: 40 }}
          onClick={handleDownloadCsv}
        >
          <FontAwesomeIcon icon={processing ? faHourglassHalf : faDownload} size="xs" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default EntitySearchHeaderCsvButton;
