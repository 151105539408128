import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildServiceCompletionItemSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'serviceCompletionItem',
    headerName: 'Completion Item',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'label',
        headerName: 'Label',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'isAlwaysRequired',
        headerName: 'Always Required',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'targetType.name',
        headerName: 'Target Type',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'owner.name',
        headerName: 'Owner',
        sortable: true,
      },
    ],
  },
];
