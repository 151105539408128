import { gql } from '@apollo/client';
import { ACCOUNTING_TASK_CODE_DELETE_DIALOG_ACCOUNTING_TASK_CODE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_ACCOUNTING_TASK_CODE = gql`
  mutation DeleteAccountingTaskCode($where: AccountingTaskCodeWhereUniqueInput!) {
    deleteAccountingTaskCode(where: $where) {
      id
      ...AccountingTaskCodeDeleteDialogAccountingTaskCodeFragment
    }
  }
  ${ACCOUNTING_TASK_CODE_DELETE_DIALOG_ACCOUNTING_TASK_CODE_FRAGMENT}
`;
