import { useMutation } from '@apollo/client';
import { faCircle, faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { PAUSE_WORK_ORDER_ISSUE } from 'app/shared-components/WorkOrderIssue/WorkOrderIssuePauseDialog';
import { RESUME_WORK_ORDER_ISSUE } from 'app/shared-components/WorkOrderIssue/WorkOrderIssueResumeDialog';
import { COMPLETE_WORK_ORDER_ISSUE_REVIEW } from 'app/shared-components/WorkOrderIssueReview/WorkOrderIssueReviewCompleteDialog';
import pLimit from 'p-limit';
import { useState } from 'react';
import {
  WorkOrderIssueBatchActionDialogCompleteReviewForm,
  WorkOrderIssueBatchActionDialogPauseIssueForm,
  WorkOrderIssueBatchActionDialogResumeIssueForm,
} from './components';

const limit = pLimit(1);

const WorkOrderIssueBatchActionDialog = ({
  actionType,
  selectedRows,
  onClose,
  onProcessingComplete,
}) => {
  const [currentSelectedRow, setCurrentSelectedRow] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processorOutput, setProcessorOutput] = useState([]);

  const [completeWorkOrderIssueReview] = useMutation(COMPLETE_WORK_ORDER_ISSUE_REVIEW);
  const [pauseWorkOrderIssue] = useMutation(PAUSE_WORK_ORDER_ISSUE);
  const [resumeWorkOrderIssue] = useMutation(RESUME_WORK_ORDER_ISSUE);

  const handleIsValidChanged = (_isValid) => setIsValid(_isValid);

  const handleSubmit = async (data) => {
    try {
      setProcessing(true);

      await Promise.all(
        selectedRows?.map((selectedRow) =>
          limit(
            () =>
              // eslint-disable-next-line no-async-promise-executor
              new Promise(async (resolveSelectedRow) => {
                setCurrentSelectedRow(selectedRow);

                let result;

                try {
                  if (actionType === 'COMPLETE_REVIEW') {
                    await completeWorkOrderIssueReview({
                      variables: {
                        where: { id: selectedRow.workOrder?.serviceTicket?.id },
                        data: {
                          id: selectedRow.openWorkOrderIssueReview?.id,
                          decision: {
                            id: data.decision?.value,
                          },
                        },
                      },
                    });
                  } else if (actionType === 'PAUSE_ISSUE') {
                    await pauseWorkOrderIssue({
                      variables: {
                        where: { id: selectedRow.workOrder?.serviceTicket?.id },
                        data: {
                          id: selectedRow.id,
                          scheduledResumption: data.resumeAt
                            ? {
                                resumeAt: data.resumeAt,
                                workOrderIssue: { id: selectedRow.id },
                              }
                            : undefined,
                        },
                      },
                    });
                  } else if (actionType === 'RESUME_ISSUE') {
                    await resumeWorkOrderIssue({
                      variables: {
                        where: { id: selectedRow.workOrder?.serviceTicket?.id },
                        data: {
                          id: selectedRow.id,
                          scheduledResumption: data.resumeAt
                            ? {
                                resumeAt: data.resumeAt,
                                workOrderIssue: { id: selectedRow.id },
                              }
                            : undefined,
                        },
                      },
                    });
                  }

                  result = 'SUCCESS';
                } catch (err) {
                  result = 'ERROR';
                }

                await new Promise((awake) => setTimeout(awake, 500));

                setCurrentSelectedRow(null);
                setProcessorOutput((prev) => [...(prev || []), { id: selectedRow.id, result }]);

                resolveSelectedRow();
              })
          )
        )
      );

      if (typeof onProcessingComplete === 'function') {
        onProcessingComplete({ processorOutput });
      }
    } catch (err) {
      //
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        {actionType === 'COMPLETE_REVIEW' && 'Complete Work Order Issue Review(s)'}
        {actionType === 'PAUSE_ISSUE' && 'Pause Work Order Issue(s)'}
        {actionType === 'RESUME_ISSUE' && 'Resume Work Order Issue(s)'}
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
        <div className="px-24 border-b-1">
          {processing ? (
            <div className="py-24">
              <Alert className="mb-24" severity="info">
                Please do not close this window or click the back button on your browser.
              </Alert>

              <div className="flex flex-row items-center mb-12">
                <Typography className="flex-1 text-16 font-500 truncate">
                  {`Processing: ${currentSelectedRow?.serviceIssue?.name} ...`}
                </Typography>

                <Typography className="ml-12 text-16 font-600">
                  {processorOutput?.length} / {selectedRows?.length}
                </Typography>
              </div>

              <div>
                <LinearProgress
                  color="info"
                  variant="determinate"
                  value={Math.min((processorOutput?.length / selectedRows?.length) * 100, 100)}
                />
              </div>
            </div>
          ) : (
            <>
              {processorOutput?.length ? (
                <>
                  <div className="py-24">
                    <Alert
                      action={
                        <div className="flex items-center h-full">
                          <Tooltip title="Success">
                            <Chip
                              avatar={<FontAwesomeIcon icon={faCheck} />}
                              className="ml-12"
                              color="success"
                              label={
                                processorOutput?.filter(({ result }) => result === 'SUCCESS').length
                              }
                              size="small"
                              sx={{ '& .MuiChip-avatar': { color: 'white' } }}
                            />
                          </Tooltip>

                          <Tooltip title="Error">
                            <Chip
                              avatar={<FontAwesomeIcon icon={faXmark} />}
                              className="ml-12"
                              color="error"
                              label={
                                processorOutput?.filter(({ result }) => result === 'ERROR').length
                              }
                              size="small"
                              sx={{ '& .MuiChip-avatar': { color: 'white' } }}
                            />
                          </Tooltip>
                        </div>
                      }
                      severity="info"
                    >
                      <AlertTitle>Processing Complete</AlertTitle>
                      {`A total of ${processorOutput?.length} work order issues where processed.`}
                    </Alert>
                  </div>
                </>
              ) : (
                <>
                  {actionType === 'COMPLETE_REVIEW' && (
                    <WorkOrderIssueBatchActionDialogCompleteReviewForm
                      onIsValidChanged={handleIsValidChanged}
                      onSubmit={handleSubmit}
                    />
                  )}

                  {actionType === 'PAUSE_ISSUE' && (
                    <WorkOrderIssueBatchActionDialogPauseIssueForm
                      onIsValidChanged={handleIsValidChanged}
                      onSubmit={handleSubmit}
                    />
                  )}

                  {actionType === 'RESUME_ISSUE' && (
                    <WorkOrderIssueBatchActionDialogResumeIssueForm
                      onIsValidChanged={handleIsValidChanged}
                      onSubmit={handleSubmit}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className="flex-1 overflow-y-auto">
          {selectedRows?.length ? (
            <List disablePadding>
              {selectedRows?.map((selectedRow) => {
                const selectedRowServiceTicket = selectedRow?.workOrder?.serviceTicket;
                const selectedRowOutput = processorOutput?.find(({ id }) => id === selectedRow.id);

                return (
                  <ListItem divider key={selectedRow.id} sx={{ pl: '24px' }}>
                    <ListItemText
                      primary={selectedRow.serviceIssue?.name}
                      secondary={`${selectedRowServiceTicket?.facility?.franchiseNumber} ${selectedRowServiceTicket?.facility?.name}`}
                    />

                    <div className="ml-12">
                      {currentSelectedRow?.id === selectedRow.id ? (
                        <CircularProgress size={24} />
                      ) : (
                        <>
                          {!selectedRowOutput && <FontAwesomeIcon icon={faCircle} size="xl" />}

                          {selectedRowOutput?.result === 'SUCCESS' && (
                            <FontAwesomeIcon
                              className="text-green"
                              icon={faCircleCheck}
                              size="xl"
                            />
                          )}

                          {selectedRowOutput?.result === 'ERROR' && (
                            <FontAwesomeIcon className="text-red" icon={faCircleXmark} size="xl" />
                          )}
                        </>
                      )}
                    </div>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div className="flex items-center justify-center w-full py-24">
              <Typography className="text-24">No Selected Rows Found</Typography>
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        {processorOutput?.length && !processing ? (
          <>
            <Button color="secondary" onClick={onClose} variant="contained">
              Done
            </Button>
          </>
        ) : (
          <>
            <Button color="primary" disabled={processing} onClick={onClose} variant="contained">
              Cancel
            </Button>

            <Button
              color="secondary"
              disabled={!isValid || processing}
              form="work-order-issue-batch-action-form"
              type="submit"
              variant="contained"
            >
              {actionType === 'COMPLETE_REVIEW' && 'Complete'}
              {actionType === 'PAUSE_ISSUE' && 'Pause'}
              {actionType === 'RESUME_ISSUE' && 'Resume'}
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueBatchActionDialog;
