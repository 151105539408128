import { useLazyQuery, useMutation } from '@apollo/client';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { IssueTemplateHitsListOption } from 'app/shared-components/IssueTemplate';
import { ProgramHitsListOption } from 'app/shared-components/Program';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ProgramCampaignCreateDialogFacilities } from './components';
import {
  CREATE_PROGRAM_CAMPAIGN,
  FETCH_PROGRAM_CAMPAIGN_CREATE_DIALOG_PROGRAM_DATA,
  FETCH_PROGRAM_CAMPAIGN_CREATE_DIALOG_SERVICE_RESPONSE_SEVERITY_DATA,
} from './queries';

const selectableFacilitiesFieldName = 'selectableFacilities';

const defaultValues = {
  description: '',
  facilities: [],
  issueTemplates: [],
  name: '',
  program: null,
  [selectableFacilitiesFieldName]: [],
};

const schema = yup.object().shape({
  description: yup.string().nullable().optional(),
  facilities: yup
    .array()
    .of(
      yup.object().shape({
        completeBy: yup.date().nullable().required('A complete by date must be provided'),
        facility: yup.object().nullable().required('A facility must be provided.'),
        isNotToExceedAmountZeroedOut: yup.boolean().required(),
        isRequestForQuote: yup.boolean().required(),
        serviceProvision: yup.object().nullable().required('A service provision must be provided.'),
        serviceResponseSeverity: yup
          .object()
          .nullable()
          .required('A response severity must be provided.'),
      })
    )
    .min(1, 'Select at least one campaign facility.'),
  issueTemplates: yup.array().min(1, 'Select at least one issue template.').required(),
  name: yup.string().nullable().required('A name must be provided.'),
  program: yup.object().nullable().required('A program must be provided.'),
  [selectableFacilitiesFieldName]: yup
    .array()
    .min(1, 'Select at least one campaign facility.')
    .required(),
});

const ProgramCampaignCreateDialog = ({ programId, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const [fetchedProgram, setFetchedProgram] = useState(false);
  const [fetchedServiceResponseSeverity, setFetchedServiceResponseSeverity] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchProgramCampaignCreateDialogProgramData,
    {
      data: programCampaignCreateDialogProgramData,
      loading: programCampaignCreateDialogProgramLoading,
      refetch: programCampaignCreateDialogProgramRefetch,
    },
  ] = useLazyQuery(FETCH_PROGRAM_CAMPAIGN_CREATE_DIALOG_PROGRAM_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetchedProgram(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Program Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    fetchProgramCampaignCreateDialogServiceResponseSeverityData,
    {
      data: programCampaignCreateDialogServiceResponseSeverityData,
      loading: programCampaignCreateDialogServiceResponseSeverityLoading,
      refetch: programCampaignCreateDialogServiceResponseSeverityRefetch,
    },
  ] = useLazyQuery(FETCH_PROGRAM_CAMPAIGN_CREATE_DIALOG_SERVICE_RESPONSE_SEVERITY_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetchedServiceResponseSeverity(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Response Severity Data',
          variant: 'error',
        })
      );
    },
  });

  const [createProgramCampaign, { loading: createProgramCampaignLoading }] = useMutation(
    CREATE_PROGRAM_CAMPAIGN,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Program Campaign Successfully Created',
            variant: 'success',
          })
        );

        if (typeof onSuccess === 'function') {
          onSuccess(data?.createProgramCampaign);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Program Campaign', variant: 'error' }));
      },
    }
  );

  const defaultServiceResponseSeverity = useMemo(() => {
    const result =
      programCampaignCreateDialogServiceResponseSeverityData?.serviceResponseSeverities?.find(
        ({ name }) => name === 'Standard'
      );

    return (
      result && {
        result,
        label: result?.name,
        value: result?.id,
      }
    );
  }, [programCampaignCreateDialogServiceResponseSeverityData?.serviceResponseSeverities]);

  const loading = useMemo(
    () =>
      [
        createProgramCampaignLoading,
        programCampaignCreateDialogProgramLoading,
        programCampaignCreateDialogServiceResponseSeverityLoading,
      ].includes(true),
    [
      createProgramCampaignLoading,
      programCampaignCreateDialogProgramLoading,
      programCampaignCreateDialogServiceResponseSeverityLoading,
    ]
  );

  const program = useMemo(
    () => programCampaignCreateDialogProgramData?.program,
    [programCampaignCreateDialogProgramData?.program]
  );

  useEffect(() => {
    if (program) {
      const formData = {
        ...defaultValues,
        program,
      };

      reset(formData);
    }
  }, [reset, program]);

  useEffect(() => {
    if (fetchProgramCampaignCreateDialogProgramData && !fetchedProgram && programId) {
      fetchProgramCampaignCreateDialogProgramData({
        variables: { where: { id: programId } },
      });
    }
  }, [fetchProgramCampaignCreateDialogProgramData, fetchedProgram, programId]);

  useEffect(() => {
    if (
      fetchProgramCampaignCreateDialogServiceResponseSeverityData &&
      !fetchedServiceResponseSeverity
    ) {
      fetchProgramCampaignCreateDialogServiceResponseSeverityData();
    }
  }, [fetchProgramCampaignCreateDialogServiceResponseSeverityData, fetchedServiceResponseSeverity]);

  const onSubmit = async (data) => {
    try {
      await createProgramCampaign({
        variables: {
          where: { id: data.program?.id },
          data: {
            description: data.description,
            facilities: data.facilities?.map((campaignFacility) => ({
              completeBy:
                campaignFacility.completeBy && moment(campaignFacility.completeBy).format(),
              facility: { id: campaignFacility?.facility?.value },
              isNotToExceedAmountZeroedOut: campaignFacility?.isNotToExceedAmountZeroedOut,
              isRequestForQuote: campaignFacility?.isRequestForQuote,
              serviceProvision: { id: campaignFacility?.serviceProvision?.id },
              serviceResponseSeverity: { id: campaignFacility?.serviceResponseSeverity?.value },
            })),
            issueTemplates: data.issueTemplates?.map((issueTemplate) => ({
              id: issueTemplate?.id,
            })),
            name: data.name,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Program Campaign
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="program-campaign-create-form"
            name="program-campaign-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={errors?.description}
                  fullWidth
                  helperText={errors?.description?.message}
                  maxRows={4}
                  minRows={4}
                  multiline
                  placeholder="Enter Description..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="program"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  components={{ Option: ProgramHitsListOption }}
                  error={errors?.program}
                  filter={[`status.enum = 'ACTIVE'`]}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  indexName="programs"
                  isClearable
                  isDisabled={!!programId}
                  isMulti={false}
                  placeholder="Select Program ..."
                  sort={['name:asc']}
                  onChange={(params) => {
                    setValue('issueTemplates', []);
                    setValue(selectableFacilitiesFieldName, []);

                    field.onChange(params);
                  }}
                />
              )}
            />

            {watchFields?.program && (
              <>
                <Controller
                  control={control}
                  name="issueTemplates"
                  render={({ field }) => (
                    <EntitySearchSelectFieldV2
                      {...field}
                      className="mb-24"
                      components={{ Option: IssueTemplateHitsListOption }}
                      error={errors?.issueTemplates}
                      filter={[
                        `id IN [${watchFields?.program?.issueTemplates.map(
                          ({ id, issueTemplate }) => `'${issueTemplate?.id || id}'`
                        )}]`,
                      ]}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      indexName="issue_templates"
                      isClearable
                      isDisabled={false}
                      isMulti
                      placeholder="Select Issue Templates..."
                      nameField="name"
                      sort={['name:asc']}
                      onChange={(params) => {
                        setValue(selectableFacilitiesFieldName, []);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                <Divider sx={{ mb: 3 }} />

                <ProgramCampaignCreateDialogFacilities
                  defaultServiceResponseSeverity={defaultServiceResponseSeverity}
                  issueTemplatesFieldName="issueTemplates"
                  name="facilities"
                  program={watchFields?.program}
                  selectableFacilitiesFieldName={selectableFacilitiesFieldName}
                />
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex flex-1 flex-col sm:flex-row">
            <Button
              className="mb-8 sm:mb-0"
              color="primary"
              form="program-campaign-create-form"
              startIcon={
                <FontAwesomeIcon
                  className={clsx({ 'text-green': isValid, 'text-red': !isValid })}
                  icon={isValid ? faCircleCheck : faCircleXmark}
                />
              }
              type="submit"
              variant="contained"
              onClick={(event) => {
                if (isValid) {
                  event.preventDefault();
                }
              }}
            >
              Preflight
            </Button>
          </div>

          <div className="flex flex-col sm:flex-row">
            <Button className="mb-8 sm:mb-0" color="primary" onClick={onClose} variant="contained">
              Cancel
            </Button>

            <Button
              className="mb-8 ml-0 sm:mb-0 sm:ml-8"
              color="secondary"
              disabled={_.isEmpty(dirtyFields) || !isValid || loading}
              form="program-campaign-create-form"
              type="submit"
              variant="contained"
            >
              Create + Send
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
};

export default ProgramCampaignCreateDialog;
