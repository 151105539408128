// eslint-disable-next-line import/prefer-default-export
export const accountingProjectSearchScreenFilterDefs = [
  {
    name: 'Accounting Project',
    refinements: [
      {
        attribute: 'acceptedCommonStandardRateTypes.commonStandardRateType.name',
        label: 'Standard Rate Type',
        operator: 'or',
      },
      {
        attribute: 'accountingWorkOrderTypes.accountingWorkOrderType.name',
        label: 'Accounting Work Order Type',
        operator: 'or',
      },
      {
        attribute: 'codingType.name',
        label: 'Coding Type',
        operator: 'or',
      },
      {
        attribute: 'numberSuffix.name',
        label: 'Number Suffix',
        operator: 'or',
      },
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
    ],
  },
];
