import { gql } from '@apollo/client';
import { ACCOUNTING_EXPENSE_TYPE_DELETE_DIALOG_ACCOUNTING_EXPENSE_TYPE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_ACCOUNTING_EXPENSE_TYPE = gql`
  mutation DeleteAccountingExpenseType($where: AccountingExpenseTypeWhereUniqueInput!) {
    deleteAccountingExpenseType(where: $where) {
      id
      ...AccountingExpenseTypeDeleteDialogAccountingExpenseTypeFragment
    }
  }
  ${ACCOUNTING_EXPENSE_TYPE_DELETE_DIALOG_ACCOUNTING_EXPENSE_TYPE_FRAGMENT}
`;
