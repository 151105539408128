import { useMutation } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ACTIVATE_BUDGET_SPECIAL_PROJECT } from './queries';

const BudgetSpecialProjectActivateDialog = ({ budgetSpecialProjectId, onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const [activateBudgetSpecialProject, { loading: activateBudgetSpecialProjectLoading }] =
    useMutation(ACTIVATE_BUDGET_SPECIAL_PROJECT, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Budget Special Project Successfully Activated',
            variant: 'success',
          })
        );

        if (typeof onSuccess === 'function') {
          onSuccess(data?.activateBudgetSpecialProject);
        }
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Activating Budget Special Project', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () => [activateBudgetSpecialProjectLoading].includes(true),
    [activateBudgetSpecialProjectLoading]
  );

  const onSubmit = async (data) => {
    try {
      await activateBudgetSpecialProject({
        variables: {
          where: { id: budgetSpecialProjectId },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Activate Budget Special Project
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ padding: '24px 0' }}>
          Once active; users will be able to select this budget special project based on your
          organization settings; do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={loading}
          form="budget-special-project-activate-form"
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          Activate
        </Button>
      </DialogActions>
    </>
  );
};

export default BudgetSpecialProjectActivateDialog;
