import _ from '@lodash';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildAccountingProjectSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'accountingProject',
    headerName: 'Accounting Project',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'codingType.name',
        headerName: 'Coding Type',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'account',
        headerName: 'Account',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'number',
        headerName: 'Number',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'numberSuffix.name',
        headerName: 'Number Suffix',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'acceptedCommonStandardRateTypes',
        headerName: 'Standard Rate Types',
        sortable: false,
        valueFormatter: ({ value }) => {
          const commonStandardRateTypes = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(commonStandardRateTypes)
            ? _.uniq(commonStandardRateTypes).join(', ')
            : undefined;
        },
        valueGetter: ({ data }) =>
          data?.acceptedCommonStandardRateTypes
            ?.map(({ commonStandardRateType }) => commonStandardRateType)
            ?.filter((el) => el) || undefined,
      },
      {
        cellDataType: 'object',
        field: 'accountingWorkOrderTypes',
        headerName: 'Accounting WOTs',
        sortable: false,
        valueFormatter: ({ value }) => {
          const accountingWorkOrderTypes = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          const maxToShow = 3;
          const overflow = accountingWorkOrderTypes?.slice(maxToShow);

          return !_.isEmpty(accountingWorkOrderTypes)
            ? `${_.uniq(accountingWorkOrderTypes.slice(0, maxToShow)).join(', ')}${
                overflow.length ? ` + ${overflow.length} More` : ''
              }`
            : undefined;
        },
        valueGetter: ({ data }) =>
          data?.accountingWorkOrderTypes
            ?.map(({ accountingWorkOrderType }) => accountingWorkOrderType)
            ?.filter((el) => el) || undefined,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'owner.name',
        headerName: 'Owner',
        sortable: true,
      },
    ],
  },
];
