import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { ServiceCompletionItemTargetTypeSelectField } from 'app/shared-components/ServiceCompletionItemTargetType';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { CREATE_SERVICE_COMPLETION_ITEM } from './queries';
import { ServiceCompletionItemCreateDialogAttachments } from './components';

const defaultValues = {
  attachments: [],
  isAlwaysRequired: false,
  label: '',
  name: '',
  targetType: null,
};

const schema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  isAlwaysRequired: yup.boolean().required(),
  label: yup.string().nullable().required('A label must be provided.'),
  name: yup.string().nullable().required('A name must be provided.'),
  targetType: yup.object().nullable().required('A target type must be provided.'),
});

const ServiceCompletionItemCreateDialog = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createServiceCompletionItem, { loading: createServiceCompletionItemLoading }] =
    useMutation(CREATE_SERVICE_COMPLETION_ITEM, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Completion Item Successfully Created',
            variant: 'success',
          })
        );

        // ROADMAP: Migrate Other Forms to Always Use a Callback
        if (typeof onSuccess === 'function') {
          onSuccess(data?.createServiceCompletionItem);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Completion Item', variant: 'error' }));
      },
    });

  const loading = useMemo(
    () => [createServiceCompletionItemLoading].includes(true),
    [createServiceCompletionItemLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createServiceCompletionItem({
        variables: {
          data: {
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            isAlwaysRequired: data.isAlwaysRequired,
            label: data.label,
            name: data.name,
            targetType: { id: data.targetType.value },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Completion Item
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-completion-item-create-form"
            name="service-completion-item-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Completion Item Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="label"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.label}
                  fullWidth
                  helperText={errors?.label?.message}
                  placeholder="Enter Completion Item Label..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <div className="flex flex-1 items-center mb-24">
              <div className="mr-24">
                <Controller
                  control={control}
                  name="isAlwaysRequired"
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={!!field.value} color="info" />}
                      label="Always Required"
                      labelPlacement="start"
                      sx={{ ml: 0 }}
                    />
                  )}
                />
              </div>

              <div className="flex-1">
                <Controller
                  control={control}
                  name="targetType"
                  render={({ field }) => (
                    <ServiceCompletionItemTargetTypeSelectField
                      {...field}
                      className=""
                      error={errors?.targetType}
                      filters={null}
                      isDisabled={false}
                      isClearable
                      isMulti={false}
                      placeholder="Select a Completion Item Target Type..."
                    />
                  )}
                />
              </div>
            </div>

            <Divider sx={{ mb: 3 }} />

            <ServiceCompletionItemCreateDialogAttachments name="attachments" />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-completion-item-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceCompletionItemCreateDialog;
