import { gql } from '@apollo/client';
import { BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_BUDGET_SPECIAL_PROJECT_FRAGMENT } from './fragments';

export const ADD_BUDGET_SPECIAL_PROJECT_COST_CODE = gql`
  mutation AddBudgetSpecialProjectCostCode(
    $where: BudgetSpecialProjectWhereUniqueInput!
    $data: BudgetSpecialProjectAddCostCodeInput!
  ) {
    addBudgetSpecialProjectCostCode(where: $where, data: $data) {
      id
      ...BudgetSpecialProjectViewScreenBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;

export const ADD_BUDGET_SPECIAL_PROJECT_SERVICE_COMPLETION_ITEM = gql`
  mutation AddBudgetSpecialProjectServiceCompletionItem(
    $where: BudgetSpecialProjectWhereUniqueInput!
    $data: BudgetSpecialProjectAddServiceCompletionItemInput!
  ) {
    addBudgetSpecialProjectServiceCompletionItem(where: $where, data: $data) {
      id
      ...BudgetSpecialProjectViewScreenBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;

export const FETCH_BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_DATA = gql`
  query FetchBudgetSpecialProjectViewScreenData($where: BudgetSpecialProjectWhereUniqueInput!) {
    budgetSpecialProject(where: $where) {
      id
      ...BudgetSpecialProjectViewScreenBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;

export const REMOVE_BUDGET_SPECIAL_PROJECT_COST_CODE = gql`
  mutation RemoveBudgetSpecialProjectCostCode(
    $where: BudgetSpecialProjectWhereUniqueInput!
    $data: BudgetSpecialProjectRemoveCostCodeInput!
  ) {
    removeBudgetSpecialProjectCostCode(where: $where, data: $data) {
      id
      ...BudgetSpecialProjectViewScreenBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;

export const REMOVE_BUDGET_SPECIAL_PROJECT_SERVICE_COMPLETION_ITEM = gql`
  mutation RemoveBudgetSpecialProjectServiceCompletionItem(
    $where: BudgetSpecialProjectWhereUniqueInput!
    $data: BudgetSpecialProjectRemoveServiceCompletionItemInput!
  ) {
    removeBudgetSpecialProjectServiceCompletionItem(where: $where, data: $data) {
      id
      ...BudgetSpecialProjectViewScreenBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;

export const UPDATE_BUDGET_SPECIAL_PROJECT = gql`
  mutation UpdateBudgetSpecialProject(
    $where: BudgetSpecialProjectWhereUniqueInput!
    $data: BudgetSpecialProjectUpdateInput!
  ) {
    updateBudgetSpecialProject(where: $where, data: $data) {
      id
      ...BudgetSpecialProjectViewScreenBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_VIEW_SCREEN_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;
