import { useLazyQuery } from '@apollo/client';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BudgetProjectViewScreenCostCodesTab,
  BudgetProjectViewScreenCompletionItemsTab,
  BudgetProjectViewScreenOverviewTab,
} from './components';
import { FETCH_BUDGET_PROJECT_VIEW_SCREEN_DATA } from './queries';

const BudgetProjectViewScreen = () => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [
    fetchBudgetProjectViewScreenData,
    {
      data: budgetProjectViewScreenData,
      loading: budgetProjectViewScreenLoading,
      refetch: budgetProjectViewScreenRefetch,
    },
  ] = useLazyQuery(FETCH_BUDGET_PROJECT_VIEW_SCREEN_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Budget Project Data',
          variant: 'error',
        })
      );
    },
  });

  const budgetProject = useMemo(
    () => budgetProjectViewScreenData?.budgetProject,
    [budgetProjectViewScreenData?.budgetProject]
  );

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const loading = useMemo(
    () => [budgetProjectViewScreenLoading].includes(true),
    [budgetProjectViewScreenLoading]
  );

  useEffect(() => {
    if (params?.budgetProjectId) {
      fetchBudgetProjectViewScreenData({
        variables: { where: { id: params?.budgetProjectId } },
      });
    }
  }, [fetchBudgetProjectViewScreenData, params?.budgetProjectId]);

  return (
    <>
      {basePath === 'tabs/overview' && (
        <BudgetProjectViewScreenOverviewTab budgetProject={budgetProject} />
      )}

      {basePath === 'tabs/cost-codes' && (
        <BudgetProjectViewScreenCostCodesTab budgetProject={budgetProject} />
      )}

      {basePath === 'tabs/completion-items' && (
        <BudgetProjectViewScreenCompletionItemsTab budgetProject={budgetProject} />
      )}
    </>
  );
};

export default BudgetProjectViewScreen;
