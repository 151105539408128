import { gql } from '@apollo/client';
import { SERVICE_COMPLETION_ITEM_ACTIVATE_DIALOG_SERVICE_COMPLETION_ITEM_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const ACTIVATE_SERVICE_COMPLETION_ITEM = gql`
  mutation ActivateServiceCompletionItem($where: ServiceCompletionItemWhereUniqueInput!) {
    activateServiceCompletionItem(where: $where) {
      id
      ...ServiceCompletionItemActivateDialogServiceCompletionItemFragment
    }
  }
  ${SERVICE_COMPLETION_ITEM_ACTIVATE_DIALOG_SERVICE_COMPLETION_ITEM_FRAGMENT}
`;
