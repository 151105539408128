import { faFile, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import {
  Alert,
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { CommonUppyDashboardModal } from 'app/shared-components/Common/CommonUppy';
import { useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

const ServiceCompletionItemViewDrawerAttachments = ({ disabled, name }) => {
  const [dashboardModalOpen, setDashboardModalOpen] = useState(false);
  const fieldCount = useRef(0);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { fields, append, prepend, remove, swap, move, insert, replace, update } = useFieldArray({
    control,
    name,
  });

  const watchFields = watch(name);

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  const handleDashboardModalRequestClose = () => setDashboardModalOpen(false);

  const handleDashboardModalRequestOpen = () => setDashboardModalOpen(true);

  const handleDashboardModalUploadSuccess = ({ file, response }) => {
    append({
      file,
      commonStoredUpload: { key: _.last(response.uploadURL?.split('/')) },
    });
  };

  return (
    <>
      {errors?.[name] && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More File Attachments Contain Errors'}
        </Alert>
      )}

      <Box>
        <Typography sx={{ mb: 2 }}>File Attachments</Typography>

        <List>
          {_.isEmpty(fields) ? (
            <Alert severity="info" sx={{ mb: 3 }}>
              No File Attachments Added
            </Alert>
          ) : (
            <List disablePadding className="border-t-1">
              {fields.map((field, index) => (
                <ListItem divider key={field.id}>
                  <ListItemAvatar>
                    <Avatar src={field.file?.preview}>
                      <FontAwesomeIcon icon={faFile} />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    className="mr-12"
                    primary={field.file?.name}
                    primaryTypographyProps={{ noWrap: true }}
                    // ROADMAP: Implement or Remove
                    // secondary={field.file?.type}
                    // secondaryTypographyProps={{ noWrap: true }}
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      disabled={disabled}
                      sx={{ width: 40, height: 40 }}
                      onClick={() => remove(index)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </List>

        <div className="flex w-full items-center justify-end my-24">
          <Button
            color="inherit"
            disabled={disabled}
            variant="outlined"
            onClick={handleDashboardModalRequestOpen}
          >
            Add Attachment
          </Button>
        </div>
      </Box>

      <CommonUppyDashboardModal
        dashboardNote="File Size: 20MB; File Types: JPEG/PNG, PDF, and MP4"
        open={dashboardModalOpen}
        uppyId="service-completion-item-view-uppy"
        uppyOptions={{
          restrictions: {
            allowedFileTypes: ['application/pdf', 'image/jpeg', 'image/png', 'video/mp4'],
            maxFileSize: 20 * 1000000,
          },
        }}
        onRequestClose={handleDashboardModalRequestClose}
        onUploadSuccess={handleDashboardModalUploadSuccess}
      />
    </>
  );
};

export default ServiceCompletionItemViewDrawerAttachments;
