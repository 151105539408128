import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BUDGET_SPECIAL_PROJECT_DEACTIVATE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT = gql`
  fragment BudgetSpecialProjectDeactivateDialogBudgetSpecialProjectFragment on BudgetSpecialProject {
    id
    status {
      id
      enum
      name
    }
  }
`;
