export const AG_GRID_KEY_MAPPINGS = {
  KEY_BACKSPACE: 'Backspace',
  KEY_ENTER: 'Enter',
  KEY_F2: 'F2',
  KEY_TAB: 'Tab',
};

export const makeReactSelectStyles = ({ error, mode }) => {
  return {
    control: (base, state) => {
      let borderColor = mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)';
      let hoveredBorderColor = mode === 'dark' ? '#ffffff' : '#000000';

      if (error) {
        borderColor = '#f44336';
        hoveredBorderColor = '#f44336';
      } else if (state.isFocused) {
        borderColor = '#4f46e5';
        hoveredBorderColor = '#4f46e5';
      }

      return {
        ...base,
        backgroundColor: mode === 'dark' ? '#1e293b' : '#ffffff',
        borderColor,
        boxShadow: 'none',
        '&:hover': {
          borderColor: hoveredBorderColor,
        },
      };
    },
    input: (base) => ({
      ...base,
      color: mode === 'dark' ? '#ffffff' : base.color,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: mode === 'dark' ? '#1e293b' : '#ffffff',
    }),
    menuPortal: (base) => ({
      ...base,
      // TODO:
      minWidth: 320,
      zIndex: 9999,
    }),
    option: (base, state) => {
      let color = base?.color;
      let backgroundColor = base?.backgroundColor;

      if (state.isFocused) {
        color = mode === 'dark' ? '#ffffff' : 'rgb(51, 51, 51)';
        backgroundColor = mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : '#deebff';
      } else if (state.isSelected && state.selectProps?.disableHighlightSelected) {
        color = mode === 'dark' ? '#ffffff' : 'rgb(51, 51, 51)';
        backgroundColor = 'transtransparent';
      }

      return { ...base, color, backgroundColor };
    },
    multiValue: (base) => ({
      ...base,
      backgroundColor: mode === 'dark' ? '#4f46e5' : base.backgroundColor,
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: mode === 'dark' ? '#ffffff' : base.color,
    }),
    singleValue: (base) => ({
      ...base,
      color: mode === 'dark' ? '#ffffff' : 'rgb(51, 51, 51)',
    }),
    valueContainer: (base) => ({
      ...base,
      lineHeight: 'initial',
    }),
  };
};
