import { gql } from '@apollo/client';
import { BUDGET_CREATE_DIALOG_BUDGET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BUDGET = gql`
  mutation CreateBudget($data: BudgetCreateInput!) {
    createBudget(data: $data) {
      id
      ...BudgetCreateDialogBudgetFragment
    }
  }
  ${BUDGET_CREATE_DIALOG_BUDGET_FRAGMENT}
`;
