// eslint-disable-next-line import/prefer-default-export
export const workOrderIssueSearchScreenFilterDefs = [
  {
    name: 'Work Order Issue',
    refinements: [
      {
        attribute: 'budget.name',
        label: 'Budget',
        operator: 'or',
      },
      {
        attribute: 'budgetProject.name',
        label: 'Budget Project',
        operator: 'or',
      },
      {
        attribute: 'budgetSpecialProject.name',
        label: 'Budget Special Project',
        operator: 'or',
      },
      {
        attribute: 'isLocked',
        label: 'Locked',
        operator: 'or',
      },
      {
        attribute: 'serviceIssue.serviceResponseSeverity.name',
        label: 'Severity',
        operator: 'or',
      },
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Service Ticket',
    refinements: [
      {
        attribute: 'workOrder.serviceTicket.serviceProvision.organizationConnection.consumer.name',
        label: 'Consumer',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.phase.name',
        label: 'Phase',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.programCampaign.program.name',
        label: 'Program',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.programCampaign.name',
        label: 'Program Campaign',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.isRequestForQuote',
        label: 'RFQ',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.status.name',
        label: 'Status',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.serviceProvision.organizationConnection.vendor.name',
        label: 'Vendor',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Facility',
    refinements: [
      {
        attribute: 'workOrder.serviceTicket.facility.businessConsultantContact.name',
        label: 'Business Consultant',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.esrContact.name',
        label: 'ESR',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.fieldConsultantContact.name',
        label: 'Field Consultant',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.address.city',
        label: 'City',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.leadAdvisorContact.name',
        label: 'Lead Advisor',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.marketTeam',
        label: 'Market Team',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.name',
        label: 'Name',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.operatorContact.name',
        label: 'Operator',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.projectAdministratorContact.name',
        label: 'Project Administrator',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.projectCoordinatorContact.name',
        label: 'Project Coordinator',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.regionalCoordinatorContact.name',
        label: 'Regional Coordinator',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.address.region',
        label: 'Region',
        operator: 'or',
      },
      {
        attribute: 'workOrder.serviceTicket.facility.regionalTeam',
        label: 'Regional Team',
        operator: 'or',
      },
    ],
  },
];
