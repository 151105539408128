import { ProgramCampaignViewScreen } from 'app/screens/ProgramCampaignViewScreen';
import { Navigate, Route, Routes } from 'react-router-dom';

const ProgramCampaignsViewPage = () => {
  return (
    <Routes>
      <Route element={<ProgramCampaignViewScreen />} path=":sectionName/:viewName" />
      <Route element={<ProgramCampaignViewScreen />} path=":sectionName/:viewName/view/:viewId" />

      <Route path="*" element={<Navigate to="tabs/overview" replace />} />
    </Routes>
  );
};

export default ProgramCampaignsViewPage;
