import { faShapes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const AccountingTaskCodeHitsListOption = (props) => {
  // ROADMAP: Switch to `props.data` when `EntitySearchSelectFieldV2` is Fully Deployed
  const accountingTaskCode = props.data?.hit || props.data;

  const option = useMemo(() => {
    if (!accountingTaskCode) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faShapes} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <div className="flex flex-1">
              <div className="flex flex-col">
                <Typography className="mt-1 text-12" variant="body2">
                  {`${accountingTaskCode.name}`}
                </Typography>
              </div>
            </div>
          }
          secondary={<Typography variant="body2">{`${accountingTaskCode.number}`}</Typography>}
        />
      </ListItem>
    );
  }, [accountingTaskCode]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default AccountingTaskCodeHitsListOption;
