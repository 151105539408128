import { faFile, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faFileImage, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import {
  Alert,
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { CommonUppyDashboardModal } from 'app/shared-components/Common/CommonUppy';
import { useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import WorkOrderIssueCompleteDialogExistingAttachmentsPopover from './WorkOrderIssueCompleteDialogExistingAttachmentsPopover';

const WorkOrderIssueCompleteDialogWorkOrderIssueAttachments = ({
  existingAttachments,
  label,
  name,
  referenceAttachments,
  serviceCompletionItem,
}) => {
  const [dashboardModalOpen, setDashboardModalOpen] = useState(false);
  const [existingAttachmentsMenuAnchorEl, setExistingAttachmentsMenuAnchorEl] = useState(null);
  const existingAttachmentsMenuOpen = Boolean(existingAttachmentsMenuAnchorEl);
  const fieldCount = useRef(0);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { fields, append, prepend, remove, swap, move, insert, replace, update } = useFieldArray({
    control,
    name,
  });

  const watchFields = watch(name);

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  const handleCloseExistingAttachmentsMenu = (event) => setExistingAttachmentsMenuAnchorEl(null);

  const handleDashboardModalRequestClose = () => setDashboardModalOpen(false);

  const handleDashboardModalRequestOpen = () => setDashboardModalOpen(true);

  const handleDashboardModalUploadSuccess = ({ file, response }) => {
    append({
      commonStoredUpload: { key: _.last(response.uploadURL?.split('/')) },
      file,
      isCompletionAttachment: true,
      sourceServiceCompletionItem: serviceCompletionItem,
    });
  };

  const handleOpenExistingAttachmentsMenu = (event) =>
    setExistingAttachmentsMenuAnchorEl(event.currentTarget);

  const handleOpenReferenceAttachmentWindow = ({ referenceAttachmentUrl }) => {
    window.open(referenceAttachmentUrl, '_blank', 'noreferrer');
  };

  const handleSelectExistingAttachment = (existingAttachment) => {
    let commonStoredObject;

    if (existingAttachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
      commonStoredObject = existingAttachment.commonStoredImage;
    } else if (existingAttachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
      commonStoredObject = existingAttachment.commonStoredPdf;
    } else if (existingAttachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
      commonStoredObject = existingAttachment.commonStoredVideo;
    }

    if (commonStoredObject) {
      append({
        commonStoredUpload: {
          key: commonStoredObject?.commonStoredUpload?.key,
        },
        file: {
          name: commonStoredObject?.name,
          preview: commonStoredObject?.thumbnailImageUrl,
        },
        isCompletionAttachment: true,
        sourceServiceCompletionItem: serviceCompletionItem,
      });
    }
  };

  return (
    <>
      {!_.isEmpty(_.get(errors, name)) && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More File Attachments Contain Errors'}
        </Alert>
      )}

      <Box>
        <Typography sx={{ mb: 2 }}>{label}:</Typography>

        {!_.isEmpty(referenceAttachments) && (
          <div className="flex flex-wrap items-center mb-16">
            {referenceAttachments?.map((referenceAttachment) => (
              <div key={referenceAttachment.id}>
                {referenceAttachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE' && (
                  <IconButton
                    onClick={() => {
                      handleOpenReferenceAttachmentWindow({
                        referenceAttachmentUrl:
                          referenceAttachment.commonStoredImage?.originalImageUrl,
                      });
                    }}
                  >
                    <Avatar
                      src={referenceAttachment.commonStoredImage?.thumbnailImageUrl}
                      sx={{ border: '2px solid', width: 42, height: 42 }}
                    >
                      <FontAwesomeIcon icon={faFileImage} />
                    </Avatar>
                  </IconButton>
                )}

                {referenceAttachment.commonStoredType?.enum === 'COMMON_STORED_PDF' && (
                  <IconButton
                    onClick={() => {
                      handleOpenReferenceAttachmentWindow({
                        referenceAttachmentUrl: referenceAttachment.commonStoredPdf?.originalPdfUrl,
                      });
                    }}
                  >
                    <Avatar
                      src={referenceAttachment.commonStoredPdf?.thumbnailImageUrl}
                      sx={{ border: '2px solid', width: 42, height: 42 }}
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Avatar>
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        )}

        <List>
          {_.isEmpty(fields) ? (
            <Alert severity="info" sx={{ mb: 3 }}>
              No File Attachments Added
            </Alert>
          ) : (
            <List disablePadding className="border-t-1">
              {fields.map((field, index) => (
                <ListItem divider key={field.id}>
                  <ListItemAvatar>
                    <Avatar src={field.file.preview}>
                      <FontAwesomeIcon icon={faFile} />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    className="mr-12"
                    primary={field.file.name}
                    primaryTypographyProps={{ noWrap: true }}
                    // ROADMAP: Implement or Remove
                    // secondary={field.file.type}
                    // secondaryTypographyProps={{ noWrap: true }}
                  />

                  <ListItemSecondaryAction>
                    <IconButton sx={{ width: 40, height: 40 }} onClick={() => remove(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </List>

        <div className="flex w-full items-center justify-end my-24">
          <Button
            className="mr-12"
            color="inherit"
            disabled={false}
            variant="outlined"
            onClick={handleOpenExistingAttachmentsMenu}
          >
            Use Existing
          </Button>

          <WorkOrderIssueCompleteDialogExistingAttachmentsPopover
            existingAttachments={existingAttachments}
            PopoverProps={{
              anchorEl: existingAttachmentsMenuAnchorEl,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              open: existingAttachmentsMenuOpen,
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              onClose: handleCloseExistingAttachmentsMenu,
            }}
            onSelectExistingAttachment={handleSelectExistingAttachment}
          />

          <Button
            color="inherit"
            disabled={false}
            variant="outlined"
            onClick={handleDashboardModalRequestOpen}
          >
            Add Attachment
          </Button>
        </div>
      </Box>

      <CommonUppyDashboardModal
        dashboardNote="File Size: 20MB; File Types: JPEG/PNG, PDF, and MP4"
        open={dashboardModalOpen}
        uppyId="work-order-issue-complete-uppy"
        uppyOptions={{
          restrictions: {
            allowedFileTypes: ['application/pdf', 'image/jpeg', 'image/png', 'video/mp4'],
            maxFileSize: 20 * 1000000,
          },
        }}
        onRequestClose={handleDashboardModalRequestClose}
        onUploadSuccess={handleDashboardModalUploadSuccess}
      />
    </>
  );
};

export default WorkOrderIssueCompleteDialogWorkOrderIssueAttachments;
