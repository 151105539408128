import { useMutation } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DELETE_BUDGET } from './queries';

const BudgetDeleteDialog = ({ budgetId, onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const [deleteBudget, { loading: deleteBudgetLoading }] = useMutation(DELETE_BUDGET, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Budget Successfully Deleted',
          variant: 'success',
        })
      );

      if (typeof onSuccess === 'function') {
        onSuccess(data?.deleteBudget);
      }
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Deleting Budget', variant: 'error' }));
    },
  });

  const loading = useMemo(() => [deleteBudgetLoading].includes(true), [deleteBudgetLoading]);

  const onSubmit = async (data) => {
    try {
      await deleteBudget({
        variables: {
          where: { id: budgetId },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>Delete Budget</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ padding: '24px 0' }}>
          This action cannot be undone; do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={loading}
          form="budget-delete-form"
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default BudgetDeleteDialog;
