import { FormControlLabel, Switch } from '@mui/material';
import { useMemo } from 'react';
import { useToggleRefinement } from 'react-instantsearch';

const EntitySearchToggleRefinement = ({ attribute, label, labelPlacement, off, on, size }) => {
  const { refine, value } = useToggleRefinement({ attribute, on, off });

  const Control = useMemo(
    () => (
      <Switch
        checked={value?.isRefined}
        size={size}
        onChange={(event) => {
          refine({ isRefined: !event.target.checked });
        }}
      />
    ),
    [refine, size, value?.isRefined]
  );

  return (
    <>
      {label ? (
        <FormControlLabel
          control={Control}
          label={label}
          labelPlacement={labelPlacement || 'bottom'}
        />
      ) : (
        Control
      )}
    </>
  );
};

export default EntitySearchToggleRefinement;
