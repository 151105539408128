import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonCurrencyField, CommonDatepicker, CommonSelect } from 'app/shared-components/Common';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { ServiceIssueHitsListOption } from 'app/shared-components/ServiceIssue';
import { ServiceIssueTagSelectField } from 'app/shared-components/ServiceIssueTag';
import { ServiceResponseSeveritySelectField } from 'app/shared-components/ServiceResponseSeverity';
import { ServiceTicketHitsListOption } from 'app/shared-components/ServiceTicket';
import { ServiceVisitHitsListOption } from 'app/shared-components/ServiceVisit';
import { SiteVisitHitsListOption } from 'app/shared-components/SiteVisit';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { WorkOrderIssueCreateDialogServiceIssueAttachments } from './components';
import { CREATE_WORK_ORDER_ISSUE, FETCH_WORK_ORDER_ISSUE_CREATE_DIALOG_DATA } from './queries';

const defaultValues = {
  actionItems: [],
  budget: null,
  budgetProject: null,
  budgetSpecialProject: null,
  completeBy: null,
  isSaveAsIssueTemplate: false,
  issueTemplate: null,
  notToExceedAmount: null,
  saveAsIssueTemplate: {
    description: '',
    name: '',
    tags: [],
  },
  serviceIssue: null,
  serviceTicket: null,
  serviceVisit: null,
  siteVisit: null,
};

const buildSchema = ({ user }) =>
  yup.object().shape({
    actionItems: yup.array().of(
      yup.object().shape({
        costCode: yup
          .object()
          .nullable()
          .when('_user', {
            is: () => ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum),
            then: (schema) => schema.required('A cost code must be provided'),
          }),
        description: yup.string().nullable().optional(),
        notToExceedAmount: yup
          .number()
          .nullable()
          .min(0.01, 'A value must be provided.')
          .when('_user', {
            is: () => ['MANUFACTURER', 'VENDOR'].includes(user?.data?.organization.type.enum),
            then: (schema) => schema.required('A NTE must be provided'),
          }),
      })
    ),
    budget: yup.object().nullable().required('A budget must be provided'),
    budgetProject: yup.object().nullable().required('A budget project must be provided'),
    budgetSpecialProject: yup.object().nullable().optional(),
    completeBy: yup.date().nullable().required('A complete by date must be provided'),
    isSaveAsIssueTemplate: yup.boolean().optional(),
    issueTemplate: yup.object().nullable().optional(),
    notToExceedAmount: yup
      .string()
      .test(
        'numericStringGreaterThanZero',
        'A value must be provided.',
        (value) => !value || (value && Number(value) >= 0.01)
      ),
    saveAsIssueTemplate: yup.mixed().when('isSaveAsIssueTemplate', {
      is: (val) => !!val,
      then: yup.object().shape({
        description: yup.string().nullable().optional(),
        name: yup.string().nullable().required('A name must be provided.'),
        tags: yup
          .array()
          .of(
            yup
              .object()
              .shape({
                __isNew__: yup.boolean().optional(),
                label: yup.string().required(),
                result: yup.object().optional(),
                value: yup.string().required(),
              })
              .required()
          )
          .optional(),
      }),
    }),
    serviceIssue: yup.object().shape({
      connect: yup.mixed().when('mutation', {
        is: (val) => val?.value === 'CONNECT',
        then: yup.object().nullable().required('A service issue must be provided.'),
      }),
      create: yup.mixed().when('mutation', {
        is: (val) => val?.value === 'CREATE',
        then: yup.object().shape({
          attachments: yup
            .array()
            .of(
              yup
                .object()
                .shape({
                  file: yup.mixed().required(),
                  commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
                })
                .required()
            )
            .optional(),
          commonTargetType: yup.object().nullable().required('A target type must be provided.'),
          description: yup.string().nullable().optional(),
          name: yup.string().nullable().required('A name must be provided.'),
          serviceResponseSeverity: yup
            .object()
            .nullable()
            .required('A response severity must be provided.'),
          // ROADMAP: Causes Incorrect Validation State with Uncontrolled Inputs
          // tags: yup
          //   .array()
          //   .of(
          //     yup
          //       .object()
          //       .shape({
          //         __isNew__: yup.boolean().optional(),
          //         label: yup.string().required(),
          //         result: yup.object().optional(),
          //         value: yup.string().required(),
          //       })
          //       .required()
          //   )
          //   .optional(),
          targetFacilityAreas: yup.mixed().when('commonTargetType', {
            is: (val) => val?.result?.enum === 'FACILITY_AREA',
            then: yup
              .array()
              .of(yup.object().required())
              .min(1, 'Select at least one Target Facility Area')
              .required(),
          }),
        }),
      }),
      mutation: yup
        .object()
        .nullable()
        .required('A service issue connection type must be provided.'),
    }),
    serviceTicket: yup.object().nullable().required('A service ticket must be provided.'),
    serviceVisit: yup.mixed().when('serviceTicketPhaseEnum', {
      is: (val) => val === 'IN_PROGRESS',
      then: yup.object().shape({
        connect: yup.mixed().when('mutation', {
          is: (val) => val?.value === 'CONNECT',
          then: yup.object().nullable().required('A service visit must be provided.'),
        }),
        create: yup.mixed().when('mutation', {
          is: (val) => val?.value === 'CREATE',
          then: yup.object().shape({
            window: yup
              .array()
              .test(
                'requireValidArrivalWindow',
                'An arrival window must be provided.',
                (value) =>
                  value?.length === 2 && !_.some(value || [], (date) => !moment(date).isValid())
              ),
          }),
        }),
        mutation: yup.object().nullable().optional(),
      }),
    }),
    siteVisit: yup.mixed().when('serviceTicketPhaseEnum', {
      is: (val) => val === 'QUOTING',
      then: yup.object().shape({
        connect: yup.mixed().when('mutation', {
          is: (val) => val?.value === 'CONNECT',
          then: yup.object().nullable().required('A site visit must be provided.'),
        }),
        create: yup.mixed().when('mutation', {
          is: (val) => val?.value === 'CREATE',
          then: yup.object().shape({
            window: yup
              .array()
              .test(
                'requireValidArrivalWindow',
                'An arrival window must be provided.',
                (value) =>
                  value?.length === 2 && !_.some(value || [], (date) => !moment(date).isValid())
              ),
          }),
        }),
        mutation: yup.object().nullable().optional(),
      }),
    }),
  });

const WorkOrderIssueCreateDialog = ({ serviceTicketId, onClose }) => {
  const apolloClient = useApolloClient();
  const { axiosSearchClient } = useMeilisearch();
  const [defaultBudget, setDefaultBudget] = useState(null);
  const [defaultBudgetDataLoading, setDefaultBudgetDataLoading] = useState(false);
  const [defaultBudgetProject, setDefaultBudgetProject] = useState(null);
  const [defaultCommonTargetType, setDefaultCommonTargetType] = useState(null);
  const [defaultCommonTargetTypeLoading, setDefaultCommonTargetTypeLoading] = useState(false);
  const [defaultServiceResponseSeverity, setDefaultServiceResponseSeverity] = useState(null);
  const [defaultServiceResponseSeverityLoading, setDefaultServiceResponseSeverityLoading] =
    useState(false);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const user = useSelector(selectUser);

  const schema = useMemo(() => buildSchema({ user }), [user]);

  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    ...methods
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueCreateDialogData,
    {
      data: workOrderIssueCreateDialogData,
      loading: workOrderIssueCreateDialogLoading,
      refetch: workOrderIssueCreateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_CREATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  useEffect(() => {
    const getDefaultCommonTargetType = async () => {
      try {
        setDefaultCommonTargetTypeLoading(true);

        const {
          data: { commonTargetTypes },
        } = await apolloClient.query({
          query: gql`
            query GetDefaultCommonTargetType($where: CommonTargetTypeWhereInput!) {
              commonTargetTypes(where: $where) {
                id
                enum
                name
              }
            }
          `,
          variables: { where: {} },
        });

        const result = commonTargetTypes?.find(
          (commonTargetType) => commonTargetType.enum === 'FACILITY_AREA'
        );

        if (result) {
          setDefaultCommonTargetType({
            result,
            label: result?.name,
            value: result?.id,
          });
        }
      } catch (err) {
        //
      } finally {
        setDefaultCommonTargetTypeLoading(false);
      }
    };

    if (apolloClient) {
      getDefaultCommonTargetType();
    }
  }, [apolloClient]);

  useEffect(() => {
    const getDefaultBudgetData = async () => {
      try {
        setDefaultBudgetDataLoading(true);

        const commonBudgetDataFilter = [
          `isDefault = 'true'`,
          `owner.id IN [${watchFields?.serviceTicket?.facility?.organizations
            ?.map(({ organization }) => organization.id)
            .filter((el) => el)}]`,
          `status.enum = 'ACTIVE'`,
        ];

        const {
          data: { hits: budgetHits },
        } = await axiosSearchClient.post('/indexes/budgets/search', {
          limit: 1,
          filter: [
            ...commonBudgetDataFilter,
            `facilities.facility.id = '${watchFields?.serviceTicket?.facility?.id}'`,
          ],
          q: '',
        });

        const _defaultBudget = budgetHits?.[0];

        let _defaultBudgetProject;

        if (_defaultBudget) {
          const {
            data: { hits: budgetProjectHits },
          } = await axiosSearchClient.post('/indexes/budget_projects/search', {
            limit: 1,
            filter: [...commonBudgetDataFilter, `budget.id = '${_defaultBudget?.id}'`],
            q: '',
          });

          _defaultBudgetProject = budgetProjectHits?.[0];
        }

        setDefaultBudget(_defaultBudget);
        setDefaultBudgetProject(_defaultBudgetProject);
      } catch (err) {
        //
      } finally {
        setDefaultBudgetDataLoading(false);
      }
    };

    if (axiosSearchClient) {
      getDefaultBudgetData();
    }
  }, [
    axiosSearchClient,
    watchFields?.serviceTicket?.facility?.id,
    watchFields?.serviceTicket?.facility?.organizations,
  ]);

  useEffect(() => {
    const getDefaultServiceResponseSeverity = async () => {
      try {
        setDefaultServiceResponseSeverityLoading(true);

        const {
          data: { serviceResponseSeverities },
        } = await apolloClient.query({
          query: gql`
            query GetDefaultServiceResponseSeverity($where: ServiceResponseSeverityWhereInput!) {
              serviceResponseSeverities(where: $where) {
                id
                color
                commonTemporalDurationUnit {
                  id
                  enum
                  name
                }
                name
                value
              }
            }
          `,
          variables: { where: {} },
        });

        const result = serviceResponseSeverities?.find(({ name }) => name === 'Standard');

        if (result) {
          setDefaultServiceResponseSeverity({
            result,
            label: result?.name,
            value: result?.id,
          });
        }
      } catch (err) {
        //
      } finally {
        setDefaultServiceResponseSeverityLoading(false);
      }
    };

    if (apolloClient) {
      getDefaultServiceResponseSeverity();
    }
  }, [apolloClient]);

  const [createWorkOrderIssue, { loading: createWorkOrderIssueLoading }] = useMutation(
    CREATE_WORK_ORDER_ISSUE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Created',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Work Order Issue', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () =>
      [
        createWorkOrderIssueLoading,
        defaultBudgetDataLoading,
        defaultCommonTargetTypeLoading,
        defaultServiceResponseSeverityLoading,
        workOrderIssueCreateDialogLoading,
      ].includes(true),
    [
      createWorkOrderIssueLoading,
      defaultBudgetDataLoading,
      defaultCommonTargetTypeLoading,
      defaultServiceResponseSeverityLoading,
      workOrderIssueCreateDialogLoading,
    ]
  );

  const serviceTicket = useMemo(
    () => workOrderIssueCreateDialogData?.serviceTicket,
    [workOrderIssueCreateDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (defaultBudget?.id) {
      setValue('budget', defaultBudget);
    }
  }, [defaultBudget?.id, watchFields?.serviceTicket?.id]);
  useEffect(() => {
    if (defaultBudgetProject?.id) {
      setValue('budgetProject', defaultBudgetProject);
    }
  }, [defaultBudgetProject?.id, watchFields?.serviceTicket?.id]);
  useEffect(() => {
    if (serviceTicket) {
      const formData = {
        ...defaultValues,

        // TODO:
        // budget: defaultBudget ?? null,
        // budgetProject: defaultBudgetProject ?? null,
        // completeBy:
        //   (defaultServiceResponseSeverity &&
        //     moment()
        //       .add(
        //         defaultServiceResponseSeverity?.result?.value,
        //         defaultServiceResponseSeverity?.result?.commonTemporalDurationUnit?.enum
        //       )
        //       .format()) ??
        //   null,

        serviceTicket,
        serviceTicketPhaseEnum: serviceTicket.phase.enum,
      };

      reset(formData);
    }
  }, [reset, serviceTicket]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderIssueCreateDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchWorkOrderIssueCreateDialogData, serviceTicketId]);

  const handleIssueTemplateSelect = async ({ index, issueTemplate }) => {
    const {
      data: { hits },
    } = await axiosSearchClient.post('/indexes/facility_areas/search', {
      filter: [
        `facility.id = '${watchFields?.serviceTicket?.facility?.id}'`,
        `serviceProvisions.serviceProvision.id = '${watchFields?.serviceTicket?.serviceProvision?.id}'`,
      ],
      limit: undefined,
      q: '',
    });

    const targetFacilityAreas = hits?.filter((facilityArea) =>
      issueTemplate?.serviceIssue?.targetFacilityAreaTypes
        .map(({ facilityAreaType }) => facilityAreaType.id)
        .includes(facilityArea.typeId)
    );

    [
      {
        path: 'serviceIssue.mutation',
        value: 'CREATE',
      },
      {
        path: 'isSaveAsIssueTemplate',
        value: false,
      },
      {
        path: 'serviceIssue.create.name',
        value: issueTemplate.serviceIssue?.name ?? '',
      },
      {
        path: 'budget',
        value: issueTemplate.workOrderIssue?.budget,
      },
      {
        path: 'budgetProject',
        value: issueTemplate.workOrderIssue?.budgetProject,
      },
      {
        path: 'budgetSpecialProject',
        value: issueTemplate.workOrderIssue?.budgetSpecialProject,
      },
      {
        path: 'serviceIssue.create.commonTargetType',
        value: {
          label: issueTemplate.serviceIssue?.commonTargetType?.name,
          result: issueTemplate.serviceIssue?.commonTargetType,
          value: issueTemplate.serviceIssue?.commonTargetType?.id,
        },
      },
      {
        path: 'serviceIssue.create.targetFacilityAreas',
        value: targetFacilityAreas,
      },
      {
        path: 'serviceIssue.create.description',
        value: issueTemplate.serviceIssue?.description ?? '',
      },
      {
        path: 'serviceIssue.create.tags',
        value:
          issueTemplate.serviceIssue?.serviceIssueTags?.map((serviceIssueTag) => ({
            label: serviceIssueTag?.name,
            result: serviceIssueTag,
            value: serviceIssueTag?.id,
          })) || [],
      },
      {
        path: 'actionItems',
        value:
          issueTemplate.workOrderIssue?.actionItems?.map((actionItem) => ({
            costCode: actionItem?.costCode,
            description: actionItem?.description ?? '',
            notToExceedAmount: actionItem?.notToExceedAmount || null,
          })) || [],
      },
      {
        path: 'notToExceedAmount',
        value: issueTemplate.workOrderIssue?.notToExceedAmount,
      },
      {
        path: 'serviceIssue.create.attachments',
        value: issueTemplate.serviceIssue?.attachments
          ?.map((attachment, attachmentIndex) => {
            let attachmentsValue;

            if (attachment.commonStoredType?.enum === 'COMMON_STORED_CAD') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredCad?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredCad?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredImage?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredImage?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredPdf?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredPdf?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredVideo?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredVideo?.commonStoredUpload?.key },
              };
            }

            return attachmentsValue;
          })
          .filter((el) => el),
      },
    ].forEach(({ path, value }) => {
      setValue(path, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: false,
      });
    });

    trigger();
  };

  const onSubmit = async (data) => {
    try {
      await createWorkOrderIssue({
        // TODO: Review
        variables: {
          where: { id: data.serviceTicket?.id },
          data: {
            actionItems: data.actionItems?.map((actionItem) => ({
              costCode: actionItem.costCode?.id ? { id: actionItem.costCode?.id } : undefined,
              description: actionItem.description,
              notToExceedAmount: actionItem.notToExceedAmount
                ? Number(actionItem.notToExceedAmount)
                : 0,
            })),
            budget: { id: data.budget?.id },
            budgetProject: { id: data.budgetProject?.id },
            budgetSpecialProject: data.budgetSpecialProject?.id
              ? { id: data.budgetSpecialProject?.id }
              : undefined,
            completeBy: data.completeBy && moment(data.completeBy).format(),
            issueTemplate:
              !data.isSaveAsIssueTemplate && data.issueTemplate
                ? {
                    id: data.issueTemplate.id,
                  }
                : undefined,
            notToExceedAmount: data.notToExceedAmount ? Number(data.notToExceedAmount) : 0,
            saveAsIssueTemplate:
              data.isSaveAsIssueTemplate && data.saveAsIssueTemplate
                ? {
                    description: data.saveAsIssueTemplate.description,
                    name: data.saveAsIssueTemplate.name,
                    tags: data.saveAsIssueTemplate.tags?.map((tag) => ({
                      name: tag.__isNew__ ? tag.value : tag.result?.name,
                    })),
                  }
                : undefined,
            serviceIssue: {
              connect: data.serviceIssue?.connect && {
                id: data.serviceIssue?.connect.id,
              },
              create: data.serviceIssue?.create && {
                attachments: data.serviceIssue.create.attachments?.map((attachment) => ({
                  commonStoredUpload: { key: attachment?.commonStoredUpload.key },
                })),
                commonTargetType: {
                  id: data.serviceIssue.create.commonTargetType?.value,
                },
                description: data.serviceIssue.create.description,
                facility: { id: data.facility?.id },
                name: data.serviceIssue.create.name,
                serviceResponseSeverity: {
                  id: data.serviceIssue.create.serviceResponseSeverity?.value,
                },
                tags: data.serviceIssue.create.tags?.map((tag) => ({
                  name: tag.__isNew__ ? tag.value : tag.result?.name,
                })),
                // ROADMAP: Implement
                // targetAsset: data.serviceIssue.create.targetAsset && {
                //   id: data.serviceIssue.create.targetAsset.value,
                // },
                targetFacilityAreas: data.serviceIssue.create.targetFacilityAreas?.map(
                  (targetFacilityArea) => ({ id: targetFacilityArea.id })
                ),
              },
            },
            ...(data?.serviceTicketPhaseEnum === 'IN_PROGRESS' &&
            (data.serviceVisit?.connect?.value || data.serviceVisit.create?.window)
              ? {
                  serviceVisit: {
                    connect: data.serviceVisit?.connect && {
                      id: data.serviceVisit?.connect?.value,
                    },
                    create: data.serviceVisit?.create && {
                      windowEndAt: _.last(data.serviceVisit.create?.window || []),
                      windowStartAt: _.first(data.serviceVisit.create?.window || []),
                    },
                  },
                }
              : {}),
            ...(data?.serviceTicketPhaseEnum === 'QUOTING' &&
            (data.siteVisit?.connect?.value || data.siteVisit.create?.window)
              ? {
                  siteVisit: {
                    connect: data.siteVisit?.connect && {
                      id: data.siteVisit?.connect?.value,
                    },
                    create: data.siteVisit?.create && {
                      windowEndAt: _.last(data.siteVisit.create?.window || []),
                      windowStartAt: _.first(data.siteVisit.create?.window || []),
                    },
                  },
                }
              : {}),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-create-form"
            name="work-order-issue-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="serviceTicket"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  components={{ Option: ServiceTicketHitsListOption }}
                  error={errors?.serviceTicket}
                  filter={["phase.enum != 'CLOSED'"]}
                  getOptionLabel={(option) => option?.serviceReferenceNumber}
                  getOptionValue={(option) => option?.id}
                  indexName="service_tickets"
                  isClearable
                  // TODO: Review
                  isDisabled={!!serviceTicketId}
                  isMulti={false}
                  placeholder="Select Service Ticket ..."
                  sort={['serviceReferenceNumber:asc']}
                  onChange={(params) => {
                    // Object.entries(defaultValues)
                    //   // .filter(([key, val]) => !['serviceTicket'].includes(key))
                    //   .forEach(([key, val]) => {
                    //     setValue(key, val);
                    //   });

                    // TODO:
                    // reset((prev) => ({ ...prev, serviceTicket: params }));

                    // TODO: Clear Everything
                    // setValue('issueTemplates', []);
                    // setValue(selectableFacilitiesFieldName, []);

                    // TODO:
                    // setValue('budget', defaultBudget ?? null);
                    reset({
                      ...defaultValues,

                      // TODO:
                      // budget: defaultBudget ?? null,
                      // budgetProject: defaultBudgetProject ?? null,
                      // completeBy:
                      //   (defaultServiceResponseSeverity &&
                      //     moment()
                      //       .add(
                      //         defaultServiceResponseSeverity?.result?.value,
                      //         defaultServiceResponseSeverity?.result?.commonTemporalDurationUnit?.enum
                      //       )
                      //       .format()) ??
                      //   null,

                      // serviceTicket,
                      // serviceTicketPhaseEnum: serviceTicket.phase.enum,
                      serviceTicket: params,
                      serviceTicketPhaseEnum: params?.phase?.enum,
                    });

                    // field.onChange(params);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="serviceIssue.mutation"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonSelect
                    {...field}
                    error={errors?.serviceIssue?.mutation}
                    isClearable
                    isDisabled={false}
                    isMulti={false}
                    options={[
                      {
                        label: 'Connect to Existing Service Issue',
                        value: 'CONNECT',
                      },
                      {
                        label: 'Create New Service Issue',
                        value: 'CREATE',
                      },
                    ]}
                    placeholder="Select Service Issue Connection..."
                    onChange={(params) => {
                      // console.log({ params });
                      if (params?.value === 'CONNECT') {
                        setValue('serviceIssue', {
                          connect: null,
                          // TODO:
                          // mutation: params,
                        });
                      } else if (params?.value === 'CREATE') {
                        setValue('serviceIssue', {
                          create: {
                            commonTargetType: defaultCommonTargetType ?? null,
                            description: '',
                            name: '',
                            targetAsset: null,
                            targetFacilityAreas: [],
                            serviceResponseSeverity: defaultServiceResponseSeverity ?? null,
                          },
                          // TODO:
                          // mutation: params,
                        });
                      } else {
                        // TODO:
                        setValue('serviceIssue', null);
                      }

                      // TODO: Clear Everything Else

                      field.onChange(params);
                    }}
                  />
                </div>
              )}
            />

            {watchFields?.serviceIssue?.mutation?.value === 'CONNECT' && (
              <>
                <Controller
                  control={control}
                  name="serviceIssue.connect"
                  render={({ field }) => (
                    <EntitySearchSelectFieldV2
                      {...field}
                      className="mb-24"
                      components={{ Option: ServiceIssueHitsListOption }}
                      error={errors?.serviceIssue?.connect}
                      // TODO: Review
                      filter={[
                        `targetFacilityAreas.facilityArea.facility.id = '${watchFields?.serviceTicket?.facility?.id}'`,
                        `targetFacilityAreas.facilityArea.serviceProvisions.serviceProvision.id = '${watchFields?.serviceTicket?.serviceProvision?.id}'`,
                        `status.enum != 'IN_PROGRESS'`,
                      ]}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      indexName="service_issues"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Service Issue..."
                      sort={['name:asc']}
                      onChange={(params) => {
                        setValue('budget', (params && defaultBudget) ?? null);
                        setValue('budgetProject', (params && defaultBudgetProject) ?? null);
                        setValue('budgetSpecialProject', null);
                        setValue(
                          'serviceIssue.create.serviceResponseSeverity',
                          (params && {
                            label: params.serviceResponseSeverity?.name,
                            result: params.serviceResponseSeverity,
                            value: params.serviceResponseSeverity?.id,
                          }) ??
                            null
                        );
                        setValue(
                          'completeBy',
                          (params &&
                            moment()
                              .add(
                                params?.serviceResponseSeverity?.value,
                                params?.serviceResponseSeverity?.commonTemporalDurationUnit?.enum
                              )
                              .format()) ??
                            null
                        );

                        watchFields?.actionItems?.forEach((actionItem, actionItemIndex) => {
                          setValue(`actionItems[${actionItemIndex}]`, {
                            ...actionItem,
                            costCode: null,
                          });
                        });

                        if (params && watchFields?.serviceTicketPhaseEnum === 'DRAFT') {
                          setValue('notToExceedAmount', params?.estimatedAmount);
                        }

                        field.onChange(params);
                      }}
                    />
                  )}
                />
              </>
            )}

            {watchFields?.serviceIssue?.mutation?.value === 'CREATE' && (
              <>
                {/* TODO: */}
                {/* <ServiceTicketCreateDialogWorkOrderIssueTemplate
                  workOrderIssueFieldName=""
                  onIssueTemplateSelect={({ issueTemplate }) =>
                    handleIssueTemplateSelect({ index: -1, issueTemplate })
                  }
                /> */}

                <Divider sx={{ mb: 3 }} />

                <Controller
                  control={control}
                  name="serviceIssue.create.name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      error={errors?.serviceIssue?.create?.name}
                      fullWidth
                      helperText={errors?.serviceIssue?.create?.name?.message}
                      placeholder="Enter Name..."
                      required
                      variant="outlined"
                    />
                  )}
                />
              </>
            )}

            <Controller
              control={control}
              name="budget"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  error={_.get(errors, 'budget')}
                  // ROADMAP: Limit to Budget Segments
                  filter={[
                    `facilities.facility.id = '${watchFields?.serviceTicket?.facility?.id}'`,
                    `owner.id IN [${watchFields?.serviceTicket?.facility?.organizations
                      ?.map(({ organization }) => organization.id)
                      .filter((el) => el)}]`,
                    `status.enum = 'ACTIVE'`,
                  ]}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  indexName="budgets"
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Budget..."
                  sort={['name:asc']}
                  onChange={(params) => {
                    setValue('budgetProject', null);
                    setValue('budgetSpecialProject', null);

                    // TODO:
                    if (watchFields?.serviceIssue?.mutation?.value === 'CREATE') {
                      setValue('serviceIssue.create.commonTargetType', null);
                      setValue('serviceIssue.create.targetFacilityAreas', []);
                    }

                    watchFields?.actionItems?.forEach((actionItem, actionItemIndex) => {
                      setValue(`actionItems[${actionItemIndex}]`, {
                        ...actionItem,
                        costCode: null,
                      });
                    });

                    field.onChange(params);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="budgetProject"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  error={_.get(errors, 'budgetProject')}
                  filter={[
                    `budget.id = '${watchFields?.budget?.id}'`,
                    `owner.id IN [${watchFields?.serviceTicket?.facility?.organizations
                      ?.map(({ organization }) => organization.id)
                      .filter((el) => el)}]`,
                    `status.enum = 'ACTIVE'`,
                  ]}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  indexName="budget_projects"
                  isClearable
                  isDisabled={!watchFields?.budget}
                  isMulti={false}
                  placeholder="Select Budget Project..."
                  sort={['name:asc']}
                  onChange={(params) => {
                    setValue('budgetSpecialProject', null);

                    watchFields?.actionItems?.forEach((actionItem, actionItemIndex) => {
                      setValue(`actionItems[${actionItemIndex}]`, {
                        ...actionItem,
                        costCode: null,
                      });
                    });

                    field.onChange(params);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="budgetSpecialProject"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  error={_.get(errors, 'budgetSpecialProject')}
                  filter={[
                    `owner.id IN [${watchFields?.serviceTicket?.facility?.organizations
                      ?.map(({ organization }) => organization.id)
                      .filter((el) => el)}]`,
                    `status.enum = 'ACTIVE'`,
                  ]}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  indexName="budget_special_projects"
                  isClearable
                  isDisabled={!watchFields?.budget || !watchFields?.budgetProject}
                  isMulti={false}
                  placeholder="Select Budget Special Project..."
                  sort={['name:asc']}
                  onChange={(params) => {
                    watchFields?.actionItems?.forEach((actionItem, actionItemIndex) => {
                      setValue(`actionItems[${actionItemIndex}]`, {
                        ...actionItem,
                        costCode: null,
                      });
                    });

                    field.onChange(params);
                  }}
                />
              )}
            />

            {watchFields?.serviceIssue?.mutation?.value === 'CREATE' &&
              watchFields?.budget &&
              watchFields?.budgetProject && (
                <>
                  {/* TODO: Review */}
                  <Controller
                    control={control}
                    name="serviceIssue.create.commonTargetType"
                    render={({ field }) => (
                      <CommonTargetTypeSelectField
                        {...field}
                        className="mb-24"
                        error={_.get(errors, 'serviceIssue.create.commonTargetType')}
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Target Type..."
                        onChange={(params) => {
                          setValue('serviceIssue.create.targetFacilityAreas', []);

                          field.onChange(params);
                        }}
                      />
                    )}
                  />

                  {watchFields?.serviceIssue?.create?.commonTargetType?.result?.enum ===
                    'FACILITY_AREA' && (
                    // TODO: Review
                    <Controller
                      control={control}
                      name="serviceIssue.create.targetFacilityAreas"
                      render={({ field }) => (
                        <EntitySearchSelectFieldV2
                          {...field}
                          className="mb-24"
                          error={_.get(errors, 'serviceIssue.create.targetFacilityAreas')}
                          // TODO: Review
                          // ROADMAP: Limit to Budget Segments
                          filter={[
                            `facility.id = '${watchFields?.serviceTicket?.facility?.id}'`,
                            `serviceProvisions.serviceProvision.id = '${watchFields?.serviceTicket?.serviceProvision?.id}'`,
                          ].filter((el) => el)}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          indexName="facility_areas"
                          isClearable
                          isDisabled={false}
                          isMulti
                          placeholder="Select Target Facility Areas..."
                          sort={['name:asc']}
                        />
                      )}
                    />
                  )}

                  {(watchFields?.serviceIssue?.mutation?.value === 'CONNECT' ||
                    watchFields?.serviceIssue?.mutation?.value === 'CREATE') &&
                    watchFields?.budget &&
                    watchFields?.budgetProject && (
                      <div className="flex flex-1 flex-col sm:flex-row mb-24">
                        <Controller
                          control={control}
                          name="serviceIssue.create.serviceResponseSeverity"
                          render={({ field }) => (
                            <ServiceResponseSeveritySelectField
                              {...field}
                              className="flex-1 mr-0 sm:mr-12 mb-12 sm:mb-0"
                              error={_.get(
                                errors,
                                'serviceIssue.create.serviceResponseSeverity'
                              )}
                              filters={null}
                              isClearable
                              isDisabled={watchFields?.serviceIssue?.mutation?.value !== 'CREATE'}
                              isMulti={false}
                              placeholder="Select Response Severity..."
                              onChange={(params) => {
                                setValue(
                                  'completeBy',
                                  params
                                    ? moment()
                                        .add(
                                          params?.result.value,
                                          params?.result.commonTemporalDurationUnit.enum
                                        )
                                        .format()
                                    : null,
                                  { shouldValidate: true }
                                );

                                field.onChange(params);
                              }}
                            />
                          )}
                        />

                        <Controller
                          control={control}
                          name="completeBy"
                          render={({ field }) => (
                            <div className="flex-1 ml-0 sm:ml-12 mt-12 sm:mt-0">
                              <CommonDatepicker
                                {...field}
                                controls={['calendar']}
                                disabled={
                                  !watchFields?.serviceIssue?.create?.serviceResponseSeverity
                                }
                                error={_.get(errors, 'completeBy')}
                                min={moment().add(1, 'day').startOf('day')}
                                placeholder="Select Complete By Date..."
                                select="date"
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}

                  {watchFields?.serviceIssue?.mutation?.value === 'CREATE' &&
                    watchFields?.budget &&
                    watchFields?.budgetProject && (
                      <>
                        <Controller
                          control={control}
                          name="serviceIssue.create.description"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              error={errors?.serviceIssue?.create?.description}
                              fullWidth
                              helperText={errors?.serviceIssue?.create?.description?.message}
                              maxRows={4}
                              minRows={4}
                              multiline
                              placeholder="Enter Description; Where / What..."
                              required
                              variant="outlined"
                            />
                          )}
                        />

                        {['FRANCHISOR', 'OPERATOR'].includes(
                          user?.data?.organization?.type.enum
                        ) && (
                          <Controller
                            control={control}
                            name="serviceIssue.create.tags"
                            render={({ field }) => (
                              <ServiceIssueTagSelectField
                                {...field}
                                className="mb-24"
                                error={errors?.serviceIssue?.create?.tags}
                                isClearable
                                isDisabled={false}
                                isMulti
                                placeholder="Select / Create Service Issue Tags..."
                              />
                            )}
                          />
                        )}
                      </>
                    )}
                </>
              )}

            {/* TODO: Review */}
            {watchFields?.serviceTicketPhaseEnum === 'DRAFT' && (
              <Controller
                control={control}
                name="notToExceedAmount"
                render={({ field }) => (
                  <CommonCurrencyField
                    {...field}
                    className="mb-24"
                    control={control}
                    disabled={false}
                    error={errors?.notToExceedAmount}
                    placeholder="Enter Not To Exceed..."
                  />
                )}
              />
            )}

            {/* TODO: Review */}
            {watchFields?.serviceTicketPhaseEnum === 'QUOTING' && (
              <>
                <Controller
                  control={control}
                  name="siteVisit.mutation"
                  render={({ field }) => (
                    <div className="mb-24">
                      <CommonSelect
                        {...field}
                        error={errors?.siteVisit?.mutation}
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        options={[
                          {
                            label: 'Connect to Existing Site Visit',
                            value: 'CONNECT',
                          },
                          {
                            label: 'Create New Site Visit',
                            value: 'CREATE',
                          },
                        ]}
                        placeholder="Select Site Visit Connection..."
                        onChange={(params) => {
                          setValue('siteVisit', null);

                          field.onChange(params);
                        }}
                      />
                    </div>
                  )}
                />

                {watchFields?.siteVisit?.mutation?.value === 'CONNECT' && (
                  <>
                    <Controller
                      control={control}
                      name="siteVisit.connect"
                      render={({ field }) => (
                        <EntitySearchSelectFieldV2
                          {...field}
                          className="mb-24"
                          components={{ Option: SiteVisitHitsListOption }}
                          error={errors?.siteVisit?.connect}
                          filter={[
                            `serviceTicket.id = '${serviceTicketId}'`,
                            `serviceTicket.status.enum = 'OPEN'`,
                          ]}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          indexName="site_visits"
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          labelFn={(hit) =>
                            `${moment(hit?.windowStartAt).format(
                              'MMM. DD, YYYY @ hh:mm A'
                            )} - ${moment(hit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}`
                          }
                          placeholder="Select Site Visit..."
                          sort={['windowStartAtTimestamp:asc']}
                        />
                      )}
                    />
                  </>
                )}

                {watchFields?.siteVisit?.mutation?.value === 'CREATE' && (
                  <>
                    <Controller
                      control={control}
                      name="siteVisit.create.window"
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar', 'time']}
                            error={errors?.siteVisit?.create?.window}
                            // TODO: Review
                            // min={
                            //   watchFields?.serviceTicket?.deferredUntil
                            //     ? moment(watchFields?.serviceTicket?.deferredUntil).toDate()
                            //     : moment().add(1, 'days').startOf('day').toDate()
                            // }
                            placeholder="Select an Arrival Window..."
                            select="range"
                            stepMinute={15}
                          />
                        </div>
                      )}
                    />
                  </>
                )}
              </>
            )}

            {/* TODO: Review */}
            {watchFields?.serviceTicketPhaseEnum === 'IN_PROGRESS' && (
              <>
                <Controller
                  control={control}
                  name="serviceVisit.mutation"
                  render={({ field }) => (
                    <div className="mb-24">
                      <CommonSelect
                        {...field}
                        error={errors?.serviceVisit?.mutation}
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        options={[
                          {
                            label: 'Connect to Existing Service Visit',
                            value: 'CONNECT',
                          },
                          {
                            label: 'Create New Service Visit',
                            value: 'CREATE',
                          },
                        ]}
                        placeholder="Select Service Visit Connection..."
                        onChange={(params) => {
                          setValue('serviceVisit', null);

                          field.onChange(params);
                        }}
                      />
                    </div>
                  )}
                />

                {watchFields?.serviceVisit?.mutation?.value === 'CONNECT' && (
                  <>
                    <Controller
                      control={control}
                      name="serviceVisit.connect"
                      render={({ field }) => (
                        <EntitySearchSelectFieldV2
                          {...field}
                          className="mb-24"
                          components={{ Option: ServiceVisitHitsListOption }}
                          error={errors?.serviceVisit?.connect}
                          filter={[
                            `serviceTicket.id = '${serviceTicketId}'`,
                            `serviceTicket.status.enum = 'OPEN'`,
                          ]}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          indexName="service_visits"
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          labelFn={(hit) =>
                            `${moment(hit?.windowStartAt).format(
                              'MMM. DD, YYYY @ hh:mm A'
                            )} - ${moment(hit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}`
                          }
                          placeholder="Select Service Visit..."
                          sort={['windowStartAtTimestamp:asc']}
                        />
                      )}
                    />
                  </>
                )}

                {watchFields?.serviceVisit?.mutation?.value === 'CREATE' && (
                  <>
                    <Controller
                      control={control}
                      name="serviceVisit.create.window"
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar', 'time']}
                            error={errors?.serviceVisit?.create?.window}
                            // TODO: Review
                            // min={
                            //   watchFields?.serviceTicket?.deferredUntil
                            //     ? moment(watchFields?.serviceTicket?.deferredUntil).toDate()
                            //     : moment().add(1, 'days').startOf('day').toDate()
                            // }
                            placeholder="Select an Arrival Window..."
                            select="range"
                            stepMinute={15}
                          />
                        </div>
                      )}
                    />
                  </>
                )}
              </>
            )}

            {/* TODO: Add ActionItems */}

            {watchFields?.serviceIssue?.mutation?.value === 'CREATE' && (
              <>
                <Divider sx={{ mb: 3 }} />

                <WorkOrderIssueCreateDialogServiceIssueAttachments name="serviceIssue.create.attachments" />
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      {/* TODO: Review */}
      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex flex-1 flex-col sm:flex-row">
            <Button
              className="mb-8 sm:mb-0"
              color="primary"
              form="work-order-issue-create-form"
              startIcon={
                <FontAwesomeIcon
                  className={clsx({ 'text-green': isValid, 'text-red': !isValid })}
                  icon={isValid ? faCircleCheck : faCircleXmark}
                />
              }
              type="submit"
              variant="contained"
              onClick={(event) => {
                if (isValid) {
                  event.preventDefault();
                }
              }}
            >
              Preflight
            </Button>
          </div>

          <div className="flex flex-col sm:flex-row">
            <Button className="mb-8 sm:mb-0" color="primary" onClick={onClose} variant="contained">
              Cancel
            </Button>

            <Button
              className="mb-8 ml-0 sm:mb-0 sm:ml-8"
              color="secondary"
              disabled={_.isEmpty(dirtyFields) || !isValid || loading}
              form="work-order-issue-create-form"
              type="submit"
              variant="contained"
            >
              Create
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueCreateDialog;
