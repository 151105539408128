import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ACCOUNTING_EXPENSE_TYPE_VIEW_DRAWER_ACCOUNTING_EXPENSE_TYPE_FRAGMENT = gql`
  fragment AccountingExpenseTypeViewDrawerAccountingExpenseTypeFragment on AccountingExpenseType {
    id
    createdAt
    updatedAt

    canBeUpdated
    name
    owner {
      id
      name
    }
  }
`;
