import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { BudgetProjectHitsListOption } from 'app/shared-components/BudgetProject';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ACTIVATE_BUDGET } from './queries';

const defaultValues = {
  projects: [],
};

const schema = yup.object().shape({
  projects: yup
    .array()
    .of(yup.object().required())
    .min(1, 'Select at least one budget project')
    .required(),
});

const BudgetActivateDialog = ({ budgetId, onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [activateBudget, { loading: activateBudgetLoading }] = useMutation(ACTIVATE_BUDGET, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Budget Successfully Activated',
          variant: 'success',
        })
      );

      if (typeof onSuccess === 'function') {
        onSuccess(data?.activateBudget);
      }
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Activating Budget', variant: 'error' }));
    },
  });

  const loading = useMemo(() => [activateBudgetLoading].includes(true), [activateBudgetLoading]);

  const onSubmit = async (data) => {
    try {
      await activateBudget({
        variables: {
          where: { id: budgetId },
          data: {
            projects: data.projects?.map((budgetProject) => ({ id: budgetProject.id })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>Activate Budget</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full"
            id="budget-activate-form"
            name="budget-activate-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <DialogContentText sx={{ padding: '24px 0' }}>
              Once active; users will be able to select this budget, and any budget projects
              selected below based on your organization settings; do you wish to proceed?
            </DialogContentText>

            <Controller
              control={control}
              name="projects"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  components={{ Option: BudgetProjectHitsListOption }}
                  error={errors?.projects}
                  filter={[`budget.id = '${budgetId}'`]}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  indexName="budget_projects"
                  isClearable
                  isDisabled={false}
                  isMulti
                  placeholder="Select Budget Projects..."
                  sort={['name:asc']}
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="budget-activate-form"
          type="submit"
          variant="contained"
        >
          Activate
        </Button>
      </DialogActions>
    </>
  );
};

export default BudgetActivateDialog;
