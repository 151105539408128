import { gql } from '@apollo/client';
import { ACCOUNTING_PROJECT_VIEW_SCREEN_ACCOUNTING_PROJECT_FRAGMENT } from './fragments';

export const FETCH_ACCOUNTING_PROJECT_VIEW_SCREEN_DATA = gql`
  query FetchAccountingProjectViewScreenData($where: AccountingProjectWhereUniqueInput!) {
    accountingProject(where: $where) {
      id
      ...AccountingProjectViewScreenAccountingProjectFragment
    }
  }
  ${ACCOUNTING_PROJECT_VIEW_SCREEN_ACCOUNTING_PROJECT_FRAGMENT}
`;

export const UPDATE_ACCOUNTING_PROJECT = gql`
  mutation UpdateAccountingProject(
    $where: AccountingProjectWhereUniqueInput!
    $data: AccountingProjectUpdateInput!
  ) {
    updateAccountingProject(where: $where, data: $data) {
      id
      ...AccountingProjectViewScreenAccountingProjectFragment
    }
  }
  ${ACCOUNTING_PROJECT_VIEW_SCREEN_ACCOUNTING_PROJECT_FRAGMENT}
`;
