import { useLazyQuery, useMutation } from '@apollo/client';
import {
  faCircleChevronDown,
  faCircleChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  AppBar,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ServiceCompletionItemActivateDialog } from 'app/shared-components/ServiceCompletionItem/ServiceCompletionItemActivateDialog';
import { ServiceCompletionItemDeactivateDialog } from 'app/shared-components/ServiceCompletionItem/ServiceCompletionItemDeactivateDialog';
import { ServiceCompletionItemDeleteDialog } from 'app/shared-components/ServiceCompletionItem/ServiceCompletionItemDeleteDialog';
import { ServiceCompletionItemTargetTypeSelectField } from 'app/shared-components/ServiceCompletionItemTargetType';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  FETCH_SERVICE_COMPLETION_ITEM_VIEW_DRAWER_DATA,
  UPDATE_SERVICE_COMPLETION_ITEM,
} from './queries';
import { ServiceCompletionItemViewDrawerAttachments } from './components';

const DRAWER_APP_BAR_HEIGHT = 64;

const defaultValues = {
  attachments: [],
  isAlwaysRequired: false,
  label: '',
  name: '',
  targetType: null,
};

const schema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  isAlwaysRequired: yup.boolean().required(),
  label: yup.string().nullable().required('A label must be provided.'),
  name: yup.string().nullable().required('A name must be provided.'),
  targetType: yup.object().nullable().required('A target type must be provided.'),
});

const ServiceCompletionItemViewDrawer = forwardRef(
  ({ basePath, contentWidth, onCloseDrawer }, ref) => {
    const [activationMenu, setActivationMenu] = useState(null);
    const dispatch = useDispatch();
    const [fetched, setFetched] = useState(false);
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const params = useParams();
    const rootRef = useRef(null);
    const { refreshSearch } = useEntitySearchApp();
    const user = useSelector(selectUser);
    const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
      useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(schema),
      });

    const { isValid, dirtyFields, errors } = formState;

    const watchFields = watch();

    const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

    const [
      fetchServiceCompletionItemViewDrawerData,
      {
        data: serviceCompletionItemViewDrawerData,
        loading: serviceCompletionItemViewDrawerLoading,
        refetch: serviceCompletionItemViewDrawerRefetch,
      },
    ] = useLazyQuery(FETCH_SERVICE_COMPLETION_ITEM_VIEW_DRAWER_DATA, {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setFetched(true),
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Fetching Completion Item Data',
            variant: 'error',
          })
        );
      },
    });

    const [updateServiceCompletionItem, { loading: updateServiceCompletionItemLoading }] =
      useMutation(UPDATE_SERVICE_COMPLETION_ITEM, {
        onCompleted: (data) => {
          dispatch(
            showMessage({
              message: 'Completion Item Successfully Updated',
              variant: 'success',
            })
          );
        },
        onError: (error) => {
          dispatch(showMessage({ message: 'Failed Updating Completion Item', variant: 'error' }));
        },
      });

    const serviceCompletionItem = useMemo(
      () => serviceCompletionItemViewDrawerData?.serviceCompletionItem,
      [serviceCompletionItemViewDrawerData?.serviceCompletionItem]
    );

    const loading = useMemo(
      () =>
        [serviceCompletionItemViewDrawerLoading, updateServiceCompletionItemLoading].includes(true),
      [serviceCompletionItemViewDrawerLoading, updateServiceCompletionItemLoading]
    );

    const canActivationServiceCompletionItem = useMemo(
      () => Boolean(serviceCompletionItem?.owner?.id === user?.data?.organization?.id),
      [serviceCompletionItem?.owner?.id, user?.data?.organization?.id]
    );

    const canDeleteServiceCompletionItem = useMemo(
      () =>
        Boolean(
          ['DRAFT'].includes(serviceCompletionItem?.status?.enum) &&
            serviceCompletionItem?.owner?.id === user?.data?.organization?.id
        ),
      [
        serviceCompletionItem?.owner?.id,
        serviceCompletionItem?.status?.enum,
        user?.data?.organization?.id,
      ]
    );

    const canUpdateServiceCompletionItem = useMemo(
      () =>
        Boolean(
          ['DRAFT', 'INACTIVE'].includes(serviceCompletionItem?.status?.enum) &&
            serviceCompletionItem?.owner?.id === user?.data?.organization?.id
        ),
      [
        serviceCompletionItem?.owner?.id,
        serviceCompletionItem?.status?.enum,
        user?.data?.organization?.id,
      ]
    );

    useImperativeHandle(ref, () => ({
      rootRef,
      refetchAll: () => {
        [serviceCompletionItemViewDrawerRefetch].forEach(() =>
          serviceCompletionItemViewDrawerRefetch()
        );
      },
    }));

    useEffect(() => {
      if (serviceCompletionItem) {
        const formData = {
          attachments:
            serviceCompletionItem.attachments?.map((attachment) => {
              let commonStoredObject;

              if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
                commonStoredObject = attachment.commonStoredImage;
              } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
                commonStoredObject = attachment.commonStoredPdf;
              } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
                commonStoredObject = attachment.commonStoredVideo;
              }

              return commonStoredObject
                ? {
                    commonStoredUpload: {
                      key: commonStoredObject?.commonStoredUpload?.key,
                    },
                    file: {
                      name: commonStoredObject?.name,
                      preview: commonStoredObject?.thumbnailImageUrl,
                    },
                  }
                : null;
            }) || [],
          isAlwaysRequired: serviceCompletionItem.isAlwaysRequired,
          label: serviceCompletionItem.label,
          name: serviceCompletionItem.name,
          // ROADMAP: Use `getOptionValue` Instead
          targetType: {
            label: serviceCompletionItem.targetType?.name,
            result: serviceCompletionItem,
            value: serviceCompletionItem.targetType?.id,
          },
        };

        reset(formData);
      }
    }, [reset, serviceCompletionItem]);

    useEffect(() => {
      if (serviceCompletionItemViewDrawerRefetch && fetched && refreshSearch) {
        serviceCompletionItemViewDrawerRefetch();
      }
    }, [serviceCompletionItemViewDrawerRefetch, fetched, refreshSearch]);

    useEffect(() => {
      if (params?.serviceCompletionItemId) {
        setFetched(false);
        fetchServiceCompletionItemViewDrawerData({
          variables: { where: { id: params?.serviceCompletionItemId } },
        });
      }
    }, [fetchServiceCompletionItemViewDrawerData, params?.serviceCompletionItemId]);

    const handleCloseActivationMenu = useCallback(() => setActivationMenu(null), []);

    const handleCloseDrawer = useCallback(() => {
      if (typeof onCloseDrawer === 'function') {
        onCloseDrawer();
      }

      setFetched(false);
    }, [onCloseDrawer]);

    const handleOpenActivationMenu = (event) => setActivationMenu(event.currentTarget);

    useEffect(() => {
      if (fetched && !serviceCompletionItem) {
        handleCloseDrawer();
      }
    }, [serviceCompletionItem, fetched, handleCloseDrawer]);

    const onSubmit = async (data) => {
      try {
        await updateServiceCompletionItem({
          variables: {
            where: {
              id: serviceCompletionItem.id,
            },
            data: {
              attachments: data.attachments?.map((attachment) => ({
                commonStoredUpload: { key: attachment?.commonStoredUpload.key },
              })),
              isAlwaysRequired: data.isAlwaysRequired,
              label: data.label,
              name: data.name,
              targetType: { id: data.targetType.value },
            },
          },
        });
      } catch (err) {
        //
      }
    };

    return (
      <div className="flex-1" style={{ width: contentWidth }}>
        <AppBar color="inherit" elevation={0} position="static">
          <Toolbar>
            <div className="flex flex-1">
              <Typography component="div" noWrap variant="h6">
                Completion Item Details
              </Typography>
            </div>

            <IconButton sx={{ width: 40, height: 40 }} onClick={handleCloseDrawer}>
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Paper
          className="flex flex-col p-24 overflow-auto border-t-1"
          elevation={0}
          square
          style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
        >
          {(loading && !fetched) || !serviceCompletionItem ? (
            <div className="flex w-full h-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <FormProvider
              {...{
                clearErrors,
                control,
                formState,
                handleSubmit,
                reset,
                setValue,
                watch,
                ...methods,
              }}
            >
              <form
                className="w-full"
                id="service-completion-item-view-form"
                name="service-completion-item-view-form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid className="mb-24" container spacing={2}>
                  <Grid alignItems="center" display="flex" md={9} order={{ md: 1, xs: 2 }} xs={12}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className=""
                          error={!!errors?.name}
                          fullWidth
                          helperText={errors?.name?.message}
                          inputProps={{ readOnly: !canUpdateServiceCompletionItem }}
                          placeholder="Enter Completion Item Name..."
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    alignItems="center"
                    display="flex"
                    justifyContent="right"
                    md={3}
                    order={{ md: 2, xs: 1 }}
                    xs={12}
                  >
                    <Button
                      color="secondary"
                      disabled={!canActivationServiceCompletionItem || loading}
                      startIcon={
                        <FontAwesomeIcon
                          icon={activationMenu ? faCircleChevronDown : faCircleChevronRight}
                        />
                      }
                      variant="contained"
                      onClick={handleOpenActivationMenu}
                    >
                      {serviceCompletionItem?.status?.name}
                    </Button>

                    <Menu
                      anchorEl={activationMenu}
                      open={Boolean(activationMenu)}
                      onClose={handleCloseActivationMenu}
                    >
                      {['ACTIVE'].includes(serviceCompletionItem?.status?.enum) && (
                        <MenuItem
                          disabled={!canActivationServiceCompletionItem}
                          onClick={() => {
                            handleCloseActivationMenu();

                            dispatch(
                              openDialog({
                                children: (
                                  <ServiceCompletionItemDeactivateDialog
                                    serviceCompletionItemId={serviceCompletionItem?.id}
                                    onClose={() => dispatch(closeDialog())}
                                  />
                                ),
                                classes: {
                                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                                },
                              })
                            );
                          }}
                        >
                          Deactivate Completion Item
                        </MenuItem>
                      )}

                      {['DRAFT', 'INACTIVE'].includes(serviceCompletionItem?.status?.enum) && (
                        <MenuItem
                          disabled={!canActivationServiceCompletionItem}
                          onClick={() => {
                            handleCloseActivationMenu();

                            dispatch(
                              openDialog({
                                children: (
                                  <ServiceCompletionItemActivateDialog
                                    serviceCompletionItemId={serviceCompletionItem?.id}
                                    onClose={() => dispatch(closeDialog())}
                                  />
                                ),
                                classes: {
                                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                                },
                              })
                            );
                          }}
                        >
                          Activate Completion Item
                        </MenuItem>
                      )}
                    </Menu>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Controller
                      control={control}
                      name="label"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={!!errors?.label}
                          fullWidth
                          helperText={errors?.label?.message}
                          inputProps={{ readOnly: !canUpdateServiceCompletionItem }}
                          placeholder="Enter Completion Item Label..."
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Divider className="mb-24" />

                <Grid className="mb-24" container spacing={2}>
                  <Grid
                    alignContent="center"
                    display="flex"
                    justifyContent={isMobile ? 'center' : 'left'}
                    md={5}
                    xs={12}
                  >
                    <Controller
                      control={control}
                      name="isAlwaysRequired"
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              {...field}
                              checked={!!field.value}
                              color="info"
                              disabled={!canUpdateServiceCompletionItem}
                            />
                          }
                          label="Always Required"
                          labelPlacement="start"
                          sx={{ ml: 0 }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    alignContent="center"
                    display="flex"
                    justifyContent={isMobile ? 'center' : 'left'}
                    md={7}
                    xs={12}
                  >
                    <Controller
                      control={control}
                      name="targetType"
                      render={({ field }) => (
                        <ServiceCompletionItemTargetTypeSelectField
                          {...field}
                          className=""
                          error={errors?.targetType}
                          filters={null}
                          isDisabled={!canUpdateServiceCompletionItem}
                          isClearable
                          isMulti={false}
                          placeholder="Select a Completion Item Target Type..."
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Divider className="mb-24" />

                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <ServiceCompletionItemViewDrawerAttachments
                      disabled={!canUpdateServiceCompletionItem}
                      name="attachments"
                    />
                  </Grid>
                </Grid>

                {(canDeleteServiceCompletionItem || canUpdateServiceCompletionItem) && (
                  <>
                    <Divider className="my-16" />

                    <Grid container spacing={2}>
                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'left'}
                        md={6}
                        xs={12}
                      >
                        <Button
                          color="error"
                          disabled={!canDeleteServiceCompletionItem || loading}
                          fullWidth={isMobile}
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              openDialog({
                                children: (
                                  <ServiceCompletionItemDeleteDialog
                                    serviceCompletionItemId={serviceCompletionItem?.id}
                                    onClose={() => dispatch(closeDialog())}
                                    onSuccess={handleCloseDrawer}
                                  />
                                ),
                                classes: {
                                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                                },
                              })
                            );
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>

                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'right'}
                        md={3}
                        xs={12}
                      >
                        <Button
                          color="primary"
                          disabled={
                            !canUpdateServiceCompletionItem || _.isEmpty(dirtyFields) || loading
                          }
                          fullWidth
                          variant="contained"
                          onClick={() => reset(undefined, { keepDefaultValues: true })}
                        >
                          Revert
                        </Button>
                      </Grid>

                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'right'}
                        md={3}
                        xs={12}
                      >
                        <Button
                          color="secondary"
                          disabled={
                            !canUpdateServiceCompletionItem ||
                            _.isEmpty(dirtyFields) ||
                            !isValid ||
                            loading
                          }
                          form="service-completion-item-view-form"
                          fullWidth
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </form>
            </FormProvider>
          )}
        </Paper>
      </div>
    );
  }
);

export default ServiceCompletionItemViewDrawer;
