import _ from '@lodash';
import { useMeilisearch } from 'app/providers/meilisearch';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';
import { AG_GRID_KEY_MAPPINGS, makeReactSelectStyles } from '../utils';

const WorkOrderIssueLineItemGridEntitySelect = memo(
  ({
    cellStartedEdit,
    components,
    eventKey,
    filter,
    getOptionLabel,
    getOptionValue,
    indexName,
    sort,
    value,
    onValueChange,

    // TODO:
    node,
    onSomething,
  }) => {
    const { axiosSearchClient } = useMeilisearch();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const mainTheme = useSelector(selectMainTheme);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const selectRef = useRef(null);
    const setValue = useCallback(
      (val) => {
        onValueChange(_.isEmpty(val) ? null : val);
        // TODO:
        onSomething(_.isEmpty(val) ? null : val, node);
      },
      [node, onValueChange, onSomething]
    );

    // TODO:
    const [error, setError] = useState(false);

    // TODO:
    const styles = useMemo(
      () => makeReactSelectStyles({ error, mode: mainTheme?.palette.mode }),
      [error, mainTheme?.palette.mode]
    );

    // TODO:
    const loadOptions = async (match, loadedOptions) => {
      let output = {
        options: [],
        hasMore: false,
      };

      try {
        const {
          data: { estimatedTotalHits, hits },
        } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
          filter,
          limit: 20,
          offset: loadedOptions.length,
          q: match,
          sort,
        });

        output = {
          options: hits,
          hasMore: estimatedTotalHits > loadedOptions.length,
        };
      } catch (err) {
        dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
      }

      return output;
    };

    // TODO:
    const renameRef = useRef(false);

    useEffect(() => {
      if (cellStartedEdit && !renameRef.current && selectRef.current) {
        let startInputValue;
        let startValue;

        if (eventKey === AG_GRID_KEY_MAPPINGS.KEY_BACKSPACE) {
          startInputValue = '';
          startValue = null;
        } else if (eventKey && eventKey.length === 1) {
          startInputValue = eventKey;
          startValue = value;
        } else {
          startInputValue = '';
          startValue = value;
        }

        setInputValue(startInputValue);
        setValue(startValue);

        setMenuIsOpen(true);

        renameRef.current = true;
        selectRef.current.focus();
      }
    }, [cellStartedEdit, eventKey, setValue, value]);

    const handleKeyDown = (event) => {
      const { key } = event;

      if (key === AG_GRID_KEY_MAPPINGS.KEY_ENTER && !menuIsOpen) {
        setMenuIsOpen(true);
      }
    };

    const handleMenuClose = () => setMenuIsOpen(false);

    const handleMenuOpen = () => setMenuIsOpen(true);

    return (
      <AsyncPaginate
        components={components}
        debounceTimeout={300}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        inputValue={inputValue}
        // TODO: Confirm w/ Chris
        isClearable
        loadOptions={loadOptions}
        menuIsOpen={menuIsOpen}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        selectRef={selectRef}
        styles={styles}
        value={value}
        onChange={setValue}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        onMenuClose={handleMenuClose}
        onMenuOpen={handleMenuOpen}
      />
    );
  }
);

export default WorkOrderIssueLineItemGridEntitySelect;
