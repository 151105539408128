import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_FINALIZE_TOTALS_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueFinalizeTotalsDialogServiceTicketFragment on ServiceTicket {
    id
    lastActivityAt
    latestCompleteBy
    phase {
      id
      enum
      name
    }
    serviceReferenceNumber
    status {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        actionItems {
          id
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        notToExceedAmount
        quotedSubtotalAmount
        reviews {
          id
          isCancelled
          isCompleted
        }
        serviceIssue {
          id
          description
          name
        }
        shippingAmount
        status {
          id
          enum
          name
        }
        subtotalAmount
        subtotalAmountCheck {
          id
          enum
          name
        }
        taxAmount
        totalAmount
      }
      notToExceedAmount
      shippingAmount
      subtotalAmount
      taxAmount
      totalAmount
    }
  }
`;
