import { gql, useQuery } from '@apollo/client';
import _ from '@lodash';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { AG_GRID_KEY_MAPPINGS, makeReactSelectStyles } from '../utils';

const WorkOrderIssueLineItemGridRateTypeSelect = memo(
  ({
    cellStartedEdit,
    components,
    eventKey,
    filters,
    getOptionLabel,
    getOptionValue,
    value,
    onValueChange,
  }) => {
    const dispatch = useDispatch();
    const [filtersCache, setFiltersCache] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const mainTheme = useSelector(selectMainTheme);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const selectRef = useRef(null);
    const setValue = useCallback(
      (val) => {
        onValueChange(_.isEmpty(val) ? null : val);
      },
      [onValueChange]
    );

    // TODO:
    const [error, setError] = useState(false);

    // TODO:
    const styles = useMemo(
      () => makeReactSelectStyles({ error, mode: mainTheme?.palette.mode }),
      [error, mainTheme?.palette.mode]
    );

    // TODO:
    useQuery(
      gql`
        query GetCommonStandardRateTypeSelectFieldData($where: CommonStandardRateTypeWhereInput!) {
          commonStandardRateTypes(where: $where) {
            id
            enum
            name
          }
        }
      `,
      {
        skip: _.isEqual(filters, filtersCache) && !!options,
        variables: { where: { ...(filters || {}) } },
        onCompleted: ({ commonStandardRateTypes }) => {
          setFiltersCache(filters);
          setOptions(commonStandardRateTypes);
        },
        onError: ({ message }) =>
          dispatch(
            showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
          ),
      }
    );

    // TODO:
    const renameRef = useRef(false);

    useEffect(() => {
      if (cellStartedEdit && !renameRef.current && selectRef.current) {
        let startInputValue;
        let startValue;

        if (eventKey === AG_GRID_KEY_MAPPINGS.KEY_BACKSPACE) {
          startInputValue = '';
          startValue = null;
        } else if (eventKey && eventKey.length === 1) {
          startInputValue = eventKey;
          startValue = value;
        } else {
          startInputValue = '';
          startValue = value;
        }

        setInputValue(startInputValue);
        setValue(startValue);

        setMenuIsOpen(true);

        renameRef.current = true;
        selectRef.current.focus();
      }
    }, [cellStartedEdit, eventKey, setValue, value]);

    const handleKeyDown = (event) => {
      const { key } = event;

      if (key === AG_GRID_KEY_MAPPINGS.KEY_ENTER && !menuIsOpen) {
        setMenuIsOpen(true);
      }
    };

    const handleMenuClose = () => setMenuIsOpen(false);

    const handleMenuOpen = () => setMenuIsOpen(true);

    return (
      <Select
        components={components}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        inputValue={inputValue}
        // TODO: Confirm w/ Chris
        isClearable
        isLoading={loading}
        menuIsOpen={menuIsOpen}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        options={options}
        ref={selectRef}
        styles={styles}
        value={value}
        onChange={setValue}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        onMenuClose={handleMenuClose}
        onMenuOpen={handleMenuOpen}
      />
    );
  }
);

export default WorkOrderIssueLineItemGridRateTypeSelect;
