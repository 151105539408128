import { gql } from '@apollo/client';
import { BUDGET_PROJECT_DEACTIVATE_DIALOG_BUDGET_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DEACTIVATE_BUDGET_PROJECT = gql`
  mutation DeactivateBudgetProject($where: BudgetProjectWhereUniqueInput!) {
    deactivateBudgetProject(where: $where) {
      id
      ...BudgetProjectDeactivateDialogBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_DEACTIVATE_DIALOG_BUDGET_PROJECT_FRAGMENT}
`;
