import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { CREATE_ACCOUNTING_EXPENSE_TYPE } from './queries';

const defaultValues = {
  name: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('A name must be provided.'),
});

const AccountingExpenseTypeCreateDialog = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createAccountingExpenseType, { loading: createAccountingExpenseTypeLoading }] =
    useMutation(CREATE_ACCOUNTING_EXPENSE_TYPE, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Accounting Expense Type Successfully Created',
            variant: 'success',
          })
        );

        // ROADMAP: Migrate Other Forms to Always Use a Callback
        if (typeof onSuccess === 'function') {
          onSuccess(data?.createAccountingExpenseType);
        }
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Creating Accounting Expense Type', variant: 'error' })
        );
      },
    });

  const loading = useMemo(
    () => [createAccountingExpenseTypeLoading].includes(true),
    [createAccountingExpenseTypeLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createAccountingExpenseType({
        variables: {
          data: {
            name: data.name,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Accounting Expense Type
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="accounting-expense-type-create-form"
            name="accounting-expense-type-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Accounting Expense Type Name..."
                  required
                  variant="outlined"
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="accounting-expense-type-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default AccountingExpenseTypeCreateDialog;
