import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const BudgetProjectsSearchPage = lazy(() => import('./BudgetProjectsSearchPage'));
export const budgetProjectsSearchPageBase = '/budget-projects';

// i18next.addResourceBundle('en', 'budgetProjectsSearchPage', en);
// i18next.addResourceBundle('tr', 'budgetProjectsSearchPage', tr);
// i18next.addResourceBundle('ar', 'budgetProjectsSearchPage', ar);

const budgetProjectsSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${budgetProjectsSearchPageBase}/search`,
      element: <BudgetProjectsSearchPage />,
    },
    {
      path: budgetProjectsSearchPageBase,
      element: <Navigate to={`${budgetProjectsSearchPageBase}/search`} />,
    },
  ],
};

export default budgetProjectsSearchPageConfig;
