import _ from '@lodash';
import {
  jwtService,
  JWT_SERVICE_EVENTS,
  JWT_SERVICE_TOKEN_TYPES,
} from 'app/providers/auth/services';
import { selectUser } from 'app/store/userSlice';
import axios from 'axios';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';

// TODO:
const {
  REACT_APP_HYVE_CLIENT_VERSION,
  REACT_APP_HYVE_SYSTEM_CLIENT,
  REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL = 'https://dev.ultraviolet-interchange.hyvery.io',
  REACT_APP_ULTRAVIOLET_INTERCHANGE_CSRF_ENDPOINT = '/csrf-token',
  REACT_APP_ULTRAVIOLET_INTERCHANGE_USERS_NAMESPACE = 'users',
} = process.env;

// TODO: Rename / Order
class SocketIoClient {
  constructor({ baseURL, csrfTokenEndpoint, namespace }) {
    // super();

    this.client = axios.create({
      baseURL,
      headers: {
        common: {
          'X-HYVE-Client-Version': REACT_APP_HYVE_CLIENT_VERSION,
          'X-HYVE-System-Client': REACT_APP_HYVE_SYSTEM_CLIENT,
        },
      },
      withCredentials: true,
    });
    this.csrfTokenEndpoint = csrfTokenEndpoint;
    this.namespace = namespace;

    this.#init();
  }

  #init() {
    if (!this.csrfTokenEndpoint || this.ready) {
      return;
    }

    Promise.all([
      new Promise((resolve, reject) => {
        this.client
          .get(this.csrfTokenEndpoint)
          .then(({ data }) => {
            this.client.defaults.headers.post['X-CSRF-Token'] = data.csrfToken;

            resolve();
          })
          .catch((error) => reject(error));
      }),
    ])
      .then(() => {
        this.ready = true;
      })
      .catch((error) => {
        console.log('// TODO:', { error });
      });
  }

  joinRoom = ({ accessToken, namespace, roomId, socketId }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post(
          // TODO:
          '/rooms/join',
          {
            namespace,
            roomId,
            socketId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(async ({ data }) => {
          // this.emit(JWT_SERVICE_EVENTS.ON_MAGIC_SIGN_IN, { data });

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };

  leaveRoom = ({ accessToken, namespace, roomId, socketId }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post(
          // TODO:
          '/rooms/leave',
          {
            namespace,
            roomId,
            socketId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(async ({ data }) => {
          // this.emit(JWT_SERVICE_EVENTS.ON_MAGIC_SIGN_IN, { data });

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };
}

// TODO: Rename
const socketIoClient = new SocketIoClient({
  baseURL: REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
  csrfTokenEndpoint: REACT_APP_ULTRAVIOLET_INTERCHANGE_CSRF_ENDPOINT,
  namespace: REACT_APP_ULTRAVIOLET_INTERCHANGE_USERS_NAMESPACE,
});

const SocketIoContext = createContext();

const SocketIoProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const user = useSelector(selectUser);

  const joinRoom = useCallback(
    ({ namespace, roomId, socketId }) => {
      if (jwtService.isTokenValid(accessToken)) {
        socketIoClient.joinRoom({ accessToken, namespace, roomId, socketId });
      }
    },
    [accessToken]
  );

  const leaveRoom = useCallback(
    ({ namespace, roomId, socketId }) => {
      if (jwtService.isTokenValid(accessToken)) {
        socketIoClient.leaveRoom({ accessToken, namespace, roomId, socketId });
      }
    },
    [accessToken]
  );

  const organizationSocket = useMemo(
    () =>
      accessToken && user?.data?.organization.id
        ? io(
            [
              REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
              // TODO:
              'organizations',
              user?.data?.organization.id,
            ].join('/'),
            {
              autoConnect: false,
              auth: {
                token: accessToken,
              },
              // TODO: Review
              transports: ['websocket'],
            }
          )
        : null,
    [accessToken, user?.data?.organization.id]
  );

  const teamSocket = useMemo(
    () =>
      accessToken && user?.data?.team.id
        ? io(
            [
              REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
              // TODO:
              'teams',
              user?.data?.team.id,
            ].join('/'),
            {
              autoConnect: false,
              auth: {
                token: accessToken,
              },
              // TODO: Review
              transports: ['websocket'],
            }
          )
        : null,
    [accessToken, user?.data?.team.id]
  );

  const userSocket = useMemo(
    () =>
      accessToken
        ? io(
            [
              REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
              REACT_APP_ULTRAVIOLET_INTERCHANGE_USERS_NAMESPACE,
            ].join('/'),
            {
              autoConnect: false,
              auth: {
                token: accessToken,
              },
              // TODO: Review
              transports: ['websocket'],
            }
          )
        : null,
    [accessToken]
  );

  useEffect(() => {
    jwtService.on(
      [
        JWT_SERVICE_EVENTS.ON_COMPLETE_SIGN_IN,
        JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION,
        JWT_SERVICE_EVENTS.ON_REFRESH_SESSION,
      ],
      ({ data }) => {
        if (_.get(data, JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN)) {
          setAccessToken(_.get(data, JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN));
        }
      }
    );

    jwtService.on(
      [JWT_SERVICE_EVENTS.ON_CLIENT_RESPONSE_ERROR, JWT_SERVICE_EVENTS.ON_REVOKE_SESSION],
      () => setAccessToken(null)
    );
  }, []);

  // TODO: Split?
  useEffect(() => {
    if (organizationSocket && teamSocket && userSocket) {
      organizationSocket.connect();
      teamSocket.connect();
      userSocket.connect();
    }

    return () => {
      organizationSocket?.disconnect();
      teamSocket?.disconnect();
      userSocket?.disconnect();
    }
  }, [organizationSocket, teamSocket, userSocket]);

  return (
    <SocketIoContext.Provider
      value={{
        joinRoom,
        leaveRoom,
        organizationSocket,
        teamSocket,
        userSocket,
      }}
    >
      {children}
    </SocketIoContext.Provider>
  );
};

export const __SocketIoContext = SocketIoContext;
export default SocketIoProvider;
