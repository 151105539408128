import { faEye, faEyeSlash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Avatar, Badge, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { selectUser } from 'app/store/userSlice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';

// TODO: Extract
export const getDisplayTitle = ({ channel, currentUser }) => {
  // TODO: Add in Other Versions
  if (!channel) return null;

  let title = channel.data?.name;
  const members = Object.values(channel.state.members);

  if (!title && members.length === 2) {
    const otherMember = members.find((member) => member.user?.id !== currentUser?.id);
    if (otherMember?.user?.name) {
      title = otherMember.user.name;
    }
  }

  return title;
};

// import Statuses from './Statuses';
const Statuses = [
  {
    title: 'Online',
    value: 'online',
    color: 'rgb(76, 175, 80)',
  },
  {
    title: 'Away',
    value: 'away',
    color: 'rgb(251, 192, 45)',
  },
  {
    title: 'Do not disturb',
    value: 'do-not-disturb',
    color: 'rgb(244, 67, 54)',
  },
  {
    title: 'Offline',
    value: 'offline',
    color: 'rgb(162,162,162)',
  },
];

const ChatChannelStatusBadge = styled(Badge)(({ theme, ...props }) => ({
  width: 40,
  height: 40,
  fontSize: 20,
  '& .MuiAvatar-root': {
    fontSize: 'inherit',
    // TODO:
    // color: theme.palette.text.secondary,
    fontWeight: 600,
  },
  '& > .MuiBadge-badge': {
    backgroundColor: props.statuscolor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
}));

const ChatChannelTypeBadge = styled(Badge)(({ theme, ...props }) => ({
  width: 20,
  height: 20,
  '& > .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const ContactAvatarBadgeContent = ({ channel, title }) => {
  const chatChannelTypeEnum = useMemo(
    () => channel?.data?.chatChannelTypeEnum,
    [channel?.data?.chatChannelTypeEnum]
  );

  const isCollaborative = useMemo(
    () => channel?.data?.isCollaborative,
    [channel?.data?.isCollaborative]
  );

  return (
    <>
      {chatChannelTypeEnum === 'MESSAGING' && (
        <Tooltip
          title={
            <>
              <Typography className="text-10 uppercase">Messaging</Typography>

              <Typography className="text-14 font-semibold">{title}</Typography>
            </>
          }
        >
          <FontAwesomeIcon icon={faUser} size="sm" />
        </Tooltip>
      )}

      {chatChannelTypeEnum === 'SERVICE_TICKET' && (
        <Tooltip
          title={
            <>
              <Typography className="text-10 uppercase">
                {`Service Ticket - ${isCollaborative ? 'Collaborative' : 'Private'}`}
              </Typography>

              <Typography className="mb-6 text-14 font-semibold">{title}</Typography>

              {channel?.data?.serviceIssueName && (
                <Typography className="text-12">{channel?.data?.serviceIssueName}</Typography>
              )}

              {channel?.data?.facilityFranchiseNumber && channel?.data?.facilityName && (
                <Typography className="text-12">{`${channel?.data?.facilityFranchiseNumber} ${channel?.data?.facilityName}`}</Typography>
              )}

              {channel?.data?.vendorName && (
                <Typography className="text-12">{channel?.data?.vendorName}</Typography>
              )}
            </>
          }
        >
          <FontAwesomeIcon icon={isCollaborative ? faEye : faEyeSlash} size="sm" />
        </Tooltip>
      )}

      {chatChannelTypeEnum === 'TEAM' && (
        <Tooltip
          title={
            <>
              <Typography className="text-10 uppercase">Team</Typography>

              <Typography className="text-14 font-semibold">{title}</Typography>
            </>
          }
        >
          <FontAwesomeIcon icon={faUsers} size="sm" />
        </Tooltip>
      )}
    </>
  );
};

function ContactAvatar({ contact, className }) {
  const { client } = useChatContext();
  const { channel: _channel, watchers } = useChannelStateContext();
  const user = useSelector(selectUser);

  // TODO: Hack
  const channel = contact || _channel;
  // console.log({ channel, contact });

  const title = useMemo(
    () => getDisplayTitle({ channel, currentUser: client?.user }),
    [channel, client?.user]
  );

  // TODO: Limit to DMs and Combine
  const renameOnlineStatus = useMemo(() => {
    return _.some(
      Object.keys(channel?.state?.members || {}).filter((key) => key !== user?.id),
      (key) => channel?.state?.members?.[key]?.user?.online
    )
      ? 'online'
      : 'offline';
  }, [channel?.state?.members, user?.id]);

  const status = useMemo(
    () => _.find(Statuses, { value: renameOnlineStatus }),
    [renameOnlineStatus]
  );

  return (
    <ChatChannelStatusBadge
      className={className}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      statuscolor={status?.color}
    >
      <ChatChannelTypeBadge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        badgeContent={
          channel?.data?.chatChannelTypeEnum && <ContactAvatarBadgeContent channel={channel} title={title} />
        }
        classes={{ badge: 'w-20 h-20 border-2' }}
        color="info"
        overlap="circular"
        showZero={false}
      >
        <Avatar
          alt={channel?.data?.name}
          src={channel?.data?.image}
          sx={{ backgroundColor: channel?.data?.color }}
        >
          {!channel?.data?.image || channel?.data?.image === '' ? title?.[0] : ''}
        </Avatar>
      </ChatChannelTypeBadge>
    </ChatChannelStatusBadge>
  );
}

export default ContactAvatar;
