import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const CostCodeHitsListOption = (props) => {
  // ROADMAP: Switch to `props.data` when `EntitySearchSelectFieldV2` is Fully Deployed
  const costCode = props.data?.hit || props.data;

  const option = useMemo(() => {
    if (!costCode) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faTag} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <div className="flex flex-1">
              <div className="flex flex-col">
                {costCode?.accountingTaskCode && (
                  <Typography className="mb-1 text-12" variant="body2">
                    {`${costCode?.accountingTaskCode.number} - ${costCode?.accountingTaskCode.name}`}
                  </Typography>
                )}

                <Typography variant="body2">{`${costCode?.csiClassification?.number} - ${costCode?.csiClassification?.name}`}</Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [costCode]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default CostCodeHitsListOption;
