// eslint-disable-next-line import/prefer-default-export
export const accountingExpenseTypeSearchScreenFilterDefs = [
  {
    name: 'Accounting Expense Type',
    refinements: [
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
    ],
  },
];
