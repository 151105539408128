import { faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import {
  Alert,
  AlertTitle,
  AppBar,
  Button,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Toolbar,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useStreamChat } from 'app/providers/stream/chat';
import { CommonAsyncPaginateSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import withReducer from 'app/store/withReducer';
import keycode from 'keycode';
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import {
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelSearch,
  Chat,
  defaultRenderMessages,
  EmptyStateIndicator,
  MessageInput,
  MessageList,
  Thread,
  useChannelActionContext,
  useChatContext,
  Window,
} from 'stream-chat-react';
import { CommonChatChannelHeader } from 'app/shared-components/Common/CommonChat';
import { closeDialog, openDialog, selectFuseDialogState } from 'app/store/fuse/dialogSlice';
import { ChatChannelCreateDialog } from 'app/shared-components/ChatChannel/ChatChannelCreateDialog';

// TODO: Review
import reducer from './store';
import { closeChatPanel, openChatPanel, selectChatPanelState } from './store/stateSlice';
import ChatPanelChannelListPreview from './ChatPanelChannelListPreview';

const Root = styled('div')(({ theme, opened }) => ({
  position: 'sticky',
  display: 'flex',
  top: 0,
  width: 70,
  maxWidth: 70,
  minWidth: 70,
  height: '100vh',
  zIndex: 1000,

  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    height: '100%',
    width: 0,
    maxWidth: 0,
    minWidth: 0,
  },

  ...(opened && {
    overflow: 'visible',
  }),

  ...(!opened && {
    overflow: 'hidden',
    animation: `hide-panel 1ms linear ${theme.transitions.duration.standard}`,
    animationFillMode: 'forwards',
  }),

  '& > .panel': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    // TODO:
    width: 480,
    minWidth: 480,
    // width: 360,
    // minWidth: 360,
    height: '100%',
    margin: 0,
    overflow: 'hidden',
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    transform: 'translate3d(0,0,0)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),

    ...(opened && {
      // TODO:
      transform: theme.direction === 'rtl' ? 'translate3d(410px,0,0)' : 'translate3d(-410px,0,0)',
      // transform: theme.direction === 'rtl' ? 'translate3d(290px,0,0)' : 'translate3d(-290px,0,0)',
    }),

    [theme.breakpoints.down('lg')]: {
      left: 'auto',
      position: 'fixed',
      // TODO:
      transform: theme.direction === 'rtl' ? 'translate3d(-480px,0,0)' : 'translate3d(480px,0,0)',
      // transform: theme.direction === 'rtl' ? 'translate3d(-360px,0,0)' : 'translate3d(360px,0,0)',
      boxShadow: 'none',
      // TODO:
      width: 480,
      minWidth: 480,
      maxWidth: '100%',

      ...(opened && {
        transform: 'translate3d(0,0,0)',
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      }),
    },
  },

  '@keyframes hide-panel': {
    '0%': {
      overflow: 'visible',
    },
    '99%': {
      overflow: 'visible',
    },
    '100%': {
      overflow: 'hidden',
    },
  },
}));

const ChatPanelAppBar = () => {
  // const { channel } = useChatContext();
  // const chatPanelState = useSelector(selectChatPanelState);
  const dispatch = useDispatch();

  // console.log('// TODO: ChatPanelAppBar', { channel, chatPanelState });

   // TODO:
   const { client, channel, setActiveChannel } = useChatContext();

  return (
    <AppBar
      className="border-l border-b shadow-md"
      color="default"
      position="static"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'light' ? '#ffffff' : '#151c2b'),
      }}
    >
      <Toolbar className="px-4">
        <div
          className="flex flex-1 items-center space-x-12"
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <IconButton
            className=""
            color="inherit"
            // TODO: ToggleChat
            onClick={(ev) => dispatch(openChatPanel())}
            size="medium"
            sx={{
              width: 40,
              height: 40,
              border: (theme) =>
                `2px solid ${theme.palette.mode === 'light' ? '#4c525c' : '#b4b7bb'}`,
            }}
          >
            <FuseSvgIcon size={24}>heroicons-outline:chat-alt-2</FuseSvgIcon>
          </IconButton>
        </div>

        <div className="flex items-center px-4 z-20">
          {/* TODO: onClick */}
          <IconButton
            color="inherit"
            size="large"
            onClick={() =>
              dispatch(
                openDialog({
                  children: (
                    <ChatChannelCreateDialog
                      // TODO:
                      // serviceTicketId={params?.serviceTicketId}
                      onClose={() => dispatch(closeDialog())}
                      // TODO: Review
                      onCreate={({ chatChannel }) => {
                        setActiveChannel(
                          client.channel(chatChannel?.type.enum.toLowerCase(), chatChannel?.id)
                        );

                        dispatch(openChatPanel());
                      }}
                    />
                  ),
                  classes: {
                    paper: 'w-full max-w-640 min-w-320 rounded-8',
                  },
                })
              )
            }
          >
            <FuseSvgIcon>heroicons-outline:plus-circle</FuseSvgIcon>
          </IconButton>

          <Divider className="mx-4 my-6" flexItem orientation="vertical" />

          <IconButton onClick={(ev) => dispatch(closeChatPanel())} color="inherit" size="large">
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const RenameChannelSearch = (props) => {
  return (
    <>
      <div style={{ position: 'absolute', top: 0, left: 72, width: 408, zIndex: 10 }}>
        <ChannelSearch {...props} />
      </div>
    </>
  );
};

const CustomMessageList = () => {
  const { channel } = useChatContext();

  const ChannelTopicAlert = useMemo(() => {
    if (!channel?.data?.topic) return null;

    return (
      <Alert className="mt-16" key="channel-topic-alert" severity="info">
        <AlertTitle>Channel Topic</AlertTitle>
        {channel?.data?.topic}
      </Alert>
    );
  }, [channel?.data?.topic]);

  const customRenderMessages = useCallback(
    (options) => {
      const elements = defaultRenderMessages(options);

      if (ChannelTopicAlert) {
        if (!elements.length) {
          elements.push(
            <div className="absolute top-0 left-0 right-0 bottom-0">
              <div className="absolute top-0 left-0 right-0 px-16" style={{ zIndex: 1 }}>
                {ChannelTopicAlert}
              </div>

              <EmptyStateIndicator key="empty-state-indicator" listType="message" />
            </div>
          );
        } else {
          elements.unshift(ChannelTopicAlert);
        }
      }

      return elements;
    },
    [ChannelTopicAlert]
  );

  return <MessageList renderMessages={customRenderMessages} />;
};

const ChatPanelRoot = () => {
  const { channel, client, setActiveChannel } = useChatContext();
  const chatPanelState = useSelector(selectChatPanelState);
  const fuseDialogState = useSelector(selectFuseDialogState);
  const dispatch = useDispatch();
  // TODO: Rename
  const ref = useRef();
  const theme = useTheme();
  const user = useSelector(selectUser);

  // console.log('// TODO: ChatPanelRoot', { channel });

  // TODO:
  const filters = {
    frozen: false,
    // type: 'messaging',
    members: { $in: [user?.id] },
  };

  // TODO: Review
  useEffect(() => {
    if (!chatPanelState && channel?.id) {
      setActiveChannel(null);
    }
  }, [channel?.id, chatPanelState, setActiveChannel]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      return chatPanelState && theme.direction === 'rtl' && dispatch(closeChatPanel());
    },
    onSwipedRight: () => {
      return chatPanelState && theme.direction === 'ltr' && dispatch(closeChatPanel());
    },
  });

  const handleDocumentKeyDown = useCallback(
    (event) => {
      if (keycode(event) === 'esc') {
        dispatch(closeChatPanel());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (chatPanelState) {
      document.addEventListener('keydown', handleDocumentKeyDown);
    } else {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    }
  }, [handleDocumentKeyDown, chatPanelState]);

  useEffect(() => {
    function handleDocumentClick(ev) {
      if (ref.current && !ref.current.contains(ev.target)) {
        dispatch(closeChatPanel());
      }
    }

    if (chatPanelState && !fuseDialogState) {
      document.addEventListener('click', handleDocumentClick, true);
    } else {
      document.removeEventListener('click', handleDocumentClick, true);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, [chatPanelState, dispatch, fuseDialogState]);

  return (
    // TODO: `id`
    <Root id="chat-panel-root" opened={chatPanelState ? 1 : 0} {...handlers}>
      <div className="panel flex flex-col max-w-full" ref={ref}>
        <ChatPanelAppBar />

        <Paper className="flex flex-1 flex-row min-h-px border-l shadow-0" square>
          <ChannelList
            additionalChannelSearchProps={{
              ExitSearchIcon: () => <FuseSvgIcon>heroicons-outline:arrow-left</FuseSvgIcon>,
              popupResults: true,
              searchForChannels: true,
            }}
            ChannelSearch={RenameChannelSearch}
            // TODO:
            filters={filters}
            // TODO:
            // options={options}
            Preview={ChatPanelChannelListPreview}
            setActiveChannelOnMount={false}
            // TODO: useMemo
            sort={[{ has_unread: -1 }, { last_message_at: -1 }, { last_updated: -1 }]}
            showChannelSearch
          />

          <Channel
            // TODO:
            EmptyPlaceholder={
              <div
                className="flex flex-col flex-1 items-center justify-center p-24"
                style={{ backgroundColor: theme.palette.mode === 'dark' ? '#080707' : '#ffffff' }}
              >
                <FuseSvgIcon size={128} color="disabled">
                  heroicons-outline:chat
                </FuseSvgIcon>

                {/* TODO: */}
                <Button
                  className="mt-12"
                  onClick={() =>
                    dispatch(
                      openDialog({
                        children: (
                          <ChatChannelCreateDialog
                            // TODO:
                            // serviceTicketId={params?.serviceTicketId}
                            onClose={() => dispatch(closeDialog())}
                            // TODO: Review
                            onCreate={({ chatChannel }) => {
                              setActiveChannel(
                                client.channel(
                                  chatChannel?.type.enum.toLowerCase(),
                                  chatChannel?.id
                                )
                              );

                              dispatch(openChatPanel());
                            }}
                          />
                        ),
                        classes: {
                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                        },
                      })
                    )
                  }
                >
                  Start a New Conversation
                </Button>
              </div>
            }
          >
            <Window hideOnThread>
              {/* TODO: */}
              {/* <ChannelSearch popupResults={false} searchForChannels /> */}

              {/* TODO: */}
              <CommonChatChannelHeader />
              {/* <ChannelHeader live={false} /> */}
              {/* TODO: */}
              {/* <MessageList /> */}
              <CustomMessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </Paper>
      </div>
    </Root>
  );
};

// ===================================================================================================

const SearchBox = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.common.black, 0.54)
      : theme.palette.common.white,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  color:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.common.black, 0.54)
      : theme.palette.common.white,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchStatusWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 2),
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(32px + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const ChatPanelSearch = forwardRef(({ className, ...SelectProps }, rootRef) => {
  // TODO: `client` or `streamChatClient`?
  const { client, setActiveChannel } = useChatContext();
  const { jumpToMessage } = useChannelActionContext();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const channelFilters = useMemo(() => ({ members: { $in: [user?.id] } }), [user?.id]);

  const loadOptions = async (match, loadedOptions) => {
    let output = {
      options: [],
      hasMore: false,
    };

    try {
      const { duration, next, previous, results } = await client.search(
        channelFilters,
        { text: { $autocomplete: match } },
        { sort: [{ relevance: -1 }, { updated_at: 1 }, { my_custom_field: -1 }], limit: 10 }
      );

      console.log({ results });
      const options = (results || []).map(({ message }) => ({
        message,
        label: message?.text,
        value: message?.id,
      }));

      output = {
        options,
        // TODO: Wrong
        hasMore: !!next,
      };
    } catch (err) {
      console.log({ err });
      dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
    }

    return output;
  };

  return (
    <div className={className} ref={rootRef}>
      <CommonAsyncPaginateSelect
        {...SelectProps}
        // TODO:
        // key={JSON.stringify({ filter, idField, indexName, nameField, sort })}
        loadOptions={loadOptions}
        onChange={(option) => {
          console.log('// TODO:', { channel: option.message?.channel });

          // setActiveChannel(option.message?.channel, {});
          // jumpToMessage(option.message?.id);
        }}
      />
    </div>
  );
});

// ===================================================================================================

const ChatPanel = () => {
  const { streamChatClient } = useStreamChat();
  const theme = useTheme();

  if (!streamChatClient) return null;

  return (
    <Chat client={streamChatClient} theme={`str-chat__theme-${theme.palette.mode}`}>
      <ChatPanelRoot />
    </Chat>
  );
};

// TODO: Review `withReducer`
export default withReducer('chatPanel', reducer)(memo(ChatPanel));
