import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line import/prefer-default-export
export const buildCostCodeSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'costCode',
    headerName: 'Cost Code',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'csiClassification.title',
        headerName: 'CSI Title',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'csiClassification.name',
        headerName: 'CSI Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'csiClassification.number',
        headerName: 'CSI Number',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'csiClassification.standard.name',
        headerName: 'CSI Standard',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'accountingExpenseType.name',
        headerName: 'Expense Type',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'accountingTaskCode.name',
        headerName: 'Task Code Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'accountingTaskCode.number',
        headerName: 'Task Code Number',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'isCapitalExpense',
        headerName: 'Capital Expense',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'capitalExpenseThreshold',
        headerName: 'Capital Expense Threshold',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'text',
        field: 'owner.name',
        headerName: 'Owner',
        sortable: true,
      },
    ],
  },
];
