import { gql } from '@apollo/client';
import { ACCOUNTING_EXPENSE_TYPE_CREATE_DIALOG_ACCOUNTING_EXPENSE_TYPE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ACCOUNTING_EXPENSE_TYPE = gql`
  mutation CreateAccountingExpenseType($data: AccountingExpenseTypeCreateInput!) {
    createAccountingExpenseType(data: $data) {
      id
      ...AccountingExpenseTypeCreateDialogAccountingExpenseTypeFragment
    }
  }
  ${ACCOUNTING_EXPENSE_TYPE_CREATE_DIALOG_ACCOUNTING_EXPENSE_TYPE_FRAGMENT}
`;
