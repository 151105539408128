import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  AccountingExpenseTypeCreateDialog,
  AccountingExpenseTypeViewDrawer,
} from 'app/shared-components/AccountingExpenseType';
import {
  EntitySearchAppProvider,
  EntitySearchDrawer,
  EntitySearchHeader,
  EntitySearchInfiniteHitsGrid,
  makeEntitySearchCommonClientAdapter,
  useEntitySearchApp,
} from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { buildAccountingExpenseTypeSearchScreenColumnDefs } from './columnDefs';
import { accountingExpenseTypeSearchScreenFilterDefs } from './filterDefs';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
  },
  '& .FusePageSimple-sidebarContent': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-toolbar': {},
}));

const AccountingExpenseTypeSearchScreenRoot = ({ basePath }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(selectUser);
  const { viewMode } = useEntitySearchApp();
  const viewDrawerRef = useRef(null);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const canCreateAccountingExpenseType = useMemo(
    () =>
      Boolean(
        user?.data?.team?.isOrganizationAdmin === true &&
          ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum)
      ),
    [user]
  );

  const leftSidebarWidth = useMemo(() => 341, []);

  const rightSidebarOpen = useMemo(
    () => !!params?.accountingExpenseTypeId,
    [params?.accountingExpenseTypeId]
  );

  const rightSidebarWidth = useMemo(() => (isMobile ? 341 : 481), [isMobile]);

  const handleLeftSidebarClose = useCallback(() => setLeftSidebarOpen(false), []);

  const handleLeftSidebarToggle = useCallback(
    () => setLeftSidebarOpen(!leftSidebarOpen),
    [leftSidebarOpen]
  );

  const handleRightSidebarClose = useCallback(
    () => navigate(`${basePath}/search`),
    [basePath, navigate]
  );

  const handleRightSidebarOpen = useCallback(
    (data) => data?.id && navigate(`/accounting-expense-types/quick-view/${data.id}`),
    [navigate]
  );

  return (
    <Root
      content={
        <SizeMe monitorHeight monitorWidth={false}>
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {viewMode === 'grid' && (
                <EntitySearchInfiniteHitsGrid onRowClicked={handleRightSidebarOpen} {...size} />
              )}
            </div>
          )}
        </SizeMe>
      }
      header={
        <EntitySearchHeader
          dateRangeAttribute="createdAtTimestamp"
          leftSidebarToggle={handleLeftSidebarToggle}
          rightSidebarToggle={null}
          rightToolbarContent={
            <>
              <Button
                color="success"
                disabled={!canCreateAccountingExpenseType}
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <AccountingExpenseTypeCreateDialog
                          onClose={() => dispatch(closeDialog())}
                          onSuccess={handleRightSidebarOpen}
                        />
                      ),
                      classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                      onClose: () => null,
                    })
                  )
                }
              >
                Create
              </Button>
            </>
          }
        />
      }
      leftSidebarContent={
        <EntitySearchDrawer
          contentWidth={leftSidebarWidth - 1}
          dateRangeAttribute="createdAtTimestamp"
          filterDefs={accountingExpenseTypeSearchScreenFilterDefs}
          title="Expense Types"
          toggleDrawer={handleLeftSidebarToggle}
        />
      }
      leftSidebarOnClose={handleLeftSidebarClose}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={leftSidebarWidth}
      rightSidebarContent={
        <AccountingExpenseTypeViewDrawer
          basePath={basePath}
          contentWidth={rightSidebarWidth - 1}
          ref={viewDrawerRef}
          onCloseDrawer={handleRightSidebarClose}
        />
      }
      rightSidebarOnClose={handleRightSidebarClose}
      rightSidebarOpen={rightSidebarOpen}
      rightSidebarWidth={rightSidebarWidth}
      scroll="content"
    />
  );
};

const AccountingExpenseTypeSearchScreen = ({ basePath }) => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const accountingExpenseTypeSearchScreenColumnDefs =
    buildAccountingExpenseTypeSearchScreenColumnDefs({
      theme,
      user,
    });

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="accounting_expense_types"
      initialColumnDefs={accountingExpenseTypeSearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
    >
      <AccountingExpenseTypeSearchScreenRoot basePath={basePath} />
    </EntitySearchAppProvider>
  );
};

export default AccountingExpenseTypeSearchScreen;
