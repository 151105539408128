// TODO: Review / Remove
import '@mock-api';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import settingsConfig from 'app/configs/settingsConfig';
import { AuthProvider } from 'app/providers/auth';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectUser } from 'app/store/userSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import withAppProviders from './withAppProviders';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { useSearchParams } from 'react-router-dom';
import _ from '@lodash';
import { jwtService } from './providers/auth/services';

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

const RenameContext = React.createContext();
const RenameProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(selectUser);
  const [waitRenameCheck, setWaitRenameCheck] = useState(true);

  useEffect(() => {
    const renameParam = searchParams.get('renameParam');

    console.log('// TODO: RenameProvider => Outside', { renameParam });

    if (renameParam && user?.data?.team?.id) {
      console.log('// TODO: RenameProvider => Inside', { renameParam });

      const renameArray = renameParam.split(',');

      if (renameArray.includes(user?.data?.team?.id)) {
        console.log('// TODO: RenameProvider => Valid Team', {
          renameArray,
          teamId: user?.data?.team?.id,
        });

        const _searchParams = {};

        searchParams.forEach((value, key) => {
          if (key !== 'renameParam') {
            _searchParams[key] = value;
          }
        });

        setSearchParams(_searchParams);
      } else {
        console.log('// TODO: RenameProvider => Invalid Team', {
          renameArray,
          teamId: user?.data?.team?.id,
        });

        jwtService.reassignSession({ teamId: _.first(renameArray) });
      }
    }

    setWaitRenameCheck(false);
  }, [searchParams, setSearchParams, user?.data?.team?.id]);

  return waitRenameCheck ? (
    <FuseSplashScreen />
  ) : (
    <RenameContext.Provider value={{ placeholder: null }}>{children}</RenameContext.Provider>
  );
};

const App = () => {
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);
  const user = useSelector(selectUser);

  // TODO: Review
  const emotionCache = useMemo(
    () => createCache(emotionCacheOptions[langDirection]),
    [langDirection]
  );

  return (
    <CacheProvider value={emotionCache}>
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRole={user?.role}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
            >
              {/* TODO: Order */}
              <RenameProvider>
                <SnackbarProvider
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  classes={{
                    containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                  }}
                  maxSnack={5}
                >
                  <FuseLayout layouts={themeLayouts} />
                </SnackbarProvider>
              </RenameProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
};

// FIXME:
// export default Sentry.withProfiler(withAppProviders(App)());
export default withAppProviders(App)();
