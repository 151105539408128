import { gql } from '@apollo/client';
import {
  SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_SERVICE_INVOICE_FRAGMENT,
  SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT,
} from './fragments';

export const FETCH_SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_DATA = gql`
  query FetchServiceInvoiceVendorReferenceNumberUpdateDialogData(
    $where: ServiceInvoiceWhereUniqueInput!
  ) {
    serviceInvoice(where: $where) {
      id
      ...ServiceInvoiceVendorReferenceNumberUpdateDialogServiceInvoiceFragment
    }
  }
  ${SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_SERVICE_INVOICE_FRAGMENT}
`;

export const UPDATE_SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER = gql`
  mutation UpdateServiceInvoiceVendorReferenceNumber(
    $where: ServiceTicketWhereUniqueInput!
    $data: ServiceInvoiceVendorReferenceNumberUpdateInput!
  ) {
    updateServiceInvoiceVendorReferenceNumber(where: $where, data: $data) {
      id
      ...ServiceInvoiceVendorReferenceNumberUpdateDialogServiceTicketFragment
    }
  }
  ${SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
