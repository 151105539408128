import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CSI_CLASSIFICATION_VIEW_DRAWER_CSI_CLASSIFICATION_FRAGMENT = gql`
  fragment CsiClassificationViewDrawerCsiClassificationFragment on CsiClassification {
    id
    createdAt
    updatedAt

    canBeUpdated
    name
    number
    standard {
      id
      enum
      name
    }
    title
  }
`;
