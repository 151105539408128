import { gql, useQuery } from '@apollo/client';
import _ from '@lodash';
import { CommonSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';
import { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const AccountingProjectNumberSuffixSelectField = forwardRef(
  ({ className, filters, ...SelectProps }, ref) => {
    const dispatch = useDispatch();
    const [filtersCache, setFiltersCache] = useState(null);
    const [options, setOptions] = useState(null);

    useQuery(
      gql`
        query GetAccountingProjectNumberSuffixSelectFieldData(
          $where: AccountingProjectNumberSuffixWhereInput!
        ) {
          accountingProjectNumberSuffixes(where: $where) {
            id
            enum
            name
          }
        }
      `,
      {
        skip: _.isEqual(filters, filtersCache) && !!options,
        variables: { where: { ...(filters ?? {}) } },
        onCompleted: ({ accountingProjectNumberSuffixes }) => {
          setFiltersCache(filters);
          setOptions(accountingProjectNumberSuffixes);
        },
        onError: ({ message }) =>
          dispatch(
            showMessage({ message: message ?? 'An Unknown Error Occurred!', variant: 'error' })
          ),
      }
    );

    return (
      <div className={className}>
        <CommonSelect
          {...SelectProps}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          options={options}
          ref={ref}
        />
      </div>
    );
  }
);

export default AccountingProjectNumberSuffixSelectField;
