import { useLazyQuery } from '@apollo/client';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ProgramCampaignViewScreenFacilitiesTab,
  ProgramCampaignViewScreenOverviewTab,
  ProgramCampaignViewScreenServiceTicketsTab,
  ProgramCampaignViewScreenWorkOrderIssuesTab,
} from './components';
import { FETCH_PROGRAM_CAMPAIGN_VIEW_SCREEN_DATA } from './queries';

const ProgramCampaignViewScreen = () => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [
    fetchProgramCampaignViewScreenData,
    {
      data: programCampaignViewScreenData,
      loading: programCampaignViewScreenLoading,
      refetch: programCampaignViewScreenRefetch,
    },
  ] = useLazyQuery(FETCH_PROGRAM_CAMPAIGN_VIEW_SCREEN_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Program Campaign Data',
          variant: 'error',
        })
      );
    },
  });

  const programCampaign = useMemo(
    () => programCampaignViewScreenData?.programCampaign,
    [programCampaignViewScreenData?.programCampaign]
  );

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const loading = useMemo(
    () => [programCampaignViewScreenLoading].includes(true),
    [programCampaignViewScreenLoading]
  );

  useEffect(() => {
    if (params?.programCampaignId) {
      fetchProgramCampaignViewScreenData({
        variables: { where: { id: params?.programCampaignId } },
      });
    }
  }, [fetchProgramCampaignViewScreenData, params?.programCampaignId]);

  return (
    <>
      {basePath === 'tabs/overview' && (
        <ProgramCampaignViewScreenOverviewTab programCampaign={programCampaign} />
      )}

      {basePath === 'tabs/service-tickets' && (
        <ProgramCampaignViewScreenServiceTicketsTab programCampaign={programCampaign} />
      )}

      {basePath === 'tabs/work-order-issues' && (
        <ProgramCampaignViewScreenWorkOrderIssuesTab programCampaign={programCampaign} />
      )}

      {basePath === 'tabs/facilities' && (
        <ProgramCampaignViewScreenFacilitiesTab programCampaign={programCampaign} />
      )}
    </>
  );
};

export default ProgramCampaignViewScreen;
