import { useLazyQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { lighten } from '@mui/material/styles';
import { showMessage } from 'app/store/fuse/messageSlice';
import { extractServiceTicketOrganizations } from 'app/utils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import {
  ServiceTicketAttachmentViewAppDownloadButton,
  ServiceTicketAttachmentViewAppFileItem,
} from './components';
import { FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_APP_DATA } from './queries';

const ServiceTicketAttachmentViewApp = ({ basePath }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const [
    fetchServiceTicketAttachmentViewAppData,
    {
      data: serviceTicketAttachmentViewAppData,
      loading: serviceTicketAttachmentViewAppLoading,
      refetch: serviceTicketAttachmentViewAppRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_ATTACHMENT_VIEW_APP_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceTicketAttachmentViewAppLoading].includes(true),
    [serviceTicketAttachmentViewAppLoading]
  );

  const serviceTicket = useMemo(
    () => serviceTicketAttachmentViewAppData?.serviceTicket,
    [serviceTicketAttachmentViewAppData?.serviceTicket]
  );

  const serviceTicketOrganizations = useMemo(
    () => serviceTicket && extractServiceTicketOrganizations(serviceTicket),
    [serviceTicket]
  );

  useEffect(() => {
    if (params?.serviceTicketId) {
      fetchServiceTicketAttachmentViewAppData({
        variables: { where: { id: params?.serviceTicketId } },
      });
    }
  }, [fetchServiceTicketAttachmentViewAppData, params?.serviceTicketId]);

  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          {(loading && !fetched) || !serviceTicket ? (
            <div className="flex w-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <Paper
              className="w-full overflow-scroll"
              elevation={0}
              square
              sx={{ backgroundColor: 'transparent', height: size?.height }}
            >
              {!_.isEmpty(serviceTicket?.attachments) ||
              !_.isEmpty(serviceTicket?.programCampaign?.program?.attachments) ||
              _.some(
                serviceTicket?.workOrder?.issues || [],
                (workOrderIssue) => !_.isEmpty(workOrderIssue.serviceIssue?.attachments)
              ) ||
              _.some(
                serviceTicket?.chatChannels || [],
                (serviceTicketChatChannel) =>
                  serviceTicketChatChannel?.isCollaborative === true &&
                  !_.isEmpty(serviceTicketChatChannel?.chatChannel?.attachments)
              ) ? (
                <div className="p-32">
                  {!_.isEmpty(serviceTicket?.attachments) && (
                    <Box
                      className="p-16 w-full rounded-16 mb-24 border"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? lighten(theme.palette.background.default, 0.4)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                    >
                      <div className="flex items-center">
                        <div className="flex-1">
                          <Typography className="text-10 uppercase">Service Ticket</Typography>

                          <Typography className="font-medium">Attachments</Typography>
                        </div>

                        <div>
                          <ServiceTicketAttachmentViewAppDownloadButton
                            attachments={serviceTicket?.attachments}
                          />
                        </div>
                      </div>

                      <div className="flex flex-wrap -m-8 mt-8">
                        {serviceTicket?.attachments?.map((attachment) => (
                          <ServiceTicketAttachmentViewAppFileItem
                            attachment={attachment}
                            basePath={basePath}
                            key={attachment.id}
                            serviceTicketOrganizations={serviceTicketOrganizations}
                            type="service_ticket_attachment"
                          />
                        ))}
                      </div>
                    </Box>
                  )}

                  {!_.isEmpty(serviceTicket?.programCampaign?.program?.attachments) && (
                    <Box
                      className="p-16 w-full rounded-16 mb-24 border"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? lighten(theme.palette.background.default, 0.4)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                    >
                      <div className="flex items-center">
                        <div className="flex-1">
                          <Typography className="text-10 uppercase">Program / Campaign</Typography>

                          <Typography className="font-medium">
                            {`${serviceTicket?.programCampaign?.program?.name} / ${serviceTicket?.programCampaign?.name}`}
                          </Typography>
                        </div>

                        <div>
                          <ServiceTicketAttachmentViewAppDownloadButton
                            attachments={serviceTicket?.programCampaign?.program?.attachments}
                          />
                        </div>
                      </div>

                      <div className="flex flex-wrap -m-8 mt-8">
                        {serviceTicket?.programCampaign?.program?.attachments?.map((attachment) => (
                          <ServiceTicketAttachmentViewAppFileItem
                            attachment={attachment}
                            basePath={basePath}
                            key={attachment.id}
                            serviceTicketOrganizations={serviceTicketOrganizations}
                            type="program_attachment"
                          />
                        ))}
                      </div>
                    </Box>
                  )}

                  {_.some(
                    serviceTicket?.workOrder?.issues || [],
                    (workOrderIssue) => !_.isEmpty(workOrderIssue.serviceIssue?.attachments)
                  ) && (
                    <>
                      {serviceTicket?.workOrder?.issues
                        ?.filter(
                          (workOrderIssue) => !_.isEmpty(workOrderIssue.serviceIssue?.attachments)
                        )
                        ?.map((workOrderIssue) => (
                          <Box
                            className="p-16 w-full rounded-16 mb-24 border"
                            key={workOrderIssue?.id}
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                  ? lighten(theme.palette.background.default, 0.4)
                                  : lighten(theme.palette.background.default, 0.02),
                            }}
                          >
                            <div className="flex items-center">
                              <div className="flex-1">
                                <Typography className="text-10 uppercase">
                                  Work Order Issue
                                </Typography>

                                <Typography className="font-medium">
                                  {workOrderIssue.serviceIssue?.name}
                                </Typography>
                              </div>

                              <div>
                                <ServiceTicketAttachmentViewAppDownloadButton
                                  attachments={workOrderIssue.serviceIssue?.attachments}
                                />
                              </div>
                            </div>

                            <div className="flex flex-wrap -m-8 mt-8">
                              {workOrderIssue.serviceIssue?.attachments?.map((attachment) => (
                                <ServiceTicketAttachmentViewAppFileItem
                                  attachment={attachment}
                                  basePath={basePath}
                                  key={attachment.id}
                                  serviceTicketOrganizations={serviceTicketOrganizations}
                                  type="service_issue_attachment"
                                />
                              ))}
                            </div>
                          </Box>
                        ))}
                    </>
                  )}

                  {_.some(
                    serviceTicket?.chatChannels || [],
                    (serviceTicketChatChannel) =>
                      serviceTicketChatChannel?.isCollaborative === true &&
                      !_.isEmpty(serviceTicketChatChannel?.chatChannel?.attachments)
                  ) && (
                    <>
                      {serviceTicket?.chatChannels
                        ?.filter(
                          (serviceTicketChatChannel) =>
                            !_.isEmpty(serviceTicketChatChannel?.chatChannel?.attachments)
                        )
                        ?.map((serviceTicketChatChannel) => (
                          <Box
                            className="p-16 w-full rounded-16 mb-24 border"
                            key={serviceTicketChatChannel?.id}
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                  ? lighten(theme.palette.background.default, 0.4)
                                  : lighten(theme.palette.background.default, 0.02),
                            }}
                          >
                            <div className="flex items-center">
                              <div className="flex-1">
                                <Typography className="text-10 uppercase">Chat Channel</Typography>

                                <Typography className="font-medium">
                                  {serviceTicketChatChannel?.chatChannel?.name}
                                </Typography>
                              </div>

                              <div>
                                <ServiceTicketAttachmentViewAppDownloadButton
                                  attachments={serviceTicketChatChannel?.chatChannel?.attachments}
                                />
                              </div>
                            </div>

                            <div className="flex flex-wrap -m-8 mt-8">
                              {serviceTicketChatChannel?.chatChannel?.attachments?.map(
                                (attachment) => (
                                  <ServiceTicketAttachmentViewAppFileItem
                                    attachment={attachment}
                                    basePath={basePath}
                                    key={attachment.id}
                                    serviceTicketOrganizations={serviceTicketOrganizations}
                                    type="chat_channel_attachment"
                                  />
                                )
                              )}
                            </div>
                          </Box>
                        ))}
                    </>
                  )}
                </div>
              ) : (
                <div className="flex items-center justify-center w-full h-full">
                  <Typography className="text-24">No Attachments Found</Typography>
                </div>
              )}
            </Paper>
          )}
        </div>
      )}
    </SizeMe>
  );
};

export default ServiceTicketAttachmentViewApp;
