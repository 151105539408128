import { gql } from '@apollo/client';
import { BUDGET_PROJECT_DELETE_DIALOG_BUDGET_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_BUDGET_PROJECT = gql`
  mutation DeleteBudgetProject($where: BudgetProjectWhereUniqueInput!) {
    deleteBudgetProject(where: $where) {
      id
      ...BudgetProjectDeleteDialogBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_DELETE_DIALOG_BUDGET_PROJECT_FRAGMENT}
`;
