import { gql } from '@apollo/client';
import { BUDGET_DELETE_DIALOG_BUDGET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_BUDGET = gql`
  mutation DeleteBudget($where: BudgetWhereUniqueInput!) {
    deleteBudget(where: $where) {
      id
      ...BudgetDeleteDialogBudgetFragment
    }
  }
  ${BUDGET_DELETE_DIALOG_BUDGET_FRAGMENT}
`;
