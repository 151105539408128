import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { CREATE_BUDGET } from './queries';

const defaultValues = {
  divisionName: '',
  divisionNumber: '',
  isDefault: false,
  name: '',
};

const schema = yup.object().shape({
  divisionName: yup.string().nullable().required('A division name must be provided.'),
  divisionNumber: yup.string().nullable().required('A division number must be provided.'),
  isDefault: yup.boolean().required(),
  name: yup.string().nullable().required('A name must be provided.'),
});

const BudgetCreateDialog = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createBudget, { loading: createBudgetLoading }] = useMutation(CREATE_BUDGET, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Budget Successfully Created',
          variant: 'success',
        })
      );

      // ROADMAP: Migrate Other Forms to Always Use a Callback
      if (typeof onSuccess === 'function') {
        onSuccess(data?.createBudget);
      }
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Creating Budget', variant: 'error' }));
    },
  });

  const loading = useMemo(() => [createBudgetLoading].includes(true), [createBudgetLoading]);

  const onSubmit = async (data) => {
    try {
      await createBudget({
        variables: {
          data: {
            divisionName: data.divisionName,
            divisionNumber: data.divisionNumber,
            isDefault: data.isDefault,
            name: data.name,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>Create Budget</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="budget-create-form"
            name="budget-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-1 items-center mb-24">
              <div className="flex-1">
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className=""
                      error={!!errors?.name}
                      fullWidth
                      helperText={errors?.name?.message}
                      placeholder="Enter Budget Name..."
                      required
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <div className="ml-24">
                <Controller
                  control={control}
                  name="isDefault"
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={!!field.value} color="info" />}
                      label="Default"
                      labelPlacement="start"
                      sx={{ ml: 0 }}
                    />
                  )}
                />
              </div>
            </div>

            <Controller
              control={control}
              name="divisionName"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.divisionName}
                  fullWidth
                  helperText={errors?.divisionName?.message}
                  placeholder="Enter Budget Division Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="divisionNumber"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.divisionNumber}
                  fullWidth
                  helperText={errors?.divisionNumber?.message}
                  placeholder="Enter Budget Division Number..."
                  required
                  variant="outlined"
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="budget-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default BudgetCreateDialog;
