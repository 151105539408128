import { gql } from '@apollo/client';
import { SERVICE_COMPLETION_ITEM_DELETE_DIALOG_SERVICE_COMPLETION_ITEM_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_SERVICE_COMPLETION_ITEM = gql`
  mutation DeleteServiceCompletionItem($where: ServiceCompletionItemWhereUniqueInput!) {
    deleteServiceCompletionItem(where: $where) {
      id
      ...ServiceCompletionItemDeleteDialogServiceCompletionItemFragment
    }
  }
  ${SERVICE_COMPLETION_ITEM_DELETE_DIALOG_SERVICE_COMPLETION_ITEM_FRAGMENT}
`;
