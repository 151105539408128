import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const AccountingProjectsViewPage = lazy(() => import('./AccountingProjectsViewPage'));
const accountingProjectsViewPageBase = '/accounting-projects';

// i18next.addResourceBundle('en', 'accountingProjectsViewPage', en);
// i18next.addResourceBundle('tr', 'accountingProjectsViewPage', tr);
// i18next.addResourceBundle('ar', 'accountingProjectsViewPage', ar);

const accountingProjectsViewPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${accountingProjectsViewPageBase}/view/:accountingProjectId/*`,
      element: <AccountingProjectsViewPage />,
    },
    {
      path: accountingProjectsViewPageBase,
      element: <Navigate to={`${accountingProjectsViewPageBase}/search`} />,
    },
  ],
};

export default accountingProjectsViewPageConfig;
