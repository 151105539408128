import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import { AgGridReact } from 'ag-grid-react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHits, useInstantSearch, usePagination, useSortBy } from 'react-instantsearch';
import { useSelector } from 'react-redux';
import { useEntitySearchApp } from '../EntitySearchApp';
import { EntitySearchInfiniteHitsGridDefaultCellRenderer } from '../EntitySearchInfiniteHitsGridDefaultCellRenderer';

const EntitySearchInfiniteHitsGrid = memo(({ height, onCellClicked, onRowClicked }) => {
  const dataSourceStateRef = useRef(null);
  const {
    gridReady,
    gridRef,
    hitsPerPage,
    indexName,
    initialColumnDefs,
    setGridReady,
    refreshSearch,
    setRefreshSearch,
  } = useEntitySearchApp();
  const [cachedInitialColumnDefs] = useState(() => initialColumnDefs);
  const gridInitialized = useRef(false);
  const mainTheme = useSelector(selectMainTheme);
  const { refine: refinePage } = usePagination();
  const { refine: refineSort } = useSortBy({
    // ROADMAP: Generate From `initialColumnDefs`
    items: [
      { label: 'None', value: indexName },
      { label: 'Name (asc)', value: `${indexName}:name:asc` },
      { label: 'Name (desc)', value: `${indexName}:name:desc` },
    ],
  });
  const { refresh, status, uiState } = useInstantSearch();
  const { results } = useHits();
  const sortModelRef = useRef(null);
  const uiStateRef = useRef(uiState);

  useEffect(() => {
    uiStateRef.current = uiState;
  }, [uiState]);

  const cellClickedListener = useCallback(
    (event) => {
      if (typeof onCellClicked === 'function') {
        onCellClicked(event?.data);
      }
    },
    [onCellClicked]
  );

  const dataSource = useMemo(
    () => ({
      rowCount: undefined,
      getRows: (params) => {
        dataSourceStateRef.current = params;

        if (sortModelRef.current && !_.isEqual(sortModelRef.current?.[0], params.sortModel?.[0])) {
          if (params.sortModel?.[0]) {
            refineSort(`${indexName}:${params.sortModel?.[0].colId}:${params.sortModel?.[0].sort}`);
          } else {
            refineSort(indexName);
          }
        } else {
          refinePage(params.startRow / hitsPerPage);
        }

        sortModelRef.current = params.sortModel;
      },
    }),
    [hitsPerPage, indexName, refinePage, refineSort]
  );

  const defaultColDef = useMemo(
    () => ({
      cellRenderer: EntitySearchInfiniteHitsGridDefaultCellRenderer,
      sortable: false,
    }),
    []
  );

  const gridStyle = useMemo(() => ({ width: '100%', height }), [height]);

  const gridTheme = useMemo(
    () => (mainTheme?.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'),
    [mainTheme]
  );

  const handleGetRowNodeId = useCallback(({ id }) => id, []);

  const handleRefreshSearch = useCallback(() => {
    refresh();
  }, [refresh]);

  const isSearching = useMemo(() => status === 'stalled', [status]);

  const onGridReady = useCallback(
    (params) => {
      if (!gridInitialized.current) {
        setGridReady(true);

        gridInitialized.current = true;
      }
    },
    [setGridReady]
  );

  const rowClickedListener = useCallback(
    (event) => {
      if (typeof onRowClicked === 'function') {
        onRowClicked(event?.data);
      }
    },
    [onRowClicked]
  );

  useEffect(() => {
    if (handleRefreshSearch && refreshSearch && setRefreshSearch) {
      handleRefreshSearch();

      setRefreshSearch(false);
    }
  }, [handleRefreshSearch, refreshSearch, setRefreshSearch]);

  useDeepCompareEffect(() => {
    if (dataSource && gridReady && gridRef) {
      gridRef.current.api.setGridOption('datasource', dataSource);
    }
  }, [
    uiState[indexName]?.menu,
    uiState[indexName]?.query,
    uiState[indexName]?.refinementList || [],
    uiState[indexName]?.sortBy,
  ]);

  useEffect(() => {
    if (
      dataSourceStateRef.current?.endRow > 0 &&
      dataSourceStateRef.current?.successCallback &&
      gridReady &&
      gridRef.current &&
      results?.hits &&
      _.isNumber(results?.nbHits)
    ) {
      const __isLastPage = dataSourceStateRef.current?.endRow >= results?.nbHits;

      if (__isLastPage && results?.nbHits === 0) {
        gridRef.current.api?.showNoRowsOverlay();
      } else {
        gridRef.current.api?.hideOverlay();
      }

      dataSourceStateRef.current?.successCallback(
        results?.hits,
        __isLastPage ? results?.nbHits : null
      );
    }
  }, [gridReady, gridRef, results?.hits, results?.nbHits]);

  return (
    <div className={gridTheme} style={gridStyle}>
      <AgGridReact
        animateRows
        cacheBlockSize={hitsPerPage}
        cacheOverflowSize={2}
        columnDefs={cachedInitialColumnDefs}
        defaultColDef={defaultColDef}
        getRowNodeId={handleGetRowNodeId}
        loading={isSearching}
        maxConcurrentDatasourceRequests={1}
        ref={gridRef}
        rowBuffer={0}
        rowSelection="multiple"
        rowModelType="infinite"
        onCellClicked={cellClickedListener}
        onGridReady={onGridReady}
        onRowClicked={rowClickedListener}
      />
    </div>
  );
});

export default EntitySearchInfiniteHitsGrid;
