import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Eventcalendar, getJson } from '@mobiscroll/js/mobiscroll.react.min';
// import { Button, Paper, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { useStreamChat } from 'app/providers/stream/chat';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { Chat } from 'stream-chat-react';
import ProgramCampaignViewScreenAccountingExpenseTypeAverageWidget from './ProgramCampaignViewScreenAccountingExpenseTypeAverageWidget';
import ProgramCampaignViewScreenAccountingExpenseTypeSpendWidget from './ProgramCampaignViewScreenAccountingExpenseTypeSpendWidget';
import ProgramCampaignViewScreenCostCodeAverageWidget from './ProgramCampaignViewScreenCostCodeAverageWidget';
import ProgramCampaignViewScreenCostCodeSpendWidget from './ProgramCampaignViewScreenCostCodeSpendWidget';
import ProgramCampaignViewScreenHeader from './ProgramCampaignViewScreenHeader';
import ProgramCampaignViewScreenFacilityCountWidget from './ProgramCampaignViewScreenFacilityCountWidget';
import ProgramCampaignViewScreenRecentChatWidget from './ProgramCampaignViewScreenRecentChatWidget';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-content': {
    backgroundColor: theme.palette.mode === 'light' ? '#f1f5f9' : '#111827',
  },
  '& .FusePageCarded-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
  },
  '& .FusePageCarded-rightSidebar': { zIndex: 9999 },
  '& .FusePageCarded-sidebarContent': {},
  '& .FusePageCarded-sidebarHeader': {},
  '& .FusePageCarded-toolbar': {},
}));

const ProgramCampaignViewScreenOverviewTabRoot = ({ programCampaign }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const user = useSelector(selectUser);

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const [myEvents, setEvents] = useState([]);
  const [isToastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState();

  const myView = useMemo(
    () => ({
      calendar: { type: 'month' },
      agenda: { type: 'day' },
    }),
    [],
  );

  const handleToastClose = useCallback(() => {
    setToastOpen(false);
  }, []);

  const handleEventClick = useCallback((args) => {
    setToastMessage(args.event.title);
    setToastOpen(true);
  }, []);

  useEffect(() => {
    getJson(
      'https://trial.mobiscroll.com/events/?vers=5',
      (events) => {
        setEvents(events);
      },
      'jsonp',
    );
  }, []);

  console.log('// TODO:', { programCampaign });

  return (
    <Root
      content={
        <>
          {!programCampaign ? (
            <FuseLoading className="h-full" />
          ) : (
            <div
              className="w-full overflow-x-hidden overflow-y-auto"
              style={{
                // NOTE: NavBar + ScreenHeader
                height: `calc(100vh - ${isMobile ? 48 + 257 : 64 + 161}px)`,
              }}
            >
              <div className="flex flex-col w-full max-w-5xl mx-auto py-24 px-24 md:px-0">
                <Alert severity="info">
                  <AlertTitle>Under Development</AlertTitle>
                  This area is under active development.
                </Alert>
                {/* <Grid container spacing={0}>
                  <Grid md={8} xs={12}>
                    <Grid container spacing={2}>
                      <Grid md={4} xs={12}>
                        <ProgramCampaignViewScreenFacilityCountWidget />
                      </Grid>

                      <Grid md={4} xs={12}>
                        <ProgramCampaignViewScreenFacilityCountWidget />
                      </Grid>

                      <Grid md={4} xs={12}>
                        <ProgramCampaignViewScreenFacilityCountWidget />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid md={6} xs={12}>
                        <ProgramCampaignViewScreenCostCodeSpendWidget
                          programCampaign={programCampaign}
                        />
                      </Grid>

                      <Grid md={6} xs={12}>
                        <ProgramCampaignViewScreenCostCodeAverageWidget
                          programCampaign={programCampaign}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid md={6} xs={12}>
                        <ProgramCampaignViewScreenAccountingExpenseTypeSpendWidget
                          programCampaign={programCampaign}
                        />
                      </Grid>

                      <Grid md={6} xs={12}>
                        <ProgramCampaignViewScreenAccountingExpenseTypeAverageWidget
                          programCampaign={programCampaign}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid className="sm:pl-24" md={4} xs={12}>
                    <Paper className="mb-24 border-1 rounded-0">
                      <div className="" style={{ height: 550 }}>
                        <Eventcalendar
                          clickToCreate={false}
                          dragToCreate={false}
                          dragToMove={false}
                          dragToResize={false}
                          eventDelete={false}
                          data={myEvents}
                          view={myView}
                          themeVariant={theme.palette.mode}
                          onEventClick={handleEventClick}
                        />
                      </div>
                    </Paper>


                    <Paper className="mb-24 border-1 rounded-0">
                      <div className="p-20 border-b-1">
                        <Typography className="text-16 font-medium">Channels</Typography>
                      </div>

                      <ProgramCampaignViewScreenRecentChatWidget
                        programCampaign={programCampaign}
                      />
                    </Paper>
                  </Grid>
                </Grid> */}
              </div>
            </div>
          )}
        </>
      }
      header={
        <>
          {!programCampaign ? (
            <FuseLoading />
          ) : (
            <ProgramCampaignViewScreenHeader
              basePath={basePath}
              programCampaign={programCampaign}
            />
          )}
        </>
      }
      scroll={isMobile ? 'normal' : 'content'}
    />
  );
};

const ProgramCampaignViewScreenOverviewTab = ({ programCampaign }) => {
  const { streamChatClient } = useStreamChat();
  const theme = useTheme();

  if (!streamChatClient) return null;

  return (
    <Chat client={streamChatClient} theme={`str-chat__theme-${theme.palette.mode}`}>
      <ProgramCampaignViewScreenOverviewTabRoot programCampaign={programCampaign} />
    </Chat>
  );
};

export default ProgramCampaignViewScreenOverviewTab;
