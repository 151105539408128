import _ from '@lodash';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildBudgetProjectSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'budgetProject',
    headerName: 'Budget Project',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'budget.name',
        headerName: 'Budget',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'codingType.name',
        headerName: 'Coding Type',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'generalLedgerNumber',
        headerName: 'GL Number',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'generalLedgerAccount',
        headerName: 'GL Account',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'projectCode',
        headerName: 'Project Code',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'commonStandardRateTypes.commonStandardRateType.name',
        headerName: 'Rate Types',
        sortable: false,
        valueFormatter: ({ value }) => {
          const commonStandardRateTypes = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(commonStandardRateTypes)
            ? _.uniq(commonStandardRateTypes).join(', ')
            : undefined;
        },
        valueGetter: ({ data }) =>
          data?.commonStandardRateTypes
            ?.map(({ commonStandardRateType }) => commonStandardRateType)
            ?.filter((el) => el) || undefined,
      },
      {
        cellDataType: 'boolean',
        field: 'isDefault',
        headerName: 'Default',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: '_count.costCodes',
        headerName: 'Cost Codes',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: '_count.serviceCompletionItems',
        headerName: 'Service Completion Items',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'owner.name',
        headerName: 'Owner',
        sortable: true,
      },
    ],
  },
];
