import _ from '@lodash';
import * as colors from '@mui/material/colors';
import numeral from 'numeral';

export const getProgramCampaignViewScreenColors = ({ limit = 10 }) => {
  const programCampaignViewScreenColors = _.shuffle(
    Object.entries(colors)
      .filter(([key, value]) =>
        [
          'amber',
          'blue',
          // 'blueGrey',
          'brown',
          // 'common',
          'cyan',
          // 'deepOrange',
          // 'deepPurple',
          'green',
          // 'grey',
          'indigo',
          // 'lightBlue',
          // 'lightGreen',
          // 'lime',
          'orange',
          'pink',
          'purple',
          'red',
          'teal',
          'yellow',
        ].includes(key)
      )
      .map(([key, value]) => value[500])
  );

  return programCampaignViewScreenColors.slice(0, limit);
};

export const programCampaignViewScreenCurrencyFormatter = (value) =>
  value ? numeral(value).format('$0,0.00') : '-';
