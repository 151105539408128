import { gql } from '@apollo/client';
import { BUDGET_SPECIAL_PROJECT_ACTIVATE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const ACTIVATE_BUDGET_SPECIAL_PROJECT = gql`
  mutation ActivateBudgetSpecialProject($where: BudgetSpecialProjectWhereUniqueInput!) {
    activateBudgetSpecialProject(where: $where) {
      id
      ...BudgetSpecialProjectActivateDialogBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_ACTIVATE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;
