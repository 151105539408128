import { faCircleCheck, faPauseCircle, faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch';
import { WorkOrderIssueBatchActionDialog } from 'app/shared-components/WorkOrderIssue/WorkOrderIssueBatchActionDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const WorkOrderIssueSelectedRowsControl = ({ selectedRows }) => {
  const dispatch = useDispatch();
  const { gridReady, gridRef, setRefreshSearch } = useEntitySearchApp();
  const theme = useTheme();

  const canBePaused = useMemo(
    () => selectedRows?.length && _.every(selectedRows, (r) => !!r.canBePaused),
    [selectedRows]
  );

  const canBeResumed = useMemo(
    () => selectedRows?.length && _.every(selectedRows, (r) => !!r.canBeResumed),
    [selectedRows]
  );

  const hasOpenWorkOrderIssueReview = useMemo(
    () => selectedRows?.length && _.every(selectedRows, (r) => !!r.hasOpenWorkOrderIssueReview),
    [selectedRows]
  );

  const handleProcessingComplete = ({ processorOutput }) => {
    if (gridReady && gridRef?.current) {
      gridRef.current.api.deselectAll();
    }

    if (typeof setRefreshSearch === 'function') {
      setRefreshSearch(true);
    }
  };

  return (
    <div
      className="absolute right-0 bottom-0 m-32 rounded border-2 shadow"
      style={{
        backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
      }}
    >
      <div
        className="flex items-center py-6 px-8 border-b-1"
        style={{
          borderRadius: '4px 4px 0 0',
          backgroundColor: theme.palette.mode === 'light' ? '#f5f5f5' : '#18212f',
        }}
      >
        <Typography className="flex-1 text-16 font-500">Actions</Typography>

        <Avatar
          className="w-24 h-24 text-14 font-500"
          style={{ color: 'white', backgroundColor: '#4f46e5' }}
          variant="rounded"
        >
          {selectedRows?.length}
        </Avatar>
      </div>

      <div className="flex items-center w-full p-6">
        <Tooltip title="Complete Review(s)">
          <div className="mx-1">
            <IconButton
              disabled={!hasOpenWorkOrderIssueReview}
              onClick={() =>
                dispatch(
                  openDialog({
                    children: (
                      <WorkOrderIssueBatchActionDialog
                        actionType="COMPLETE_REVIEW"
                        selectedRows={selectedRows}
                        onProcessingComplete={handleProcessingComplete}
                        onClose={() => dispatch(closeDialog())}
                      />
                    ),
                    classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                    onClose: () => null,
                  })
                )
              }
            >
              <FontAwesomeIcon icon={faCircleCheck} />
            </IconButton>
          </div>
        </Tooltip>

        <Tooltip title="Pause Issue(s)">
          <div className="mx-1">
            <IconButton
              disabled={!canBePaused}
              onClick={() =>
                dispatch(
                  openDialog({
                    children: (
                      <WorkOrderIssueBatchActionDialog
                        actionType="PAUSE_ISSUE"
                        selectedRows={selectedRows}
                        onProcessingComplete={handleProcessingComplete}
                        onClose={() => dispatch(closeDialog())}
                      />
                    ),
                    classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                    onClose: () => null,
                  })
                )
              }
            >
              <FontAwesomeIcon icon={faPauseCircle} />
            </IconButton>
          </div>
        </Tooltip>

        <Tooltip title="Resume Issue(s)">
          <div className="mx-1">
            <IconButton
              disabled={!canBeResumed}
              onClick={() =>
                dispatch(
                  openDialog({
                    children: (
                      <WorkOrderIssueBatchActionDialog
                        actionType="RESUME_ISSUE"
                        selectedRows={selectedRows}
                        onProcessingComplete={handleProcessingComplete}
                        onClose={() => dispatch(closeDialog())}
                      />
                    ),
                    classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                    onClose: () => null,
                  })
                )
              }
            >
              <FontAwesomeIcon icon={faPlayCircle} />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default WorkOrderIssueSelectedRowsControl;
