import { gql } from '@apollo/client';
import { BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT } from './fragments';

export const ADD_BUDGET_PROJECT_COST_CODE = gql`
  mutation AddBudgetProjectCostCode(
    $where: BudgetProjectWhereUniqueInput!
    $data: BudgetProjectAddCostCodeInput!
  ) {
    addBudgetProjectCostCode(where: $where, data: $data) {
      id
      ...BudgetProjectViewScreenBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT}
`;

export const ADD_BUDGET_PROJECT_SERVICE_COMPLETION_ITEM = gql`
  mutation AddBudgetProjectServiceCompletionItem(
    $where: BudgetProjectWhereUniqueInput!
    $data: BudgetProjectAddServiceCompletionItemInput!
  ) {
    addBudgetProjectServiceCompletionItem(where: $where, data: $data) {
      id
      ...BudgetProjectViewScreenBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT}
`;

export const FETCH_BUDGET_PROJECT_VIEW_SCREEN_DATA = gql`
  query FetchBudgetProjectViewScreenData($where: BudgetProjectWhereUniqueInput!) {
    budgetProject(where: $where) {
      id
      ...BudgetProjectViewScreenBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT}
`;

export const REMOVE_BUDGET_PROJECT_COST_CODE = gql`
  mutation RemoveBudgetProjectCostCode(
    $where: BudgetProjectWhereUniqueInput!
    $data: BudgetProjectRemoveCostCodeInput!
  ) {
    removeBudgetProjectCostCode(where: $where, data: $data) {
      id
      ...BudgetProjectViewScreenBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT}
`;

export const REMOVE_BUDGET_PROJECT_SERVICE_COMPLETION_ITEM = gql`
  mutation RemoveBudgetProjectServiceCompletionItem(
    $where: BudgetProjectWhereUniqueInput!
    $data: BudgetProjectRemoveServiceCompletionItemInput!
  ) {
    removeBudgetProjectServiceCompletionItem(where: $where, data: $data) {
      id
      ...BudgetProjectViewScreenBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT}
`;

export const UPDATE_BUDGET_PROJECT = gql`
  mutation UpdateBudgetProject(
    $where: BudgetProjectWhereUniqueInput!
    $data: BudgetProjectUpdateInput!
  ) {
    updateBudgetProject(where: $where, data: $data) {
      id
      ...BudgetProjectViewScreenBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT}
`;
