import { gql } from '@apollo/client';
import { CSI_CLASSIFICATION_DELETE_DIALOG_CSI_CLASSIFICATION_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_CSI_CLASSIFICATION = gql`
  mutation DeleteCsiClassification($where: CsiClassificationWhereUniqueInput!) {
    deleteCsiClassification(where: $where) {
      id
      ...CsiClassificationDeleteDialogCsiClassificationFragment
    }
  }
  ${CSI_CLASSIFICATION_DELETE_DIALOG_CSI_CLASSIFICATION_FRAGMENT}
`;
