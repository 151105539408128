import React, { useEffect, useState } from 'react';
import _ from '@lodash';
import axios from 'axios';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import {
  jwtService,
  JWT_SERVICE_EVENTS,
  JWT_SERVICE_TOKEN_TYPES,
} from 'app/providers/auth/services';

const { REACT_APP_MEILISEARCH_HOST } = process.env;

const axiosSearchClient = axios.create({ baseURL: REACT_APP_MEILISEARCH_HOST });

const MeilisearchContext = React.createContext();

const MeilisearchProvider = ({ children }) => {
  const [instantSearchClient, setInstantSearchClient] = useState(null);
  const [searchToken, setSearchToken] = useState(null);

  useEffect(() => {
    jwtService.on(
      [
        JWT_SERVICE_EVENTS.ON_COMPLETE_SIGN_IN,
        JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION,
        JWT_SERVICE_EVENTS.ON_REFRESH_SESSION,
      ],
      ({ data }) => {
        if (_.get(data, JWT_SERVICE_TOKEN_TYPES.SEARCH_TOKEN)) {
          setSearchToken(_.get(data, JWT_SERVICE_TOKEN_TYPES.SEARCH_TOKEN));
        }
      }
    );

    jwtService.on(
      [JWT_SERVICE_EVENTS.ON_CLIENT_RESPONSE_ERROR, JWT_SERVICE_EVENTS.ON_REVOKE_SESSION],
      () => setSearchToken(null)
    );
  }, []);

  useEffect(() => {
    if (searchToken) {
      axiosSearchClient.defaults.headers.common.Authorization = `Bearer ${searchToken}`;

      const { searchClient, setMeiliSearchParams } = instantMeiliSearch(
        REACT_APP_MEILISEARCH_HOST,
        searchToken,
        {
          primaryKey: 'id',
        }
      );

      setInstantSearchClient(searchClient);
    } else {
      delete axiosSearchClient.defaults.headers.common.Authorization;

      setInstantSearchClient(null);
    }
  }, [searchToken]);

  return (
    <MeilisearchContext.Provider
      value={{
        axiosSearchClient,
        instantSearchClient,
      }}
    >
      {children}
    </MeilisearchContext.Provider>
  );
};

export const __MeilisearchContext = MeilisearchContext;
export default MeilisearchProvider;
