import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { AccountingProjectCodingTypeSelectField } from 'app/shared-components/AccountingProjectCodingType';
import { AccountingProjectNumberSuffixSelectField } from 'app/shared-components/AccountingProjectNumberSuffix';
import { CommonStandardRateTypeSelectField } from 'app/shared-components/CommonStandardRateType';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { AccountingProjectCreateDialogAttachments } from './components';
import { CREATE_ACCOUNTING_PROJECT } from './queries';

const defaultValues = {
  acceptedCommonStandardRateTypes: [],
  account: '',
  accountingWorkOrderTypes: [],
  attachments: [],
  codingType: null,
  name: '',
  number: '',
  numberSuffix: null,
};

const schema = yup.object().shape({
  acceptedCommonStandardRateTypes: yup
    .array()
    .test(
      'requireOneAcceptedCommonStandardRateType',
      'One or more standard rate types must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
  account: yup.mixed().when('codingType', {
    is: (option) => ['GL', 'GL_POET'].includes(option?.enum),
    then: yup.string().nullable().required('An account must be provided.'),
  }),
  accountingWorkOrderTypes: yup.array().optional(),
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  codingType: yup.object().nullable().required('A coding type must be provided.'),
  name: yup.string().nullable().required('A name must be provided.'),
  number: yup.mixed().when('codingType', {
    is: (option) => ['GL_POET', 'POET'].includes(option?.enum),
    then: yup.string().nullable().required('A number must be provided.'),
  }),
  numberSuffix: yup.mixed().when('codingType', {
    is: (option) => ['GL_POET', 'POET'].includes(option?.enum),
    then: yup.object().nullable().optional(),
  }),
});

const AccountingProjectCreateDialog = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createAccountingProject, { loading: createAccountingProjectLoading }] = useMutation(
    CREATE_ACCOUNTING_PROJECT,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Accounting Project Successfully Created',
            variant: 'success',
          })
        );

        // ROADMAP: Migrate Other Forms to Always Use a Callback
        if (typeof onSuccess === 'function') {
          onSuccess(data);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Accounting Project', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [createAccountingProjectLoading].includes(true),
    [createAccountingProjectLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createAccountingProject({
        variables: {
          data: {
            acceptedCommonStandardRateTypes: data.acceptedCommonStandardRateTypes?.map(
              ({ value }) => ({ id: value })
            ),
            account: data.account,
            accountingWorkOrderTypes: data.accountingWorkOrderTypes?.map(({ id }) => ({
              id,
            })),
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            codingType: { id: data.codingType?.id },
            name: data.name,
            number: data.number,
            numberSuffix: data.numberSuffix?.id ? { id: data.numberSuffix.id } : null,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Accounting Project
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="accounting-project-create-form"
            name="accounting-project-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Accounting Project Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="codingType"
              render={({ field }) => (
                <AccountingProjectCodingTypeSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.codingType}
                  filters={null}
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Accounting Project Coding Type..."
                  onChange={(params) => {
                    setValue('account', '');
                    setValue('number', '');
                    setValue('numberSuffix', null);

                    field.onChange(params);
                  }}
                />
              )}
            />

            {['GL', 'GL_POET'].includes(watchFields?.codingType?.enum) && (
              <>
                <Controller
                  control={control}
                  name="account"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      error={!!errors?.account}
                      fullWidth
                      helperText={errors?.account?.message}
                      placeholder="Enter Accounting Project Account..."
                      required
                      variant="outlined"
                    />
                  )}
                />
              </>
            )}

            {['GL_POET', 'POET'].includes(watchFields?.codingType?.enum) && (
              <>
                <Controller
                  control={control}
                  name="number"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-24"
                      error={!!errors?.number}
                      fullWidth
                      helperText={errors?.number?.message}
                      placeholder="Enter Accounting Project Number..."
                      required
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="numberSuffix"
                  render={({ field }) => (
                    <AccountingProjectNumberSuffixSelectField
                      {...field}
                      className="mb-24"
                      error={errors?.numberSuffix}
                      filters={null}
                      isDisabled={false}
                      isClearable
                      isMulti={false}
                      placeholder="Select Number Suffix..."
                    />
                  )}
                />
              </>
            )}

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="acceptedCommonStandardRateTypes"
              render={({ field }) => (
                <CommonStandardRateTypeSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.acceptedCommonStandardRateTypes}
                  filters={null}
                  isDisabled={false}
                  isClearable
                  isMulti
                  placeholder="Select Standard Rate Types..."
                />
              )}
            />

            <Controller
              control={control}
              name="accountingWorkOrderTypes"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  error={errors?.accountingWorkOrderTypes}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  filter={null}
                  indexName="accounting_work_order_types"
                  isClearable
                  isDisabled={false}
                  isMulti
                  placeholder="Select Accounting Work Order Types..."
                  sort={['name:asc']}
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <AccountingProjectCreateDialogAttachments name="attachments" />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="accounting-project-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default AccountingProjectCreateDialog;
