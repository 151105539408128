import { gql } from '@apollo/client';
import { BUDGET_VIEW_SCREEN_BUDGET_FRAGMENT } from './fragments';

export const ADD_BUDGET_FACILITY = gql`
  mutation AddBudgetFacility($where: BudgetWhereUniqueInput!, $data: BudgetAddFacilityInput!) {
    addBudgetFacility(where: $where, data: $data) {
      id
      ...BudgetViewScreenBudgetFragment
    }
  }
  ${BUDGET_VIEW_SCREEN_BUDGET_FRAGMENT}
`;

export const FETCH_BUDGET_VIEW_SCREEN_DATA = gql`
  query FetchBudgetViewScreenData($where: BudgetWhereUniqueInput!) {
    budget(where: $where) {
      id
      ...BudgetViewScreenBudgetFragment
    }
  }
  ${BUDGET_VIEW_SCREEN_BUDGET_FRAGMENT}
`;

export const REMOVE_BUDGET_FACILITY = gql`
  mutation RemoveBudgetFacility(
    $where: BudgetWhereUniqueInput!
    $data: BudgetRemoveFacilityInput!
  ) {
    removeBudgetFacility(where: $where, data: $data) {
      id
      ...BudgetViewScreenBudgetFragment
    }
  }
  ${BUDGET_VIEW_SCREEN_BUDGET_FRAGMENT}
`;

export const UPDATE_BUDGET = gql`
  mutation UpdateBudget($where: BudgetWhereUniqueInput!, $data: BudgetUpdateInput!) {
    updateBudget(where: $where, data: $data) {
      id
      ...BudgetViewScreenBudgetFragment
    }
  }
  ${BUDGET_VIEW_SCREEN_BUDGET_FRAGMENT}
`;
