import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const BudgetsSearchPage = lazy(() => import('./BudgetsSearchPage'));
export const budgetsSearchPageBase = '/budgets';

// i18next.addResourceBundle('en', 'budgetsSearchPage', en);
// i18next.addResourceBundle('tr', 'budgetsSearchPage', tr);
// i18next.addResourceBundle('ar', 'budgetsSearchPage', ar);

const budgetsSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${budgetsSearchPageBase}/search`,
      element: <BudgetsSearchPage />,
    },
    {
      path: budgetsSearchPageBase,
      element: <Navigate to={`${budgetsSearchPageBase}/search`} />,
    },
  ],
};

export default budgetsSearchPageConfig;
