import { useLazyQuery, useMutation } from '@apollo/client';
import {
  faBan,
  faDollarSign,
  faPlus,
  faTasks,
  faTextWidth,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { CommonImageLightbox } from 'app/shared-components/Common/CommonLightbox';
import { CommonVideoJsPlayer } from 'app/shared-components/Common/CommonVideo';
import { WorkOrderIssueCreateDialog } from 'app/shared-components/WorkOrderIssue';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import numeral from 'numeral';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import {
  WorkOrderViewAppApprovalPhaseBar,
  WorkOrderViewAppComments,
  WorkOrderViewAppDraftPhaseBar,
  WorkOrderViewAppInProgressPhaseBar,
  WorkOrderViewAppQuotingPhaseBar,
  WorkOrderViewAppReleasePhaseBar,
  WorkOrderViewAppSiteVisitPhaseBar,
  WorkOrderViewAppWorkOrderIssueTable,
} from './components';
import {
  FETCH_WORK_ORDER_VIEW_APP_DATA,
  UPDATE_SERVICE_TICKET_IS_REQUEST_FOR_QUOTE,
} from './queries';

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#f1f5f9' : '#111827',
  borderColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderTopWidth: 1,
  height: 63,
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

// TODO:
const ButtonWithTooltip = ({ disabled, TooltipProps, ...ButtonProps }) => {
  const button = useMemo(
    () => <Button disabled={disabled} {...ButtonProps} />,
    [ButtonProps, disabled]
  );

  return disabled ? button : <Tooltip {...TooltipProps}>{button}</Tooltip>;
};

const WorkOrderViewApp = forwardRef(({ basePath }, ref) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
  const [imageLightboxSlides, setImageLightboxSlides] = useState([]);
  const [openTables, setOpenTables] = useState({});
  const params = useParams();
  const rootRef = useRef(null);
  const [showCancelledWorkOrderIssues, setShowCancelledWorkOrderIssues] = useState(false);
  const [showCodingStrings, setShowCodingStrings] = useState(false);
  const theme = useTheme();
  const user = useSelector(selectUser);
  const [videoJsPlayerOpen, setVideoJsPlayerOpen] = useState(false);
  const [videoJsPlayerOptions, setVideoJsPlayerOptions] = useState({});

  const serviceTicketId = params?.serviceTicketId;

  const [
    fetchWorkOrderViewAppData,
    {
      data: workOrderViewAppData,
      loading: workOrderViewAppLoading,
      refetch: workOrderViewAppRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_VIEW_APP_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    updateServiceTicketIsRequestForQuote,
    { loading: updateServiceTicketIsRequestForQuoteLoading },
  ] = useMutation(UPDATE_SERVICE_TICKET_IS_REQUEST_FOR_QUOTE, {
    onCompleted: (data) => {
      dispatch(
        showMessage({
          message: 'Service Ticket Successfully Updated',
          variant: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Updating Service Ticket', variant: 'error' }));
    },
  });

  const consumer = useMemo(
    () => workOrderViewAppData?.serviceTicket?.serviceProvision?.organizationConnection.consumer,
    [workOrderViewAppData?.serviceTicket?.serviceProvision?.organizationConnection.consumer]
  );

  const creatorOrganizationType = useMemo(
    () => workOrderViewAppData?.serviceTicket?.creatorOrganizationType,
    [workOrderViewAppData?.serviceTicket?.creatorOrganizationType]
  );

  const isApprovalPhaseCompleted = useMemo(
    () => workOrderViewAppData?.serviceTicket?.isApprovalPhaseCompleted,
    [workOrderViewAppData?.serviceTicket?.isApprovalPhaseCompleted]
  );

  const isRequestForQuote = useMemo(
    () => workOrderViewAppData?.serviceTicket?.isRequestForQuote,
    [workOrderViewAppData?.serviceTicket?.isRequestForQuote]
  );

  const loading = useMemo(
    () => [workOrderViewAppLoading].includes(true),
    [workOrderViewAppLoading]
  );

  const phase = useMemo(
    () => workOrderViewAppData?.serviceTicket?.phase,
    [workOrderViewAppData?.serviceTicket?.phase]
  );

  const phaseReviews = useMemo(
    () => workOrderViewAppData?.serviceTicket?.phaseReviews,
    [workOrderViewAppData?.serviceTicket?.phaseReviews]
  );

  const status = useMemo(
    () => workOrderViewAppData?.serviceTicket?.status,
    [workOrderViewAppData?.serviceTicket?.status]
  );

  const vendor = useMemo(
    () => workOrderViewAppData?.serviceTicket?.serviceProvision?.organizationConnection.vendor,
    [workOrderViewAppData?.serviceTicket?.serviceProvision?.organizationConnection.vendor]
  );

  const workOrder = useMemo(
    () => workOrderViewAppData?.serviceTicket.workOrder,
    [workOrderViewAppData?.serviceTicket.workOrder]
  );

  const creator = useMemo(
    () =>
      [consumer, vendor]
        .filter((el) => el)
        .find(({ type }) => type.enum === creatorOrganizationType?.enum),
    [consumer, creatorOrganizationType, vendor]
  );

  // FIXME:
  const serviceTicketPhaseReview = useMemo(
    () =>
      phaseReviews?.find(({ isCancelled, isCompleted, serviceTicketPhase }) =>
        Boolean(!isCancelled && !isCompleted && serviceTicketPhase?.id === phase?.id)
      ),
    [phase?.id, phaseReviews]
  );

  // TODO:
  const canUpdateWorkOrder = useMemo(() => {
    let approval;

    // TODO: Expand
    if (phase?.enum === 'DRAFT' && status?.enum === 'OPEN') {
      approval = creator?.id === user?.data?.organization.id;
    } else if (phase?.enum === 'SITE_VISIT' && status?.enum === 'OPEN') {
      approval = vendor?.id === user?.data?.organization.id;

      // TODO: Wrong?
    } else if (phase?.enum === 'QUOTING' && status?.enum === 'OPEN' && !serviceTicketPhaseReview) {
      approval = vendor?.id === user?.data?.organization.id;
      // TODO: Switch
    } else if (phase?.enum === 'SCHEDULING' && status?.enum === 'OPEN') {
      approval = vendor?.id === user?.data?.organization.id;
    } else if (phase?.enum === 'IN_PROGRESS' && status?.enum === 'OPEN') {
      approval = vendor?.id === user?.data?.organization.id;
    }

    return approval;
  }, [
    creator?.id,
    phase?.enum,
    serviceTicketPhaseReview,
    status?.enum,
    user?.data?.organization.id,
    vendor?.id,
  ]);

  useImperativeHandle(ref, () => ({
    rootRef,
    refetchAll: () => {
      [workOrderViewAppRefetch].forEach(() => workOrderViewAppRefetch());
    },
  }));

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderViewAppData({ variables: { where: { id: serviceTicketId } } });
    }
  }, [fetchWorkOrderViewAppData, serviceTicketId]);

  const handleCloseImageLightbox = () => setImageLightboxOpen(false);

  const handleCloseVideoJsPlayer = () => setVideoJsPlayerOpen(false);

  const handleOpenImageLightbox = ({ slides }) => {
    if (_.isArray(slides) && !_.isEmpty(slides)) {
      setImageLightboxSlides(slides);
      setImageLightboxOpen(true);
    }
  };

  const handleOpenPdfWindow = ({ pdfUrl }) => {
    window.open(pdfUrl, '_blank', 'noreferrer');
  };

  const handleOpenVideoJsPlayer = ({ options }) => {
    if (_.isPlainObject(options) && !_.isEmpty(options)) {
      setVideoJsPlayerOptions(options);
      setVideoJsPlayerOpen(true);
    }
  };

  const handleToggleIsRequestForQuote = () => {
    updateServiceTicketIsRequestForQuote({
      variables: {
        where: { id: serviceTicketId },
        data: {
          isRequestForQuote: !isRequestForQuote,
        },
      },
    });
  };

  const handleToggleShowCodingStrings = () => setShowCodingStrings(!showCodingStrings);

  const handleToggleShowCancelledWorkOrderIssues = () =>
    setShowCancelledWorkOrderIssues(!showCancelledWorkOrderIssues);

  const handleToggleTable = ({ tableId }) =>
    setOpenTables((_openTables) => ({
      ...(_openTables || {}),
      [tableId]: !_openTables?.[tableId],
    }));

  return (
    <SizeMe
      // TODO: Review
      // refreshMode="debounce"
      monitorHeight
      monitorWidth={false}
    >
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          {(loading && !fetched) || !workOrder ? (
            <div className="flex w-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <div
                className="flex flex-col w-full overflow-scroll"
                style={{ height: size?.height }}
              >
                {phase.enum === 'DRAFT' && (
                  <WorkOrderViewAppDraftPhaseBar
                    // TODO:
                    canUpdateWorkOrder={canUpdateWorkOrder}
                    consumer={consumer}
                    creator={creator}
                    phase={phase}
                    serviceTicketId={serviceTicketId}
                    status={status}
                    vendor={vendor}
                    user={user}
                    workOrder={workOrder}
                  />
                )}

                {phase.enum === 'APPROVAL' && (
                  <WorkOrderViewAppApprovalPhaseBar
                    // TODO:
                    canUpdateWorkOrder={canUpdateWorkOrder}
                    consumer={consumer}
                    creator={creator}
                    phase={phase}
                    phaseReviews={phaseReviews}
                    serviceTicketId={serviceTicketId}
                    status={status}
                    vendor={vendor}
                    user={user}
                    workOrder={workOrder}
                  />
                )}

                {phase.enum === 'SITE_VISIT' && (
                  <WorkOrderViewAppSiteVisitPhaseBar
                    consumer={consumer}
                    isApprovalPhaseCompleted={isApprovalPhaseCompleted}
                    phase={phase}
                    serviceTicketId={serviceTicketId}
                    user={user}
                    vendor={vendor}
                    workOrder={workOrder}
                  />
                )}

                {phase.enum === 'QUOTING' && (
                  <WorkOrderViewAppQuotingPhaseBar
                    consumer={consumer}
                    phase={phase}
                    serviceTicketId={serviceTicketId}
                    user={user}
                    vendor={vendor}
                    workOrder={workOrder}
                  />
                )}

                {phase.enum === 'RELEASE' && (
                  <WorkOrderViewAppReleasePhaseBar
                    consumer={consumer}
                    phase={phase}
                    serviceTicketId={serviceTicketId}
                    user={user}
                    vendor={vendor}
                    // workOrder={workOrder}
                  />
                )}

                {phase.enum === 'IN_PROGRESS' && (
                  <WorkOrderViewAppInProgressPhaseBar
                    consumer={consumer}
                    // phase={phase}
                    serviceTicketId={serviceTicketId}
                    user={user}
                    vendor={vendor}
                    workOrder={workOrder}
                  />
                )}

                {/* TODO: */}
                <WorkOrderViewAppComments serviceTicketId={serviceTicketId} workOrder={workOrder} />

                <StyledDivider />

                <div
                  className="flex flex-row"
                  style={{
                    borderBottom: `1px solid ${
                      theme.palette.mode === 'light' ? grey[400] : grey[800]
                    }`,
                  }}
                >
                  <div className="flex flex-1 flex-col">
                    <div className="flex items-center mt-16 mb-48 px-24">
                      <div className="flex flex-1 items-center">
                        <FontAwesomeIcon className="mr-12" icon={faTasks} />
                        <Typography className="text-16 font-600 uppercase">
                          Service Issues
                        </Typography>
                      </div>

                      <div className="flex items-center">
                        {consumer?.id === user?.data?.organization.id && (
                          <Tooltip title="Toggle Coding Strings">
                            <span>
                              <IconButton
                                color={showCodingStrings ? 'info' : 'inherit'}
                                disabled={false}
                                size="small"
                                sx={{
                                  ml: 2,
                                  borderStyle: 'solid',
                                  borderWidth: '2px',
                                }}
                                onClick={handleToggleShowCodingStrings}
                              >
                                <FontAwesomeIcon className="w-16 h-16" icon={faTextWidth} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}

                        {!['DRAFT'].includes(phase.enum) && (
                          <Tooltip title="Toggle Cancelled Issues">
                            <span>
                              <IconButton
                                color={showCancelledWorkOrderIssues ? 'info' : 'inherit'}
                                disabled={false}
                                size="small"
                                sx={{
                                  ml: 2,
                                  borderStyle: 'solid',
                                  borderWidth: '2px',
                                }}
                                onClick={handleToggleShowCancelledWorkOrderIssues}
                              >
                                <FontAwesomeIcon className="w-16 h-16" icon={faBan} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}

                        {canUpdateWorkOrder &&
                          ['DRAFT'].includes(phase.enum) &&
                          consumer?.id === user?.data?.organization.id && (
                            <Tooltip title="Toggle Request For Quote">
                              <span>
                                <IconButton
                                  color={isRequestForQuote ? 'info' : 'inherit'}
                                  disabled={updateServiceTicketIsRequestForQuoteLoading}
                                  size="small"
                                  sx={{
                                    ml: 2,
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                  }}
                                  onClick={handleToggleIsRequestForQuote}
                                >
                                  <FontAwesomeIcon className="w-16 h-16" icon={faDollarSign} />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}

                        {canUpdateWorkOrder &&
                          // TODO:
                          ['DRAFT', 'IN_PROGRESS', 'QUOTING', 'REQUEST'].includes(phase.enum) && (
                            <Tooltip title="Add Service Issue">
                              <span>
                                <IconButton
                                  color="inherit"
                                  // TODO: Enable on Completion
                                  disabled
                                  size="small"
                                  sx={{
                                    ml: 2,
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                  }}
                                  onClick={() =>
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <WorkOrderIssueCreateDialog
                                            serviceTicketId={serviceTicketId}
                                            onClose={() => dispatch(closeDialog())}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    )
                                  }
                                >
                                  <FontAwesomeIcon className="w-16 h-16" icon={faPlus} />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {!_.isEmpty(workOrder.issues) ? (
                  <>
                    {_.map(
                      _.orderBy(
                        _.filter(workOrder.issues || [], (workOrderIssue) => {
                          if (!showCancelledWorkOrderIssues) {
                            return !['CANCELLED'].includes(workOrderIssue.status?.enum);
                          }

                          return true;
                        }),
                        [
                          'serviceIssue.serviceResponseSeverity.ordinal',
                          'serviceIssue.description',
                        ],
                        // TODO: Check with Chris
                        ['desc', 'desc']
                      ),
                      (workOrderIssue, index) => {
                        return (
                          // TODO:
                          <WorkOrderViewAppWorkOrderIssueTable
                            // TODO: Review
                            key={workOrderIssue?.id}
                            workOrderIssue={workOrderIssue}
                            // TODO:
                            consumer={consumer}
                            vendor={vendor}
                            user={user}
                            canUpdateWorkOrder={canUpdateWorkOrder}
                            handleToggleTable={handleToggleTable}
                            openTables={openTables}
                            phase={phase}
                            serviceTicketId={serviceTicketId}
                            handleOpenImageLightbox={handleOpenImageLightbox}
                            handleOpenPdfWindow={handleOpenPdfWindow}
                            // setImageLightboxSlides={setImageLightboxSlides}
                            handleOpenVideoJsPlayer={handleOpenVideoJsPlayer}
                            // setVideoJsPlayerOptions={setVideoJsPlayerOptions}
                            // TODO:
                            serviceTicketPhaseReview={serviceTicketPhaseReview}
                            // TODO:
                            showCodingStrings={showCodingStrings}
                          />
                        );
                      }
                    )}
                  </>
                ) : (
                  <div className="flex items-center justify-center w-full h-full">
                    <Typography className="text-20">No Service Issues Found</Typography>
                  </div>
                )}
              </div>

              <Footer>
                <div className="flex flex-1 items-center h-full px-24">
                  <div className="flex flex-1 flex-col">
                    <Typography className="mb-1 text-12 text-grey-700 font-600 uppercase">
                      NTE
                    </Typography>

                    <Typography className="text-16 font-600">
                      {numeral(workOrder?.notToExceedAmount).format('$0,0.00')}
                    </Typography>
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="mb-1 text-12 text-grey-700 font-600 uppercase">
                      Subtotal
                    </Typography>

                    <Typography className="text-16 font-600">
                      {numeral(workOrder?.subtotalAmount).format('$0,0.00')}
                    </Typography>
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="mb-1 text-12 text-grey-700 font-600 uppercase">
                      Shipping
                    </Typography>

                    <Typography className="text-16 font-600">
                      {numeral(workOrder?.shippingAmount).format('$0,0.00')}
                    </Typography>
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="mb-1 text-12 text-grey-700 font-600 uppercase">
                      Tax
                    </Typography>

                    <Typography className="text-16 font-600">
                      {numeral(workOrder?.taxAmount).format('$0,0.00')}
                    </Typography>
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="mb-1 text-12 text-grey-700 font-600 uppercase">
                      Total
                    </Typography>

                    <Typography className="text-16 font-600">
                      {numeral(workOrder?.totalAmount).format('$0,0.00')}
                    </Typography>
                  </div>
                </div>
              </Footer>

              {/* TODO: */}
              <CommonImageLightbox
                open={imageLightboxOpen}
                slides={imageLightboxSlides}
                onClose={handleCloseImageLightbox}
              />

              {/* TODO: */}
              <CommonVideoJsPlayer
                open={videoJsPlayerOpen}
                options={videoJsPlayerOptions}
                onClose={handleCloseVideoJsPlayer}
                onReady={null}
              />
            </div>
          )}
        </div>
      )}
    </SizeMe>
  );
});

export default WorkOrderViewApp;
