import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_DATA,
  UPDATE_SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER,
} from './queries';

const defaultValues = {
  vendorReferenceNumber: '',
};

const schema = yup.object().shape({
  vendorReferenceNumber: yup
    .string()
    .nullable()
    .required('A vendor reference number must be provided.'),
});

const ServiceInvoiceVendorReferenceNumberUpdateDialog = ({
  serviceInvoiceId,
  serviceTicketId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [
    fetchServiceInvoiceVendorReferenceNumberUpdateDialogData,
    {
      data: serviceInvoiceVendorReferenceNumberUpdateDialogData,
      loading: serviceInvoiceVendorReferenceNumberUpdateDialogLoading,
      refetch: serviceInvoiceVendorReferenceNumberUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Invoice Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    updateServiceInvoiceVendorReferenceNumber,
    { loading: updateServiceInvoiceVendorReferenceNumberLoading },
  ] = useMutation(UPDATE_SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Service Invoice Successfully Updated',
          variant: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Updating Service Invoice', variant: 'error' }));
    },
  });

  const loading = useMemo(
    () =>
      [
        serviceInvoiceVendorReferenceNumberUpdateDialogLoading,
        updateServiceInvoiceVendorReferenceNumberLoading,
      ].includes(true),
    [
      serviceInvoiceVendorReferenceNumberUpdateDialogLoading,
      updateServiceInvoiceVendorReferenceNumberLoading,
    ]
  );

  useEffect(() => {
    if (serviceInvoiceVendorReferenceNumberUpdateDialogData?.serviceInvoice) {
      setValue(
        'vendorReferenceNumber',
        serviceInvoiceVendorReferenceNumberUpdateDialogData?.serviceInvoice.vendorReferenceNumber ||
          ''
      );
    }
  }, [setValue, serviceInvoiceVendorReferenceNumberUpdateDialogData?.serviceInvoice]);

  useEffect(() => {
    if (serviceInvoiceId) {
      fetchServiceInvoiceVendorReferenceNumberUpdateDialogData({
        variables: { where: { id: serviceInvoiceId } },
      });
    }
  }, [fetchServiceInvoiceVendorReferenceNumberUpdateDialogData, serviceInvoiceId]);

  const onSubmit = async (data) => {
    try {
      await updateServiceInvoiceVendorReferenceNumber({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: serviceInvoiceId,
            vendorReferenceNumber: data.vendorReferenceNumber,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update Service Invoice
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-invoice-vendor-reference-number-update-form"
            name="service-invoice-vendor-reference-number-update-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="vendorReferenceNumber"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.vendorReferenceNumber}
                  fullWidth
                  helperText={errors?.vendorReferenceNumber?.message}
                  placeholder="Enter Vendor Reference Number..."
                  required
                  variant="outlined"
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-invoice-vendor-reference-number-update-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceInvoiceVendorReferenceNumberUpdateDialog;
