import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildBudgetSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'budget',
    headerName: 'Budget',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'divisionName',
        headerName: 'Division Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'divisionNumber',
        headerName: 'Division Number',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'isDefault',
        headerName: 'Default',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: '_count.facilities',
        headerName: 'Facilities',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: '_count.facilityAreas',
        headerName: 'Facility Areas',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: '_count.assets',
        headerName: 'Assets',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'owner.name',
        headerName: 'Owner',
        sortable: true,
      },
    ],
  },
];
