// eslint-disable-next-line import/prefer-default-export
export const csiClassificationSearchScreenFilterDefs = [
  {
    name: 'CSI Classification',
    refinements: [
      {
        attribute: 'standard.name',
        label: 'Standard',
        operator: 'or',
      },
    ],
  },
];
