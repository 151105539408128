import { gql, useApolloClient, useMutation } from '@apollo/client';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMeilisearch } from 'app/providers/meilisearch';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch/EntitySearchSelectFieldV2';
import { FacilityHitsListOption } from 'app/shared-components/Facility';
import { ServiceProvisionHitsListOption } from 'app/shared-components/ServiceProvision';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  // ROADMAP: Re-Implement or Remove
  // ServiceTicketCreateDialogServiceTicketAttachments,
  ServiceTicketCreateDialogWorkOrderIssues,
} from './components';
import { CREATE_SERVICE_TICKET } from './queries';

const defaultValues = {
  // ROADMAP: Re-Implement or Remove
  // attachments: [],
  facility: null,
  isRequestForQuote: false,
  serviceProvision: null,
  serviceResponseSeverity: null,
  workOrderIssues: [],
};

const buildSchema = ({ user }) =>
  yup.object().shape({
    // ROADMAP: Re-Implement or Remove
    // attachments: yup
    //   .array()
    //   .of(
    //     yup
    //       .object()
    //       .shape({
    //         file: yup.mixed().required(),
    //         commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
    //       })
    //       .required()
    //   )
    //   .optional(),
    facility: yup.object().nullable().required('A facility must be provided.'),
    isRequestForQuote: yup.boolean().required(),
    serviceProvision: yup.object().nullable().required('A service provision must be provided.'),
    workOrderIssues: yup
      .array()
      .of(
        yup.object().shape({
          actionItems: yup.array().of(
            yup.object().shape({
              costCode: yup
                .object()
                .nullable()
                .when('_user', {
                  is: () => ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum),
                  then: (schema) => schema.required('A cost code must be provided'),
                }),
              description: yup.string().nullable().optional(),
              notToExceedAmount: yup
                .number()
                .nullable()
                .min(0.01, 'A value must be provided.')
                .when('_user', {
                  is: () => ['MANUFACTURER', 'VENDOR'].includes(user?.data?.organization.type.enum),
                  then: (schema) => schema.required('A NTE must be provided'),
                }),
            })
          ),
          budget: yup.object().nullable().required('A budget must be provided'),
          budgetProject: yup.object().nullable().required('A budget project must be provided'),
          budgetSpecialProject: yup.object().nullable().optional(),
          completeBy: yup.date().nullable().required('A complete by date must be provided'),
          isSaveAsIssueTemplate: yup.boolean().optional(),
          issueTemplate: yup.object().nullable().optional(),
          notToExceedAmount: yup
            .string()
            .test(
              'numericStringGreaterThanZero',
              'A value must be provided.',
              (value) => !value || (value && Number(value) >= 0.01)
            ),
          saveAsIssueTemplate: yup.mixed().when('isSaveAsIssueTemplate', {
            is: (val) => !!val,
            then: yup.object().shape({
              description: yup.string().nullable().optional(),
              name: yup.string().nullable().required('A name must be provided.'),
              tags: yup
                .array()
                .of(
                  yup
                    .object()
                    .shape({
                      __isNew__: yup.boolean().optional(),
                      label: yup.string().required(),
                      result: yup.object().optional(),
                      value: yup.string().required(),
                    })
                    .required()
                )
                .optional(),
            }),
          }),
          serviceIssue: yup.object().shape({
            connect: yup.mixed().when('mutation', {
              is: (val) => val === 'CONNECT',
              then: yup.object().nullable().required('A service issue must be provided.'),
            }),
            create: yup.mixed().when('mutation', {
              is: (val) => val === 'CREATE',
              then: yup.object().shape({
                attachments: yup
                  .array()
                  .of(
                    yup
                      .object()
                      .shape({
                        file: yup.mixed().required(),
                        commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
                      })
                      .required()
                  )
                  .optional(),
                commonTargetType: yup
                  .object()
                  .nullable()
                  .required('A target type must be provided.'),
                description: yup.string().nullable().optional(),
                name: yup.string().nullable().required('A name must be provided.'),
                serviceResponseSeverity: yup
                  .object()
                  .nullable()
                  .required('A response severity must be provided.'),
                // ROADMAP: Re-Implement or Remove
                // serviceResponsibleParty: yup.object().nullable().optional(),
                // ROADMAP: Causes Incorrect Validation State with Uncontrolled Inputs
                // tags: yup
                //   .array()
                //   .of(
                //     yup
                //       .object()
                //       .shape({
                //         __isNew__: yup.boolean().optional(),
                //         label: yup.string().required(),
                //         result: yup.object().optional(),
                //         value: yup.string().required(),
                //       })
                //       .required()
                //   )
                //   .optional(),
                targetFacilityAreas: yup.mixed().when('commonTargetType', {
                  is: (val) => val?.result?.enum === 'FACILITY_AREA',
                  then: yup
                    .array()
                    .of(yup.object().required())
                    .min(1, 'Select at least one Target Facility Area')
                    .required(),
                }),
              }),
            }),
            mutation: yup
              .string()
              .nullable()
              .required('A service issue connection type must be provided.'),
          }),
        })
      )
      .min(1, 'Select or Create at least one Service Issue')
      .required(),
  });

const ServiceTicketCreateDialog = ({ onClose }) => {
  const apolloClient = useApolloClient();
  const { axiosSearchClient } = useMeilisearch();
  const [defaultBudget, setDefaultBudget] = useState(null);
  const [defaultBudgetDataLoading, setDefaultBudgetDataLoading] = useState(false);
  const [defaultBudgetProject, setDefaultBudgetProject] = useState(null);
  const [defaultCommonTargetType, setDefaultCommonTargetType] = useState(null);
  const [defaultCommonTargetTypeLoading, setDefaultCommonTargetTypeLoading] = useState(false);
  const [defaultServiceResponseSeverity, setDefaultServiceResponseSeverity] = useState(null);
  const [defaultServiceResponseSeverityLoading, setDefaultServiceResponseSeverityLoading] =
    useState(false);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const [sendServiceTicketOnCreate, setSendServiceTicketOnCreate] = useState(false);
  const user = useSelector(selectUser);

  const schema = useMemo(() => buildSchema({ user }), [user]);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  useEffect(() => {
    const getDefaultCommonTargetType = async () => {
      try {
        setDefaultCommonTargetTypeLoading(true);

        const {
          data: { commonTargetTypes },
        } = await apolloClient.query({
          query: gql`
            query GetDefaultCommonTargetType($where: CommonTargetTypeWhereInput!) {
              commonTargetTypes(where: $where) {
                id
                enum
                name
              }
            }
          `,
          variables: { where: {} },
        });

        const result = commonTargetTypes?.find(
          (commonTargetType) => commonTargetType.enum === 'FACILITY_AREA'
        );

        if (result) {
          setDefaultCommonTargetType({
            result,
            label: result?.name,
            value: result?.id,
          });
        }
      } catch (err) {
        //
      } finally {
        setDefaultCommonTargetTypeLoading(false);
      }
    };

    if (apolloClient) {
      getDefaultCommonTargetType();
    }
  }, [apolloClient]);

  useEffect(() => {
    const getDefaultBudgetData = async () => {
      try {
        setDefaultBudgetDataLoading(true);

        const commonBudgetDataFilter = [
          `isDefault = 'true'`,
          `owner.id IN [${watchFields?.facility?.organizations
            ?.map(({ organization }) => organization.id)
            .filter((el) => el)}]`,
          `status.enum = 'ACTIVE'`,
        ];

        const {
          data: { hits: budgetHits },
        } = await axiosSearchClient.post('/indexes/budgets/search', {
          limit: 1,
          filter: [
            ...commonBudgetDataFilter,
            `facilities.facility.id = '${watchFields?.facility?.id}'`,
          ],
          q: '',
        });

        const _defaultBudget = budgetHits?.[0];

        let _defaultBudgetProject;

        if (_defaultBudget) {
          const {
            data: { hits: budgetProjectHits },
          } = await axiosSearchClient.post('/indexes/budget_projects/search', {
            limit: 1,
            filter: [...commonBudgetDataFilter, `budget.id = '${_defaultBudget?.id}'`],
            q: '',
          });

          _defaultBudgetProject = budgetProjectHits?.[0];
        }

        setDefaultBudget(_defaultBudget);
        setDefaultBudgetProject(_defaultBudgetProject);
      } catch (err) {
        //
      } finally {
        setDefaultBudgetDataLoading(false);
      }
    };

    if (axiosSearchClient) {
      getDefaultBudgetData();
    }
  }, [axiosSearchClient, watchFields?.facility?.id, watchFields?.facility?.organizations]);

  useEffect(() => {
    const getDefaultServiceResponseSeverity = async () => {
      try {
        setDefaultServiceResponseSeverityLoading(true);

        const {
          data: { serviceResponseSeverities },
        } = await apolloClient.query({
          query: gql`
            query GetDefaultServiceResponseSeverity($where: ServiceResponseSeverityWhereInput!) {
              serviceResponseSeverities(where: $where) {
                id
                color
                commonTemporalDurationUnit {
                  id
                  enum
                  name
                }
                name
                value
              }
            }
          `,
          variables: { where: {} },
        });

        const result = serviceResponseSeverities?.find(({ name }) => name === 'Standard');

        if (result) {
          setDefaultServiceResponseSeverity({
            result,
            label: result?.name,
            value: result?.id,
          });
        }
      } catch (err) {
        //
      } finally {
        setDefaultServiceResponseSeverityLoading(false);
      }
    };

    if (apolloClient) {
      getDefaultServiceResponseSeverity();
    }
  }, [apolloClient]);

  const [createServiceTicket, { loading: createServiceTicketLoading }] = useMutation(
    CREATE_SERVICE_TICKET,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Ticket Successfully Created',
            variant: 'success',
          })
        );

        navigate(`/service-tickets/view/${data.createServiceTicket?.id}`);
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Service Ticket', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () =>
      [
        createServiceTicketLoading,
        defaultBudgetDataLoading,
        defaultCommonTargetTypeLoading,
        defaultServiceResponseSeverityLoading,
      ].includes(true),
    [
      createServiceTicketLoading,
      defaultBudgetDataLoading,
      defaultCommonTargetTypeLoading,
      defaultServiceResponseSeverityLoading,
    ]
  );

  const latestCompleteByMoment = (() => {
    let _latestCompleteByMoment;
    let longestDurationHours;

    watchFields?.workOrderIssues?.forEach(({ completeBy }) => {
      if (completeBy) {
        const duration = moment.duration(moment(completeBy).diff(moment()));

        if (
          !_latestCompleteByMoment ||
          !longestDurationHours ||
          duration.asHours() > longestDurationHours
        ) {
          _latestCompleteByMoment = moment(completeBy);
          longestDurationHours = duration.asHours();
        }
      }
    });

    return _latestCompleteByMoment;
  })();

  const serviceProvisionFilter = useMemo(() => {
    let _serviceProvisionFilter;

    if (watchFields?.facility?.id) {
      _serviceProvisionFilter = [
        `currentVersion.status.enum = 'ACTIVE'`,
        `facilities.id = '${watchFields?.facility?.id}'`,
        [
          `organizationConnection.consumer.id = '${user?.data?.organization?.id}'`,
          `organizationConnection.vendor.id = '${user?.data?.organization?.id}'`,
        ],
      ];
    }

    return _serviceProvisionFilter;
  }, [user?.data?.organization?.id, watchFields?.facility?.id]);

  const onSubmit = async (data) => {
    try {
      await createServiceTicket({
        variables: {
          data: {
            // ROADMAP: Re-Implement or Remove
            // attachments: data.attachments?.map((attachment) => ({
            //   commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            // })),
            facility: { id: data.facility?.id },
            isRequestForQuote: data.isRequestForQuote,
            sendServiceTicketOnCreate,
            serviceProvision: { id: data.serviceProvision?.id },
            workOrderIssues: data.workOrderIssues?.map((workOrderIssue) => ({
              actionItems: workOrderIssue.actionItems?.map((actionItem) => ({
                costCode: actionItem.costCode?.id ? { id: actionItem.costCode?.id } : undefined,
                description: actionItem.description,
                notToExceedAmount: actionItem.notToExceedAmount
                  ? Number(actionItem.notToExceedAmount)
                  : 0,
              })),
              budget: { id: workOrderIssue.budget?.id },
              budgetProject: { id: workOrderIssue.budgetProject?.id },
              budgetSpecialProject: workOrderIssue.budgetSpecialProject?.id
                ? { id: workOrderIssue.budgetSpecialProject?.id }
                : undefined,
              completeBy: workOrderIssue.completeBy && moment(workOrderIssue.completeBy).format(),
              issueTemplate:
                !workOrderIssue.isSaveAsIssueTemplate && workOrderIssue.issueTemplate
                  ? {
                      id: workOrderIssue.issueTemplate.id,
                    }
                  : undefined,
              notToExceedAmount: workOrderIssue.notToExceedAmount
                ? Number(workOrderIssue.notToExceedAmount)
                : 0,
              saveAsIssueTemplate:
                workOrderIssue.isSaveAsIssueTemplate && workOrderIssue.saveAsIssueTemplate
                  ? {
                      description: workOrderIssue.saveAsIssueTemplate.description,
                      name: workOrderIssue.saveAsIssueTemplate.name,
                      tags: workOrderIssue.saveAsIssueTemplate.tags?.map((tag) => ({
                        name: tag.__isNew__ ? tag.value : tag.result?.name,
                      })),
                    }
                  : undefined,
              serviceIssue: {
                connect: workOrderIssue.serviceIssue?.connect && {
                  id: workOrderIssue.serviceIssue?.connect.id,
                },
                create: workOrderIssue.serviceIssue?.create && {
                  attachments: workOrderIssue.serviceIssue.create.attachments?.map(
                    (attachment) => ({
                      commonStoredUpload: { key: attachment?.commonStoredUpload.key },
                    })
                  ),
                  commonTargetType: {
                    id: workOrderIssue.serviceIssue.create.commonTargetType?.value,
                  },
                  description: workOrderIssue.serviceIssue.create.description,
                  facility: { id: data.facility?.id },
                  name: workOrderIssue.serviceIssue.create.name,
                  serviceResponseSeverity: {
                    id: workOrderIssue.serviceIssue.create.serviceResponseSeverity?.value,
                  },
                  // ROADMAP: Re-Implement or Remove
                  // serviceResponsibleParty: workOrderIssue.serviceIssue.create
                  //   .serviceResponsibleParty && {
                  //   id: workOrderIssue.serviceIssue.create.serviceResponsibleParty?.value,
                  // },
                  tags: workOrderIssue.serviceIssue.create.tags?.map((tag) => ({
                    name: tag.__isNew__ ? tag.value : tag.result?.name,
                  })),
                  // ROADMAP: Implement
                  // targetAsset: workOrderIssue.serviceIssue.create.targetAsset && {
                  //   id: workOrderIssue.serviceIssue.create.targetAsset.value,
                  // },
                  targetFacilityAreas: workOrderIssue.serviceIssue.create.targetFacilityAreas?.map(
                    (targetFacilityArea) => ({ id: targetFacilityArea.id })
                  ),
                },
              },
            })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Service Ticket
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        {loading && !fetched ? (
          <div className="flex items-center justify-center h-200">
            <FuseLoading />
          </div>
        ) : (
          <FormProvider
            {...{
              clearErrors,
              control,
              formState,
              handleSubmit,
              reset,
              setValue,
              watch,
              ...methods,
            }}
          >
            <form
              className="w-full pt-20"
              id="service-ticket-create-form"
              name="service-ticket-create-form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-1 items-center mb-24">
                <Controller
                  control={control}
                  name="facility"
                  render={({ field }) => (
                    <EntitySearchSelectFieldV2
                      {...field}
                      className="flex-1"
                      components={{ Option: FacilityHitsListOption }}
                      error={errors?.facility}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      indexName="facilities"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Facility ..."
                      sort={['name:asc']}
                      onChange={(params) => {
                        setValue('serviceProvision', null);

                        field.onChange(params);
                      }}
                    />
                  )}
                />

                {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum) && (
                  <Controller
                    control={control}
                    name="isRequestForQuote"
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={!!field.value} color="info" />}
                        label="RFQ"
                        labelPlacement="start"
                        sx={{ ml: 3 }}
                      />
                    )}
                  />
                )}
              </div>

              {watchFields?.facility && (
                <Controller
                  control={control}
                  name="serviceProvision"
                  render={({ field }) => (
                    <EntitySearchSelectFieldV2
                      {...field}
                      className="mb-24"
                      components={{ Option: ServiceProvisionHitsListOption }}
                      error={errors?.serviceProvision}
                      filter={serviceProvisionFilter}
                      getOptionLabel={(option) => option?.organizationConnection?.vendor?.name}
                      getOptionValue={(option) => option?.id}
                      indexName="service_provisions"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Service Provision ..."
                      sort={['name:asc']}
                    />
                  )}
                />
              )}

              {watchFields?.facility && watchFields?.serviceProvision && (
                <>
                  {latestCompleteByMoment && (
                    <>
                      <Divider sx={{ mb: 3 }} />

                      <div className="mb-24">
                        <Alert severity="info">
                          <span className="mr-8">Latest Complete By:</span>

                          <strong>
                            {latestCompleteByMoment &&
                              latestCompleteByMoment.format('MMMM DD, YYYY')}
                          </strong>
                        </Alert>
                      </div>
                    </>
                  )}

                  <Divider sx={{ mb: 3 }} />

                  <ServiceTicketCreateDialogWorkOrderIssues
                    defaultBudget={defaultBudget}
                    defaultBudgetProject={defaultBudgetProject}
                    defaultCommonTargetType={defaultCommonTargetType}
                    defaultServiceResponseSeverity={defaultServiceResponseSeverity}
                    name="workOrderIssues"
                  />

                  {/* ROADMAP: Re-Implement or Remove */}
                  {/* <Divider sx={{ mb: 3 }} />
                  <ServiceTicketCreateDialogServiceTicketAttachments name="attachments" /> */}
                </>
              )}
            </form>
          </FormProvider>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex flex-1 flex-col sm:flex-row">
            <Button
              className="mb-8 sm:mb-0"
              color="primary"
              form="service-ticket-create-form"
              startIcon={
                <FontAwesomeIcon
                  className={clsx({ 'text-green': isValid, 'text-red': !isValid })}
                  icon={isValid ? faCircleCheck : faCircleXmark}
                />
              }
              type="submit"
              variant="contained"
              onClick={(event) => {
                if (isValid) {
                  event.preventDefault();
                }
              }}
            >
              Preflight
            </Button>
          </div>

          <div className="flex flex-col sm:flex-row">
            <Button className="mb-8 sm:mb-0" color="primary" onClick={onClose} variant="contained">
              Cancel
            </Button>

            <Button
              className="mb-8 ml-0 sm:mb-0 sm:ml-8"
              color="secondary"
              disabled={_.isEmpty(dirtyFields) || !isValid || loading}
              form="service-ticket-create-form"
              type="submit"
              variant="contained"
              onClick={() => setSendServiceTicketOnCreate(false)}
            >
              Create
            </Button>

            <Button
              className="mb-8 ml-0 sm:mb-0 sm:ml-8"
              color="secondary"
              disabled={_.isEmpty(dirtyFields) || !isValid || loading}
              form="service-ticket-create-form"
              type="submit"
              variant="contained"
              onClick={() => setSendServiceTicketOnCreate(true)}
            >
              Create + Send
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  );
};

export default ServiceTicketCreateDialog;
