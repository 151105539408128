import { useMutation } from '@apollo/client';
import { Switch } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ADD_BUDGET_PROJECT_COST_CODE, REMOVE_BUDGET_PROJECT_COST_CODE } from '../queries';

const BudgetProjectViewScreenCostCodeActiveCellRenderer = (params) => {
  const dispatch = useDispatch();

  const [addBudgetProjectCostCode, { loading: addBudgetProjectCostCodeLoading }] = useMutation(
    ADD_BUDGET_PROJECT_COST_CODE,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Cost Code Successfully Added',
            variant: 'success',
          })
        );

        params.setValue([
          ...(params.value || []),
          {
            budgetProject: {
              id: data.addBudgetProjectCostCode?.id,
              name: data.addBudgetProjectCostCode?.name,
            },
          },
        ]);
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Adding Cost Code', variant: 'error' }));
      },
    }
  );

  const [removeBudgetProjectCostCode, { loading: removeBudgetProjectCostCodeLoading }] =
    useMutation(REMOVE_BUDGET_PROJECT_COST_CODE, {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Cost Code Successfully Removed',
            variant: 'success',
          })
        );

        params.setValue(
          (params.value || []).filter(
            ({ budgetProject }) => budgetProject?.id !== data.removeBudgetProjectCostCode?.id
          )
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Removing Cost Code', variant: 'error' }));
      },
    });

  const checked = useMemo(
    () =>
      params?.value
        ?.map(({ budgetProject }) => budgetProject?.id)
        .includes(params?.budgetProjectId),
    [params?.budgetProjectId, params?.value]
  );

  const loading = useMemo(
    () => [addBudgetProjectCostCodeLoading, removeBudgetProjectCostCodeLoading].includes(true),
    [addBudgetProjectCostCodeLoading, removeBudgetProjectCostCodeLoading]
  );

  const handleChange = async (event) => {
    const {
      target: { checked: _checked },
    } = event;

    if (_checked && params.budgetProjectId && params.data?.id) {
      await addBudgetProjectCostCode({
        variables: {
          where: { id: params.budgetProjectId },
          data: { costCodes: [{ id: params.data?.id }] },
        },
      });
    } else if (!_checked && params.budgetProjectId && params.data?.id) {
      await removeBudgetProjectCostCode({
        variables: {
          where: { id: params.budgetProjectId },
          data: { costCodes: [{ id: params.data?.id }] },
        },
      });
    }
  };

  return (
    <div className="flex justify-center">
      <Switch checked={!!checked} color="success" disabled={loading} onChange={handleChange} />
    </div>
  );
};

export default BudgetProjectViewScreenCostCodeActiveCellRenderer;
