import { useLazyQuery, useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AccountingExpenseTypeDeleteDialog } from 'app/shared-components/AccountingExpenseType/AccountingExpenseTypeDeleteDialog';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  FETCH_ACCOUNTING_EXPENSE_TYPE_VIEW_DRAWER_DATA,
  UPDATE_ACCOUNTING_EXPENSE_TYPE,
} from './queries';

const DRAWER_APP_BAR_HEIGHT = 64;

const defaultValues = {
  name: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('A name must be provided.'),
});

const AccountingExpenseTypeViewDrawer = forwardRef(
  ({ basePath, contentWidth, onCloseDrawer }, ref) => {
    const dispatch = useDispatch();
    const [fetched, setFetched] = useState(false);
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const params = useParams();
    const rootRef = useRef(null);
    const { refreshSearch } = useEntitySearchApp();
    const user = useSelector(selectUser);
    const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
      useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(schema),
      });

    const { isValid, dirtyFields, errors } = formState;

    const watchFields = watch();

    const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

    const [
      fetchAccountingExpenseTypeViewDrawerData,
      {
        data: accountingExpenseTypeViewDrawerData,
        loading: accountingExpenseTypeViewDrawerLoading,
        refetch: accountingExpenseTypeViewDrawerRefetch,
      },
    ] = useLazyQuery(FETCH_ACCOUNTING_EXPENSE_TYPE_VIEW_DRAWER_DATA, {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setFetched(true),
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Fetching Accounting Expense Type Data',
            variant: 'error',
          })
        );
      },
    });

    const [updateAccountingExpenseType, { loading: updateAccountingExpenseTypeLoading }] =
      useMutation(UPDATE_ACCOUNTING_EXPENSE_TYPE, {
        onCompleted: (data) => {
          dispatch(
            showMessage({
              message: 'Accounting Expense Type Successfully Updated',
              variant: 'success',
            })
          );
        },
        onError: (error) => {
          dispatch(
            showMessage({ message: 'Failed Updating Accounting Expense Type', variant: 'error' })
          );
        },
      });

    const accountingExpenseType = useMemo(
      () => accountingExpenseTypeViewDrawerData?.accountingExpenseType,
      [accountingExpenseTypeViewDrawerData?.accountingExpenseType]
    );

    const loading = useMemo(
      () =>
        [accountingExpenseTypeViewDrawerLoading, updateAccountingExpenseTypeLoading].includes(true),
      [accountingExpenseTypeViewDrawerLoading, updateAccountingExpenseTypeLoading]
    );

    const canUpdateAccountingExpenseType = useMemo(
      () =>
        Boolean(
          accountingExpenseType?.canBeUpdated === true &&
            accountingExpenseType?.owner?.id === user?.data?.organization?.id
        ),
      [
        accountingExpenseType?.canBeUpdated,
        accountingExpenseType?.owner?.id,
        user?.data?.organization?.id,
      ]
    );

    useImperativeHandle(ref, () => ({
      rootRef,
      refetchAll: () => {
        [accountingExpenseTypeViewDrawerRefetch].forEach(() =>
          accountingExpenseTypeViewDrawerRefetch()
        );
      },
    }));

    useEffect(() => {
      if (accountingExpenseType) {
        const formData = {
          name: accountingExpenseType?.name,
        };

        reset(formData);
      }
    }, [reset, accountingExpenseType]);

    useEffect(() => {
      if (accountingExpenseTypeViewDrawerRefetch && fetched && refreshSearch) {
        accountingExpenseTypeViewDrawerRefetch();
      }
    }, [accountingExpenseTypeViewDrawerRefetch, fetched, refreshSearch]);

    useEffect(() => {
      if (params?.accountingExpenseTypeId) {
        setFetched(false);
        fetchAccountingExpenseTypeViewDrawerData({
          variables: { where: { id: params?.accountingExpenseTypeId } },
        });
      }
    }, [fetchAccountingExpenseTypeViewDrawerData, params?.accountingExpenseTypeId]);

    const handleCloseDrawer = useCallback(() => {
      if (typeof onCloseDrawer === 'function') {
        onCloseDrawer();
      }

      setFetched(false);
    }, [onCloseDrawer]);

    useEffect(() => {
      if (fetched && !accountingExpenseType) {
        handleCloseDrawer();
      }
    }, [accountingExpenseType, fetched, handleCloseDrawer]);

    const onSubmit = async (data) => {
      try {
        await updateAccountingExpenseType({
          variables: {
            where: {
              id: accountingExpenseType.id,
            },
            data: {
              name: data.name,
            },
          },
        });
      } catch (err) {
        //
      }
    };

    return (
      <div className="flex-1" style={{ width: contentWidth }}>
        <AppBar color="inherit" elevation={0} position="static">
          <Toolbar>
            <div className="flex flex-1">
              <Typography component="div" noWrap variant="h6">
                Expense Type Details
              </Typography>
            </div>

            <IconButton sx={{ width: 40, height: 40 }} onClick={handleCloseDrawer}>
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Paper
          className="flex flex-col p-24 overflow-auto border-t-1"
          elevation={0}
          square
          style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
        >
          {(loading && !fetched) || !accountingExpenseType ? (
            <div className="flex w-full h-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <FormProvider
              {...{
                clearErrors,
                control,
                formState,
                handleSubmit,
                reset,
                setValue,
                watch,
                ...methods,
              }}
            >
              <form
                className="w-full"
                id="accounting-expense-type-view-form"
                name="accounting-expense-type-view-form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors?.name}
                          fullWidth
                          helperText={errors?.name?.message}
                          inputProps={{ readOnly: !canUpdateAccountingExpenseType }}
                          required
                          variant="outlined"
                          placeholder="Enter Accounting Expense Type Name..."
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {canUpdateAccountingExpenseType && (
                  <>
                    <Divider className="my-16" />

                    <Grid container spacing={2}>
                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'left'}
                        md={6}
                        xs={12}
                      >
                        <Button
                          color="error"
                          disabled={loading}
                          fullWidth={isMobile}
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              openDialog({
                                children: (
                                  <AccountingExpenseTypeDeleteDialog
                                    accountingExpenseTypeId={accountingExpenseType?.id}
                                    onClose={() => dispatch(closeDialog())}
                                    onSuccess={handleCloseDrawer}
                                  />
                                ),
                                classes: {
                                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                                },
                              })
                            );
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>

                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'right'}
                        md={3}
                        xs={12}
                      >
                        <Button
                          color="primary"
                          disabled={_.isEmpty(dirtyFields) || loading}
                          fullWidth
                          variant="contained"
                          onClick={() => reset(undefined, { keepDefaultValues: true })}
                        >
                          Revert
                        </Button>
                      </Grid>

                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'right'}
                        md={3}
                        xs={12}
                      >
                        <Button
                          color="secondary"
                          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                          form="accounting-expense-type-view-form"
                          fullWidth
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </form>
            </FormProvider>
          )}
        </Paper>
      </div>
    );
  }
);

export default AccountingExpenseTypeViewDrawer;
