import { gql } from '@apollo/client';
import { COST_CODE_CREATE_DIALOG_COST_CODE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_COST_CODE = gql`
  mutation CreateCostCode($data: CostCodeCreateInput!) {
    createCostCode(data: $data) {
      id
      ...CostCodeCreateDialogCostCodeFragment
    }
  }
  ${COST_CODE_CREATE_DIALOG_COST_CODE_FRAGMENT}
`;
