import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const BudgetProjectsViewPage = lazy(() => import('./BudgetProjectsViewPage'));
const budgetProjectsViewPageBase = '/budget-projects';

// i18next.addResourceBundle('en', 'budgetProjectsViewPage', en);
// i18next.addResourceBundle('tr', 'budgetProjectsViewPage', tr);
// i18next.addResourceBundle('ar', 'budgetProjectsViewPage', ar);

const budgetProjectsViewPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${budgetProjectsViewPageBase}/view/:budgetProjectId/*`,
      element: <BudgetProjectsViewPage />,
    },
    {
      path: budgetProjectsViewPageBase,
      element: <Navigate to={`${budgetProjectsViewPageBase}/search`} />,
    },
  ],
};

export default budgetProjectsViewPageConfig;
