import { gql } from '@apollo/client';
import { SERVICE_COMPLETION_ITEM_VIEW_DRAWER_SERVICE_COMPLETION_ITEM_FRAGMENT } from './fragments';

export const FETCH_SERVICE_COMPLETION_ITEM_VIEW_DRAWER_DATA = gql`
  query FetchServiceCompletionItemViewDrawerData($where: ServiceCompletionItemWhereUniqueInput!) {
    serviceCompletionItem(where: $where) {
      id
      ...ServiceCompletionItemViewDrawerServiceCompletionItemFragment
    }
  }
  ${SERVICE_COMPLETION_ITEM_VIEW_DRAWER_SERVICE_COMPLETION_ITEM_FRAGMENT}
`;

export const UPDATE_SERVICE_COMPLETION_ITEM = gql`
  mutation UpdateServiceCompletionItem(
    $where: ServiceCompletionItemWhereUniqueInput!
    $data: ServiceCompletionItemUpdateInput!
  ) {
    updateServiceCompletionItem(where: $where, data: $data) {
      id
      ...ServiceCompletionItemViewDrawerServiceCompletionItemFragment
    }
  }
  ${SERVICE_COMPLETION_ITEM_VIEW_DRAWER_SERVICE_COMPLETION_ITEM_FRAGMENT}
`;
