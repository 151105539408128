import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ACCOUNTING_PROJECT_VIEW_SCREEN_ACCOUNTING_PROJECT_FRAGMENT = gql`
  fragment AccountingProjectViewScreenAccountingProjectFragment on AccountingProject {
    id
    createdAt
    updatedAt

    acceptedCommonStandardRateTypes {
      id
      enum
      name
    }
    account
    accountingWorkOrderTypes {
      id
      name
    }
    attachments {
      id
      createdAt
      commonStoredImage {
        id
        # TODO: Use or Remove
        # commonStoredUpload {
        #   id
        #   uploader {
        #     id
        #     name
        #   }
        # }
        isProcessed
        lightboxImageUrl
        name
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredType {
        id
        enum
        name
      }
      commonStoredPdf {
        id
        # TODO: Use or Remove
        # commonStoredUpload {
        #   id
        #   uploader {
        #     id
        #     name
        #   }
        # }
        isProcessed
        lightboxImageUrl
        name
        originalPdfUrl
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredVideo {
        id
        captionsFileUrl
        # TODO: Use or Remove
        # commonStoredUpload {
        #   id
        #   uploader {
        #     id
        #     name
        #   }
        # }
        isProcessed
        lightboxImageUrl
        name
        playlistFileUrl
        standardImageUrl
        thumbnailImageUrl
      }
    }
    codingType {
      id
      enum
      name
    }
    isEditable
    name
    number
    numberSuffix {
      id
      enum
      name
    }
    owner {
      id
      name
    }
    status {
      id
      enum
      name
    }
  }
`;
