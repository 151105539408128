import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { CsiClassificationStandardSelectField } from 'app/shared-components/CsiClassificationStandard';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { CREATE_CSI_CLASSIFICATION } from './queries';

const defaultValues = {
  name: '',
  number: '',
  standard: null,
  title: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('A name must be provided.'),
  number: yup.string().nullable().required('A number must be provided.'),
  standard: yup.object().nullable().required('A standard must be provided.'),
  title: yup.string().nullable().required('A title must be provided.'),
});

const CsiClassificationCreateDialog = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createCsiClassification, { loading: createCsiClassificationLoading }] = useMutation(
    CREATE_CSI_CLASSIFICATION,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'CSI Classification Successfully Created',
            variant: 'success',
          })
        );

        // ROADMAP: Migrate Other Forms to Always Use a Callback
        if (typeof onSuccess === 'function') {
          onSuccess(data?.createCsiClassification);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating CSI Classification', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [createCsiClassificationLoading].includes(true),
    [createCsiClassificationLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createCsiClassification({
        variables: {
          data: {
            name: data.name,
            number: data.number,
            standard: { id: data.standard?.value },
            title: data.title,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create CSI Classification
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="csi-classification-create-form"
            name="csi-classification-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.title}
                  fullWidth
                  helperText={errors?.title?.message}
                  placeholder="Enter CSI Classification Title..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter CSI Classification Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="number"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.number}
                  fullWidth
                  helperText={errors?.number?.message}
                  placeholder="Enter CSI Classification Number..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="standard"
              render={({ field }) => (
                <CsiClassificationStandardSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.standard}
                  filters={null}
                  isDisabled={false}
                  isClearable
                  isMulti={false}
                  placeholder="Select a CSI Classification Standard..."
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="csi-classification-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default CsiClassificationCreateDialog;
