import {
  // faGear,
  faInbox,
  // faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { lighten } from '@mui/material/styles';
import { Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import CommonChatChannelAvatar from './CommonChatChannelAvatar';

// TODO: Extract
export const getDisplayTitle = ({ channel, currentUser }) => {
  let title = channel.data?.name;
  const members = Object.values(channel.state.members);

  if (!title && members.length === 2) {
    const otherMember = members.find((member) => member.user?.id !== currentUser?.id);
    if (otherMember?.user?.name) {
      title = otherMember.user.name;
    }
  }

  return title;
};

// TODO: Rename Vars
const CommonChatChannelHeader = ({ onSearchClick, onToggleLeftSidebar, ...props }) => {
  const { client } = useChatContext();
  const { channel, watcher_count: watcherCount } = useChannelStateContext();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const chatChannelTypeEnum = useMemo(
    () => channel?.data?.chatChannelTypeEnum,
    [channel?.data?.chatChannelTypeEnum]
  );

  const title = useMemo(
    () => getDisplayTitle({ channel, currentUser: client?.user }),
    [channel, client?.user]
  );

  // TODO: useMemo
  const { member_count: memberCount, subtitle } = channel?.data || {};

  // TODO:
  // console.log({ subtitle });

  const handleViewServiceTicket = useCallback(
    () =>
      channel?.data?.serviceTicketId &&
      navigate(`/service-tickets/view/${channel?.data?.serviceTicketId}`),
    [channel?.data?.serviceTicketId, navigate]
  );

  return (
    <Box
      className="w-full border-b-1"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? lighten(theme.palette.background.default, 0.4)
            : lighten(theme.palette.background.default, 0.02),
      }}
    >
      <Toolbar className="flex items-center justify-between px-16 w-full">
        <div className="flex items-center">
          <IconButton
            aria-label="Open drawer"
            // TODO:
            onClick={onToggleLeftSidebar}
            className="flex lg:hidden"
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:view-list</FuseSvgIcon>
          </IconButton>

          <div
            // TODO: Remove: `cursor-pointer`
            className="flex items-center"
            // onClick={() => {
            //   setContactSidebarOpen(true);
            // }}
            // onKeyDown={() => setContactSidebarOpen(true)}
            // role="button"
            // tabIndex={0}
          >
            <CommonChatChannelAvatar
              className="relative mx-8"
              // contact={user}
            />

            {/* <div className="flex flex-col"> */}
            {/* TODO: Truncate? */}
            <div>
              <Typography className="text-16 font-500 px-4" color="inherit" noWrap>
                {title}
              </Typography>

              {/* TODO: https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelHeader/ChannelHeader.tsx */}
              {/* {subtitle && <p className='str-chat__header-livestream-left--subtitle'>{subtitle}</p>} */}

              {/* TODO: Truncate? */}
              <Typography color="inherit" className="text-14 font-400 px-4">
                {`${memberCount} Members, ${watcherCount} Online`}
              </Typography>
            </div>
          </div>
        </div>

        <div className="flex items-center">
          {chatChannelTypeEnum === 'SERVICE_TICKET' && (
            <Tooltip title="View Service Ticket">
              <span>
                <IconButton size="large" onClick={handleViewServiceTicket}>
                  <FontAwesomeIcon className="text-20" icon={faInbox} />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {/* TODO: Seperator */}

          {/* TODO: */}
          {/* <IconButton size="large" onClick={onSearchClick}>
            <FontAwesomeIcon className="text-20" icon={faMagnifyingGlass} />
          </IconButton> */}

          {/* TODO: Seperator */}

          {/* TODO: */}
          {/* <IconButton size="large" onClick={() => {
            // navigator.setAppBadge(1);

              Notification.requestPermission().then(perm => {
                if(perm === 'granted') {
                  const notification = new Notification("Example notification", {
                  body: "This is more text",
                  data: {hello: "world"}
                })

                // alert(notification.data.hello)
                }
              })
          }}>
            <FontAwesomeIcon className="text-18" icon={faGear} />
          </IconButton> */}
        </div>

        {/* <ChatMoreMenu className="-mx-8" /> */}
      </Toolbar>
    </Box>
  );
};

export default CommonChatChannelHeader;
