import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line import/prefer-default-export
export const buildWorkOrderIssueSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'work-order-issue',
    headerName: 'Work Order Issue',
    children: [
      {
        cellClass: ['checkboxCell'],
        checkboxSelection: true,
        field: 'actions',
        headerName: 'Select',
        lockPinned: true,
        lockPosition: 'left',
        lockVisible: true,
        maxWidth: 80,
        minWidth: 80,
        pinned: 'left',
        resizable: false,
        showDisabledCheckboxes: false,
        sortable: false,
        width: 80,
      },
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'serviceIssue.name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'serviceIssue.description',
        headerName: 'Description',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'serviceIssue.serviceResponseSeverity.name',
        headerName: 'Severity',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'hasOpenWorkOrderIssueReview',
        headerName: 'Has Open Review',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'completeByTimestamp',
        headerName: 'Complete By',
        sortable: true,
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY') : undefined,
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.workOrder?.serviceTicket?.status.enum) &&
            !!data?.openConsumerTaskCount &&
            ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum)
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellDataType: 'number',
        field: 'openConsumerTaskCount',
        headerName: 'Open Consumer Tasks',
        sortable: true,
      },
      {
        cellClass: ({ data }) => {
          const classes = [];

          if (
            ['OPEN'].includes(data?.workOrder?.serviceTicket?.status.enum) &&
            !!data?.openVendorTaskCount &&
            ['MANUFACTURER', 'VENDOR'].includes(user?.data?.organization.type.enum)
          ) {
            classes.push(theme.palette.mode === 'light' ? 'bg-amber-200' : 'bg-amber-800');
          }

          return classes;
        },
        cellDataType: 'number',
        field: 'openVendorTaskCount',
        headerName: 'Open Vendor Tasks',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'isLocked',
        headerName: 'Locked',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'budget.name',
        headerName: 'Budget',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'budgetProject.name',
        headerName: 'Budget Project',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'budgetSpecialProject.name',
        headerName: 'Budget Special Project',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'notToExceedAmount',
        headerName: 'NTE',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'subtotalAmount',
        headerName: 'Subtotal',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'shippingAmount',
        headerName: 'Shipping',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'taxAmount',
        headerName: 'Tax',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'totalAmount',
        headerName: 'Total',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'quotedSubtotalAmount',
        headerName: 'Subtotal (Quoted)',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'completedSubtotalAmount',
        headerName: 'Subtotal (Completed)',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'finalizedSubtotalAmount',
        headerName: 'Subtotal (Finalized)',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
    ],
  },
  {
    groupId: 'service-ticket',
    headerName: 'Service Ticket',
    children: [
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.serviceReferenceNumber',
        headerName: 'SRN',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.phase.name',
        headerName: 'Phase',
        sortable: true,
      },
      {
        cellDataType: 'boolean',
        field: 'workOrder.serviceTicket.isRequestForQuote',
        headerName: 'Is RFQ',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.serviceProvision.organizationConnection.consumer.name',
        headerName: 'Consumer',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.creatorUser.name',
        headerName: 'Creator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.serviceProvision.organizationConnection.vendor.name',
        headerName: 'Vendor',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Program',
    children: [
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.programCampaign.program.name',
        headerName: 'Program',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.programCampaign.name',
        headerName: 'Program Campaign',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Service Invoice',
    children: [
      {
        cellDataType: 'text',
        field: 'serviceInvoice.vendorReferenceNumber',
        headerName: 'VRN',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'serviceInvoice.submittedAtTimestamp',
        headerName: 'Submitted At',
        sortable: true,
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY') : undefined,
      },
    ],
  },
  {
    headerName: 'Facility',
    children: [
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.franchiseNumber',
        headerName: 'Number',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.marketTeam',
        headerName: 'Market Team',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.regionalTeam',
        headerName: 'Regional Team',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.businessConsultantContact.name',
        headerName: 'Business Consultant',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.esrContact.name',
        headerName: 'ESR',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.fieldConsultantContact.name',
        headerName: 'Field Consultant',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.leadAdvisorContact.name',
        headerName: 'Lead Advisor',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.operatorContact.name',
        headerName: 'Operator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.projectAdministratorContact.name',
        headerName: 'Project Administrator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.projectCoordinatorContact.name',
        headerName: 'Project Coordinator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrder.serviceTicket.facility.regionalCoordinatorContact.name',
        headerName: 'Regional Coordinator',
        sortable: true,
      },
    ],
  },
];
