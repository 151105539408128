import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { CREATE_BUDGET_SPECIAL_PROJECT } from './queries';
import { BudgetSpecialProjectCreateDialogAttachments } from './components';

const defaultValues = {
  attachments: [],
  name: '',
  referenceNumber: '',
};

const schema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  name: yup.string().nullable().required('A name must be provided.'),
  referenceNumber: yup.string().nullable().optional(),
});

const BudgetSpecialProjectCreateDialog = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createBudgetSpecialProject, { loading: createBudgetSpecialProjectLoading }] = useMutation(
    CREATE_BUDGET_SPECIAL_PROJECT,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Budget Special Project Successfully Created',
            variant: 'success',
          })
        );

        // ROADMAP: Migrate Other Forms to Always Use a Callback
        if (typeof onSuccess === 'function') {
          onSuccess(data?.createBudgetSpecialProject);
        }
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Creating Budget Special Project', variant: 'error' })
        );
      },
    }
  );

  const loading = useMemo(
    () => [createBudgetSpecialProjectLoading].includes(true),
    [createBudgetSpecialProjectLoading]
  );

  const onSubmit = async (data) => {
    try {
      await createBudgetSpecialProject({
        variables: {
          data: {
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            name: data.name,
            referenceNumber: data.referenceNumber,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Budget Special Project
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="budget-special-project-create-form"
            name="budget-special-project-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Budget Special Project Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="referenceNumber"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.referenceNumber}
                  fullWidth
                  helperText={errors?.referenceNumber?.message}
                  placeholder="Enter Reference Number..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <BudgetSpecialProjectCreateDialogAttachments name="attachments" />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="budget-special-project-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default BudgetSpecialProjectCreateDialog;
