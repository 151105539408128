import { useLazyQuery } from '@apollo/client';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BudgetViewScreenFacilitiesTab,
  BudgetViewScreenBudgetProjectsTab,
  BudgetViewScreenOverviewTab,
} from './components';
import { FETCH_BUDGET_VIEW_SCREEN_DATA } from './queries';

const BudgetViewScreen = () => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [
    fetchBudgetViewScreenData,
    {
      data: budgetViewScreenData,
      loading: budgetViewScreenLoading,
      refetch: budgetViewScreenRefetch,
    },
  ] = useLazyQuery(FETCH_BUDGET_VIEW_SCREEN_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Budget Data',
          variant: 'error',
        })
      );
    },
  });

  const budget = useMemo(() => budgetViewScreenData?.budget, [budgetViewScreenData?.budget]);

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const loading = useMemo(
    () => [budgetViewScreenLoading].includes(true),
    [budgetViewScreenLoading]
  );

  useEffect(() => {
    if (params?.budgetId) {
      fetchBudgetViewScreenData({
        variables: { where: { id: params?.budgetId } },
      });
    }
  }, [fetchBudgetViewScreenData, params?.budgetId]);

  return (
    <>
      {basePath === 'tabs/overview' && <BudgetViewScreenOverviewTab budget={budget} />}

      {basePath === 'tabs/budget-projects' && <BudgetViewScreenBudgetProjectsTab budget={budget} />}

      {basePath === 'tabs/facilities' && <BudgetViewScreenFacilitiesTab budget={budget} />}
    </>
  );
};

export default BudgetViewScreen;
