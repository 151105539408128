import { useLazyQuery, useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  AppBar,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AccountingTaskCodeHitsListOption } from 'app/shared-components/AccountingTaskCode';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { CostCodeDeleteDialog } from 'app/shared-components/CostCode/CostCodeDeleteDialog';
import { CsiClassificationHitsListOption } from 'app/shared-components/CsiClassification';
import { EntitySearchSelectFieldV2, useEntitySearchApp } from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { FETCH_COST_CODE_VIEW_DRAWER_DATA, UPDATE_COST_CODE } from './queries';
import { CostCodeViewDrawerAttachments } from './components';

const DRAWER_APP_BAR_HEIGHT = 64;

const defaultValues = {
  accountingExpenseType: null,
  accountingTaskCode: null,
  attachments: [],
  capitalExpenseThreshold: '',
  csiClassification: null,
  isCapitalExpense: false,
};

const schema = yup.object().shape({
  accountingExpenseType: yup.object().nullable().optional(),
  accountingTaskCode: yup.object().nullable().optional(),
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  capitalExpenseThreshold: yup
    .mixed()
    .nullable()
    .when('isCapitalExpense', {
      is: true,
      then: yup
        .mixed()
        .test(
          'requireCapitalExpenseThreshold',
          'A capital expense threshold must be provided.',
          (value) => _.isNumber(value) && value >= 0.01
        ),
    }),
  csiClassification: yup.object().nullable().required('A CSI classification must be provided.'),
  isCapitalExpense: yup.boolean().required(),
});

const CostCodeViewDrawer = forwardRef(({ basePath, contentWidth, onCloseDrawer }, ref) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const rootRef = useRef(null);
  const { refreshSearch } = useEntitySearchApp();
  const user = useSelector(selectUser);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [
    fetchCostCodeViewDrawerData,
    {
      data: costCodeViewDrawerData,
      loading: costCodeViewDrawerLoading,
      refetch: costCodeViewDrawerRefetch,
    },
  ] = useLazyQuery(FETCH_COST_CODE_VIEW_DRAWER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Cost Code Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateCostCode, { loading: updateCostCodeLoading }] = useMutation(UPDATE_COST_CODE, {
    onCompleted: (data) => {
      dispatch(
        showMessage({
          message: 'Cost Code Successfully Updated',
          variant: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Updating Cost Code', variant: 'error' }));
    },
  });

  const costCode = useMemo(
    () => costCodeViewDrawerData?.costCode,
    [costCodeViewDrawerData?.costCode]
  );

  const loading = useMemo(
    () => [costCodeViewDrawerLoading, updateCostCodeLoading].includes(true),
    [costCodeViewDrawerLoading, updateCostCodeLoading]
  );

  const canDeleteCostCode = useMemo(
    () =>
      Boolean(
        costCode?.canBeDeleted === true && costCode?.owner?.id === user?.data?.organization?.id
      ),
    [costCode?.canBeDeleted, costCode?.owner?.id, user?.data?.organization?.id]
  );

  useImperativeHandle(ref, () => ({
    rootRef,
    refetchAll: () => {
      [costCodeViewDrawerRefetch].forEach(() => costCodeViewDrawerRefetch());
    },
  }));

  useEffect(() => {
    if (costCode) {
      const formData = {
        accountingExpenseType: costCode.accountingExpenseType
          ? costCode.accountingExpenseType
          : null,
        accountingTaskCode: costCode.accountingTaskCode ? costCode.accountingTaskCode : null,
        attachments:
          costCode.attachments?.map((attachment) => {
            let commonStoredObject;

            if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
              commonStoredObject = attachment.commonStoredImage;
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
              commonStoredObject = attachment.commonStoredPdf;
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
              commonStoredObject = attachment.commonStoredVideo;
            }

            return commonStoredObject
              ? {
                  commonStoredUpload: {
                    key: commonStoredObject?.commonStoredUpload?.key,
                  },
                  file: {
                    name: commonStoredObject?.name,
                    preview: commonStoredObject?.thumbnailImageUrl,
                  },
                }
              : null;
          }) || [],
        capitalExpenseThreshold: costCode.capitalExpenseThreshold,
        csiClassification: costCode.csiClassification,
        isCapitalExpense: costCode.isCapitalExpense,
      };

      reset(formData);
    }
  }, [reset, costCode]);

  useEffect(() => {
    if (costCodeViewDrawerRefetch && fetched && refreshSearch) {
      costCodeViewDrawerRefetch();
    }
  }, [costCodeViewDrawerRefetch, fetched, refreshSearch]);

  useEffect(() => {
    if (params?.costCodeId) {
      setFetched(false);
      fetchCostCodeViewDrawerData({
        variables: { where: { id: params?.costCodeId } },
      });
    }
  }, [fetchCostCodeViewDrawerData, params?.costCodeId]);

  const handleCloseDrawer = useCallback(() => {
    if (typeof onCloseDrawer === 'function') {
      onCloseDrawer();
    }

    setFetched(false);
  }, [onCloseDrawer]);

  useEffect(() => {
    if (fetched && !costCode) {
      handleCloseDrawer();
    }
  }, [costCode, fetched, handleCloseDrawer]);

  const onSubmit = async (data) => {
    try {
      await updateCostCode({
        variables: {
          where: {
            id: costCode.id,
          },
          data: {
            accountingExpenseType: data.accountingExpenseType?.id
              ? { id: data.accountingExpenseType?.id }
              : null,
            accountingTaskCode: data.accountingTaskCode?.id
              ? { id: data.accountingTaskCode?.id }
              : null,
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            capitalExpenseThreshold: data.isCapitalExpense ? data.capitalExpenseThreshold : null,
            isCapitalExpense: data.isCapitalExpense,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <div className="flex-1" style={{ width: contentWidth }}>
      <AppBar color="inherit" elevation={0} position="static">
        <Toolbar>
          <div className="flex flex-1">
            <Typography component="div" noWrap variant="h6">
              Cost Code Details
            </Typography>
          </div>

          <IconButton sx={{ width: 40, height: 40 }} onClick={handleCloseDrawer}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper
        className="flex flex-col p-24 overflow-auto border-t-1"
        elevation={0}
        square
        style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
      >
        {(loading && !fetched) || !costCode ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <FormProvider
            {...{
              clearErrors,
              control,
              formState,
              handleSubmit,
              reset,
              setValue,
              watch,
              ...methods,
            }}
          >
            <form
              className="w-full"
              id="cost-code-view-form"
              name="cost-code-view-form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="csiClassification"
                    render={({ field }) => (
                      <EntitySearchSelectFieldV2
                        {...field}
                        className="mb-24"
                        components={{ Option: CsiClassificationHitsListOption }}
                        error={errors?.csiClassification}
                        filter={[
                          [`costCodes.id NOT EXISTS`],
                          [`costCodes.owner.id != '${user?.data?.organization?.id}'`],
                        ]}
                        getOptionLabel={(option) => `${option?.number} - ${option.name}`}
                        getOptionValue={(option) => option?.id}
                        indexName="csi_classifications"
                        isClearable
                        isDisabled
                        isMulti={false}
                        placeholder="Select a CSI Classification..."
                        sort={['name:asc']}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="accountingTaskCode"
                    render={({ field }) => (
                      <EntitySearchSelectFieldV2
                        {...field}
                        className="mb-24"
                        components={{ Option: AccountingTaskCodeHitsListOption }}
                        error={errors?.accountingTaskCode}
                        filter={null}
                        getOptionLabel={(option) => `${option?.number} - ${option.name}`}
                        getOptionValue={(option) => option?.id}
                        indexName="accounting_task_codes"
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Accounting Task Code..."
                        sort={['name:asc']}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Divider className="mb-24" />

              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="accountingExpenseType"
                    render={({ field }) => (
                      <EntitySearchSelectFieldV2
                        {...field}
                        className="mb-24"
                        error={errors?.accountingExpenseType}
                        filter={null}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        indexName="accounting_expense_types"
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Accounting Expense Type..."
                        sort={['name:asc']}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  alignContent="center"
                  display="flex"
                  justifyContent={isMobile ? 'center' : 'left'}
                  md={6}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name="isCapitalExpense"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            {...field}
                            checked={!!field.value}
                            color="info"
                            onChange={(_params) => {
                              setValue('capitalExpenseThreshold', '', {
                                shouldValidate: true,
                              });

                              field.onChange(_params);
                            }}
                          />
                        }
                        label="Capital Expense"
                        labelPlacement="start"
                        sx={{ ml: 0, mb: 3 }}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  alignContent="center"
                  display="flex"
                  justifyContent={isMobile ? 'center' : 'left'}
                  md={6}
                  xs={12}
                >
                  <Controller
                    control={control}
                    name="capitalExpenseThreshold"
                    render={({ field }) => (
                      <CommonCurrencyField
                        {...field}
                        className="mb-24"
                        control={control}
                        disabled={!watchFields?.isCapitalExpense}
                        error={errors?.capitalExpenseThreshold}
                        placeholder="Enter Capital Expense Threshold..."
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Divider className="mb-24" />

              <Grid container spacing={2}>
                <Grid xs={12}>
                  <CostCodeViewDrawerAttachments name="attachments" />
                </Grid>
              </Grid>

              <Divider className="my-16" />

              <Grid container spacing={2}>
                <Grid
                  alignContent="center"
                  display="flex"
                  justifyContent={isMobile ? 'center' : 'left'}
                  md={6}
                  xs={12}
                >
                  <Button
                    color="error"
                    disabled={!canDeleteCostCode || loading}
                    fullWidth={isMobile}
                    variant="contained"
                    onClick={() => {
                      dispatch(
                        openDialog({
                          children: (
                            <CostCodeDeleteDialog
                              costCodeId={costCode?.id}
                              onClose={() => dispatch(closeDialog())}
                              onSuccess={handleCloseDrawer}
                            />
                          ),
                          classes: {
                            paper: 'w-full max-w-640 min-w-320 rounded-8',
                          },
                        })
                      );
                    }}
                  >
                    Delete
                  </Button>
                </Grid>

                <Grid
                  alignContent="center"
                  display="flex"
                  justifyContent={isMobile ? 'center' : 'right'}
                  md={3}
                  xs={12}
                >
                  <Button
                    color="primary"
                    disabled={_.isEmpty(dirtyFields) || loading}
                    fullWidth
                    variant="contained"
                    onClick={() => reset(undefined, { keepDefaultValues: true })}
                  >
                    Revert
                  </Button>
                </Grid>

                <Grid
                  alignContent="center"
                  display="flex"
                  justifyContent={isMobile ? 'center' : 'right'}
                  md={3}
                  xs={12}
                >
                  <Button
                    color="secondary"
                    disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                    form="cost-code-view-form"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        )}
      </Paper>
    </div>
  );
});

export default CostCodeViewDrawer;
