import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BUDGET_PROJECT_VIEW_SCREEN_BUDGET_PROJECT_FRAGMENT = gql`
  fragment BudgetProjectViewScreenBudgetProjectFragment on BudgetProject {
    id
    createdAt
    updatedAt

    attachments {
      id
      createdAt
      commonStoredImage {
        id
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredType {
        id
        enum
        name
      }
      commonStoredPdf {
        id
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        originalPdfUrl
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredVideo {
        id
        captionsFileUrl
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        playlistFileUrl
        standardImageUrl
        thumbnailImageUrl
      }
    }
    budget {
      id
      divisionName
      divisionNumber
      name
      owner {
        id
        name
      }
      status {
        id
        enum
        name
      }
    }
    commonStandardRateTypes {
      commonStandardRateType {
        id
        enum
        name
      }
    }
    costCodes(skip: 0, take: 5) {
      costCode {
        id
        csiClassification {
          id
          name
          number
          title
        }
      }
    }
    generalLedgerAccount
    isDefault
    name
    owner {
      id
      name
    }
    projectCode
    serviceCompletionItems(skip: 0, take: 5) {
      serviceCompletionItem {
        id
        name
        status {
          id
          enum
          name
        }
      }
    }
    status {
      id
      enum
      name
    }
  }
`;
