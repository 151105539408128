import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  COMPLETE_SERVICE_INVOICE_REVIEW,
  FETCH_SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_DATA,
} from './queries';

const defaultValues = {
  submittedAt: null,
  vendorReferenceNumber: '',
};

const schema = yup.object().shape({
  submittedAt: yup.date().nullable().required('A submitted date must be provided.'),
  vendorReferenceNumber: yup
    .string()
    .nullable()
    .required('A vendor reference number must be provided.'),
});

const ServiceInvoiceReviewApprovalDialog = ({
  serviceInvoiceReviewId,
  serviceTicketId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const [
    fetchServiceInvoiceReviewApprovalDialogData,
    {
      data: serviceInvoiceReviewApprovalDialogData,
      loading: serviceInvoiceReviewApprovalDialogLoading,
      refetch: serviceInvoiceReviewApprovalDialogRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [completeServiceInvoiceReview, { loading: completeServiceInvoiceReviewLoading }] =
    useMutation(COMPLETE_SERVICE_INVOICE_REVIEW, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Invoice Review Successfully Approved',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Approving Service Invoice Review', variant: 'error' })
        );
      },
    });

  const decision = useMemo(
    () =>
      serviceInvoiceReviewApprovalDialogData?.serviceInvoiceReviewDecisions.find(
        (serviceInvoiceReviewDecision) => serviceInvoiceReviewDecision.enum === 'APPROVE'
      ),
    [serviceInvoiceReviewApprovalDialogData?.serviceInvoiceReviewDecisions]
  );

  const loading = useMemo(
    () =>
      [completeServiceInvoiceReviewLoading, serviceInvoiceReviewApprovalDialogLoading].includes(
        true
      ),
    [completeServiceInvoiceReviewLoading, serviceInvoiceReviewApprovalDialogLoading]
  );

  const serviceInvoice = useMemo(
    () => serviceInvoiceReviewApprovalDialogData?.serviceInvoiceReview?.serviceInvoice,
    [serviceInvoiceReviewApprovalDialogData?.serviceInvoiceReview?.serviceInvoice]
  );

  useEffect(() => {
    if (serviceInvoice?.submittedAt) {
      setValue('submittedAt', serviceInvoice?.submittedAt || null);
    }
  }, [setValue, serviceInvoice?.submittedAt]);

  useEffect(() => {
    if (serviceInvoice?.vendorReferenceNumber) {
      setValue('vendorReferenceNumber', serviceInvoice?.vendorReferenceNumber || null);
    }
  }, [setValue, serviceInvoice?.vendorReferenceNumber]);

  useEffect(() => {
    if (serviceInvoiceReviewId) {
      fetchServiceInvoiceReviewApprovalDialogData({
        variables: { where: { id: serviceInvoiceReviewId } },
      });
    }
  }, [fetchServiceInvoiceReviewApprovalDialogData, serviceInvoiceReviewId]);

  const onSubmit = async (data) => {
    try {
      await completeServiceInvoiceReview({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: serviceInvoiceReviewId,
            decision: { id: decision?.id },
            submittedAt: data.submittedAt,
            vendorReferenceNumber: data.vendorReferenceNumber,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Confirm Service Invoice Approval / Receipt
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="service-invoice-review-approval-form"
            name="service-invoice-review-approval-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-1 items-center mb-24">
              <div className="flex-1 pr-12">
                <Controller
                  control={control}
                  name="vendorReferenceNumber"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors?.vendorReferenceNumber}
                      fullWidth
                      helperText={errors?.vendorReferenceNumber?.message}
                      placeholder="Enter Vendor Reference Number..."
                      required
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <Controller
                control={control}
                name="submittedAt"
                render={({ field }) => (
                  <div className="flex-1 pl-12">
                    <CommonDatepicker
                      {...field}
                      controls={['calendar']}
                      disabled={false}
                      error={errors?.submittedAt}
                      placeholder="Select Submitted Date..."
                      select="date"
                    />
                  </div>
                )}
              />
            </div>
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-invoice-review-approval-form"
          type="submit"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceInvoiceReviewApprovalDialog;
