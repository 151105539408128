import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import _ from '@lodash';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { EntitySearchInfiniteHitsGridColumnTreeSortableTreeItem } from './components';
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';
import {
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
} from './utilities';

const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};

const dropAnimationConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const EntitySearchInfiniteHitsGridColumnTree = ({
  collapsible = true,
  defaultItems = [],
  shouldRender,
  hideable = true,
  indentationWidth = 50,
  indicator = true,
  maintainDepth = true,
  removable,
}) => {
  const [activeId, setActiveId] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const { gridReady, gridRef, viewMode } = useEntitySearchApp();
  const init = useRef(false);
  const [items, setItems] = useState(() => defaultItems);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [overId, setOverId] = useState(null);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);

    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, items]);

  const projected =
    activeId && overId
      ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth, maintainDepth)
      : null;

  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  };

  const getMovementAnnouncement = (eventName, _activeId, _overId) => {
    if (_overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          _overId === currentPosition.overId
        ) {
          return null;
          // eslint-disable-next-line no-else-return
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            _overId,
          });
        }
      }

      const clonedItems = _.cloneDeep(flattenTree(items));
      const overIndex = clonedItems.findIndex(({ id }) => id === _overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === _activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${_activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (projected.depth > previousItem.depth) {
          announcement = `${_activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling = previousItem;

          while (previousSibling && projected.depth < previousSibling.depth) {
            const { parentId } = previousSibling;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${_activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return null;
  };

  const handleColumnChanged = useCallback(() => {
    if (gridReady && gridRef.current?.api) {
      const columns = gridRef.current.api.getAllGridColumns();
      const columnGroups = _.uniqBy(
        columns.map((column) => column.getParent()),
        (columnGroup) =>
          JSON.stringify(columnGroup?.getChildren().map((column) => column.getColId()))
      );

      const newItems = columnGroups
        .filter(
          (columnGroup, index) =>
            columnGroup && columnGroups[index - 1]?.getGroupId() !== columnGroup.getGroupId()
        )
        .map((columnGroup, index) => ({
          id: `${columnGroup.getGroupId()}_${index}`,
          groupId: columnGroup.getGroupId(),
          headerName: columnGroup.getColGroupDef()?.headerName,
          isColumn: columnGroup.isColumn,
          children: [],
        }));

      let currentIndex = 0;

      gridRef.current.api.getAllGridColumns().forEach((column, index) => {
        if (newItems[currentIndex]?.groupId !== column.getParent()?.getGroupId()) {
          currentIndex += 1;
        }

        if (newItems[currentIndex]) {
          newItems[currentIndex].children.push({
            id: `${column.getColId()}_${index}`,
            colId: column.getColId(),
            headerName: column.getColDef()?.headerName,
            isColumn: column.isColumn,
            isVisible: column.isVisible(),
            children: [],
          });
        }
      });

      newItems.forEach((newItem) => {
        let newItemIsVisible;

        if (_.every(newItem.children, ({ isVisible }) => !isVisible)) {
          newItemIsVisible = false;
        } else if (_.every(newItem.children, ({ isVisible }) => isVisible)) {
          newItemIsVisible = true;
        }

        newItem.isVisible = newItemIsVisible;
      });

      setItems((prevItems) => {
        const nextItems = [...newItems].map((newItem) => ({
          ...newItem,
          collapsed: prevItems?.find(({ id }) => id === newItem.id)?.collapsed,
        }));

        return nextItems;
      });
    }
  }, [gridReady, gridRef]);

  const handleCollapse = (id) => {
    setItems((prevItems) =>
      setProperty(prevItems, id, 'collapsed', (value) => {
        return !value;
      })
    );
  };

  const handleDragCancel = () => {
    resetState();
  };

  const handleDragEnd = ({ active, over }) => {
    resetState();

    if (gridReady && gridRef.current?.api && over && projected) {
      const clonedItems = _.cloneDeep(flattenTree(items));
      const { depth, parentId } = projected;

      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];
      const activeColumn = activeTreeItem?.isColumn
        ? gridRef.current.api.getColumn(activeTreeItem?.colId)
        : null;
      const activeColumnGroup = activeColumn
        ? activeColumn.getParent()
        : gridRef.current.api.getColumnGroup(activeTreeItem?.groupId);

      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const overTreeItem = clonedItems[overIndex];
      const overColumn = overTreeItem?.isColumn
        ? gridRef.current.api.getColumn(overTreeItem?.colId)
        : null;
      const overColumnGroup = overColumn
        ? overColumn.getParent()
        : gridRef.current.api.getColumnGroup(overTreeItem?.groupId);

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      if (
        activeTreeItem &&
        overTreeItem &&
        activeTreeItem.depth === overTreeItem.depth &&
        ((activeTreeItem &&
          activeTreeItem.isColumn === true &&
          activeColumnGroup &&
          overColumnGroup &&
          activeColumnGroup.getGroupId().split('_')[0] ===
            overColumnGroup.getGroupId().split('_')[0]) ||
          (activeTreeItem &&
            activeTreeItem.isColumn === false &&
            overTreeItem &&
            overTreeItem.isColumn === false))
      ) {
        const newColDefs = [];

        newItems.forEach((newItem) => {
          let colDef = {};

          const columnGroup = gridRef.current.api.getColumnGroup(newItem.groupId);

          if (columnGroup) {
            colDef = {
              ...colDef,
              ...columnGroup.getColGroupDef(),
              children: newItem.children.map(({ colId }) => {
                const column = gridRef.current.api.getColumn(colId);

                return column?.getColDef();
              }),
            };
          }

          newColDefs.push(colDef);
        });

        gridRef.current.api.setGridOption('columnDefs', newColDefs);
      }
    }
  };

  const handleDragMove = ({ delta }) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }) => {
    setOverId(over?.id ?? null);
  };

  const handleDragStart = ({ active: { id: _activeId } }) => {
    setActiveId(_activeId);
    setOverId(_activeId);

    const _activeItem = flattenedItems.find(({ id }) => id === _activeId);

    if (_activeItem) {
      setCurrentPosition({
        parentId: _activeItem.parentId,
        overId: _activeId,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  };

  const handleRemove = (id) => {
    setItems((prevItems) => removeItem(prevItems, id));
  };

  const handleVisible = (id) => {
    if (gridReady && gridRef.current?.api) {
      const clonedItems = _.cloneDeep(flattenTree(items));

      const selectedIndex = clonedItems.findIndex(({ id: _id }) => _id === id);
      const selectedTreeItem = clonedItems[selectedIndex];

      if (['active'].includes(selectedTreeItem.headerName.toLowerCase())) {
        // ROADMAP: Dynamically Disable Buttons Instead
      } else if (selectedTreeItem?.isColumn === true && selectedTreeItem?.colId) {
        gridRef.current.api.setColumnsVisible(
          [selectedTreeItem.colId],
          !selectedTreeItem.isVisible
        );
      } else if (selectedTreeItem?.isColumn === false && selectedTreeItem?.groupId)
        gridRef.current.api.setColumnsVisible(
          selectedTreeItem.children?.map(({ colId }) => colId) ?? [],
          !selectedTreeItem.isVisible
        );
    }
  };

  const announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  useEffect(() => {
    if (gridReady && gridRef.current?.api && !init.current) {
      gridRef.current.api.addEventListener('columnMoved', (params) => {
        if (!params.column?.isMoving()) {
          handleColumnChanged();
        }
      });

      gridRef.current.api.addEventListener('columnVisible', (params) => {
        handleColumnChanged();
      });

      handleColumnChanged();

      init.current = true;
    }
  }, [gridReady, gridRef, handleColumnChanged]);

  if (!shouldRender || viewMode !== 'grid') {
    return null;
  }

  return (
    <DndContext
      accessibility={{ announcements }}
      collisionDetection={closestCenter}
      measuring={measuring}
      sensors={sensors}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(
          ({ id, children, collapsed, depth, headerName, isColumn, isVisible }) => {
            return (
              <EntitySearchInfiniteHitsGridColumnTreeSortableTreeItem
                collapsed={Boolean(collapsed && children.length)}
                depth={id === activeId && projected ? projected.depth : depth}
                id={id}
                indentationWidth={indentationWidth}
                indicator={indicator}
                isColumn={isColumn}
                isVisible={isVisible}
                key={id}
                value={headerName}
                onCollapse={collapsible && children.length ? () => handleCollapse(id) : undefined}
                onRemove={removable ? () => handleRemove(id) : undefined}
                onVisible={hideable ? () => handleVisible(id) : undefined}
              />
            );
          }
        )}

        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <EntitySearchInfiniteHitsGridColumnTreeSortableTreeItem
                childCount={getChildCount(items, activeId) + 1}
                clone
                depth={activeItem.depth}
                id={activeId}
                indentationWidth={indentationWidth}
                value={activeItem.headerName}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );
};

export default EntitySearchInfiniteHitsGridColumnTree;
