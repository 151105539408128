// eslint-disable-next-line import/prefer-default-export
export const budgetSearchScreenFilterDefs = [
  {
    name: 'Budget',
    refinements: [
      {
        attribute: 'divisionName',
        label: 'Division Name',
        operator: 'or',
      },
      {
        attribute: 'divisionNumber',
        label: 'Division Number',
        operator: 'or',
      },
      {
        attribute: 'isDefault',
        label: 'Default',
        operator: 'or',
      },
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
    ],
  },
];
