import { gql, useApolloClient, useMutation } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonDatepicker } from 'app/shared-components/Common';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch/EntitySearchSelectField';
import { FacilityHitsListOption } from 'app/shared-components/Facility';
import { ServiceProvisionHitsListOption } from 'app/shared-components/ServiceProvision';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  // ROADMAP: Re-Implement or Remove
  // ServiceTicketCreateDialogServiceTicketAttachments,
  ServiceTicketCreateDialogWorkOrderIssues,
} from './components';
import { CREATE_SERVICE_TICKET } from './queries';

const defaultValues = {
  // ROADMAP: Re-Implement or Remove
  // attachments: [],
  facility: null,
  isRequestForQuote: false,
  serviceProvision: null,
  serviceResponseSeverity: null,
  workOrderIssues: [],
};

const schema = yup.object().shape({
  // ROADMAP: Re-Implement or Remove
  // attachments: yup
  //   .array()
  //   .of(
  //     yup
  //       .object()
  //       .shape({
  //         file: yup.mixed().required(),
  //         commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
  //       })
  //       .required()
  //   )
  //   .optional(),
  facility: yup.object().nullable().required('A facility must be provided.'),
  isRequestForQuote: yup.boolean().required(),
  serviceProvision: yup.object().nullable().required('A service provision must be provided.'),
  serviceResponseSeverity: yup
    .object()
    .nullable()
    .required('A response severity must be provided.'),
  workOrderIssues: yup
    .array()
    .of(
      yup.object().shape({
        actionItems: yup.array().of(
          yup.object().shape({
            costCode: yup.object().nullable().required('A cost code must be provided.'),
            description: yup.string().nullable().optional(),
            notToExceedAmount: yup
              .number()
              .nullable()
              .min(0.01, 'A value must be provided.')
              .required('A NTE must be provided.'),
          })
        ),
        budget: yup.object().nullable().required('A budget must be provided'),
        budgetProject: yup.object().nullable().required('A budget project must be provided'),
        budgetSpecialProject: yup.object().nullable().optional(),
        isSaveAsIssueTemplate: yup.boolean().optional(),
        issueTemplate: yup.object().nullable().optional(),
        notToExceedAmount: yup.number().nullable().min(0.01, 'A value must be provided'),
        saveAsIssueTemplate: yup.mixed().when('isSaveAsIssueTemplate', {
          is: (val) => !!val,
          then: yup.object().shape({
            description: yup.string().nullable().optional(),
            name: yup.string().nullable().required('A name must be provided.'),
            tags: yup
              .array()
              .of(
                yup
                  .object()
                  .shape({
                    __isNew__: yup.boolean().optional(),
                    label: yup.string().required(),
                    result: yup.object().optional(),
                    value: yup.string().required(),
                  })
                  .required()
              )
              .optional(),
          }),
        }),
        serviceIssue: yup.object().shape({
          connect: yup.mixed().when('mutation', {
            is: (val) => val === 'CONNECT',
            then: yup.object().nullable().required('A service issue must be provided.'),
          }),
          create: yup.mixed().when('mutation', {
            is: (val) => val === 'CREATE',
            then: yup.object().shape({
              attachments: yup
                .array()
                .of(
                  yup
                    .object()
                    .shape({
                      file: yup.mixed().required(),
                      commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
                    })
                    .required()
                )
                .optional(),
              commonTargetType: yup.object().nullable().required('A target type must be provided.'),
              description: yup.string().nullable().optional(),
              name: yup.string().nullable().required('A name must be provided.'),
              serviceResponseSeverity: yup
                .object()
                .nullable()
                .required('A response severity must be provided.'),
              // ROADMAP: Re-Implement or Remove
              // serviceResponsibleParty: yup.object().nullable().optional(),
              // ROADMAP: Causes Incorrect Validation State with Uncontrolled Inputs
              // tags: yup
              //   .array()
              //   .of(
              //     yup
              //       .object()
              //       .shape({
              //         __isNew__: yup.boolean().optional(),
              //         label: yup.string().required(),
              //         result: yup.object().optional(),
              //         value: yup.string().required(),
              //       })
              //       .required()
              //   )
              //   .optional(),
              targetFacilityAreas: yup.mixed().when('commonTargetType', {
                is: (val) => val?.result?.enum === 'FACILITY_AREA',
                then: yup
                  .array()
                  .of(
                    yup
                      .object()
                      .shape({
                        hit: yup.object().optional(),
                        label: yup.string().required(),
                        value: yup.string().required(),
                      })
                      .required()
                  )
                  .min(1, 'Select at least one Target Facility Area')
                  .required(),
              }),
            }),
          }),
          mutation: yup
            .string()
            .nullable()
            .required('A service issue connection type must be provided.'),
        }),
      })
    )
    .min(1, 'Select or Create at least one Service Issue')
    .required(),
});

const ServiceTicketCreateDialog = ({ onClose }) => {
  const apolloClient = useApolloClient();
  const { axiosSearchClient } = useMeilisearch();
  const [defaultBudget, setDefaultBudget] = useState(null);
  const [defaultBudgetDataLoading, setDefaultBudgetDataLoading] = useState(false);
  const [defaultBudgetProject, setDefaultBudgetProject] = useState(null);
  const [defaultCommonTargetType, setDefaultCommonTargetType] = useState(null);
  const [defaultCommonTargetTypeLoading, setDefaultCommonTargetTypeLoading] = useState(false);
  const [defaultServiceResponseSeverity, setDefaultServiceResponseSeverity] = useState(null);
  const [defaultServiceResponseSeverityLoading, setDefaultServiceResponseSeverityLoading] =
    useState(false);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const [sendServiceTicketOnCreate, setSendServiceTicketOnCreate] = useState(false);
  const [serviceProvisionFilter, setServiceProvisionFilter] = useState(null);
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  useEffect(() => {
    let _serviceProvisionFilter;

    if (watchFields?.facility?.value) {
      _serviceProvisionFilter = `facilities.id = '${watchFields?.facility?.value}'`;
    }

    setValue('serviceProvision', null);
    setServiceProvisionFilter(_serviceProvisionFilter);
  }, [setValue, watchFields?.facility]);

  useEffect(() => {
    const getDefaultCommonTargetType = async () => {
      try {
        setDefaultCommonTargetTypeLoading(true);

        const {
          data: { commonTargetTypes },
        } = await apolloClient.query({
          query: gql`
            query GetDefaultCommonTargetType($where: CommonTargetTypeWhereInput!) {
              commonTargetTypes(where: $where) {
                id
                enum
                name
              }
            }
          `,
          variables: { where: {} },
        });

        const result = commonTargetTypes?.find(
          (commonTargetType) => commonTargetType.enum === 'FACILITY_AREA'
        );

        if (result) {
          setDefaultCommonTargetType({
            result,
            label: result?.name,
            value: result?.id,
          });
        }
      } catch (err) {
        //
      } finally {
        setDefaultCommonTargetTypeLoading(false);
      }
    };

    if (apolloClient) {
      getDefaultCommonTargetType();
    }
  }, [apolloClient]);

  useEffect(() => {
    const getDefaultBudgetData = async () => {
      try {
        setDefaultBudgetDataLoading(true);

        const commonBudgetDataFilter = [
          `isDefault = 'true'`,
          `owner.id IN [${watchFields?.facility?.hit?.organizations
            ?.map(({ organization }) => organization.id)
            .filter((el) => el)}]`,
          `status.enum = 'ACTIVE'`,
        ];

        const {
          data: { hits: budgetHits },
        } = await axiosSearchClient.post('/indexes/budgets/search', {
          limit: 1,
          filter: [
            ...commonBudgetDataFilter,
            `facilities.facility.id = '${watchFields?.facility?.hit?.id}'`,
          ],
          q: '',
        });

        const _defaultBudget = budgetHits?.[0];

        let _defaultBudgetProject;

        if (_defaultBudget) {
          const {
            data: { hits: budgetProjectHits },
          } = await axiosSearchClient.post('/indexes/budget_projects/search', {
            limit: 1,
            filter: [...commonBudgetDataFilter, `budget.id = '${_defaultBudget?.id}'`],
            q: '',
          });

          _defaultBudgetProject = budgetProjectHits?.[0];
        }

        setDefaultBudget(_defaultBudget);
        setDefaultBudgetProject(_defaultBudgetProject);
      } catch (err) {
        //
      } finally {
        setDefaultBudgetDataLoading(false);
      }
    };

    if (axiosSearchClient) {
      getDefaultBudgetData();
    }
  }, [
    axiosSearchClient,
    watchFields?.facility?.hit?.id,
    watchFields?.facility?.hit?.organizations,
  ]);

  useEffect(() => {
    const getDefaultServiceResponseSeverity = async () => {
      try {
        setDefaultServiceResponseSeverityLoading(true);

        const {
          data: { serviceResponseSeverities },
        } = await apolloClient.query({
          query: gql`
            query GetDefaultServiceResponseSeverity($where: ServiceResponseSeverityWhereInput!) {
              serviceResponseSeverities(where: $where) {
                id
                color
                commonTemporalDurationUnit {
                  id
                  enum
                  name
                }
                name
                value
              }
            }
          `,
          variables: { where: {} },
        });

        const result = serviceResponseSeverities?.find(({ name }) => name === 'Standard');

        if (result) {
          setDefaultServiceResponseSeverity({
            result,
            label: result?.name,
            value: result?.id,
          });
        }
      } catch (err) {
        //
      } finally {
        setDefaultServiceResponseSeverityLoading(false);
      }
    };

    if (apolloClient) {
      getDefaultServiceResponseSeverity();
    }
  }, [apolloClient]);

  const [createServiceTicket, { loading: createServiceTicketLoading }] = useMutation(
    CREATE_SERVICE_TICKET,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Service Ticket Successfully Created',
            variant: 'success',
          })
        );

        navigate(`/service-tickets/view/${data.createServiceTicket?.id}`);
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Service Ticket', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () =>
      [
        createServiceTicketLoading,
        defaultBudgetDataLoading,
        defaultCommonTargetTypeLoading,
        defaultServiceResponseSeverityLoading,
      ].includes(true),
    [
      createServiceTicketLoading,
      defaultBudgetDataLoading,
      defaultCommonTargetTypeLoading,
      defaultServiceResponseSeverityLoading,
    ]
  );

  const latestCompleteByMoment = useMemo(() => {
    return moment(watchFields?.deferredUntil || undefined).add(
      watchFields?.serviceResponseSeverity?.result.value,
      watchFields?.serviceResponseSeverity?.result.commonTemporalDurationUnit.enum
    );
  }, [watchFields?.serviceResponseSeverity, watchFields?.deferredUntil]);

  const onSubmit = async (data) => {
    try {
      await createServiceTicket({
        variables: {
          data: {
            // ROADMAP: Re-Implement or Remove
            // attachments: data.attachments?.map((attachment) => ({
            //   commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            // })),
            completeBy: latestCompleteByMoment.format(),
            deferredUntil: data.deferredUntil && moment(data.deferredUntil).format(),
            facility: { id: data.facility?.value },
            isRequestForQuote: data.isRequestForQuote,
            sendServiceTicketOnCreate,
            serviceProvision: { id: data.serviceProvision?.value },
            serviceResponseSeverity: { id: data.serviceResponseSeverity?.value },
            workOrderIssues: data.workOrderIssues?.map((workOrderIssue) => ({
              actionItems: workOrderIssue.actionItems?.map((actionItem) => ({
                costCode: { id: actionItem.costCode?.id },
                description: actionItem.description,
                notToExceedAmount: actionItem.notToExceedAmount,
              })),
              budget: { id: workOrderIssue.budget?.id },
              budgetProject: { id: workOrderIssue.budgetProject?.id },
              budgetSpecialProject: workOrderIssue.budgetSpecialProject?.id
                ? { id: workOrderIssue.budgetSpecialProject?.id }
                : undefined,
              issueTemplate:
                !workOrderIssue.isSaveAsIssueTemplate && workOrderIssue.issueTemplate
                  ? {
                      id: workOrderIssue.issueTemplate.value,
                    }
                  : undefined,
              notToExceedAmount: workOrderIssue.notToExceedAmount,
              saveAsIssueTemplate:
                workOrderIssue.isSaveAsIssueTemplate && workOrderIssue.saveAsIssueTemplate
                  ? {
                      description: workOrderIssue.saveAsIssueTemplate.description,
                      name: workOrderIssue.saveAsIssueTemplate.name,
                      tags: workOrderIssue.saveAsIssueTemplate.tags?.map((tag) => ({
                        name: tag.__isNew__ ? tag.value : tag.result?.name,
                      })),
                    }
                  : undefined,
              serviceIssue: {
                connect: workOrderIssue.serviceIssue?.connect && {
                  id: workOrderIssue.serviceIssue?.connect.value,
                },
                create: workOrderIssue.serviceIssue?.create && {
                  attachments: workOrderIssue.serviceIssue.create.attachments?.map(
                    (attachment) => ({
                      commonStoredUpload: { key: attachment?.commonStoredUpload.key },
                    })
                  ),
                  commonTargetType: {
                    id: workOrderIssue.serviceIssue.create.commonTargetType?.value,
                  },
                  description: workOrderIssue.serviceIssue.create.description,
                  facility: { id: data.facility?.value },
                  name: workOrderIssue.serviceIssue.create.name,
                  serviceResponseSeverity: {
                    id: workOrderIssue.serviceIssue.create.serviceResponseSeverity?.value,
                  },
                  // ROADMAP: Re-Implement or Remove
                  // serviceResponsibleParty: workOrderIssue.serviceIssue.create
                  //   .serviceResponsibleParty && {
                  //   id: workOrderIssue.serviceIssue.create.serviceResponsibleParty?.value,
                  // },
                  tags: workOrderIssue.serviceIssue.create.tags?.map((tag) => ({
                    name: tag.__isNew__ ? tag.value : tag.result?.name,
                  })),
                  // ROADMAP: Implement
                  // targetAsset: workOrderIssue.serviceIssue.create.targetAsset && {
                  //   id: workOrderIssue.serviceIssue.create.targetAsset.value,
                  // },
                  targetFacilityAreas: workOrderIssue.serviceIssue.create.targetFacilityAreas?.map(
                    (targetFacilityArea) => ({ id: targetFacilityArea.value })
                  ),
                },
              },
            })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Service Ticket
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        {loading && !fetched ? (
          <div className="flex items-center justify-center h-200">
            <FuseLoading />
          </div>
        ) : (
          <FormProvider
            {...{
              clearErrors,
              control,
              formState,
              handleSubmit,
              reset,
              setValue,
              watch,
              ...methods,
            }}
          >
            <form
              className="w-full pt-20"
              id="service-ticket-create-form"
              name="service-ticket-create-form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-1 items-center mb-24">
                <Controller
                  control={control}
                  name="facility"
                  render={({ field }) => (
                    <EntitySearchSelectField
                      {...field}
                      className="flex-1"
                      components={{ Option: FacilityHitsListOption }}
                      error={errors?.facility}
                      idField="id"
                      indexName="facilities"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      nameField="name"
                      placeholder="Select Facility ..."
                      sort={['name:asc']}
                    />
                  )}
                />

                {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum) && (
                  <Controller
                    control={control}
                    name="isRequestForQuote"
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={!!field.value} color="info" />}
                        label="RFQ"
                        labelPlacement="start"
                        sx={{ ml: 3 }}
                      />
                    )}
                  />
                )}
              </div>

              {watchFields?.facility && (
                <Controller
                  control={control}
                  name="serviceProvision"
                  render={({ field }) => (
                    <EntitySearchSelectField
                      {...field}
                      className="mb-24"
                      components={{ Option: ServiceProvisionHitsListOption }}
                      error={errors?.serviceProvision}
                      // ROADMAP: Filter if Vendor is Viewing
                      filter={[
                        `facilities.id = '${watchFields?.facility.value}'`,
                        // ROADMAP: Implement
                        // "status.enum = 'active'",
                      ]}
                      idField="id"
                      indexName="service_provisions"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      placeholder="Select Service Provision ..."
                      nameField="name"
                      sort={['name:asc']}
                    />
                  )}
                />
              )}

              {watchFields?.facility && watchFields?.serviceProvision && (
                <>
                  <Divider sx={{ mb: 3 }} />

                  <Controller
                    control={control}
                    name="deferredUntil"
                    render={({ field }) => (
                      <div className="mb-24">
                        <CommonDatepicker
                          {...field}
                          controls={['calendar']}
                          disabled={false}
                          error={errors?.deferredUntil}
                          min={moment().add(1, 'day').startOf('day')}
                          placeholder="Select Deferred Until Date..."
                          select="date"
                        />
                      </div>
                    )}
                  />

                  {watchFields?.serviceResponseSeverity && (
                    <div className="mb-24">
                      <Alert
                        severity="info"
                        sx={{
                          backgroundColor: watchFields?.serviceResponseSeverity?.result.color,
                          color: 'white',
                          '& .MuiAlert-icon': {
                            color: 'white',
                          },
                        }}
                      >
                        <span className="mr-8">Latest Complete By:</span>

                        <strong>
                          {latestCompleteByMoment && latestCompleteByMoment.format('MMMM DD, YYYY')}
                        </strong>
                      </Alert>
                    </div>
                  )}

                  <Divider sx={{ mb: 3 }} />

                  <ServiceTicketCreateDialogWorkOrderIssues
                    defaultBudget={defaultBudget}
                    defaultBudgetProject={defaultBudgetProject}
                    defaultCommonTargetType={defaultCommonTargetType}
                    defaultServiceResponseSeverity={defaultServiceResponseSeverity}
                    name="workOrderIssues"
                    serviceTicketServiceResponseSeverityFieldName="serviceResponseSeverity"
                  />

                  {/* ROADMAP: Re-Implement or Remove */}
                  {/* <Divider sx={{ mb: 3 }} />
                  <ServiceTicketCreateDialogServiceTicketAttachments name="attachments" /> */}
                </>
              )}
            </form>
          </FormProvider>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-ticket-create-form"
          type="submit"
          variant="contained"
          onClick={() => setSendServiceTicketOnCreate(false)}
        >
          Create
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="service-ticket-create-form"
          type="submit"
          variant="contained"
          onClick={() => setSendServiceTicketOnCreate(true)}
        >
          Create + Send
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceTicketCreateDialog;
