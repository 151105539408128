import { gql } from '@apollo/client';
import { ACCOUNTING_EXPENSE_TYPE_VIEW_DRAWER_ACCOUNTING_EXPENSE_TYPE_FRAGMENT } from './fragments';

export const FETCH_ACCOUNTING_EXPENSE_TYPE_VIEW_DRAWER_DATA = gql`
  query FetchAccountingExpenseTypeViewDrawerData($where: AccountingExpenseTypeWhereUniqueInput!) {
    accountingExpenseType(where: $where) {
      id
      ...AccountingExpenseTypeViewDrawerAccountingExpenseTypeFragment
    }
  }
  ${ACCOUNTING_EXPENSE_TYPE_VIEW_DRAWER_ACCOUNTING_EXPENSE_TYPE_FRAGMENT}
`;

export const UPDATE_ACCOUNTING_EXPENSE_TYPE = gql`
  mutation UpdateAccountingExpenseType(
    $where: AccountingExpenseTypeWhereUniqueInput!
    $data: AccountingExpenseTypeUpdateInput!
  ) {
    updateAccountingExpenseType(where: $where, data: $data) {
      id
      ...AccountingExpenseTypeViewDrawerAccountingExpenseTypeFragment
    }
  }
  ${ACCOUNTING_EXPENSE_TYPE_VIEW_DRAWER_ACCOUNTING_EXPENSE_TYPE_FRAGMENT}
`;
