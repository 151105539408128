import { gql } from '@apollo/client';
import { PROGRAM_CAMPAIGN_VIEW_SCREEN_PROGRAM_CAMPAIGN_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const FETCH_PROGRAM_CAMPAIGN_VIEW_SCREEN_DATA = gql`
  query FetchProgramCampaignViewScreenData($where: ProgramCampaignWhereUniqueInput!) {
    programCampaign(where: $where) {
      id
      ...ProgramCampaignViewScreenProgramCampaignFragment
    }
  }
  ${PROGRAM_CAMPAIGN_VIEW_SCREEN_PROGRAM_CAMPAIGN_FRAGMENT}
`;
