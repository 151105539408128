import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { BudgetHitsListOption } from 'app/shared-components/Budget';
import { CommonStandardRateTypeSelectField } from 'app/shared-components/CommonStandardRateType';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { CREATE_BUDGET_PROJECT } from './queries';
import { BudgetProjectCreateDialogAttachments } from './components';

const defaultValues = {
  attachments: [],
  budget: null,
  commonStandardRateTypes: [],
  generalLedgerAccount: '',
  isDefault: false,
  name: '',
  projectCode: '',
};

const schema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  budget: yup.object().nullable().required('A budget must be provided.'),
  commonStandardRateTypes: yup
    .array()
    .test(
      'requireOneCommonStandardRateType',
      'One or more rate types must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
  generalLedgerAccount: yup.string().nullable().optional(),
  isDefault: yup.boolean().required(),
  name: yup.string().nullable().required('A name must be provided.'),
  projectCode: yup.string().nullable().optional(),
});

const BudgetProjectCreateDialog = ({ budget, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createBudgetProject, { loading: createBudgetProjectLoading }] = useMutation(
    CREATE_BUDGET_PROJECT,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Budget Project Successfully Created',
            variant: 'success',
          })
        );

        // ROADMAP: Migrate Other Forms to Always Use a Callback
        if (typeof onSuccess === 'function') {
          onSuccess(data?.createBudgetProject);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Budget Project', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [createBudgetProjectLoading].includes(true),
    [createBudgetProjectLoading]
  );

  useEffect(() => {
    if (budget) {
      const formData = { budget };

      reset(formData);
    }
  }, [budget, reset]);

  const onSubmit = async (data) => {
    try {
      await createBudgetProject({
        variables: {
          data: {
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            budget: { id: data.budget.id },
            commonStandardRateTypes: data.commonStandardRateTypes?.map(({ value }) => ({
              id: value,
            })),
            generalLedgerAccount: data.generalLedgerAccount,
            isDefault: data.isDefault,
            name: data.name,
            projectCode: data.projectCode,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create Budget Project
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="budget-project-create-form"
            name="budget-project-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  placeholder="Enter Budget Project Name..."
                  required
                  variant="outlined"
                />
              )}
            />

            <div className="flex flex-1 items-center mb-24">
              <div className="flex-1">
                <Controller
                  control={control}
                  name="budget"
                  render={({ field }) => (
                    <EntitySearchSelectFieldV2
                      {...field}
                      className=""
                      components={{ Option: BudgetHitsListOption }}
                      error={errors?.budget}
                      filter={[`owner.id = '${user?.data?.organization?.id}'`]}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option?.id}
                      indexName="budgets"
                      isClearable
                      isDisabled={!_.isEmpty(budget)}
                      isMulti={false}
                      placeholder="Select a Budget..."
                      sort={['name:asc']}
                    />
                  )}
                />
              </div>

              <div className="ml-24">
                <Controller
                  control={control}
                  name="isDefault"
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={!!field.value} color="info" />}
                      label="Default"
                      labelPlacement="start"
                      sx={{ ml: 0 }}
                    />
                  )}
                />
              </div>
            </div>

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="generalLedgerAccount"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.generalLedgerAccount}
                  fullWidth
                  helperText={errors?.generalLedgerAccount?.message}
                  placeholder="Enter General Ledger Account..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="projectCode"
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  error={!!errors?.projectCode}
                  fullWidth
                  helperText={errors?.projectCode?.message}
                  placeholder="Enter Project Code..."
                  required
                  variant="outlined"
                />
              )}
            />

            <Controller
              control={control}
              name="commonStandardRateTypes"
              render={({ field }) => (
                <CommonStandardRateTypeSelectField
                  {...field}
                  className="mb-24"
                  error={errors?.commonStandardRateTypes}
                  filters={null}
                  isDisabled={false}
                  isClearable
                  isMulti
                  placeholder="Select Rate Types..."
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <BudgetProjectCreateDialogAttachments name="attachments" />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="budget-project-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default BudgetProjectCreateDialog;
