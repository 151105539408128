import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ReactFlowProvider } from '@xyflow/react';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  WorkOrderIssueReCodeDialogReCodeActionsFlow,
  WorkOrderIssueReCodeDialogTimeoutDialog,
} from './components';
import {
  CHECK_IN_WORK_ORDER_ISSUE,
  CHECK_OUT_WORK_ORDER_ISSUE,
  EXTEND_WORK_ORDER_ISSUE,
  FETCH_WORK_ORDER_ISSUE_RE_CODE_DIALOG_DATA,
  RE_CODE_WORK_ORDER_ISSUE,
} from './queries';
import { calculateActionItemsTotal } from './utils';

const defaultValues = {
  reCodeActions: [],
  targetBudget: null,
  targetBudgetProject: null,
  targetBudgetSpecialProject: null,
};

const buildSchema = ({ user, workOrderIssue }) => {
  yup.addMethod(yup.number, 'maxDigitsAfterDecimal', function maxDigitsAfterDecimal() {
    return this.test(
      'maxDigitsAfterDecimalTest',
      'Number field must have 2 digits after decimal or less',
      (number) => (_.isNumber(number) ? /^\d+(\.\d{1,2})?$/.test(number) : true)
    );
  });

  return yup.object().shape({
    reCodeActions: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            id: yup.string().nullable().required('An ID must be provided'),
            sourceActionItems: yup
              .array()
              .of(yup.object().nullable().required('A source action item must be provided'))
              .min(1, 'Select at least one source action item')
              .required('Source action items must be provided'),
            targetActionItems: yup
              .array()
              .of(
                yup.object().shape({
                  codingStrings: yup
                    .array()
                    .of(yup.object().nullable().optional())
                    .required('Coding strings must be provided'),
                  commonStandardRateType: yup
                    .object()
                    .nullable()
                    .required('A standard rate type must be provided.'),
                  commonStandardFlatRate: yup.mixed().when('commonStandardRateType', {
                    is: (val) => val?.result.enum === 'FLAT_RATE',
                    then: yup.object().shape({
                      quantity: yup
                        .number()
                        .nullable()
                        .min(0.01, 'A value must be provided.')
                        .maxDigitsAfterDecimal()
                        .required('An amount must be provided.'),
                    }),
                  }),
                  commonStandardHourlyRate: yup.mixed().when('commonStandardRateType', {
                    is: (val) => val?.result.enum === 'HOURLY_RATE',
                    then: yup.object().shape({
                      technicians: yup
                        .number()
                        .nullable()
                        .min(1, 'A value must be provided.')
                        .required('A value must be provided.'),
                      hours: yup
                        .number()
                        .nullable()
                        .min(0.01, 'A value must be provided.')
                        .maxDigitsAfterDecimal()
                        .required('A value must be provided.'),
                      rateAmount: yup
                        .number()
                        .nullable()
                        .min(0.01, 'A value must be provided.')
                        .maxDigitsAfterDecimal()
                        .required('A value must be provided.'),
                    }),
                  }),
                  costCode: yup
                    .object()
                    .nullable()
                    .when('_user', {
                      is: () =>
                        ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum),
                      then: (schema) => schema.required('A cost code must be provided'),
                    }),
                  description: yup.string().nullable().optional(),
                  notToExceedAmount: yup
                    .number()
                    .nullable()
                    .min(0.01, 'A value must be provided.')
                    .maxDigitsAfterDecimal()
                    .optional(),
                })
              )
              .min(1, 'Select at least one target action item')
              .required('Target action items must be provided'),
            type: yup.object().nullable().required('A type must be provided'),
          })
          .test(
            'testSourceTargetActionItemsTotal',
            'Source and target action items total must match',
            (value) => {
              if (!value) return false;

              const sourceActionItemsTotal = calculateActionItemsTotal(value?.sourceActionItems);
              const targetActionItemsTotal = calculateActionItemsTotal(value?.targetActionItems);

              return sourceActionItemsTotal === targetActionItemsTotal;
            }
          )
      )
      .test(
        'testSourceTargetActionItemsCoverage',
        'Source and target action items coverage must match',
        (value) => {
          if (!value) return false;

          const sourceActionItemIds = _.flatten(
            value
              ?.map(({ sourceActionItems }) =>
                sourceActionItems?.map((sourceActionItem) => sourceActionItem.id)
              )
              ?.filter((el) => el) ?? []
          );

          return (
            value &&
            _.every(workOrderIssue?.actionItems ?? [], (actionItem) =>
              sourceActionItemIds?.includes(actionItem.id)
            )
          );
        }
      ),
    targetBudget: yup.object().nullable().required('A budget must be provided'),
    targetBudgetProject: yup.object().nullable().required('A budget project must be provided'),
    targetBudgetSpecialProject: yup.object().nullable().optional(),
  });
};

const WorkOrderIssueReCodeDialog = ({ serviceTicketId, workOrderIssueId, onClose, onSuccess }) => {
  const [confirmationStepComplete, setConfirmationStepComplete] = useState(false);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const lastActivityAt = useRef(moment().format());
  const lastActivityInterval = useRef(null);
  const lastActivityTimeout = useRef(null);
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [workOrderIssue, setWorkOrderIssue] = useState(null);
  const [
    workOrderIssueReCodeDialogTimeoutDialogOpen,
    setWorkOrderIssueReCodeDialogTimeoutDialogOpen,
  ] = useState(false);

  const schema = useMemo(() => buildSchema({ user, workOrderIssue }), [user, workOrderIssue]);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueReCodeDialogData,
    {
      data: workOrderIssueReCodeDialogData,
      loading: workOrderIssueReCodeDialogLoading,
      refetch: workOrderIssueReCodeDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_RE_CODE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Work Order Issue Data',
          variant: 'error',
        })
      );
    },
  });

  const [checkInWorkOrderIssue, { loading: checkInWorkOrderIssueLoading }] = useMutation(
    CHECK_IN_WORK_ORDER_ISSUE,
    {
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Checking In Work Order Issue', variant: 'error' }));
      },
    }
  );

  const [
    checkOutWorkOrderIssue,
    { data: checkOutWorkOrderIssueData, loading: checkOutWorkOrderIssueLoading },
  ] = useMutation(CHECK_OUT_WORK_ORDER_ISSUE, {
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Checking Out Work Order Issue', variant: 'error' }));
    },
  });

  const [
    extendWorkOrderIssue,
    { data: extendWorkOrderIssueData, loading: extendWorkOrderIssueLoading },
  ] = useMutation(EXTEND_WORK_ORDER_ISSUE, {
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Extending Work Order Issue', variant: 'error' }));
    },
  });

  const [reCodeWorkOrderIssue, { loading: reCodeWorkOrderIssueLoading }] = useMutation(
    RE_CODE_WORK_ORDER_ISSUE,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Re-Coded',
            variant: 'success',
          })
        );

        if (typeof onSuccess === 'function') {
          onSuccess(data?.reCodeWorkOrderIssue);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Re-Coding Work Order Issue', variant: 'error' }));
      },
    }
  );

  const facility = useMemo(
    () => checkOutWorkOrderIssueData?.checkOutWorkOrderIssue?.facility,
    [checkOutWorkOrderIssueData?.checkOutWorkOrderIssue?.facility]
  );

  const loading = useMemo(
    () =>
      [
        checkInWorkOrderIssueLoading,
        checkOutWorkOrderIssueLoading,
        extendWorkOrderIssueLoading,
        reCodeWorkOrderIssueLoading,
        workOrderIssueReCodeDialogLoading,
      ].includes(true),
    [
      checkInWorkOrderIssueLoading,
      checkOutWorkOrderIssueLoading,
      extendWorkOrderIssueLoading,
      reCodeWorkOrderIssueLoading,
      workOrderIssueReCodeDialogLoading,
    ]
  );

  const sessionLockedAt = useMemo(() => {
    const extendedLockedAt =
      extendWorkOrderIssueData?.checkOutWorkOrderIssue?.workOrder?.issues?.find(
        ({ id }) => id === workOrderIssue?.id
      )?.lockedAt;

    return extendedLockedAt ?? workOrderIssue?.lockedAt;
  }, [
    extendWorkOrderIssueData?.checkOutWorkOrderIssue,
    workOrderIssue?.id,
    workOrderIssue?.lockedAt,
  ]);

  const sessionExpiresAt = useMemo(
    () => moment(sessionLockedAt).add(5, 'minutes').format(),
    [sessionLockedAt]
  );

  const targetBudgetHasChanged = useMemo(
    () => Boolean(watchFields?.targetBudget?.id !== workOrderIssue?.budget?.id),
    [watchFields?.targetBudget?.id, workOrderIssue?.budget?.id]
  );

  const targetBudgetProjectHasChanged = useMemo(
    () => Boolean(watchFields?.targetBudgetProject?.id !== workOrderIssue?.budgetProject?.id),
    [watchFields?.targetBudgetProject?.id, workOrderIssue?.budgetProject?.id]
  );

  const targetBudgetSpecialProjectHasChanged = useMemo(
    () =>
      Boolean(
        watchFields?.targetBudgetSpecialProject?.id !== workOrderIssue?.budgetSpecialProject?.id
      ),
    [watchFields?.targetBudgetSpecialProject?.id, workOrderIssue?.budgetSpecialProject?.id]
  );

  const workOrderIssueReCodeActionTypes = useMemo(
    () => workOrderIssueReCodeDialogData?.workOrderIssueReCodeActionTypes,
    [workOrderIssueReCodeDialogData?.workOrderIssueReCodeActionTypes]
  );

  useEffect(() => {
    if (checkOutWorkOrderIssueData?.checkOutWorkOrderIssue) {
      setWorkOrderIssue(
        checkOutWorkOrderIssueData?.checkOutWorkOrderIssue?.workOrder?.issues?.find(
          ({ id }) => id === workOrderIssueId
        )
      );
    }
  }, [checkOutWorkOrderIssueData?.checkOutWorkOrderIssue, workOrderIssueId]);

  useEffect(() => {
    if (workOrderIssue) {
      const formData = {
        reCodeActions: [],
        targetBudget: workOrderIssue.budget,
        targetBudgetProject: workOrderIssue.budgetProject,
        targetBudgetSpecialProject: workOrderIssue.budgetSpecialProject,
      };

      reset(formData);
    }
  }, [reset, workOrderIssue]);

  useEffect(() => {
    if (serviceTicketId && workOrderIssueId) {
      checkOutWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: workOrderIssueId,
          },
        },
      });

      fetchWorkOrderIssueReCodeDialogData();
    }
  }, [
    checkOutWorkOrderIssue,
    fetchWorkOrderIssueReCodeDialogData,
    serviceTicketId,
    workOrderIssueId,
  ]);

  const handleClose = useCallback(() => {
    if (serviceTicketId && workOrderIssueId) {
      checkInWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: workOrderIssueId,
          },
        },
      });
    }

    if (typeof onClose === 'function') {
      onClose();
    }

    return null;
  }, [checkInWorkOrderIssue, onClose, serviceTicketId, workOrderIssueId]);

  const handleCompleteConfirmationStep = () => setConfirmationStepComplete(true);

  const handleEndSession = useCallback(() => {
    setWorkOrderIssueReCodeDialogTimeoutDialogOpen(false);

    if (handleClose) {
      handleClose();
    }

    return null;
  }, [handleClose]);

  const handleExtendSession = useCallback(() => {
    setWorkOrderIssueReCodeDialogTimeoutDialogOpen(false);

    if (extendWorkOrderIssue && serviceTicketId && workOrderIssueId) {
      extendWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: workOrderIssueId,
          },
        },
      });
    }

    return null;
  }, [extendWorkOrderIssue, serviceTicketId, workOrderIssueId]);

  const handleWindowEvents = useCallback(() => {
    clearTimeout(lastActivityTimeout.current);

    lastActivityTimeout.current = setTimeout(() => {
      lastActivityAt.current = moment().format();
    }, 300);
  }, []);

  const onSubmit = async (data) => {
    try {
      await reCodeWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            reCodeActions: data.reCodeActions?.map((reCodeAction) => ({
              sourceActionItems:
                reCodeAction.sourceActionItems?.map((sourceActionItem) => ({
                  id: sourceActionItem.id,
                })) ?? [],
              targetActionItems:
                reCodeAction.targetActionItems?.map((targetActionItem) => ({
                  commonStandardFlatRate:
                    targetActionItem.commonStandardRateType?.result?.enum === 'FLAT_RATE'
                      ? targetActionItem.commonStandardFlatRate
                      : undefined,
                  commonStandardHourlyRate:
                    targetActionItem.commonStandardRateType?.result?.enum === 'HOURLY_RATE'
                      ? targetActionItem.commonStandardHourlyRate
                      : undefined,
                  commonStandardRateType: { id: targetActionItem.commonStandardRateType?.value },
                  costCode: { id: targetActionItem.costCode?.id },
                  description: targetActionItem.description,
                  notToExceedAmount: targetActionItem.notToExceedAmount
                    ? Number(targetActionItem.notToExceedAmount)
                    : 0,
                  workOrderIssue: { id: workOrderIssue?.id },
                })) ?? [],
              targetBudget: { id: data.targetBudget?.id },
              targetBudgetProject: { id: data.targetBudgetProject?.id },
              targetBudgetSpecialProject: data.targetBudgetSpecialProject?.id
                ? { id: data.targetBudgetSpecialProject?.id }
                : undefined,
              type: { id: reCodeAction.type?.id },
              workOrderIssue: { id: workOrderIssue?.id },
            })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    const eventTypes = ['click', 'keydown', 'mousemove', 'scroll'];

    eventTypes.forEach((type) => window.addEventListener(type, handleWindowEvents));

    return () => {
      eventTypes.forEach((type) => window.removeEventListener(type, handleWindowEvents));
    };
  }, [handleWindowEvents]);

  useEffect(() => {
    if (lastActivityInterval.current) {
      clearInterval(lastActivityInterval.current);
    }

    if (handleEndSession && handleExtendSession && sessionLockedAt) {
      lastActivityInterval.current = setInterval(() => {
        if (moment().isAfter(moment(sessionLockedAt).add(5, 'minutes'))) {
          handleEndSession();
        } else if (moment().isAfter(moment(sessionLockedAt).add(3, 'minutes'))) {
          setWorkOrderIssueReCodeDialogTimeoutDialogOpen(true);
        } else if (
          lastActivityAt.current &&
          moment(lastActivityAt.current).isValid() &&
          moment(lastActivityAt.current).isAfter(moment().subtract(1, 'minutes'))
        ) {
          handleExtendSession();
        }

        return null;
      }, 60 * 1000);
    }

    return () => {
      clearInterval(lastActivityInterval.current);
    };
  }, [handleEndSession, handleExtendSession, sessionLockedAt]);

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Re-Code Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full"
            id="work-order-issue-re-code-form"
            name="work-order-issue-re-code-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ width: '100%', height: 'calc(100vh - 202px)' }}>
              <>
                {!confirmationStepComplete ? (
                  <div className="flex flex-col justify-center m-auto w-320 h-full">
                    <Controller
                      control={control}
                      name="targetBudget"
                      render={({ field }) => (
                        <EntitySearchSelectFieldV2
                          {...field}
                          className="mb-24"
                          error={errors?.targetBudget}
                          // ROADMAP: Limit to Budget Segments
                          filter={[
                            `owner.id = '${user?.data?.organization?.id}'`,
                            `status.enum = 'ACTIVE'`,
                          ]}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          indexName="budgets"
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          placeholder="Select Budget..."
                          sort={['name:asc']}
                          onChange={(params) => {
                            setValue('targetBudgetProject', null);
                            setValue('targetBudgetSpecialProject', null);

                            field.onChange(params);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="targetBudgetProject"
                      render={({ field }) => (
                        <EntitySearchSelectFieldV2
                          {...field}
                          className="mb-24"
                          error={errors?.targetBudgetProject}
                          filter={[
                            `budget.id = '${watchFields?.targetBudget?.id}'`,
                            `owner.id = '${user?.data?.organization?.id}'`,
                            `status.enum = 'ACTIVE'`,
                          ]}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          indexName="budget_projects"
                          isClearable
                          isDisabled={!watchFields?.targetBudget}
                          isMulti={false}
                          placeholder="Select Budget Project..."
                          sort={['name:asc']}
                          onChange={(params) => {
                            setValue('targetBudgetSpecialProject', null);

                            field.onChange(params);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="targetBudgetSpecialProject"
                      render={({ field }) => (
                        <EntitySearchSelectFieldV2
                          {...field}
                          className="mb-24"
                          error={errors?.targetBudgetSpecialProject}
                          filter={[
                            `owner.id = '${user?.data?.organization?.id}'`,
                            `status.enum = 'ACTIVE'`,
                          ]}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.id}
                          indexName="budget_special_projects"
                          isClearable
                          isDisabled={
                            !watchFields?.targetBudget || !watchFields?.targetBudgetProject
                          }
                          isMulti={false}
                          placeholder="Select Budget Special Project..."
                          sort={['name:asc']}
                        />
                      )}
                    />

                    <Button
                      color="secondary"
                      disabled={!watchFields?.targetBudget || !watchFields?.targetBudgetProject}
                      variant="contained"
                      onClick={handleCompleteConfirmationStep}
                    >
                      {`${
                        targetBudgetHasChanged ||
                        targetBudgetProjectHasChanged ||
                        targetBudgetSpecialProjectHasChanged
                          ? 'Change'
                          : 'Confirm'
                      } Budget / Project`}
                    </Button>
                  </div>
                ) : (
                  <ReactFlowProvider>
                    <WorkOrderIssueReCodeDialogReCodeActionsFlow
                      facility={facility}
                      targetBudgetHasChanged={targetBudgetHasChanged}
                      targetBudgetProjectHasChanged={targetBudgetProjectHasChanged}
                      targetBudgetSpecialProjectHasChanged={targetBudgetSpecialProjectHasChanged}
                      workOrderIssue={workOrderIssue}
                      workOrderIssueReCodeActionTypes={workOrderIssueReCodeActionTypes}
                    />
                  </ReactFlowProvider>
                )}
              </>
            </div>
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={handleClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={!isValid || loading}
          form="work-order-issue-re-code-form"
          type="submit"
          variant="contained"
        >
          Re-Code
        </Button>
      </DialogActions>

      <WorkOrderIssueReCodeDialogTimeoutDialog
        open={workOrderIssueReCodeDialogTimeoutDialogOpen}
        sessionExpiresAt={sessionExpiresAt}
        onEndSession={handleEndSession}
        onExtendSession={handleExtendSession}
      />
    </>
  );
};

export default WorkOrderIssueReCodeDialog;
