import { gql } from '@apollo/client';
import { CSI_CLASSIFICATION_VIEW_DRAWER_CSI_CLASSIFICATION_FRAGMENT } from './fragments';

export const FETCH_CSI_CLASSIFICATION_VIEW_DRAWER_DATA = gql`
  query FetchCsiClassificationViewDrawerData($where: CsiClassificationWhereUniqueInput!) {
    csiClassification(where: $where) {
      id
      ...CsiClassificationViewDrawerCsiClassificationFragment
    }
  }
  ${CSI_CLASSIFICATION_VIEW_DRAWER_CSI_CLASSIFICATION_FRAGMENT}
`;

export const UPDATE_CSI_CLASSIFICATION = gql`
  mutation UpdateCsiClassification(
    $where: CsiClassificationWhereUniqueInput!
    $data: CsiClassificationUpdateInput!
  ) {
    updateCsiClassification(where: $where, data: $data) {
      id
      ...CsiClassificationViewDrawerCsiClassificationFragment
    }
  }
  ${CSI_CLASSIFICATION_VIEW_DRAWER_CSI_CLASSIFICATION_FRAGMENT}
`;
