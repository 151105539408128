import { faPieChart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import _ from '@lodash';
import { Avatar, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useFormContext, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';

const AccountingProjectViewHeader = ({ basePath }) => {
  const theme = useTheme();

  const {
    formState: { dirtyFields, isSubmitting, isValid },
  } = useFormContext();

  const watchIsEditableField = useWatch({ name: 'isEditable' });
  const watchNameField = useWatch({ name: 'name' });

  return (
    <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32 border-b-1">
      <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
        <motion.div
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
        >
          <Typography
            className="flex items-center sm:mb-12"
            color="inherit"
            component={Link}
            role="button"
            to="/accounting-projects/search"
          >
            <FuseSvgIcon size={20}>
              {theme.direction === 'ltr'
                ? 'heroicons-outline:arrow-sm-left'
                : 'heroicons-outline:arrow-sm-right'}
            </FuseSvgIcon>

            <span className="flex mx-4 font-medium">Accounting Projects</span>
          </Typography>
        </motion.div>

        <div className="flex items-center max-w-full">
          <motion.div
            className="hidden sm:flex"
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { delay: 0.3 } }}
          >
            <Avatar variant="rounded">
              <FontAwesomeIcon icon={faPieChart} size="lg" />
            </Avatar>
          </motion.div>

          <motion.div
            className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.3 } }}
          >
            <Typography className="text-16 sm:text-20 truncate font-semibold">
              {watchNameField || 'New Accounting Project'}
            </Typography>

            <Typography variant="caption" className="font-medium">
              Accounting Project Detail
            </Typography>
          </motion.div>
        </div>
      </div>
      <motion.div
        className="flex"
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
      >
        <Button
          className="whitespace-nowrap mx-4"
          color="secondary"
          disabled={isSubmitting || !watchIsEditableField}
          startIcon={
            <FuseSvgIcon className="hidden sm:flex">
              {!watchIsEditableField ? 'heroicons-outline:lock-closed' : 'heroicons-outline:trash'}
            </FuseSvgIcon>
          }
          variant="contained"
          // TODO:
          // onClick={handleRemoveProduct}
        >
          Delete
        </Button>

        <Button
          className="whitespace-nowrap mx-4"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || isSubmitting || !isValid || !watchIsEditableField}
          form="accounting-project-update-form"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </motion.div>
    </div>
  );
};

export default AccountingProjectViewHeader;
