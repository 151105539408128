import { useMeilisearch } from 'app/providers/meilisearch';
import { useSocketIo } from 'app/providers/socketIo';
import React, { useEffect, useRef, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

const EntitySearchAppContext = React.createContext();

const EntitySearchAppProvider = ({
  availableViewModes,
  children,
  filters,
  hitsPerPage = 40,
  indexName,
  initialColumnDefs,
  initialViewMode,
  paginationTotalHits = 2000,
  makeSearchAdapter,
  makeSearchAdapterParams,
}) => {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef(null);
  const { instantSearchClient } = useMeilisearch();
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [searchClient, setSearchClient] = useState(null);
  const [viewMode, setViewMode] = useState(initialViewMode);

  useEffect(() => {
    console.log('// TODO: TEST => EntitySearchAppProvider => instantSearchClient');
    if (instantSearchClient) {
      setSearchClient(
        typeof makeSearchAdapter === 'function'
          ? {
              ...instantSearchClient,
              search: makeSearchAdapter(instantSearchClient, makeSearchAdapterParams),
            }
          : instantSearchClient
      );
    }
  }, [instantSearchClient, makeSearchAdapter, makeSearchAdapterParams]);

  useEffect(() => {
    if (refreshSearch && setRefreshSearch) {
      console.log('// TODO: TEST => Provider => Inside', { refreshSearch, setRefreshSearch });
      setRefreshSearch(false);
    }
  }, [refreshSearch, setRefreshSearch]);

  const [connectedSockets, setConnectedSockets] = useState({});
  const initializedSockets = useRef({});
  const { joinRoom, leaveRoom, organizationSocket, teamSocket } = useSocketIo();

  useEffect(() => {
    const cleanUpSocket = ({ socket }) => {
      console.log('// SOCKET.IO => CLEAN UP', {
        initializedSockets: { ...initializedSockets.current },
        socket: { ...socket },
      });

      if (socket.connected) {
        leaveRoom({
          namespace: socket.nsp,
          roomId: `aggregate:${indexName}`,
          socketId: socket.id,
        });
      }

      initializedSockets.current[socket.nsp] = false;
    };

    const initializeSocket = ({ socket }) => {
      joinRoom({
        namespace: socket.nsp,
        roomId: `aggregate:${indexName}`,
        socketId: socket.id,
      });

      initializedSockets.current[socket.nsp] = true;
    };

    const makeOnConnect =
      ({ socket }) =>
      () => {
        console.log('// SOCKET.IO => Connected', { socket });

        setConnectedSockets((prev) => ({ ...(prev || {}), [socket.nsp]: true }));

        if (!initializedSockets.current[socket.nsp]) {
          initializeSocket({ socket });
        }
      };

    const makeOnDisconnect =
      ({ socket }) =>
      () => {
        console.log('// SOCKET.IO => Disconnected', { socket });

        setConnectedSockets((prev) => ({ ...(prev || {}), [socket.nsp]: false }));

        initializedSockets.current[socket.nsp] = false;
      };

    const makeOnDocumentDelete =
      ({ socket }) =>
      (value) => {
        console.log('// SOCKET.IO => document.delete', { socket, value });

        if (value.roomId === `aggregate:${indexName}`) {
          console.log('// SOCKET.IO => document.delete', { roomId: value.roomId });

          // TODO:
          setRefreshSearch(true);
        }
      };

    const makeOnDocumentUpsert =
      ({ socket }) =>
      (value) => {
        console.log('// SOCKET.IO => document.upsert', { socket, value });

        if (value.roomId === `aggregate:${indexName}`) {
          console.log('// SOCKET.IO => document.upsert', { roomId: value.roomId });

          // TODO:
          setRefreshSearch(true);
          // if (gridReady && gridRef.current) {
          //   const rowNode = gridRef.current.api.getRowNode(value.id);

          //   if (rowNode) {
          //     rowNode.setData(value);
          //   }
          // }
        }
      };

    const socketListeners = {};

    // TODO: Review
    if (gridReady && organizationSocket && teamSocket) {
      [organizationSocket, teamSocket].forEach((socket) => {
        console.log('// SOCKET.IO => ON', { socket });

        socketListeners[socket.nsp] = {
          connect: makeOnConnect({ socket }),
          disconnect: makeOnDisconnect({ socket }),
          'document.delete': makeOnDocumentDelete({ socket }),
          'document.upsert': makeOnDocumentUpsert({ socket }),
        };

        Object.keys(socketListeners[socket.nsp]).forEach((eventName) => {
          console.log('// SOCKET.IO => ON', {
            socket,
            eventName,
            socketListener: socketListeners[socket.nsp][eventName],
          });

          socket.on(eventName, socketListeners[socket.nsp][eventName]);
        });

        if (socket?.connected) {
          socketListeners[socket.nsp].connect();
        }
      });
    }

    return () => {
      console.log('// // SOCKET.IO => UNMOUNT', { organizationSocket, teamSocket });
      // TODO: Review
      if (gridReady && organizationSocket && teamSocket) {
        [organizationSocket, teamSocket].forEach((socket) => {
          console.log('// SOCKET.IO => OFF', {
            socket,
            socketListeners,
            dafuq: socketListeners[socket.nsp],
          });

          Object.keys(socketListeners[socket.nsp]).forEach((eventName) => {
            console.log('// SOCKET.IO => OFF', {
              socket,
              eventName,
              socketListener: socketListeners[socket.nsp][eventName],
            });

            socket.off(eventName, socketListeners[socket.nsp][eventName]);
          });

          cleanUpSocket({ socket });
        });
      }
    };
  }, [gridReady, indexName, joinRoom, leaveRoom, organizationSocket, teamSocket, setRefreshSearch]);

  if (!searchClient) return null;

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <Configure
        filters={filters}
        hitsPerPage={hitsPerPage}
        paginationTotalHits={paginationTotalHits}
      />

      <EntitySearchAppContext.Provider
        value={{
          availableViewModes,
          connectedSockets,
          gridReady,
          gridRef,
          hitsPerPage,
          indexName,
          initialColumnDefs,
          refreshSearch,
          setGridReady,
          setRefreshSearch,
          setViewMode,
          viewMode,
        }}
      >
        {children}
      </EntitySearchAppContext.Provider>
    </InstantSearch>
  );
};

export const __EntitySearchAppContext = EntitySearchAppContext;
export default EntitySearchAppProvider;
