import { useMeilisearch } from 'app/providers/meilisearch';
import React, { useEffect, useRef, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

const EntitySearchAppContext = React.createContext();

const EntitySearchAppProvider = ({
  availableViewModes,
  children,
  filters,
  hitsPerPage = 40,
  indexName,
  initialColumnDefs,
  initialViewMode,
  paginationTotalHits = 2000,
  makeSearchAdapter,
  makeSearchAdapterParams,
}) => {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef(null);
  const { instantSearchClient } = useMeilisearch();
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [searchClient, setSearchClient] = useState(null);
  const [viewMode, setViewMode] = useState(initialViewMode);

  useEffect(() => {
    console.log('// TODO: TEST => EntitySearchAppProvider => instantSearchClient');
    if (instantSearchClient) {
      setSearchClient(
        typeof makeSearchAdapter === 'function'
          ? {
              ...instantSearchClient,
              search: makeSearchAdapter(instantSearchClient, makeSearchAdapterParams),
            }
          : instantSearchClient
      );
    }
  }, [instantSearchClient, makeSearchAdapter, makeSearchAdapterParams]);

  if (!searchClient) return null;

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <Configure
        filters={filters}
        hitsPerPage={hitsPerPage}
        paginationTotalHits={paginationTotalHits}
      />

      <EntitySearchAppContext.Provider
        value={{
          availableViewModes,
          gridReady,
          gridRef,
          hitsPerPage,
          indexName,
          initialColumnDefs,
          refreshSearch,
          setGridReady,
          setRefreshSearch,
          setViewMode,
          viewMode,
        }}
      >
        {children}
      </EntitySearchAppContext.Provider>
    </InstantSearch>
  );
};

export const __EntitySearchAppContext = EntitySearchAppContext;
export default EntitySearchAppProvider;
