// eslint-disable-next-line import/prefer-default-export
export const programCampaignSearchScreenFilterDefs = [
  {
    name: 'Program Campaign',
    refinements: [
      {
        attribute: 'isEnded',
        label: 'Ended',
        operator: 'or',
      },
      {
        attribute: 'isProcessed',
        label: 'Processed',
        operator: 'or',
      },
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Issue Template',
    refinements: [
      {
        attribute: 'issueTemplates.issueTemplate.name',
        label: 'Name',
        operator: 'or',
      },
      {
        attribute: 'issueTemplates.issueTemplate.tags.name',
        label: 'Tags',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Program',
    refinements: [
      {
        attribute: 'program.creator.name',
        label: 'Creator',
        operator: 'or',
      },
      {
        attribute: 'program.status.name',
        label: 'Status',
        operator: 'or',
      },
      {
        attribute: 'program.tags.name',
        label: 'Tags',
        operator: 'or',
      },
    ],
  },
];
