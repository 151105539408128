import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const AccountingExpenseTypesSearchPage = lazy(() => import('./AccountingExpenseTypesSearchPage'));
const accountingExpenseTypesSearchPageBase = '/accounting-expense-types';

// i18next.addResourceBundle('en', 'accountingExpenseTypesSearchPage', en);
// i18next.addResourceBundle('tr', 'accountingExpenseTypesSearchPage', tr);
// i18next.addResourceBundle('ar', 'accountingExpenseTypesSearchPage', ar);

const accountingExpenseTypesSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${accountingExpenseTypesSearchPageBase}/search`,
      element: <AccountingExpenseTypesSearchPage />,
    },
    {
      path: `${accountingExpenseTypesSearchPageBase}/quick-view/:accountingExpenseTypeId`,
      element: <AccountingExpenseTypesSearchPage />,
    },
    {
      path: accountingExpenseTypesSearchPageBase,
      element: <Navigate to={`${accountingExpenseTypesSearchPageBase}/search`} />,
    },
  ],
};

export default accountingExpenseTypesSearchPageConfig;
