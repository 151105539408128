import { useMutation, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Alert,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_ISSUE_FINALIZE_TOTALS_DIALOG_DATA,
  FINALIZE_TOTALS_WORK_ORDER_ISSUE,
} from './queries';

const defaultValues = {
  workOrderIssues: [],
};

const schema = yup.object().shape({
  workOrderIssues: yup
    .array()
    .test(
      'requireOneWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value.filter((el) => el).length
    ),
});

const WorkOrderIssueFinalizeTotalsDialog = ({
  defaultWorkOrderIssueId,
  serviceTicketId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueFinalizeTotalsDialogData,
    {
      data: workOrderIssueFinalizeTotalsDialogData,
      loading: workOrderIssueFinalizeTotalsDialogLoading,
      refetch: workOrderIssueFinalizeTotalsDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_FINALIZE_TOTALS_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [finalizeTotalsWorkOrderIssue, { loading: finalizeTotalsWorkOrderIssueLoading }] =
    useMutation(FINALIZE_TOTALS_WORK_ORDER_ISSUE, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Successfully Finalized',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Finalizing Work Order Issue', variant: 'error' }));
      },
    });

  const filteredWorkOrderIssues = useMemo(
    () =>
      workOrderIssueFinalizeTotalsDialogData?.serviceTicket?.workOrder?.issues
        ?.filter(({ actionItems, reviews, status, subtotalAmountCheck }) =>
          Boolean(
            ['APPROVED'].includes(subtotalAmountCheck?.enum) &&
              ['COMPLETED'].includes(status?.enum) &&
              actionItems &&
              !_.some(actionItems || [], ({ totalAmount }) => !totalAmount) &&
              !_.some(reviews || [], ({ isCancelled, isCompleted }) =>
                Boolean(!isCancelled && !isCompleted)
              )
          )
        )
        ?.map((workOrderIssues) => workOrderIssues),
    [workOrderIssueFinalizeTotalsDialogData?.serviceTicket]
  );

  const loading = useMemo(
    () =>
      [workOrderIssueFinalizeTotalsDialogLoading, finalizeTotalsWorkOrderIssueLoading].includes(
        true
      ),
    [workOrderIssueFinalizeTotalsDialogLoading, finalizeTotalsWorkOrderIssueLoading]
  );

  const serviceTicket = useMemo(
    () => workOrderIssueFinalizeTotalsDialogData?.serviceTicket,
    [workOrderIssueFinalizeTotalsDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (defaultWorkOrderIssueId && filteredWorkOrderIssues) {
      const newDefaultValues = _.cloneDeep(defaultValues);
      const defaultWorkOrderIssueIndex = filteredWorkOrderIssues?.findIndex(
        ({ id }) => id === defaultWorkOrderIssueId
      );

      if (defaultWorkOrderIssueIndex !== -1) {
        newDefaultValues.workOrderIssues[defaultWorkOrderIssueIndex] = defaultWorkOrderIssueId;
      }

      reset(newDefaultValues);
    }
  }, [defaultWorkOrderIssueId, filteredWorkOrderIssues, reset]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderIssueFinalizeTotalsDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchWorkOrderIssueFinalizeTotalsDialogData, serviceTicketId]);

  const handleToggleAllWorkOrderIssues = () => {
    if (
      filteredWorkOrderIssues?.length === watchFields.workOrderIssues?.length &&
      !_.some(watchFields.workOrderIssues || [], (value) => !value)
    ) {
      setValue('workOrderIssues', [], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    } else {
      setValue(
        'workOrderIssues',
        filteredWorkOrderIssues?.map(({ id }) => id),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    }
  };

  const onSubmit = async (data) => {
    try {
      await Promise.all(
        data?.workOrderIssues
          ?.filter((el) => el)
          ?.map(
            (workOrderIssueId) =>
              // eslint-disable-next-line no-async-promise-executor
              new Promise(async (resolveWorkOrderIssue, rejectWorkOrderIssue) => {
                try {
                  await finalizeTotalsWorkOrderIssue({
                    variables: {
                      where: { id: serviceTicketId },
                      data: {
                        id: workOrderIssueId,
                      },
                    },
                  });

                  resolveWorkOrderIssue();
                } catch (err) {
                  rejectWorkOrderIssue(err);
                }
              })
          )
      );
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Finalize Work Order Issue Totals
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-finalize-totals-form"
            name="work-order-issue-finalize-totals-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Alert className="mb-24" severity="info">
              Once finalized, a work order issues' totals can no longer be adjusted.
            </Alert>

            <Controller
              control={control}
              name="workOrderIssues"
              render={({ field }) => (
                <>
                  <div className="mb-24">
                    <div
                      className="border-1 overflow-y-auto"
                      style={{
                        maxHeight: 290,
                        borderColor: errors.workOrderIssues ? '#f44336' : grey[300],
                      }}
                    >
                      <List disablePadding>
                        <ListItemButton onClick={handleToggleAllWorkOrderIssues}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                !_.some(watchFields.workOrderIssues || [], (value) => !value)
                              }
                              edge="start"
                              disableRipple
                              indeterminate={
                                (filteredWorkOrderIssues?.length !==
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value)) ||
                                (filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value) &&
                                  _.some(watchFields.workOrderIssues || [], (value) => !value))
                              }
                              tabIndex={-1}
                              // value={id}
                              onChange={handleToggleAllWorkOrderIssues}
                            />
                          </ListItemIcon>

                          <ListItemText primary="Select All" />
                        </ListItemButton>

                        {filteredWorkOrderIssues?.map(({ id, serviceIssue }, index) => {
                          const handleChange = () => {
                            const newFieldValue = [...field?.value];

                            newFieldValue[index] = !newFieldValue[index] ? id : null;

                            field.onChange(newFieldValue);
                          };

                          return (
                            <ListItemButton key={id} onClick={handleChange}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={!!field?.value?.[index]}
                                  edge="start"
                                  disableRipple
                                  tabIndex={-1}
                                  value={id}
                                  onChange={handleChange}
                                />
                              </ListItemIcon>

                              <ListItemText primary={serviceIssue.name} />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </div>

                    {errors.workOrderIssues && (
                      <div className="mx-14 mt-3">
                        <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
                          {errors.workOrderIssues?.message}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={
            // ROADMAP: Fix or Remove
            // _.isEmpty(dirtyFields) ||
            !isValid || loading
          }
          form="work-order-issue-finalize-totals-form"
          type="submit"
          variant="contained"
        >
          Finalize
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueFinalizeTotalsDialog;
