import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildFacilitySearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'facility',
    headerName: 'Facility',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'franchiseNumber',
        headerName: 'Number',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'address.city',
        headerName: 'City',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'address.region',
        headerName: 'Region',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'marketTeam',
        headerName: 'Market Team',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'regionalTeam',
        headerName: 'Regional Team',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'businessConsultantContact.name',
        headerName: 'Business Consultant',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'esrContact.name',
        headerName: 'ESR',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'fieldConsultantContact.name',
        headerName: 'Field Consultant',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'leadAdvisorContact.name',
        headerName: 'Lead Advisor',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'operatorContact.name',
        headerName: 'Operator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'projectAdministratorContact.name',
        headerName: 'Project Administrator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'projectCoordinatorContact.name',
        headerName: 'Project Coordinator',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'regionalCoordinatorContact.name',
        headerName: 'Regional Coordinator',
        sortable: true,
      },
    ],
  },
];
