import { memo } from 'react';

const EntitySearchInfiniteHitsGridDefaultCellRenderer = memo(({ data, value, valueFormatted }) => {
  if (!data?.id) {
    return (
      <div className="w-full h-full p-12 animate-pulse">
        <div className="bg-gray-200 rounded-full dark:bg-gray-700 h-full w-full" />
      </div>
    );
  }

  return valueFormatted || value;
});

export default EntitySearchInfiniteHitsGridDefaultCellRenderer;
