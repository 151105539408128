import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { List, ListItem, Paper, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import {
  getProgramCampaignViewScreenColors,
  programCampaignViewScreenCurrencyFormatter,
} from '../utils';

const ProgramCampaignViewScreenAccountingExpenseTypeSpendWidget = ({ programCampaign }) => {
  const theme = useTheme();

  const colors = useMemo(() => getProgramCampaignViewScreenColors({ limit: 10 }), []);

  const labels = useMemo(
    () =>
      programCampaign?.analytics?.accountingExpenseTypes?.map(
        ({ accountingExpenseType }) => accountingExpenseType.name
      ),
    [programCampaign?.analytics?.accountingExpenseTypes]
  );

  const series = useMemo(
    () =>
      programCampaign?.analytics?.accountingExpenseTypes
        ?.map(({ totalAmountSum }) => totalAmountSum ?? 0)
        ?.sort((a, b) => b - a)
        ?.slice(0, 10),
    [programCampaign?.analytics?.accountingExpenseTypes]
  );

  const seriesTotal = useMemo(() => series?.reduce((acc, value) => acc + value, 0), [series]);

  const options = useMemo(
    () => ({
      chart: {
        animations: {
          speed: 400,
          animateGradually: {
            enabled: false,
          },
        },
        background: 'transparent',
        fontFamily: 'inherit',
        foreColor: theme?.palette.mode === 'light' ? '#111827' : '#ffffff',
        height: '100%',
        id: 'rename-donut',
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      colors,
      dataLabels: {
        enabled: true,
      },
      labels,
      legend: {
        show: false,
      },
      noData: {
        text: 'No Data to Display',
      },
      plotOptions: {
        pie: {
          customScale: 0.9,
          expandOnClick: false,
          donut: {
            size: '70%',
          },
        },
      },
      series,
      stroke: {
        colors: [theme?.palette.background.paper],
      },
      theme: {
        mode: theme?.palette.mode,
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
        theme: theme?.palette.mode,
        y: {
          formatter: programCampaignViewScreenCurrencyFormatter,
        },
      },
    }),
    [colors, labels, series, theme?.palette]
  );

  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl">
      <div className="p-20 pb-8">
        <Typography className="text-16 font-medium">Accounting Expense Type Spend</Typography>
      </div>

      <Chart options={{ ...options }} series={options?.series} type={options?.chart.type} />

      {!_.isEmpty(series) && (
        <div className="h-192 my-8 overflow-auto">
          <List className="border-t-1" disablePadding>
            {series?.map((value, index) => (
              <ListItem divider>
                <FontAwesomeIcon icon={faCircle} style={{ color: options.colors[index] }} />

                <Tooltip title={labels[index]}>
                  <Typography className="ml-12 truncate">{labels[index]}</Typography>
                </Tooltip>

                <Typography className="ml-12 font-medium text-right">
                  {programCampaignViewScreenCurrencyFormatter(value)}
                </Typography>

                <Typography className="ml-12 text-right">
                  {`${((value / seriesTotal) * 100).toFixed(1)}%`}
                </Typography>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </Paper>
  );
};

export default ProgramCampaignViewScreenAccountingExpenseTypeSpendWidget;
