import { useMutation } from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ACTIVATE_SERVICE_COMPLETION_ITEM } from './queries';

const ServiceCompletionItemActivateDialog = ({ serviceCompletionItemId, onClose, onSuccess }) => {
  const dispatch = useDispatch();

  const [activateServiceCompletionItem, { loading: activateServiceCompletionItemLoading }] =
    useMutation(ACTIVATE_SERVICE_COMPLETION_ITEM, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Completion Item Successfully Activated',
            variant: 'success',
          })
        );

        if (typeof onSuccess === 'function') {
          onSuccess(data?.activateServiceCompletionItem);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Activating Completion Item', variant: 'error' }));
      },
    });

  const loading = useMemo(
    () => [activateServiceCompletionItemLoading].includes(true),
    [activateServiceCompletionItemLoading]
  );

  const onSubmit = async (data) => {
    try {
      await activateServiceCompletionItem({
        variables: {
          where: { id: serviceCompletionItemId },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Activate Completion Item
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText sx={{ padding: '24px 0' }}>
          Once active; users will be required to complete this item based on your organization
          settings; do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={loading}
          form="service-completion-item-activate-form"
          type="submit"
          variant="contained"
          onClick={onSubmit}
        >
          Activate
        </Button>
      </DialogActions>
    </>
  );
};

export default ServiceCompletionItemActivateDialog;
