import {
  faCodeCommit,
  faCodeFork,
  faCodeMerge,
  faCodePullRequest,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { getIncomers, getOutgoers, Handle, useEdges, useNodes } from '@xyflow/react';
import { capitalCase } from 'change-case';
import numeral from 'numeral';
import { memo, useCallback } from 'react';

const TooltipTypographyWrapper = memo(({ className, content }) => {
  return (
    <Tooltip title={content}>
      <Typography className={className} noWrap>
        {content}
      </Typography>
    </Tooltip>
  );
});

const WorkOrderIssueReCodeDialogReCodeActionsFlowTargetNode = memo(
  ({ data, isConnectable, targetPosition, ...node }) => {
    const edges = useEdges();
    const nodes = useNodes();
    const theme = useTheme();

    const incomers = getIncomers(node, nodes, edges);
    const outgoers = getOutgoers(node, nodes, edges);

    const handleUpdateReCodeAction = useCallback(() => {
      if (data.reCodeAction && typeof data.handleUpdateReCodeAction === 'function') {
        data.handleUpdateReCodeAction({ reCodeAction: data.reCodeAction });
      }
    }, [data]);

    return (
      <>
        {!!incomers?.length && (
          <Handle isConnectable={isConnectable} position={targetPosition} type="target" />
        )}

        <div
          className="flex items-center w-320 h-112 py-12 pl-6 pr-12 border-1 rounded"
          style={{ backgroundColor: theme.palette.mode === 'light' ? grey[100] : grey[900] }}
        >
          <div style={{ width: 42 }}>
            {!!incomers?.length && (
              <Tooltip title="Edit">
                <IconButton
                  className="nodrag"
                  sx={{ width: 42, height: 42 }}
                  onClick={handleUpdateReCodeAction}
                >
                  {/* ROADMAP: Don't Hardcode ENUMs */}
                  {data.reCodeAction?.type?.enum === 'ACCEPT' && (
                    <FontAwesomeIcon icon={faCodeCommit} size="sm" />
                  )}
                  {data.reCodeAction?.type?.enum === 'MERGE' && (
                    <FontAwesomeIcon icon={faCodeMerge} size="sm" />
                  )}
                  {data.reCodeAction?.type?.enum === 'REPLACE' && (
                    <FontAwesomeIcon icon={faCodePullRequest} size="sm" />
                  )}
                  {data.reCodeAction?.type?.enum === 'SPLIT' && (
                    <FontAwesomeIcon icon={faCodeFork} size="sm" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </div>

          <div className="flex flex-1 flex-col pl-6" style={{ width: 174 }}>
            {data.targetActionItem?.costCode?.accountingTaskCode ? (
              <TooltipTypographyWrapper
                className="text-11"
                content={`${data.targetActionItem.costCode.accountingTaskCode.name} (${data.targetActionItem.costCode.accountingTaskCode.number})`}
              />
            ) : (
              <Typography className="text-11" noWrap>
                CSI Classification
              </Typography>
            )}

            <TooltipTypographyWrapper
              className="text-14"
              content={
                data.targetActionItem?.costCode?.csiClassification
                  ? `${data.targetActionItem.costCode.csiClassification.name} (${data.targetActionItem.costCode.csiClassification.number})`
                  : 'Cost Code Not Set'}
            />

            <div className="mt-6">
              {/* ROADMAP: Handle Multiple Coding Strings */}
              {data.targetActionItem?.codingStrings?.[0] ? (
                <>
                  <Typography className="text-11" noWrap>
                    {capitalCase(data.targetActionItem.codingStrings[0].typeEnum)}
                  </Typography>

                  <TooltipTypographyWrapper
                    className="text-14"
                    content={data.targetActionItem.codingStrings[0].value}
                  />
                </>
              ) : (
                <>
                  <Typography className="text-11" noWrap>
                    Coding String
                  </Typography>

                  <Typography className="text-14" noWrap>
                    Not Set
                  </Typography>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col items-end" style={{ width: 84 }}>
            <Typography className="text-11">Total</Typography>
            <Typography className="text-14">
              {numeral(data.targetActionItem?.commonStandardFlatRate?.quantity ?? 0).format(
                '$0,0.00'
              )}
            </Typography>
          </div>
        </div>
      </>
    );
  }
);

export default WorkOrderIssueReCodeDialogReCodeActionsFlowTargetNode;
