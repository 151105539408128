import { gql } from '@apollo/client';
import { ACCOUNTING_PROJECT_CREATE_DIALOG_ACCOUNTING_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ACCOUNTING_PROJECT = gql`
  mutation CreateAccountingProject($data: AccountingProjectCreateInput!) {
    createAccountingProject(data: $data) {
      id
      ...AccountingProjectCreateDialogAccountingProjectFragment
    }
  }
  ${ACCOUNTING_PROJECT_CREATE_DIALOG_ACCOUNTING_PROJECT_FRAGMENT}
`;
