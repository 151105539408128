import { gql } from '@apollo/client';
import { ACCOUNTING_TASK_CODE_CREATE_DIALOG_ACCOUNTING_TASK_CODE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ACCOUNTING_TASK_CODE = gql`
  mutation CreateAccountingTaskCode($data: AccountingTaskCodeCreateInput!) {
    createAccountingTaskCode(data: $data) {
      id
      ...AccountingTaskCodeCreateDialogAccountingTaskCodeFragment
    }
  }
  ${ACCOUNTING_TASK_CODE_CREATE_DIALOG_ACCOUNTING_TASK_CODE_FRAGMENT}
`;
