import {
  faBriefcase,
  faCircleChevronDown,
  faCircleChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Avatar, Button, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  BudgetProjectActivateDialog,
  BudgetProjectDeactivateDialog,
} from 'app/shared-components/BudgetProject';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';

const BudgetProjectViewScreenHeader = ({ basePath, budgetProject }) => {
  const [activationMenu, setActivationMenu] = useState(null);
  const dispatch = useDispatch();
  // NOTE: Uses a Different `breakpoint` to Other Screens
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useSelector(selectUser);

  const canActivationBudgetProject = useMemo(
    () =>
      Boolean(
        budgetProject?.budget?.status?.enum === 'ACTIVE' &&
          budgetProject?.owner?.id === user?.data?.organization?.id
      ),
    [budgetProject?.budget?.status?.enum, budgetProject?.owner?.id, user?.data?.organization?.id]
  );

  const informationHeaderWidth = useMemo(() => 340, []);

  const handleChangeTab = (event, value) => navigate(value);

  const handleCloseActivationMenu = useCallback(() => setActivationMenu(null), []);

  const handleOpenActivationMenu = (event) => setActivationMenu(event.currentTarget);

  return (
    <SizeMe monitorHeight={false} monitorWidth>
      {({ size }) => (
        <div
          className="flex flex-col sm:flex-row items-center justify-center w-full h-auto h-256 sm:h-160"
          style={{ backgroundColor: theme.palette.mode === 'light' ? '#f1f5f9' : '#111827' }}
        >
          <div
            className="flex flex-col items-center justify-center pt-16 sm:pt-0 px-0 sm:px-24"
            style={{ width: informationHeaderWidth }}
          >
            <Typography
              className="flex items-center mb-12 w-full"
              color="inherit"
              component={Link}
              role="button"
              to="/budget-projects/search"
            >
              <FuseSvgIcon size={20}>
                {theme.direction === 'ltr'
                  ? 'heroicons-outline:arrow-sm-left'
                  : 'heroicons-outline:arrow-sm-right'}
              </FuseSvgIcon>

              <span className="flex mx-4 font-medium">Budget Projects</span>
            </Typography>

            <div className="flex items-center">
              <Avatar variant="rounded">
                <FontAwesomeIcon icon={faBriefcase} size="lg" />
              </Avatar>

              <div className="ml-16">
                <Typography
                  className="text-20 truncate font-semibold"
                  // NOTE: Padding + Avatar + Margin
                  style={{ width: informationHeaderWidth - (48 + 40 + 16) }}
                >
                  {budgetProject?.name ?? 'Budget Project'}
                </Typography>

                <Typography variant="caption" className="font-medium">
                  Budget Project Detail
                </Typography>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full h-full">
            <div className="flex flex-1 items-center justify-start sm:justify-end px-24 mt-20 mb-32 sm:my-0">
              <Button
                color="secondary"
                disabled={!canActivationBudgetProject}
                startIcon={
                  <FontAwesomeIcon
                    icon={activationMenu ? faCircleChevronDown : faCircleChevronRight}
                  />
                }
                variant="contained"
                onClick={handleOpenActivationMenu}
              >
                {budgetProject?.status?.name}
              </Button>

              <Menu
                anchorEl={activationMenu}
                open={Boolean(activationMenu)}
                onClose={handleCloseActivationMenu}
              >
                {['ACTIVE'].includes(budgetProject?.status?.enum) && (
                  <MenuItem
                    disabled={!canActivationBudgetProject}
                    onClick={() => {
                      handleCloseActivationMenu();

                      dispatch(
                        openDialog({
                          children: (
                            <BudgetProjectDeactivateDialog
                              budgetProjectId={budgetProject?.id}
                              onClose={() => dispatch(closeDialog())}
                            />
                          ),
                          classes: {
                            paper: 'w-full max-w-640 min-w-320 rounded-8',
                          },
                        })
                      );
                    }}
                  >
                    Deactivate Budget Project
                  </MenuItem>
                )}

                {['DRAFT', 'INACTIVE'].includes(budgetProject?.status?.enum) && (
                  <MenuItem
                    disabled={!canActivationBudgetProject}
                    onClick={() => {
                      handleCloseActivationMenu();

                      dispatch(
                        openDialog({
                          children: (
                            <BudgetProjectActivateDialog
                              budgetProjectId={budgetProject?.id}
                              onClose={() => dispatch(closeDialog())}
                            />
                          ),
                          classes: {
                            paper: 'w-full max-w-640 min-w-320 rounded-8',
                          },
                        })
                      );
                    }}
                  >
                    Activate Budget Project
                  </MenuItem>
                )}
              </Menu>
            </div>

            <Tabs
              classes={{ root: 'w-full h-48' }}
              indicatorColor="secondary"
              scrollButtons="auto"
              sx={{
                maxWidth: size.width - (isMobile ? 0 : informationHeaderWidth),
                '& .Mui-selected': {
                  backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                },
              }}
              textColor="inherit"
              value={`../${basePath}`}
              variant="scrollable"
              onChange={handleChangeTab}
            >
              <Tab className="h-48" label="Overview" value="../tabs/overview" />
              <Tab className="h-48" label="Cost Codes" value="../tabs/cost-codes" />
              <Tab className="h-48" label="Completion Items" value="../tabs/completion-items" />
            </Tabs>
          </div>
        </div>
      )}
    </SizeMe>
  );
};

export default BudgetProjectViewScreenHeader;
