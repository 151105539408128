import { useMutation } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { BudgetProjectDeleteDialog } from 'app/shared-components/BudgetProject';
import { CommonStandardRateTypeSelectField } from 'app/shared-components/CommonStandardRateType';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_BUDGET_PROJECT } from '../queries';
import BudgetProjectViewScreenAttachments from './BudgetProjectViewScreenAttachments';
import BudgetProjectViewScreenHeader from './BudgetProjectViewScreenHeader';

const defaultValues = {
  attachments: [],
  commonStandardRateTypes: [],
  generalLedgerAccount: '',
  isDefault: false,
  name: '',
  projectCode: '',
};

const schema = yup.object().shape({
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  commonStandardRateTypes: yup
    .array()
    .test(
      'requireOneCommonStandardRateType',
      'One or more rate types must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
  generalLedgerAccount: yup.string().nullable().optional(),
  isDefault: yup.boolean().required(),
  name: yup.string().nullable().required('A name must be provided.'),
  projectCode: yup.string().nullable().optional(),
});

const BudgetProjectViewScreenOverviewTab = ({ budgetProject }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [updateBudgetProject, { loading: updateBudgetProjectLoading }] = useMutation(
    UPDATE_BUDGET_PROJECT,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Budget Project Successfully Updated',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Updating Budget Project', variant: 'error' }));
      },
    }
  );

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const canDeleteBudgetProject = useMemo(
    () =>
      Boolean(
        ['DRAFT'].includes(budgetProject?.status?.enum) &&
          budgetProject?.owner?.id === user?.data?.organization?.id
      ),
    [budgetProject?.owner?.id, budgetProject?.status?.enum, user?.data?.organization?.id]
  );

  const canUpdateBudgetProject = useMemo(
    () =>
      Boolean(
        ['DRAFT', 'INACTIVE'].includes(budgetProject?.status?.enum) &&
          budgetProject?.owner?.id === user?.data?.organization?.id
      ),
    [budgetProject?.owner?.id, budgetProject?.status?.enum, user?.data?.organization?.id]
  );

  const loading = useMemo(
    () => [updateBudgetProjectLoading].includes(true),
    [updateBudgetProjectLoading]
  );

  useEffect(() => {
    if (budgetProject) {
      const formData = {
        attachments:
          budgetProject.attachments?.map((attachment) => {
            let commonStoredObject;

            if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
              commonStoredObject = attachment.commonStoredImage;
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
              commonStoredObject = attachment.commonStoredPdf;
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
              commonStoredObject = attachment.commonStoredVideo;
            }

            return commonStoredObject
              ? {
                  commonStoredUpload: {
                    key: commonStoredObject?.commonStoredUpload?.key,
                  },
                  file: {
                    name: commonStoredObject?.name,
                    preview: commonStoredObject?.thumbnailImageUrl,
                  },
                }
              : null;
          }) || [],
        commonStandardRateTypes: budgetProject.commonStandardRateTypes?.map(
          ({ commonStandardRateType }) => ({
            label: commonStandardRateType.name,
            result: commonStandardRateType,
            value: commonStandardRateType.id,
          })
        ),
        generalLedgerAccount: budgetProject.generalLedgerAccount,
        isDefault: budgetProject.isDefault,
        name: budgetProject.name,
        projectCode: budgetProject.projectCode,
      };

      reset(formData);
    }
  }, [reset, budgetProject]);

  const onSubmit = async (data) => {
    try {
      await updateBudgetProject({
        variables: {
          where: { id: budgetProject.id },
          data: {
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            commonStandardRateTypes: data.commonStandardRateTypes?.map(({ value }) => ({
              id: value,
            })),
            generalLedgerAccount: data.generalLedgerAccount,
            isDefault: data.isDefault,
            name: data.name,
            projectCode: data.projectCode,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <FusePageCarded
      content={
        <>
          {!budgetProject ? (
            <FuseLoading className="h-full" />
          ) : (
            <div className="flex flex-col w-full max-w-5xl mx-auto p-24">
              <Grid container spacing={2}>
                <Grid md={8} xs={12}>
                  <FormProvider
                    {...{
                      clearErrors,
                      control,
                      formState,
                      handleSubmit,
                      reset,
                      setValue,
                      watch,
                      ...methods,
                    }}
                  >
                    <form
                      className="w-full"
                      id="budget-project-view-screen-overview-form"
                      name="budget-project-view-screen-overview-form"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Paper
                        className="p-24 border-1"
                        sx={{
                          backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
                        }}
                      >
                        <Grid className="mb-24" container spacing={2}>
                          <Grid
                            alignItems="center"
                            display="flex"
                            md={9}
                            order={{ md: 1, xs: 2 }}
                            xs={12}
                          >
                            <Controller
                              control={control}
                              name="name"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className=""
                                  error={!!errors?.name}
                                  fullWidth
                                  helperText={errors?.name?.message}
                                  inputProps={{ readOnly: !canUpdateBudgetProject }}
                                  placeholder="Enter Budget Project Name..."
                                  required
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid
                            alignItems="center"
                            display="flex"
                            justifyContent="right"
                            md={3}
                            order={{ md: 2, xs: 1 }}
                            xs={12}
                          >
                            <Controller
                              control={control}
                              name="isDefault"
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      {...field}
                                      checked={!!field.value}
                                      color="info"
                                      disabled={!canUpdateBudgetProject}
                                    />
                                  }
                                  label="Default"
                                  labelPlacement="start"
                                  sx={{ ml: 0 }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Divider sx={{ mb: 3 }} />

                        <Grid container spacing={2}>
                          <Grid xs={12}>
                            <Controller
                              control={control}
                              name="generalLedgerAccount"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  error={!!errors?.generalLedgerAccount}
                                  fullWidth
                                  helperText={errors?.generalLedgerAccount?.message}
                                  inputProps={{ readOnly: !canUpdateBudgetProject }}
                                  placeholder="Enter General Ledger Account..."
                                  required
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid xs={12}>
                            <Controller
                              control={control}
                              name="projectCode"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  error={!!errors?.projectCode}
                                  fullWidth
                                  helperText={errors?.projectCode?.message}
                                  inputProps={{ readOnly: !canUpdateBudgetProject }}
                                  placeholder="Enter Project Code..."
                                  required
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid xs={12}>
                            <Controller
                              control={control}
                              name="commonStandardRateTypes"
                              render={({ field }) => (
                                <CommonStandardRateTypeSelectField
                                  {...field}
                                  className="mb-24"
                                  error={errors?.commonStandardRateTypes}
                                  filters={null}
                                  isDisabled={!canUpdateBudgetProject}
                                  isClearable
                                  isMulti
                                  placeholder="Select Rate Types..."
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Divider sx={{ mb: 3 }} />

                        <Grid container spacing={2}>
                          <Grid xs={12}>
                            <BudgetProjectViewScreenAttachments
                              disabled={!canUpdateBudgetProject}
                              name="attachments"
                            />
                          </Grid>
                        </Grid>

                        {(canDeleteBudgetProject || canUpdateBudgetProject) && (
                          <>
                            <Divider className="my-16" />

                            <Grid container spacing={2}>
                              <Grid
                                alignContent="center"
                                display="flex"
                                justifyContent={isMobile ? 'center' : 'left'}
                                md={6}
                                xs={12}
                              >
                                <Button
                                  color="error"
                                  disabled={!canDeleteBudgetProject || loading}
                                  fullWidth={isMobile}
                                  variant="contained"
                                  onClick={() => {
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <BudgetProjectDeleteDialog
                                            budgetProjectId={budgetProject?.id}
                                            onClose={() => dispatch(closeDialog())}
                                            onSuccess={() => navigate('/budget-projects/search')}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    );
                                  }}
                                >
                                  Delete
                                </Button>
                              </Grid>

                              <Grid
                                alignContent="center"
                                display="flex"
                                justifyContent={isMobile ? 'center' : 'right'}
                                md={3}
                                xs={12}
                              >
                                <Button
                                  color="primary"
                                  disabled={
                                    !canUpdateBudgetProject || _.isEmpty(dirtyFields) || loading
                                  }
                                  fullWidth
                                  variant="contained"
                                  onClick={() => reset(undefined, { keepDefaultValues: true })}
                                >
                                  Revert
                                </Button>
                              </Grid>

                              <Grid
                                alignContent="center"
                                display="flex"
                                justifyContent={isMobile ? 'center' : 'right'}
                                md={3}
                                xs={12}
                              >
                                <Button
                                  color="secondary"
                                  disabled={
                                    !canUpdateBudgetProject ||
                                    _.isEmpty(dirtyFields) ||
                                    !isValid ||
                                    loading
                                  }
                                  form="budget-project-view-screen-overview-form"
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                >
                                  Update
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Paper>
                    </form>
                  </FormProvider>
                </Grid>

                <Grid className="sm:pl-24" md={4} xs={12}>
                  <Button
                    className="mb-24"
                    color="inherit"
                    disabled={!budgetProject.budget?.id}
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate(`/budgets/view/${budgetProject.budget?.id}`)}
                  >
                    View Budget
                  </Button>

                  <Paper
                    className="mb-24 border-1"
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Typography className="pt-12 px-12 text-18 font-500">Cost Codes</Typography>
                      </Grid>

                      <Grid xs={12}>
                        {!_.isEmpty(budgetProject?.costCodes) ? (
                          <List className="border-t-1" disablePadding>
                            {budgetProject.costCodes.slice(0, 5).map(({ costCode }) => (
                              <ListItem component="div" dense divider key={costCode.id}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <div className="flex flex-1">
                                      <div className="flex flex-col">
                                        <Typography className="mb-1 text-12" variant="body2">
                                          {`${costCode.csiClassification?.name}`}
                                        </Typography>

                                        <Typography variant="body2">{`${costCode.csiClassification?.number}`}</Typography>
                                      </div>
                                    </div>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <div className="flex justify-center p-24 border-t-1">
                            No Cost Codes Found
                          </div>
                        )}
                      </Grid>

                      <Grid className="p-16" xs={12}>
                        <Button
                          color="info"
                          fullWidth
                          size="small"
                          variant="contained"
                          onClick={() => navigate('../tabs/cost-codes')}
                        >
                          View Cost Codes
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Paper
                    className="mb-24 border-1"
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Typography className="pt-12 px-12 text-18 font-500">
                          Completion Items
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        {!_.isEmpty(budgetProject?.serviceCompletionItems) ? (
                          <List className="border-t-1" disablePadding>
                            {budgetProject.serviceCompletionItems
                              .slice(0, 5)
                              .map(({ serviceCompletionItem }) => (
                                <ListItem
                                  component="div"
                                  dense
                                  divider
                                  key={serviceCompletionItem.id}
                                >
                                  <ListItemText
                                    disableTypography
                                    primary={
                                      <div className="flex flex-1">
                                        <div className="flex flex-col">
                                          <Typography className="mb-1 text-12" variant="body2">
                                            {`${serviceCompletionItem.status?.name}`}
                                          </Typography>

                                          <Typography variant="body2">{`${serviceCompletionItem.name}`}</Typography>
                                        </div>
                                      </div>
                                    }
                                  />
                                </ListItem>
                              ))}
                          </List>
                        ) : (
                          <div className="flex justify-center p-24 border-t-1">
                            No Completion Items Found
                          </div>
                        )}
                      </Grid>

                      <Grid className="p-16" xs={12}>
                        <Button
                          color="info"
                          fullWidth
                          size="small"
                          variant="contained"
                          onClick={() => navigate('../tabs/completion-items')}
                        >
                          View Completion Items
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      }
      header={
        <>
          {!budgetProject ? (
            <FuseLoading />
          ) : (
            <BudgetProjectViewScreenHeader basePath={basePath} budgetProject={budgetProject} />
          )}
        </>
      }
      scroll={isMobile ? 'normal' : 'content'}
    />
  );
};

export default BudgetProjectViewScreenOverviewTab;
