import _ from '@lodash';

// eslint-disable-next-line import/prefer-default-export
export const isWorkOrderIssueRowSelectable = ({ data, selectedRows }, { organizationTypeEnum }) => {
  const output =
    ['FRANCHISOR', 'OPERATOR'].includes(organizationTypeEnum) &&
    ((_.isEmpty(selectedRows) &&
      (data?.canBePaused || data?.canBeResumed || data?.hasOpenWorkOrderIssueReview)) ||
      _.some(selectedRows, (selectedRow) =>
        Boolean(
          (data?.canBePaused && selectedRow?.canBePaused) ||
            (data?.canBeResumed && selectedRow?.canBeResumed) ||
            (data?.hasOpenWorkOrderIssueReview && selectedRow?.hasOpenWorkOrderIssueReview)
        )
      ));

  return output;
};
