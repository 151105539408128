import { Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const AccountingProjectViewTabs = ({ basePath }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleTabChange = (event, value) => navigate(value);

  return (
    <div
      className="w-full h-64 border-b-1"
      style={{ backgroundColor: theme.palette.mode === 'light' ? 'white' : '#1e293b' }}
    >
      <Tabs
        classes={{ root: 'w-full h-64 max-w-5xl mx-auto' }}
        indicatorColor="secondary"
        scrollButtons="auto"
        textColor="inherit"
        value={`../${basePath}`}
        variant="scrollable"
        onChange={handleTabChange}
      >
        <Tab className="h-64" label="Definition" value="../tabs/definition" />
      </Tabs>
    </div>
  );
};

export default AccountingProjectViewTabs;
