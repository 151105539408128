import _ from '@lodash';
import { Typography } from '@mui/material';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';
import makeStyles from './makeStyles';

const CommonAsyncPaginate = forwardRef(({ error, ...props }, ref) => {
  const mainTheme = useSelector(selectMainTheme);
  const rootRef = useRef(null);
  const selectRef = useRef(null);

  // ROADMAP: Add to Other `CommonSelect` Components
  useImperativeHandle(ref, () => ({
    blur: () => {
      selectRef.current.blur();
    },
    focus: () => {
      selectRef.current.focus();
    },
    get root() {
      return rootRef.current;
    },
    get select() {
      return selectRef.current;
    },
  }));

  const helperText = useMemo(
    () => (_.isArray(error) ? error[0]?.message : error?.message),
    [error]
  );

  const styles = useMemo(
    () => makeStyles({ error, mode: mainTheme?.palette.mode }),
    [error, mainTheme?.palette.mode]
  );

  return (
    <div ref={rootRef}>
      <AsyncPaginate
        defaultOptions
        menuPlacement="auto"
        menuPortalTarget={document.body}
        {...props}
        debounceTimeout={300}
        selectRef={selectRef}
        styles={styles}
      />

      {helperText && (
        <div className="mx-14 mt-3">
          <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
            {helperText}
          </Typography>
        </div>
      )}
    </div>
  );
});

export default CommonAsyncPaginate;
