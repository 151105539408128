import { gql } from '@apollo/client';
import { BUDGET_SPECIAL_PROJECT_DELETE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_BUDGET_SPECIAL_PROJECT = gql`
  mutation DeleteBudgetSpecialProject($where: BudgetSpecialProjectWhereUniqueInput!) {
    deleteBudgetSpecialProject(where: $where) {
      id
      ...BudgetSpecialProjectDeleteDialogBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_DELETE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;
