import { useContext } from 'react';
import { __SocketIoContext } from './SocketIoProvider';

const useSocketIo = () => {
  const context = useContext(__SocketIoContext);

  if (context === undefined) {
    throw new Error('useSocketIo must be used within a SocketIoProvider');
  }

  return context;
};

export default useSocketIo;
