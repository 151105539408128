import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { CommonDownloadZipDialog } from 'app/shared-components/Common';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';

const ServiceTicketAttachmentViewAppDownloadButton = ({ attachments, onProcessingComplete }) => {
  const dispatch = useDispatch();

  return (
    <Tooltip title="Download">
      <span>
        <IconButton
          color="inherit"
          disabled={false}
          size="small"
          sx={{
            ml: 2,
            borderStyle: 'solid',
            borderWidth: '2px',
          }}
          onClick={() =>
            dispatch(
              openDialog({
                children: (
                  <CommonDownloadZipDialog
                    files={attachments
                      ?.filter((attachment) =>
                        ['COMMON_STORED_IMAGE', 'COMMON_STORED_PDF'].includes(
                          attachment.commonStoredType?.enum
                        )
                      )
                      ?.map((attachment) => {
                        let file;

                        if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
                          file = {
                            id: attachment.id,
                            name: attachment.commonStoredImage.name,
                            url: attachment.commonStoredImage.originalImageUrl,
                          };
                        } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
                          file = {
                            id: attachment.id,
                            name: attachment.commonStoredPdf.name,
                            url: attachment.commonStoredPdf.originalPdfUrl,
                          };
                        }

                        return file;
                      })
                      ?.filter((el) => el)}
                    onClose={() => dispatch(closeDialog())}
                    onProcessingComplete={onProcessingComplete}
                  />
                ),
                classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                onClose: () => null,
              })
            )
          }
        >
          <FontAwesomeIcon className="w-12 h-12" icon={faDownload} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ServiceTicketAttachmentViewAppDownloadButton;
