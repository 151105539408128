import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_COMPLETION_ITEM_DEACTIVATE_DIALOG_SERVICE_COMPLETION_ITEM_FRAGMENT = gql`
  fragment ServiceCompletionItemDeactivateDialogServiceCompletionItemFragment on ServiceCompletionItem {
    id
    status {
      id
      enum
      name
    }
  }
`;
