// eslint-disable-next-line import/prefer-default-export
export const costCodeSearchScreenFilterDefs = [
  {
    name: 'Cost Code',
    refinements: [
      {
        attribute: 'accountingExpenseType.name',
        label: 'Expense Type',
        operator: 'or',
      },
      {
        attribute: 'budgetProjects.budgetProject.name',
        label: 'Budget Project',
        operator: 'or',
      },
      {
        attribute: 'budgetSpecialProjects.budgetSpecialProject.name',
        label: 'Budget Special Project',
        operator: 'or',
      },
      {
        attribute: 'isCapitalExpense',
        label: 'Capital Expense',
        operator: 'or',
      },
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Task Code',
    refinements: [
      {
        attribute: 'accountingTaskCode.name',
        label: 'Task Code Name',
        operator: 'or',
      },
      {
        attribute: 'accountingTaskCode.number',
        label: 'Task Code Number',
        operator: 'or',
      },
    ],
  },
];
