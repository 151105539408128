import { faStar as faRegStar } from '@fortawesome/free-regular-svg-icons';
import { faFingerprint, faStar as faSolidStar, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const UserMenuTeamHitsListOption = ({
  defaultTeam,
  hit,
  loading,
  onChangeTeam,
  onSetDefaultTeam,
}) => {
  const user = useSelector(selectUser);

  const isActiveTeam = useMemo(
    () => hit?.id === user.data?.team?.id,
    [hit?.id, user.data?.team?.id]
  );

  const isDefaultTeam = useMemo(() => hit?.id === defaultTeam?.id, [hit?.id, defaultTeam?.id]);

  const isValidTeam = useMemo(
    () => !_.isEmpty(hit?.users?.find(({ user: _user }) => _user?.id === user?.id)),
    [hit?.users, user?.id]
  );

  const handleChangeTeam = useCallback(
    ({ teamId }) => {
      if (typeof onChangeTeam === 'function') {
        onChangeTeam({ teamId });
      }
    },
    [onChangeTeam]
  );

  const handleSetDefaultTeam = useCallback(
    ({ teamId }) => {
      if (typeof onSetDefaultTeam === 'function') {
        onSetDefaultTeam({ teamId });
      }
    },
    [onSetDefaultTeam]
  );

  const ListOption = useMemo(() => {
    if (!hit?.id) return null;

    return (
      <ListItemButton
        key={hit.id}
        selected={isActiveTeam}
        onClick={() => {
          if (!isActiveTeam) {
            handleChangeTeam({ teamId: hit.id });
          }
        }}
      >
        <ListItemAvatar>
          {isActiveTeam ? (
            <Avatar className="bg-light-blue-600">
              <FontAwesomeIcon icon={faFingerprint} size="sm" />
            </Avatar>
          ) : (
            <Avatar>
              <FontAwesomeIcon icon={faUsers} size="sm" />
            </Avatar>
          )}
        </ListItemAvatar>

        <ListItemText
          primary={hit.name}
          primaryTypographyProps={{ className: 'mr-40', noWrap: true }}
          secondary={hit.owner?.name}
          secondaryTypographyProps={{ className: 'mr-40', noWrap: true }}
        />

        {isValidTeam && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                if (!isDefaultTeam && !loading) {
                  handleSetDefaultTeam({ teamId: hit?.id });
                }

                return false;
              }}
            >
              {isDefaultTeam ? (
                <FontAwesomeIcon className="text-amber-600" icon={faSolidStar} size="sm" />
              ) : (
                <FontAwesomeIcon className="text-amber-600" icon={faRegStar} size="sm" />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItemButton>
    );
  }, [
    handleChangeTeam,
    handleSetDefaultTeam,
    hit?.id,
    hit?.name,
    hit?.owner?.name,
    isActiveTeam,
    isDefaultTeam,
    isValidTeam,
    loading,
  ]);

  return ListOption;
};

export default UserMenuTeamHitsListOption;
