import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_COMPLETION_ITEM_VIEW_DRAWER_SERVICE_COMPLETION_ITEM_FRAGMENT = gql`
  fragment ServiceCompletionItemViewDrawerServiceCompletionItemFragment on ServiceCompletionItem {
    id
    createdAt
    updatedAt

    attachments {
      id
      createdAt
      commonStoredImage {
        id
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredType {
        id
        enum
        name
      }
      commonStoredPdf {
        id
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        originalPdfUrl
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredVideo {
        id
        captionsFileUrl
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        playlistFileUrl
        standardImageUrl
        thumbnailImageUrl
      }
    }
    isAlwaysRequired
    label
    name
    owner {
      id
      name
    }
    status {
      id
      enum
      name
    }
    targetType {
      id
      enum
      name
    }
  }
`;
