import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_FINALIZE_TOTALS_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const FETCH_WORK_ORDER_ISSUE_FINALIZE_TOTALS_DIALOG_DATA = gql`
  query FetchWorkOrderIssueFinalizeTotalsDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueFinalizeTotalsDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_FINALIZE_TOTALS_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FINALIZE_TOTALS_WORK_ORDER_ISSUE = gql`
  mutation FinalizeTotalsWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueFinalizeTotalsInput!
  ) {
    finalizeTotalsWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueFinalizeTotalsDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_FINALIZE_TOTALS_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
