import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonAsyncPaginateSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';
import { forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';

const EntitySearchSelectFieldV2MoreSelectedBadge = ({ items }) => {
  const style = {
    background: '#4f46e5',
    borderRadius: '4px',
    fontSize: '85%',
    marginLeft: 'auto',
    order: 99,
    padding: '4px',
  };

  const label = `+ ${items.length} Item${items.length !== 1 ? 's' : ''} Selected`;
  const title = items.join(', ');

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const EntitySearchSelectFieldV2MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = props.selectProps?.maxToShow || 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  // eslint-disable-next-line no-nested-ternary
  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <EntitySearchSelectFieldV2MoreSelectedBadge items={overflow} />
  ) : null;
};

const EntitySearchSelectFieldV2Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        {props.isMulti && (
          <FontAwesomeIcon className="mr-6" icon={props.isSelected ? faSquareCheck : faSquare} />
        )}

        {props.label}
      </components.Option>
    </div>
  );
};

const EntitySearchSelectFieldV2 = forwardRef(
  ({ className, filter, indexName, sort, ...SelectProps }, ref) => {
    const { axiosSearchClient } = useMeilisearch();
    const dispatch = useDispatch();

    const loadOptions = useCallback(
      async (match, loadedOptions) => {
        let output = {
          options: [],
          hasMore: false,
        };

        try {
          const {
            data: { estimatedTotalHits, hits },
          } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
            filter,
            limit: 20,
            offset: loadedOptions.length,
            q: match,
            sort,
          });

          output = {
            options: hits,
            hasMore: estimatedTotalHits > loadedOptions.length,
          };
        } catch (err) {
          dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
        }

        return output;
      },
      [axiosSearchClient, dispatch, filter, indexName, sort]
    );

    return (
      <div className={className}>
        <CommonAsyncPaginateSelect
          {...SelectProps}
          closeMenuOnSelect={!SelectProps.isMulti}
          components={{
            MultiValue: EntitySearchSelectFieldV2MultiValue,
            Option: EntitySearchSelectFieldV2Option,
            ...(SelectProps.components ?? {}),
          }}
          hideSelectedOptions={!SelectProps.isMulti}
          key={JSON.stringify({ filter, indexName, sort })}
          loadOptions={loadOptions}
          ref={ref}
        />
      </div>
    );
  }
);

export default EntitySearchSelectFieldV2;
