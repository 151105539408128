import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Avatar,
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { lighten } from '@mui/material/styles';
import { CommonSelect, CommonUppyDashboardModal } from 'app/shared-components/Common';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { ServiceTicketHitsListOption } from 'app/shared-components/ServiceTicket';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useState } from 'react';
import { CirclePicker } from 'react-color';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { FETCH_CHAT_CHANNEL_CREATE_DIALOG_DATA, CREATE_CHAT_CHANNEL } from './queries';

const defaultValues = {
  color: '',
  commonStoredUpload: null,
  memberships: null,
  name: '',
  serviceTicketChatChannel: null,
  topic: '',
  type: null,
};

const schema = yup.object().shape({
  color: yup.string().nullable().optional(),
  // ROADMAP: Causes Incorrect Validation State
  // commonStoredUpload: yup.object().shape({ key: yup.string().required() }).optional(),
  memberships: yup
    .mixed()
    .when('type', {
      is: (val) => ['MESSAGING'].includes(val?.enum),
      then: yup.object().nullable().required('A user must be provided.'),
    })
    .when('type', {
      is: (val) => ['TEAM'].includes(val?.enum),
      then: yup.array().min(1, 'Select at least one user.').required(),
    }),
  name: yup.mixed().when('type', {
    is: (val) => ['SERVICE_TICKET', 'TEAM'].includes(val?.enum),
    then: yup.string().nullable().required('A name must be provided.'),
  }),
  serviceTicketChatChannel: yup.mixed().when('type', {
    is: (val) => ['SERVICE_TICKET'].includes(val?.enum),
    then: yup.object().shape({
      isCollaborative: yup.object().nullable().required('An organization access must be provided.'),
      serviceTicket: yup.object().nullable().required('A service ticket must be provided.'),
      targetWorkOrderIssue: yup.object().nullable().optional(),
    }),
  }),
  topic: yup.mixed().when('type', {
    is: (val) => ['TEAM'].includes(val?.enum),
    then: yup.string().nullable().optional(),
  }),
  type: yup.object().nullable().required('A type must be provided.'),
});

const ChatChannelCreateDialog = ({ serviceTicketId, workOrderIssue, onClose, onCreate }) => {
  const apolloClient = useApolloClient();
  const [channelColorAnchorEl, setChannelColorAnchorEl] = useState(null);
  const [channelImageSrc, setChannelImageSrc] = useState(null);
  const [dashboardModalOpen, setDashboardModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const user = useSelector(selectUser);
  const [workOrderIssueOptions, setWorkOrderIssueOptions] = useState([]);
  const [workOrderIssueOptionsLoading, setWorkOrderIssueOptionsLoading] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchChatChannelCreateDialogData,
    {
      data: chatChannelCreateDialogData,
      loading: chatChannelCreateDialogLoading,
      refetch: chatChannelCreateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_CHAT_CHANNEL_CREATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Chat Channel Data',
          variant: 'error',
        })
      );
    },
  });

  const [createChatChannel, { loading: createChatChannelLoading }] = useMutation(
    CREATE_CHAT_CHANNEL,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Chat Channel Successfully Created',
            variant: 'success',
          })
        );

        if (typeof onCreate === 'function') {
          onCreate({ chatChannel: data?.createChatChannel });
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Creating Chat Channel', variant: 'error' }));
      },
    }
  );

  const chatChannelTypes = useMemo(
    () => _.orderBy(chatChannelCreateDialogData?.chatChannelTypes, 'name'),
    [chatChannelCreateDialogData?.chatChannelTypes]
  );

  const loading = useMemo(
    () => [chatChannelCreateDialogLoading, createChatChannelLoading].includes(true),
    [chatChannelCreateDialogLoading, createChatChannelLoading]
  );

  useEffect(() => {
    if (chatChannelTypes && serviceTicketId) {
      const serviceTicketChatChannelType = chatChannelTypes?.find(
        (chatChannelType) => chatChannelType.enum === 'SERVICE_TICKET'
      );

      if (serviceTicketChatChannelType) {
        const formData = {
          ...defaultValues,
          serviceTicketChatChannel: {
            isCollaborative: null,
            serviceTicket: serviceTicketId,
            targetWorkOrderIssue: workOrderIssue
              ? {
                  label: workOrderIssue?.serviceIssue?.name,
                  value: workOrderIssue?.id,
                }
              : null,
          },
          type: serviceTicketChatChannelType,
        };

        reset(formData);
      }
    }
  }, [chatChannelTypes, reset, serviceTicketId, workOrderIssue]);

  useEffect(() => {
    const getWorkOrderIssueOptions = async () => {
      try {
        setWorkOrderIssueOptionsLoading(true);

        let _workOrderIssueOptions;

        if (watchFields?.serviceTicketChatChannel?.serviceTicket) {
          const selectedServiceTicketId = _.isPlainObject(
            watchFields?.serviceTicketChatChannel?.serviceTicket
          )
            ? watchFields?.serviceTicketChatChannel?.serviceTicket?.value
            : watchFields?.serviceTicketChatChannel?.serviceTicket;

          const {
            data: { serviceTicket },
          } = await apolloClient.query({
            query: gql`
              query GetWorkOrderIssueOptions($where: ServiceTicketWhereUniqueInput!) {
                serviceTicket(where: $where) {
                  id
                  workOrder {
                    id
                    issues {
                      id
                      serviceIssue {
                        id
                        name
                      }
                      status {
                        id
                        enum
                        name
                      }
                    }
                  }
                }
              }
            `,
            variables: { where: { id: selectedServiceTicketId } },
          });

          _workOrderIssueOptions = serviceTicket?.workOrder?.issues
            ?.filter((result) => !['CANCELLED', 'CLOSED'].includes(result?.status?.enum))
            ?.map((result) => ({
              result,
              label: result?.serviceIssue?.name,
              value: result?.id,
            }));
        }

        setWorkOrderIssueOptions(_workOrderIssueOptions || []);
      } catch (err) {
        //
      } finally {
        setWorkOrderIssueOptionsLoading(false);
      }
    };

    if (apolloClient && watchFields?.type?.enum === 'SERVICE_TICKET') {
      getWorkOrderIssueOptions();
    }
  }, [apolloClient, watchFields?.type?.enum, watchFields?.serviceTicketChatChannel?.serviceTicket]);

  useEffect(() => {
    setFetched(false);
    fetchChatChannelCreateDialogData({
      variables: { chatChannelTypeWhere: {} },
    });
  }, [fetchChatChannelCreateDialogData]);

  const onSubmit = async (data) => {
    try {
      await createChatChannel({
        variables: {
          data: {
            color: data?.color,
            commonStoredUpload: data?.commonStoredUpload
              ? { key: data?.commonStoredUpload.key }
              : null,
            name: data?.name,
            memberships: !_.isEmpty(data?.memberships)
              ? (_.isArray(data?.memberships) ? data?.memberships : [data?.memberships]).map(
                  (member) => ({ user: { id: member.value } })
                )
              : null,
            serviceTicketChatChannel: data?.serviceTicketChatChannel
              ? {
                  isCollaborative: data?.serviceTicketChatChannel?.isCollaborative.value === 'true',
                  serviceTicket: { id: data?.serviceTicketChatChannel?.serviceTicket.value },
                  targetWorkOrderIssue: data?.serviceTicketChatChannel?.targetWorkOrderIssue?.value
                    ? { id: data?.serviceTicketChatChannel?.targetWorkOrderIssue?.value }
                    : null,
                }
              : null,
            topic: data?.topic,
            type: { id: data?.type.id },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  const handleClearChannelColorAndImage = () => {
    setChannelImageSrc(null);
    setValue('color', '');
    setValue('commonStoredUpload', null, { shouldValidate: true });
  };

  const handleColorPickerClose = () => setChannelColorAnchorEl(null);

  const handleColorPickerOpen = ({ currentTarget }) => setChannelColorAnchorEl(currentTarget);

  const handleColorPickerChangeComplete = ({ hex }) => {
    setChannelImageSrc(null);
    setValue('color', hex);
    setValue('commonStoredUpload', null, { shouldValidate: true });

    handleColorPickerClose();
  };

  const handleDashboardModalRequestClose = () => setDashboardModalOpen(false);

  const handleDashboardModalRequestOpen = () => setDashboardModalOpen(true);

  const handleDashboardModalUploadSuccess = ({ file, response }) => {
    setChannelImageSrc(file?.preview);
    setValue('color', '');
    setValue(
      'commonStoredUpload',
      { key: _.last(response.uploadURL?.split('/')) },
      { shouldValidate: true }
    );
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Create {watchFields?.type ? watchFields?.type.name : 'Chat'} Channel
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{
            clearErrors,
            control,
            formState,
            handleSubmit,
            reset,
            setValue,
            watch,
            ...methods,
          }}
        >
          <form
            className="w-full pt-20"
            id="chat-channel-create-form"
            name="chat-channel-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            {!watchFields?.type && (
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <List className="mb-24" disablePadding>
                    {chatChannelTypes?.map((chatChannelType) => (
                      <ListItemButton
                        className="mb-16 p-16 border-1"
                        key={chatChannelType.id}
                        onClick={() => field.onChange(chatChannelType)}
                        sx={{
                          borderColor: (theme) => theme.palette.divider,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? lighten(theme.palette.background.default, 0.4)
                              : lighten(theme.palette.background.default, 0.02),
                        }}
                      >
                        <ListItemAvatar>
                          <FontAwesomeIcon icon={faMessage} />
                        </ListItemAvatar>

                        <ListItemText primary={chatChannelType.name} />
                      </ListItemButton>
                    ))}
                  </List>
                )}
              />
            )}

            {watchFields?.type && (
              <>
                {['SERVICE_TICKET', 'TEAM'].includes(watchFields?.type?.enum) && (
                  <>
                    <div className="flex items-center mb-24">
                      <Avatar
                        className="w-64 h-64 mr-24 text-32 capitalize"
                        src={channelImageSrc}
                        sx={watchFields?.color ? { backgroundColor: watchFields?.color } : {}}
                      >
                        {!channelImageSrc || !watchFields?.commonStoredUpload
                          ? watchFields?.name?.[0]
                          : ''}
                      </Avatar>

                      <ButtonGroup color="primary" disableElevation variant="contained">
                        <Button
                          color={
                            !watchFields?.color && !watchFields?.commonStoredUpload
                              ? 'info'
                              : 'primary'
                          }
                          onClick={handleClearChannelColorAndImage}
                        >
                          Default
                        </Button>

                        <Button
                          color={watchFields?.color ? 'info' : 'primary'}
                          onClick={handleColorPickerOpen}
                        >
                          Color
                        </Button>

                        <Button
                          color={watchFields?.commonStoredUpload ? 'info' : 'primary'}
                          onClick={handleDashboardModalRequestOpen}
                        >
                          Image
                        </Button>
                      </ButtonGroup>
                    </div>

                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={errors?.name}
                          fullWidth
                          helperText={errors?.name?.message}
                          required
                          variant="outlined"
                          placeholder="Enter Chat Channel Name..."
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="topic"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={errors?.topic}
                          fullWidth
                          helperText={errors?.topic?.message}
                          maxRows={4}
                          minRows={4}
                          multiline
                          placeholder="Enter Topic..."
                          required
                          variant="outlined"
                        />
                      )}
                    />

                    <Divider className="mb-24" />
                  </>
                )}

                {['MESSAGING', 'TEAM'].includes(watchFields?.type?.enum) && (
                  <>
                    <Controller
                      control={control}
                      name="memberships"
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          components={null}
                          error={errors?.memberships}
                          filter={[`id != ${user.id}`]}
                          idField="id"
                          indexName="users"
                          isClearable
                          isDisabled={false}
                          isMulti={watchFields?.type?.enum === 'TEAM'}
                          nameField="name"
                          placeholder={`Select ${
                            watchFields?.type?.enum === 'TEAM' ? 'Users' : 'User'
                          }...`}
                          sort={['name:asc']}
                        />
                      )}
                    />
                  </>
                )}

                {['SERVICE_TICKET'].includes(watchFields?.type?.enum) && (
                  <>
                    <Controller
                      control={control}
                      name="serviceTicketChatChannel.serviceTicket"
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          components={{ Option: ServiceTicketHitsListOption }}
                          error={errors?.serviceTicketChatChannel?.serviceTicket}
                          filter={["phase.enum != 'CLOSED'"]}
                          idField="id"
                          indexName="service_tickets"
                          isClearable
                          isDisabled={!!serviceTicketId}
                          isMulti={false}
                          nameField="serviceReferenceNumber"
                          placeholder="Select Service Ticket..."
                          sort={['serviceReferenceNumber:asc']}
                          onChange={(params) => {
                            setValue('serviceTicketChatChannel.targetWorkOrderIssue', null);

                            field.onChange(params);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name="serviceTicketChatChannel.targetWorkOrderIssue"
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonSelect
                            {...field}
                            error={errors?.serviceTicketChatChannel?.targetWorkOrderIssue}
                            isClearable
                            isDisabled={!!workOrderIssue}
                            isLoading={workOrderIssueOptionsLoading}
                            isMulti={false}
                            options={workOrderIssueOptions}
                            placeholder="Select Work Order Issue..."
                          />
                        </div>
                      )}
                    />

                    <Controller
                      control={control}
                      name="serviceTicketChatChannel.isCollaborative"
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonSelect
                            {...field}
                            error={errors?.serviceTicketChatChannel?.isCollaborative}
                            isClearable
                            isDisabled={false}
                            isMulti={false}
                            options={[
                              {
                                label: 'Collaborative',
                                value: 'true',
                              },
                              {
                                label: 'Private',
                                value: 'false',
                              },
                            ]}
                            placeholder="Select Organization Access..."
                          />
                        </div>
                      )}
                    />
                  </>
                )}
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="chat-channel-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>

      <Popover
        open={Boolean(channelColorAnchorEl)}
        anchorEl={channelColorAnchorEl}
        onClose={handleColorPickerClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="p-24">
          <CirclePicker onChangeComplete={handleColorPickerChangeComplete} />
        </div>
      </Popover>

      <CommonUppyDashboardModal
        closeAfterFinish
        dashboardNote="File Size: 20MB; File Types: JPEG/PNG"
        open={dashboardModalOpen}
        uppyId="chat-channel-create-uppy"
        uppyOptions={{
          restrictions: {
            allowedFileTypes: ['image/jpeg', 'image/png'],
            maxFileSize: 20 * 1000000,
            maxNumberOfFiles: 1,
          },
        }}
        onRequestClose={handleDashboardModalRequestClose}
        onUploadSuccess={handleDashboardModalUploadSuccess}
      />
    </>
  );
};

export default ChatChannelCreateDialog;
