import _ from '@lodash';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/utils/FuseUtils';
import fingerprintjs from '@fingerprintjs/fingerprintjs';

import * as Sentry from '@sentry/react';

const { REACT_APP_HYVE_CLIENT_VERSION, REACT_APP_HYVE_SYSTEM_CLIENT } = process.env;

export const JWT_SERVICE_EVENTS = {
  ON_CLIENT_RESPONSE_ERROR: 'onClientResponseError',
  ON_COMPLETE_SIGN_IN: 'onCompleteSignIn',
  ON_INITIALIZE_SIGN_IN: 'onInitializeSignIn',
  ON_INIT_ERROR: 'onInitError',
  ON_INIT_SUCCESS: 'onInitSuccess',
  ON_MAGIC_SIGN_IN: 'onMagicSignIn',
  ON_REASSIGN_SESSION: 'onReassignSession',
  ON_REFRESH_SESSION: 'onRefreshSession',
  ON_REVOKE_SESSION: 'onRevokeSession',
  ON_VERIFY_SIGN_IN: 'onVerifySignIn',

  // TODO:
  ON_REASSIGN_SESSION_START: 'onReassignSessionStart',
};

export const JWT_SERVICE_TEAM_MEMBERSHIP_ROLES = {
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  TEAM_MEMBER: 'TEAM_MEMBER',
};

export const JWT_SERVICE_TOKEN_TYPES = {
  ACCESS_TOKEN: 'accessToken',
  ANALYTICS_TOKEN: 'analyticsToken',
  CHAT_TOKEN: 'chatToken',
  ACTIVITY_FEEDS_TOKEN: 'activityFeedsToken',
  REFRESH_TOKEN: 'refreshToken',
  SEARCH_TOKEN: 'searchToken',
  SIGN_IN_TOKEN: 'signInToken',
  VERIFICATION_TOKEN: 'verificationToken',
};

class JwtService extends FuseUtils.EventEmitter {
  constructor({ baseURL, csrfTokenEndpoint, tokenStoragePrefix }) {
    super();

    this.client = axios.create({
      baseURL,
      headers: {
        common: {
          'X-HYVE-Client-Version': REACT_APP_HYVE_CLIENT_VERSION,
          'X-HYVE-System-Client': REACT_APP_HYVE_SYSTEM_CLIENT,
        },
      },
      withCredentials: true,
    });
    this.csrfTokenEndpoint = csrfTokenEndpoint;
    this.tokenStoragePrefix = tokenStoragePrefix;

    this.#setInterceptors();
    this.#init();
  }

  #init() {
    if (!this.csrfTokenEndpoint || this.ready) {
      return;
    }

    Promise.all([
      new Promise((resolve, reject) => {
        this.client
          .get(this.csrfTokenEndpoint)
          .then(({ data }) => {
            this.client.defaults.headers.post['X-CSRF-Token'] = data.csrfToken;

            resolve();
          })
          .catch((error) => reject(error));
      }),
      new Promise((resolve, reject) => {
        const deviceFingerprint = window.localStorage.getItem(
          `${this.tokenStoragePrefix}.deviceFingerprint`
        );

        if (deviceFingerprint) {
          this.client.defaults.headers.common['X-HYVE-Device-Fingerprint'] = deviceFingerprint;

          resolve();
        } else {
          fingerprintjs
            .load()
            .then((fp) => fp.get())
            .then(({ visitorId }) => {
              localStorage.setItem(`${this.tokenStoragePrefix}.deviceFingerprint`, visitorId);
              this.client.defaults.headers.common['X-HYVE-Device-Fingerprint'] = visitorId;

              resolve();
            })
            .catch((error) => reject(error));
        }
      }),
    ])
      .then(() => {
        this.ready = true;
        this.emit(JWT_SERVICE_EVENTS.ON_INIT_SUCCESS, {});
      })
      .catch((error) => {
        this.emit(JWT_SERVICE_EVENTS.ON_INIT_ERROR, { error });
      });
  }

  #setInterceptors = () => {
    if (!this.client) {
      return;
    }

    this.client.interceptors.request.use(async (request) => {
      try {
        // const existingTransaction = Sentry.getCurrentHub().getScope()?.getTransaction();

        // // Create a transaction if one does not exist in order to work around
        // // https://github.com/getsentry/sentry-javascript/issues/3169
        // // https://github.com/getsentry/sentry-javascript/issues/4072
        // const transaction =
        //   existingTransaction ??
        //   Sentry.startTransaction({ name: `API Request: ${request.method} ${request.url}` });

        // Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

        // request.tracing = {
        //   transaction,
        // };

        return request;
      } catch (err) {
        console.error({ err });
        Sentry.captureException(err);
        return request;
      }
    });

    // this.client.interceptors.response.use(
    //   (response) => {
    //     response.config.tracing?.transaction?.finish();

    //     return response;
    //   },
    //   (error) => {
    //     error.config.tracing?.transaction?.finish();

    //     return error;
    //   }
    // );

    this.client.interceptors.response.use(
      (response) => {
        // console.log({ config: response.config });
        // response.config.tracing?.transaction?.finish();

        return response;
      },
      (error) => {
        error.config.tracing?.transaction?.finish();

        return new Promise((resolve, reject) => {
          if (_.get(error, 'response.status') === 401 && !_.get(error, 'config.__isRetryRequest')) {
            this.#setSession(null);
            this.emit(JWT_SERVICE_EVENTS.ON_CLIENT_RESPONSE_ERROR, { error });
          }

          throw error;
        });
      }
    );
  };

  #getToken = (type) => {
    return window.localStorage.getItem(`${this.tokenStoragePrefix}.${type}`);
  };

  #setToken = (type, token) => {
    if (token) {
      localStorage.setItem(`${this.tokenStoragePrefix}.${type}`, token);
    } else {
      localStorage.removeItem(`${this.tokenStoragePrefix}.${type}`);
    }
  };

  #setSession = (tokens) => {
    if (_.isPlainObject(tokens) && !_.isEmpty(tokens)) {
      Object.keys(tokens).forEach((type) => {
        const token = tokens[type];

        if (
          !_.isEmpty(token) &&
          Object.keys(JWT_SERVICE_TOKEN_TYPES)
            .map((key) => JWT_SERVICE_TOKEN_TYPES[key])
            .includes(type)
        ) {
          this.#setToken(type, token);

          if (type === JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN) {
            this.client.defaults.headers.common.Authorization = `Bearer ${token}`;
          }
        }
      });
    } else {
      Object.keys(JWT_SERVICE_TOKEN_TYPES)
        .map((key) => JWT_SERVICE_TOKEN_TYPES[key])
        .forEach((type) => {
          this.#setToken(type, null);
        });

      delete this.client.defaults.headers.common.Authorization;
    }
  };

  getSession = () => {
    const session = {};

    Object.keys(JWT_SERVICE_TOKEN_TYPES)
      .map((key) => JWT_SERVICE_TOKEN_TYPES[key])
      .forEach((type) => {
        session[type] = this.#getToken(type);
      });

    return session;
  };

  isTokenValid = (token) => {
    if (!token) {
      return false;
    }

    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      return false;
    }

    return true;
  };

  addUserVerificationMethod = ({ emailAddress, method, phoneNumber }) => {
    return new Promise((resolveAction, rejectAction) => {
      const session = this.getSession();

      if (_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)) {
        this.client
          .post(
            '/user/verification-method/add',
            {
              emailAddress,
              method,
              phoneNumber,
            },
            {
              headers: {
                Authorization: `Bearer ${_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)}`,
              },
            }
          )
          .then(async ({ data }) => {
            // TODO:
            // this.#setSession(data);
            this.emit(JWT_SERVICE_EVENTS.ON_ADD_USER_VERIFICATION_METHOD, { data });

            resolveAction(data);
          })
          .catch((err) => rejectAction(err));
      } else {
        this.emit(JWT_SERVICE_EVENTS.ON_ADD_USER_VERIFICATION_METHOD, {});
      }
    });
  };

  completeSignIn = ({ signInToken }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post(
          '/session/establish',
          {},
          {
            headers: {
              Authorization: `Bearer ${signInToken}`,
            },
          }
        )
        .then(async ({ data }) => {
          this.#setSession(data);
          this.emit(JWT_SERVICE_EVENTS.ON_COMPLETE_SIGN_IN, { data });

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };

  initializeSignIn = ({ emailAddress, method, phoneNumber }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post('/auth/sign-in', {
          emailAddress,
          method,
          phoneNumber,
        })
        .then(({ data }) => {
          this.emit(JWT_SERVICE_EVENTS.ON_INITIALIZE_SIGN_IN, { data });

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };

  magicSignIn = ({ verificationToken }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post(
          '/magic/sign-in',
          {},
          {
            headers: {
              Authorization: `Bearer ${verificationToken}`,
            },
          }
        )
        .then(async ({ data }) => {
          this.emit(JWT_SERVICE_EVENTS.ON_MAGIC_SIGN_IN, { data });

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };

  reassignSession = ({ teamId }) => {
    return new Promise((resolveAction, rejectAction) => {
      const session = this.getSession();

      // TODO: Add START/COMPLETE Everywhere?
      this.emit(JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION_START, {});

      if (_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)) {
        this.client
          .post(
            '/session/reassign',
            {
              teamId,
            },
            {
              headers: {
                Authorization: `Bearer ${_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)}`,
              },
            }
          )
          .then(async ({ data }) => {
            this.#setSession(data);
            this.emit(JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION, { data });

            resolveAction(data);
          })
          .catch((err) => rejectAction(err));
      } else {
        this.emit(JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION, {});
      }
    });
  };

  refreshSession = () => {
    return new Promise((resolveAction, rejectAction) => {
      const session = this.getSession();

      if (_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)) {
        this.client
          .post(
            '/session/refresh',
            {},
            {
              headers: {
                Authorization: `Bearer ${_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)}`,
              },
            }
          )
          .then(async ({ data }) => {
            this.#setSession(data);
            this.emit(JWT_SERVICE_EVENTS.ON_REFRESH_SESSION, { data });

            resolveAction(data);
          })
          .catch((err) => rejectAction(err));
      } else {
        this.emit(JWT_SERVICE_EVENTS.ON_REFRESH_SESSION, {});
      }
    });
  };

  revokeSession = () => {
    return new Promise((resolveAction, rejectAction) => {
      const session = this.getSession();

      if (_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)) {
        this.client
          .post(
            '/session/revoke',
            {},
            {
              headers: {
                Authorization: `Bearer ${_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)}`,
              },
            }
          )
          .then(async ({ data }) => {
            this.#setSession(data);
            this.emit(JWT_SERVICE_EVENTS.ON_REVOKE_SESSION, { data });

            resolveAction(data);
          })
          .catch((err) => rejectAction(err));
      } else {
        this.emit(JWT_SERVICE_EVENTS.ON_REVOKE_SESSION, {});
      }
    });
  };

  setDefaultUserTeam = ({ teamId }) => {
    return new Promise((resolveAction, rejectAction) => {
      const session = this.getSession();

      if (_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)) {
        this.client
          .post(
            '/user/team/set-default',
            {
              teamId,
            },
            {
              headers: {
                Authorization: `Bearer ${_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)}`,
              },
            }
          )
          .then(async ({ data }) => {
            // TODO:
            // this.#setSession(data);
            // this.emit(JWT_SERVICE_EVENTS.ON_REVOKE_SESSION, { data });

            resolveAction(data);
          })
          .catch((err) => rejectAction(err));
      } else {
        // this.emit(JWT_SERVICE_EVENTS.ON_REVOKE_SESSION, {});
      }
    });
  };

  setFcmToken = ({ fcmToken }) => {
    return new Promise((resolveAction, rejectAction) => {
      const session = this.getSession();

      if (_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)) {
        this.client
          .post(
            '/user/system-device/set-fcm-token',
            {
              fcmToken,
            },
            {
              headers: {
                Authorization: `Bearer ${_.get(session, JWT_SERVICE_TOKEN_TYPES.REFRESH_TOKEN)}`,
              },
            }
          )
          .then(async ({ data }) => {
            // TODO:
            // this.#setSession(data);
            // this.emit(JWT_SERVICE_EVENTS.ON_REVOKE_SESSION, { data });

            resolveAction(data);
          })
          .catch((err) => rejectAction(err));
      } else {
        // this.emit(JWT_SERVICE_EVENTS.ON_REVOKE_SESSION, {});
      }
    });
  };

  verifySignIn = ({ key, secret }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post('/verification/sign-in', {
          key,
          secret,
        })
        .then(({ data }) => {
          this.emit(JWT_SERVICE_EVENTS.ON_VERIFY_SIGN_IN, { data });

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };
}

const instance = new JwtService({
  baseURL: process.env.REACT_APP_ULTRAVIOLET_SSO_BASE_URL,
  csrfTokenEndpoint: process.env.REACT_APP_ULTRAVIOLET_SSO_CSRF_ENDPOINT,
  tokenStoragePrefix: process.env.REACT_APP_ULTRAVIOLET_SSO_TOKEN_STORAGE_PREFIX,
});

export default instance;
