import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import MagicSignInConfig from '../main/magic-sign-in/MagicSignInConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Config from '../main/404/Error404Config';
import ProductModelsConfig from '../main/product-models/ProductModelsConfig';
import FacilityDetailsConfig from '../main/facility-details/FacilityDetailsConfig';
import AssetDetailsConfig from '../main/asset-details/AssetDetailsConfig';
import ExampleConfig from '../main/example/ExampleConfig';

// TODO:
import { AccountingExpenseTypesSearchPageConfig } from '../main/accounting-expense-types/AccountingExpenseTypesSearchPage';
import { AccountingProjectsSearchPageConfig } from '../main/accounting-projects/AccountingProjectsSearchPage';
import { AccountingProjectsViewPageConfig } from '../main/accounting-projects/AccountingProjectsViewPage';
import { AssetsSearchPageConfig } from '../main/assets/AssetsSearchPage';
import { CalendarAppPageConfig } from '../main/apps/CalendarAppPage';
import { ChatAppPageConfig } from '../main/apps/ChatAppPage';
import { FacilitiesSearchPageConfig } from '../main/facilities/FacilitiesSearchPage';
import { FacilityAreasSearchPageConfig } from '../main/facility-areas/FacilityAreasSearchPage';
import { FacilityGroupsSearchPageConfig } from '../main/facility-groups/FacilityGroupsSearchPage';
import { GlobalSearchRedirectPageConfig } from '../main/global-search/GlobalSearchRedirectPage';
import { HyveIntelligenceViewPageConfig } from '../main/analytics/HyveIntelligenceViewPage';
import { IssueTemplatesDashboardPageConfig } from '../main/issue-templates/IssueTemplatesDashboardPage';
import { IssueTemplatesSearchPageConfig } from '../main/issue-templates/IssueTemplatesSearchPage';
import { IssueTemplatesViewPageConfig } from '../main/issue-templates/IssueTemplatesViewPage';
import { ProgramsDashboardPageConfig } from '../main/programs/ProgramsDashboardPage';
import { ProgramsSearchPageConfig } from '../main/programs/ProgramsSearchPage';
import { ProgramsViewPageConfig } from '../main/programs/ProgramsViewPage';
import { SegmentsSearchPageConfig } from '../main/segments/SegmentsSearchPage';
import { SegmentsViewPageConfig } from '../main/segments/SegmentsViewPage';
// DEPRECATED: Remove
// import { ServiceIssueOverviewPageConfig } from '../main/service-issue-overview';
import { ServiceIssuesDashboardPageConfig } from '../main/service-issues/ServiceIssuesDashboardPage';
import { ServiceIssuesSearchPageConfig } from '../main/service-issues/ServiceIssuesSearchPage';
import { ServiceIssuesViewPageConfig } from '../main/service-issues/ServiceIssuesViewPage';
import { ServiceProvisionsSearchPageConfig } from '../main/service-provisions/ServiceProvisionsSearchPage';
import { ServiceProvisionsViewPageConfig } from '../main/service-provisions/ServiceProvisionsViewPage';
import { ServiceTicketsDashboardPageConfig } from '../main/service-tickets/ServiceTicketsDashboardPage';
import { ServiceTicketsSearchPageConfig } from '../main/service-tickets/ServiceTicketsSearchPage';
import { ServiceTicketsViewPageConfig } from '../main/service-tickets/ServiceTicketsViewPage';
import { UsersViewPageConfig } from '../main/users/UsersViewPage';

const routeConfigs = [
  Error404Config,
  ExampleConfig,
  ProductModelsConfig,
  FacilityDetailsConfig,
  AssetDetailsConfig,
  MagicSignInConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,

  // TODO:
  AccountingExpenseTypesSearchPageConfig,
  AccountingProjectsSearchPageConfig,
  AccountingProjectsViewPageConfig,
  AssetsSearchPageConfig,
  CalendarAppPageConfig,
  ChatAppPageConfig,
  FacilitiesSearchPageConfig,
  FacilityAreasSearchPageConfig,
  FacilityGroupsSearchPageConfig,
  GlobalSearchRedirectPageConfig,
  HyveIntelligenceViewPageConfig,
  IssueTemplatesDashboardPageConfig,
  IssueTemplatesSearchPageConfig,
  IssueTemplatesViewPageConfig,
  ProgramsDashboardPageConfig,
  ProgramsSearchPageConfig,
  ProgramsViewPageConfig,
  SegmentsSearchPageConfig,
  SegmentsViewPageConfig,
  ServiceIssuesDashboardPageConfig,
  ServiceIssuesSearchPageConfig,
  ServiceIssuesViewPageConfig,
  ServiceProvisionsSearchPageConfig,
  ServiceProvisionsViewPageConfig,
  ServiceTicketsDashboardPageConfig,
  ServiceTicketsSearchPageConfig,
  ServiceTicketsViewPageConfig,
  UsersViewPageConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    // TODO:
    element: <Navigate to="/service-tickets" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
