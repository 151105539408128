import { faCommentDots, faFilePdf, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faFileInvoice,
  faPaperclip,
  faTasks,
  faTicket,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import moment from 'moment';
import { memo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ServiceTicketEventTimelineItemDescription = memo(({ serviceTicketEvent }) => {
  return (
    <div>
      {serviceTicketEvent.type?.enum === 'SERVICE_INVOICE_GENERATED' && (
        <>
          Service Invoice for{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceInvoice?.vendorReferenceNumber}
          </strong>{' '}
          generated by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_INVOICE_REVIEW_CANCELLED' && (
        <>
          Service Invoice Review for{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceInvoiceReview?.serviceInvoice?.vendorReferenceNumber}
          </strong>{' '}
          cancelled by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_INVOICE_REVIEW_COMPLETED' && (
        <>
          Service Invoice Review for{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceInvoiceReview?.serviceInvoice?.vendorReferenceNumber}
          </strong>{' '}
          completed by{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceInvoiceReview?.completor?.name}
          </strong>
          .
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_INVOICE_REVIEW_CREATED' && (
        <>
          Service Invoice Review for{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceInvoiceReview?.serviceInvoice?.vendorReferenceNumber}
          </strong>{' '}
          created by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_APPROVAL_PHASE_ENDED' && (
        <>
          Ticket <strong className="font-600">Approval Phase</strong> completed by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_APPROVAL_PHASE_STARTED' && (
        <>
          Ticket <strong className="font-600">Approval Phase</strong> started by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_ATTACHMENT_CREATED' && (
        <>
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong> created a{' '}
          <strong className="font-600">File Attachment</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_ATTACHMENT_DELETED' && (
        <>
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong> deleted a{' '}
          <strong className="font-600">File Attachment</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_CANCELLED' && (
        <>
          Ticket cancelled by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_CHAT_CHANNEL_ATTACHMENT_CREATED' && (
        <>
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong> created a{' '}
          <strong className="font-600">Chat Channel Attachment</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_CHAT_CHANNEL_ATTACHMENT_DELETED' && (
        <>
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong> deleted a{' '}
          <strong className="font-600">Chat Channel Attachment</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_CHAT_CHANNEL_CREATED' && (
        <>
          Chat Channel{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceTicketChatChannel?.chatChannel?.name}
          </strong>{' '}
          created by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_CREATED' && (
        <>
          Ticket created by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_DRAFT_PHASE_ENDED' && (
        <>
          Ticket <strong className="font-600">Draft Phase</strong> completed by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_DRAFT_PHASE_STARTED' && (
        <>
          Ticket <strong className="font-600">Draft Phase</strong> started by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_IN_PROGRESS_PHASE_ENDED' && (
        <>
          Ticket <strong className="font-600">In Progress Phase</strong> completed by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_IN_PROGRESS_PHASE_STARTED' && (
        <>
          Ticket <strong className="font-600">In Progress Phase</strong> started by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_PHASE_REVIEW_CANCELLED' && (
        <>
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceTicketPhaseReview?.serviceTicketPhase?.name}
          </strong>{' '}
          Phase Review cancelled by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_PHASE_REVIEW_COMPLETED' && (
        <>
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceTicketPhaseReview?.serviceTicketPhase?.name}
          </strong>{' '}
          Phase Review completed by{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceTicketPhaseReview?.completor?.name}
          </strong>
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_PHASE_REVIEW_CREATED' && (
        <>
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceTicketPhaseReview?.serviceTicketPhase?.name}
          </strong>{' '}
          Phase Review created by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_QUOTING_PHASE_ENDED' && (
        <>
          Ticket <strong className="font-600">Quoting Phase</strong> completed by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_QUOTING_PHASE_STARTED' && (
        <>
          Ticket <strong className="font-600">Quoting Phase</strong> started by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_RELEASE_PHASE_ENDED' && (
        <>
          Ticket <strong className="font-600">Release Phase</strong> completed by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_RELEASE_PHASE_STARTED' && (
        <>
          Ticket <strong className="font-600">Release Phase</strong> started by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_SITE_VISIT_PHASE_ENDED' && (
        <>
          Ticket <strong className="font-600">Site Visit Phase</strong> completed by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_SITE_VISIT_PHASE_STARTED' && (
        <>
          Ticket <strong className="font-600">Site Visit Phase</strong> started by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_TASK_CANCELLED' && (
        <>
          Ticket Task{' '}
          <strong className="font-600">{serviceTicketEvent.sourceServiceTicketTask?.name}</strong>{' '}
          cancelled.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_TASK_COMPLETED' && (
        <>
          Ticket Task{' '}
          <strong className="font-600">{serviceTicketEvent.sourceServiceTicketTask?.name}</strong>{' '}
          completed by{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceServiceTicketTask?.completor?.name}
          </strong>
          .
        </>
      )}
      {serviceTicketEvent.type?.enum === 'SERVICE_TICKET_TASK_CREATED' && (
        <>
          Ticket Task{' '}
          <strong className="font-600">{serviceTicketEvent.sourceServiceTicketTask?.name}</strong>{' '}
          created.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_COMMENTS_UPDATED' && (
        <>
          Work Order comments updated by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_CANCELLED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          cancelled by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_CLOSED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          closed by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_CODED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          coded by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_COMPLETED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          completed by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_CREATED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          created by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_DELETED' && (
        <>
          Work Order Issue deleted by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_FINALIZED' && (
        <>
          Work Order Issue finalized by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_INVOICED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          invoiced by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_PAUSED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          paused by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_RE_CODED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          re-coded by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          response severity updated by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_RELEASED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          released by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_RESUMED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          resumed by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {[
        'WORK_ORDER_ISSUE_SERVICE_VISIT_SCHEDULED',
        'WORK_ORDER_ISSUE_SITE_VISIT_SCHEDULED',
      ].includes(serviceTicketEvent.type?.enum) && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          scheduled by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {[
        'WORK_ORDER_ISSUE_SERVICE_VISIT_RESCHEDULED',
        'WORK_ORDER_ISSUE_SITE_VISIT_RESCHEDULED',
      ].includes(serviceTicketEvent.type?.enum) && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          rescheduled by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_UPDATED' && (
        <>
          Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          updated by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_ACTION_ITEM_CREATED' && (
        <>
          Work Order Issue Action Item{' '}
          <strong className="font-600">
            {`${serviceTicketEvent.sourceWorkOrderIssueActionItem?.costCode?.csiClassification?.name} (${serviceTicketEvent.sourceWorkOrderIssueActionItem?.costCode?.csiClassification?.number})`}
          </strong>{' '}
          created by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_ACTION_ITEM_DELETED' && (
        <>
          Work Order Issue Action Item deleted by{' '}
          <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_ACTION_ITEM_UPDATED' && (
        <>
          Work Order Issue Action Item{' '}
          <strong className="font-600">
            {`${serviceTicketEvent.sourceWorkOrderIssueActionItem?.costCode?.csiClassification?.name} (${serviceTicketEvent.sourceWorkOrderIssueActionItem?.costCode?.csiClassification?.number})`}
          </strong>{' '}
          updated by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_REVIEW_CANCELLED' && (
        <>
          Review for Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssueReview?.workOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          cancelled by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_REVIEW_COMPLETED' && (
        <>
          Review for Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssueReview?.workOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          completed by{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssueReview?.completor?.name}
          </strong>
          .
        </>
      )}
      {serviceTicketEvent.type?.enum === 'WORK_ORDER_ISSUE_REVIEW_CREATED' && (
        <>
          Review for Work Order Issue{' '}
          <strong className="font-600">
            {serviceTicketEvent.sourceWorkOrderIssueReview?.workOrderIssue?.serviceIssue?.name}
          </strong>{' '}
          created by <strong className="font-600">{serviceTicketEvent.initiator?.name}</strong>.
        </>
      )}
    </div>
  );
});

const ServiceTicketEventTimelineItem = ({
  basePath,
  last,
  serviceTicketEvent,
  serviceTicketOrganizations,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  if (!serviceTicketEvent) return null;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color="primary"
          className="w-40 h-40 p-0  mt-0 flex items-center justify-center"
        >
          {serviceTicketEvent.sourceType?.enum === 'SERVICE_INVOICE' && (
            <FontAwesomeIcon className="text-20" icon={faFilePdf} />
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_INVOICE_REVIEW' && (
            <FontAwesomeIcon className="text-20" icon={faUser} />
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET' && (
            <FontAwesomeIcon className="text-20" icon={faTicket} />
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_ATTACHMENT' && (
            <FontAwesomeIcon className="text-20" icon={faPaperclip} />
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_CHAT_CHANNEL' && (
            <FontAwesomeIcon className="text-20" icon={faCommentDots} />
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_PHASE_REVIEW' && (
            <FontAwesomeIcon className="text-20" icon={faUser} />
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_TASK' && (
            <FontAwesomeIcon className="text-20" icon={faTasks} />
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_VISIT' && (
            <FontAwesomeIcon className="text-20" icon={faTruck} />
          )}

          {[
            'WORK_ORDER',
            'WORK_ORDER_ISSUE',
            'WORK_ORDER_ISSUE_ACTION_ITEM',
            'WORK_ORDER_ISSUE_REVIEW',
          ].includes(serviceTicketEvent.sourceType?.enum) && (
            <FontAwesomeIcon className="text-20" icon={faFileInvoice} />
          )}
        </TimelineDot>

        {!last && <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent className="flex flex-col items-start pt-0 pb-48">
        <ServiceTicketEventTimelineItemDescription serviceTicketEvent={serviceTicketEvent} />

        <div className="flex flex-col sm:flex-row sm:items-center mt-8 sm:mt-4 sm:space-x-8 text-md leading-5">
          <Typography className="text-13" color="text.secondary">
            {moment(serviceTicketEvent.occurredAt).format('MMM. DD, YYYY @ hh:mm A')}
          </Typography>

          {serviceTicketEvent.sourceType &&
            serviceTicketEvent.sourceType?.enum !== 'SERVICE_TICKET' && (
              <div className="hidden sm:block">&bull;</div>
            )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_INVOICE' && (
            <Link
              className="cursor-pointer"
              to={`../activity/service-invoices/view/${serviceTicketEvent.sourceServiceInvoice?.id}`}
            >
              View Service Invoice
            </Link>
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_INVOICE_REVIEW' && (
            <Link
              className="cursor-pointer"
              to={`../activity/service-invoices/view/${serviceTicketEvent.sourceServiceInvoiceReview?.serviceInvoice?.id}`}
            >
              View Service Invoice
            </Link>
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_ATTACHMENT' && (
            <Link
              className="cursor-pointer"
              to={`../activity/file-attachments/view/${serviceTicketEvent.sourceServiceTicketAttachment?.id}`}
            >
              View File Attachment
            </Link>
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_CHAT_CHANNEL' && (
            <Link
              className="cursor-pointer"
              to={`../chat/channels/view/${serviceTicketEvent.sourceServiceTicketChatChannel?.chatChannel?.id}`}
            >
              Go to Chat Channel
            </Link>
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_PHASE_REVIEW' && (
            <Link className="cursor-pointer" to="../activity/work-order">
              Go to Work Order
            </Link>
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_TICKET_TASK' && (
            <Link
              className="cursor-pointer"
              to={`../activity/tasks/view/${serviceTicketEvent.sourceServiceTicketTask?.id}`}
            >
              View Task
            </Link>
          )}

          {serviceTicketEvent.sourceType?.enum === 'SERVICE_VISIT' && (
            <Link className="cursor-pointer" to="../activity/service-visits">
              Go to Service Visits
            </Link>
          )}

          {[
            'WORK_ORDER',
            'WORK_ORDER_ISSUE',
            'WORK_ORDER_ISSUE_ACTION_ITEM',
            'WORK_ORDER_ISSUE_REVIEW',
          ].includes(serviceTicketEvent.sourceType?.enum) && (
            <Link className="cursor-pointer" to="../activity/work-order">
              Go to Work Order
            </Link>
          )}
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ServiceTicketEventTimelineItem;
