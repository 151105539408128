// eslint-disable-next-line import/prefer-default-export
export const facilitySearchScreenFilterDefs = [
  {
    name: 'Facility',
    refinements: [
      {
        attribute: 'businessConsultantContact.name',
        label: 'Business Consultant',
        operator: 'or',
      },
      {
        attribute: 'esrContact.name',
        label: 'ESR',
        operator: 'or',
      },
      {
        attribute: 'fieldConsultantContact.name',
        label: 'Field Consultant',
        operator: 'or',
      },
      {
        attribute: 'address.city',
        label: 'City',
        operator: 'or',
      },
      {
        attribute: 'leadAdvisorContact.name',
        label: 'Lead Advisor',
        operator: 'or',
      },
      {
        attribute: 'marketTeam',
        label: 'Market Team',
        operator: 'or',
      },
      {
        attribute: 'operatorContact.name',
        label: 'Operator',
        operator: 'or',
      },
      {
        attribute: 'projectAdministratorContact.name',
        label: 'Project Administrator',
        operator: 'or',
      },
      {
        attribute: 'regionalCoordinatorContact.name',
        label: 'Regional Coordinator',
        operator: 'or',
      },
      {
        attribute: 'address.region',
        label: 'Region',
        operator: 'or',
      },
      {
        attribute: 'regionalTeam',
        label: 'Regional Team',
        operator: 'or',
      },
    ],
  },
];
