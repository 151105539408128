import { useLazyQuery, useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AccountingTaskCodeDeleteDialog } from 'app/shared-components/AccountingTaskCode/AccountingTaskCodeDeleteDialog';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  FETCH_ACCOUNTING_TASK_CODE_VIEW_DRAWER_DATA,
  UPDATE_ACCOUNTING_TASK_CODE,
} from './queries';

const DRAWER_APP_BAR_HEIGHT = 64;

const defaultValues = {
  name: '',
  number: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('A name must be provided.'),
  number: yup.string().nullable().required('A number must be provided.'),
});

const AccountingTaskCodeViewDrawer = forwardRef(
  ({ basePath, contentWidth, onCloseDrawer }, ref) => {
    const dispatch = useDispatch();
    const [fetched, setFetched] = useState(false);
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const params = useParams();
    const rootRef = useRef(null);
    const { refreshSearch } = useEntitySearchApp();
    const user = useSelector(selectUser);
    const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
      useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(schema),
      });

    const { isValid, dirtyFields, errors } = formState;

    const watchFields = watch();

    const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

    const [
      fetchAccountingTaskCodeViewDrawerData,
      {
        data: accountingTaskCodeViewDrawerData,
        loading: accountingTaskCodeViewDrawerLoading,
        refetch: accountingTaskCodeViewDrawerRefetch,
      },
    ] = useLazyQuery(FETCH_ACCOUNTING_TASK_CODE_VIEW_DRAWER_DATA, {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setFetched(true),
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Fetching Accounting Task Code Data',
            variant: 'error',
          })
        );
      },
    });

    const [updateAccountingTaskCode, { loading: updateAccountingTaskCodeLoading }] = useMutation(
      UPDATE_ACCOUNTING_TASK_CODE,
      {
        onCompleted: (data) => {
          dispatch(
            showMessage({
              message: 'Accounting Task Code Successfully Updated',
              variant: 'success',
            })
          );
        },
        onError: (error) => {
          dispatch(
            showMessage({ message: 'Failed Updating Accounting Task Code', variant: 'error' })
          );
        },
      }
    );

    const accountingTaskCode = useMemo(
      () => accountingTaskCodeViewDrawerData?.accountingTaskCode,
      [accountingTaskCodeViewDrawerData?.accountingTaskCode]
    );

    const loading = useMemo(
      () => [accountingTaskCodeViewDrawerLoading, updateAccountingTaskCodeLoading].includes(true),
      [accountingTaskCodeViewDrawerLoading, updateAccountingTaskCodeLoading]
    );

    const canUpdateAccountingTaskCode = useMemo(
      () =>
        Boolean(
          accountingTaskCode?.canBeUpdated === true &&
            accountingTaskCode?.owner?.id === user?.data?.organization?.id
        ),
      [
        accountingTaskCode?.canBeUpdated,
        accountingTaskCode?.owner?.id,
        user?.data?.organization?.id,
      ]
    );

    useImperativeHandle(ref, () => ({
      rootRef,
      refetchAll: () => {
        [accountingTaskCodeViewDrawerRefetch].forEach(() => accountingTaskCodeViewDrawerRefetch());
      },
    }));

    useEffect(() => {
      if (accountingTaskCode) {
        const formData = {
          name: accountingTaskCode?.name,
          number: accountingTaskCode?.number,
        };

        reset(formData);
      }
    }, [reset, accountingTaskCode]);

    useEffect(() => {
      if (accountingTaskCodeViewDrawerRefetch && fetched && refreshSearch) {
        accountingTaskCodeViewDrawerRefetch();
      }
    }, [accountingTaskCodeViewDrawerRefetch, fetched, refreshSearch]);

    useEffect(() => {
      if (params?.accountingTaskCodeId) {
        setFetched(false);
        fetchAccountingTaskCodeViewDrawerData({
          variables: { where: { id: params?.accountingTaskCodeId } },
        });
      }
    }, [fetchAccountingTaskCodeViewDrawerData, params?.accountingTaskCodeId]);

    const handleCloseDrawer = useCallback(() => {
      if (typeof onCloseDrawer === 'function') {
        onCloseDrawer();
      }

      setFetched(false);
    }, [onCloseDrawer]);

    useEffect(() => {
      if (fetched && !accountingTaskCode) {
        handleCloseDrawer();
      }
    }, [accountingTaskCode, fetched, handleCloseDrawer]);

    const onSubmit = async (data) => {
      try {
        await updateAccountingTaskCode({
          variables: {
            where: {
              id: accountingTaskCode.id,
            },
            data: {
              name: data.name,
              number: data.number,
            },
          },
        });
      } catch (err) {
        //
      }
    };

    return (
      <div className="flex-1" style={{ width: contentWidth }}>
        <AppBar color="inherit" elevation={0} position="static">
          <Toolbar>
            <div className="flex flex-1">
              <Typography component="div" noWrap variant="h6">
                Task Code Details
              </Typography>
            </div>

            <IconButton sx={{ width: 40, height: 40 }} onClick={handleCloseDrawer}>
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Paper
          className="flex flex-col p-24 overflow-auto border-t-1"
          elevation={0}
          square
          style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
        >
          {(loading && !fetched) || !accountingTaskCode ? (
            <div className="flex w-full h-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <FormProvider
              {...{
                clearErrors,
                control,
                formState,
                handleSubmit,
                reset,
                setValue,
                watch,
                ...methods,
              }}
            >
              <form
                className="w-full"
                id="accounting-task-code-view-form"
                name="accounting-task-code-view-form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors?.name}
                          fullWidth
                          helperText={errors?.name?.message}
                          inputProps={{ readOnly: !canUpdateAccountingTaskCode }}
                          required
                          variant="outlined"
                          placeholder="Enter Accounting Task Code Name..."
                        />
                      )}
                    />
                  </Grid>

                  <Grid xs={12}>
                    <Controller
                      control={control}
                      name="number"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors?.number}
                          fullWidth
                          helperText={errors?.number?.message}
                          inputProps={{ readOnly: !canUpdateAccountingTaskCode }}
                          required
                          variant="outlined"
                          placeholder="Enter Accounting Task Code Number..."
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {canUpdateAccountingTaskCode && (
                  <>
                    <Divider className="my-16" />

                    <Grid container spacing={2}>
                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'left'}
                        md={6}
                        xs={12}
                      >
                        <Button
                          color="error"
                          disabled={loading}
                          fullWidth={isMobile}
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              openDialog({
                                children: (
                                  <AccountingTaskCodeDeleteDialog
                                    accountingTaskCodeId={accountingTaskCode?.id}
                                    onClose={() => dispatch(closeDialog())}
                                    onSuccess={handleCloseDrawer}
                                  />
                                ),
                                classes: {
                                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                                },
                              })
                            );
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>

                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'right'}
                        md={3}
                        xs={12}
                      >
                        <Button
                          color="primary"
                          disabled={_.isEmpty(dirtyFields) || loading}
                          fullWidth
                          variant="contained"
                          onClick={() => reset(undefined, { keepDefaultValues: true })}
                        >
                          Revert
                        </Button>
                      </Grid>

                      <Grid
                        alignContent="center"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'right'}
                        md={3}
                        xs={12}
                      >
                        <Button
                          color="secondary"
                          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                          form="accounting-task-code-view-form"
                          fullWidth
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </form>
            </FormProvider>
          )}
        </Paper>
      </div>
    );
  }
);

export default AccountingTaskCodeViewDrawer;
