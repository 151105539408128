import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from '@lodash';
import { AccountingTaskCodeHitsListOption } from 'app/shared-components/AccountingTaskCode';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { CsiClassificationHitsListOption } from 'app/shared-components/CsiClassification';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { CREATE_COST_CODE } from './queries';
import { CostCodeCreateDialogAttachments } from './components';

const defaultValues = {
  accountingExpenseType: null,
  accountingTaskCode: null,
  attachments: [],
  capitalExpenseThreshold: '',
  csiClassification: null,
  isCapitalExpense: false,
};

const schema = yup.object().shape({
  accountingExpenseType: yup.object().nullable().optional(),
  accountingTaskCode: yup.object().nullable().optional(),
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  capitalExpenseThreshold: yup
    .mixed()
    .nullable()
    .when('isCapitalExpense', {
      is: true,
      then: yup
        .mixed()
        .test(
          'requireCapitalExpenseThreshold',
          'A capital expense threshold must be provided.',
          (value) => _.isNumber(value) && value >= 0.01
        ),
    }),
  csiClassification: yup.object().nullable().required('A CSI classification must be provided.'),
  isCapitalExpense: yup.boolean().required(),
});

const CostCodeCreateDialog = ({ onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [createCostCode, { loading: createCostCodeLoading }] = useMutation(CREATE_COST_CODE, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Cost Code Successfully Created',
          variant: 'success',
        })
      );

      // ROADMAP: Migrate Other Forms to Always Use a Callback
      if (typeof onSuccess === 'function') {
        onSuccess(data?.createCostCode);
      }
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Creating Cost Code', variant: 'error' }));
    },
  });

  const loading = useMemo(() => [createCostCodeLoading].includes(true), [createCostCodeLoading]);

  const onSubmit = async (data) => {
    try {
      await createCostCode({
        variables: {
          data: {
            accountingExpenseType: data.accountingExpenseType?.id
              ? { id: data.accountingExpenseType?.id }
              : null,
            accountingTaskCode: data.accountingTaskCode?.id
              ? { id: data.accountingTaskCode?.id }
              : null,
            attachments: data.attachments?.map((attachment) => ({
              commonStoredUpload: { key: attachment?.commonStoredUpload.key },
            })),
            capitalExpenseThreshold: data.isCapitalExpense ? data.capitalExpenseThreshold : null,
            csiClassification: { id: data.csiClassification.id },
            isCapitalExpense: data.isCapitalExpense,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>Create Cost Code</DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="cost-code-create-form"
            name="cost-code-create-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="csiClassification"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  components={{ Option: CsiClassificationHitsListOption }}
                  error={errors?.csiClassification}
                  filter={[
                    [`costCodes.id NOT EXISTS`],
                    [`costCodes.owner.id != '${user?.data?.organization?.id}'`],
                  ]}
                  getOptionLabel={(option) => `${option?.number} - ${option.name}`}
                  getOptionValue={(option) => option?.id}
                  indexName="csi_classifications"
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select a CSI Classification..."
                  sort={['name:asc']}
                />
              )}
            />

            <Controller
              control={control}
              name="accountingTaskCode"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  components={{ Option: AccountingTaskCodeHitsListOption }}
                  error={errors?.accountingTaskCode}
                  filter={null}
                  getOptionLabel={(option) => `${option?.number} - ${option.name}`}
                  getOptionValue={(option) => option?.id}
                  indexName="accounting_task_codes"
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Accounting Task Code..."
                  sort={['name:asc']}
                />
              )}
            />

            <Divider sx={{ mb: 3 }} />

            <Controller
              control={control}
              name="accountingExpenseType"
              render={({ field }) => (
                <EntitySearchSelectFieldV2
                  {...field}
                  className="mb-24"
                  error={errors?.accountingExpenseType}
                  filter={null}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  indexName="accounting_expense_types"
                  isClearable
                  isDisabled={false}
                  isMulti={false}
                  placeholder="Select Accounting Expense Type..."
                  sort={['name:asc']}
                />
              )}
            />

            <div className="flex flex-1 items-center mb-24">
              <div className="mr-24">
                <Controller
                  control={control}
                  name="isCapitalExpense"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          checked={!!field.value}
                          color="info"
                          onChange={(params) => {
                            setValue('capitalExpenseThreshold', '', {
                              shouldValidate: true,
                            });

                            field.onChange(params);
                          }}
                        />
                      }
                      label="Capital Expense"
                      labelPlacement="start"
                      sx={{ ml: 0 }}
                    />
                  )}
                />
              </div>

              <div className="flex-1">
                <Controller
                  control={control}
                  name="capitalExpenseThreshold"
                  render={({ field }) => (
                    <CommonCurrencyField
                      {...field}
                      className=""
                      control={control}
                      disabled={!watchFields?.isCapitalExpense}
                      error={errors?.capitalExpenseThreshold}
                      placeholder="Enter Capital Expense Threshold..."
                    />
                  )}
                />
              </div>
            </div>

            <Divider sx={{ mb: 3 }} />

            <CostCodeCreateDialogAttachments name="attachments" />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="cost-code-create-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default CostCodeCreateDialog;
