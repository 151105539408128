import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const WorkOrderIssueLineItemGridSelectOption = (props) => {
  const option = useMemo(
    () => (
      <div>
        <Typography noWrap>{props?.label}</Typography>
      </div>
    ),
    [props?.label]
  );
  return <components.Option {...props}>{option}</components.Option>;
};

export default WorkOrderIssueLineItemGridSelectOption;
