import _ from '@lodash';
import { capitalCase } from 'change-case';
import humanizeDuration from 'humanize-duration';
import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line import/prefer-default-export
export const buildProgramSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'program',
    headerName: 'Program',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'description',
        headerName: 'Description',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'issueTemplates',
        headerName: 'Issue Templates',
        sortable: false,
        valueGetter: ({ data }) => data?.issueTemplates && data?.issueTemplates.length,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'tags',
        headerName: 'Tags',
        sortable: false,
        valueFormatter: ({ value }) => {
          const tags = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(tags) ? _.uniq(tags).join(', ') : undefined;
        },
      },
      {
        cellDataType: 'text',
        field: 'creator.name',
        headerName: 'Creator',
        sortable: true,
      },
    ],
  },
  {
    groupId: 'sharing-rule',
    headerName: 'Sharing Rule',
    children: [
      {
        cellDataType: 'text',
        field: 'commonSharingRule.type.name',
        headerName: 'Type',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'commonSharingRule.subjectType.name',
        headerName: 'Subject Type',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'commonSharingRule.subjectTeams',
        headerName: 'Subject Teams',
        sortable: false,
        valueFormatter: ({ value }) => {
          const subjectTeams = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(subjectTeams) ? _.uniq(subjectTeams).join(', ') : undefined;
        },
        valueGetter: ({ data }) =>
          data?.commonSharingRule?.subjectTeams?.map(({ team }) => team)?.filter((el) => el) ||
          undefined,
      },
    ],
  },
  {
    groupId: 'summary',
    headerName: 'Summary',
    children: [
      {
        cellDataType: 'number',
        field: 'statistics.facilityCount',
        headerName: 'Total Facilities',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'statistics.serviceTicketCount',
        headerName: 'Total Tickets',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'statistics.workOrderIssueCount',
        headerName: 'Total Issues',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'statistics.workOrderIssueTotalAmountSum',
        headerName: 'Total Spend',
        sortable: true,
        valueFormatter: ({ value }) => (_.isNumber(value) ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'statistics.workOrderTotalAmountAverage',
        headerName: 'Average Spend / Ticket',
        sortable: true,
        valueFormatter: ({ value }) => (_.isNumber(value) ? numeral(value).format('$0,0.00') : '-'),
      },
      {
        cellDataType: 'number',
        field: 'statistics.workOrderIssueTotalAmountAverage',
        headerName: 'Average Spend / Issue',
        sortable: true,
        valueFormatter: ({ value }) => (_.isNumber(value) ? numeral(value).format('$0,0.00') : '-'),
      },
    ],
  },
  {
    groupId: 'progress',
    headerName: 'Progress',
    children: [
      {
        cellDataType: 'number',
        field: 'statistics.programCampaignStatusCounts.open',
        headerName: 'Open',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'statistics.programCampaignStatusCounts.complete',
        headerName: 'Complete',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'statistics.programCampaignStatusCounts.cancelled',
        headerName: 'Cancelled',
        sortable: true,
      },
    ],
  },
  {
    groupId: 'duration-averages',
    headerName: 'Duration Averages',
    children: [
      {
        field: 'statistics.serviceTicketEventDurationAverages.draftPhaseDurationAverage',
        headerName: 'Draft Phase',
      },
      {
        field: 'statistics.serviceTicketEventDurationAverages.siteVisitPhaseDurationAverage',
        headerName: 'Site Visit Phase',
      },
      {
        field: 'statistics.serviceTicketEventDurationAverages.quotingPhaseDurationAverage',
        headerName: 'Quoting Phase',
      },
      {
        field: 'statistics.serviceTicketEventDurationAverages.releasePhaseDurationAverage',
        headerName: 'Release Phase',
      },
      {
        field:
          'statistics.serviceTicketEventDurationAverages.inProgressPhaseCompletedDurationAverage',
        headerName: 'In Progress Phase - Completed',
      },
      {
        field: 'statistics.serviceTicketEventDurationAverages.inProgressPhaseClosedDurationAverage',
        headerName: 'In Progress Phase - Closed',
      },
      {
        field: 'statistics.serviceTicketEventDurationAverages.overallCompletedDurationAverage',
        headerName: 'Overall Completed',
      },
      {
        field: 'statistics.serviceTicketEventDurationAverages.overallClosedDurationAverage',
        headerName: 'Overall Closed',
      },
    ].map(({ field, headerName }) => ({
      cellDataType: 'number',
      field,
      headerName,
      sortable: true,
      valueFormatter: ({ value }) =>
        _.isNumber(value) && value > 0
          ? capitalCase(humanizeDuration(value * 1000, { largest: 2 }))
          : '-',
    })),
  },
];
