import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ACCOUNTING_TASK_CODE_VIEW_DRAWER_ACCOUNTING_TASK_CODE_FRAGMENT = gql`
  fragment AccountingTaskCodeViewDrawerAccountingTaskCodeFragment on AccountingTaskCode {
    id
    createdAt
    updatedAt

    canBeUpdated
    name
    number
    owner {
      id
      name
    }
  }
`;
