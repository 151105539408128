import { useMutation } from '@apollo/client';
import { Switch } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  ADD_BUDGET_SPECIAL_PROJECT_COST_CODE,
  REMOVE_BUDGET_SPECIAL_PROJECT_COST_CODE,
} from '../queries';

const BudgetSpecialProjectViewScreenCostCodeActiveCellRenderer = (params) => {
  const dispatch = useDispatch();

  const [addBudgetSpecialProjectCostCode, { loading: addBudgetSpecialProjectCostCodeLoading }] =
    useMutation(ADD_BUDGET_SPECIAL_PROJECT_COST_CODE, {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Cost Code Successfully Added',
            variant: 'success',
          })
        );

        params.setValue([
          ...(params.value || []),
          {
            budgetSpecialProject: {
              id: data.addBudgetSpecialProjectCostCode?.id,
              name: data.addBudgetSpecialProjectCostCode?.name,
            },
          },
        ]);
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Adding Cost Code', variant: 'error' }));
      },
    });

  const [
    removeBudgetSpecialProjectCostCode,
    { loading: removeBudgetSpecialProjectCostCodeLoading },
  ] = useMutation(REMOVE_BUDGET_SPECIAL_PROJECT_COST_CODE, {
    onCompleted: (data) => {
      dispatch(
        showMessage({
          message: 'Cost Code Successfully Removed',
          variant: 'success',
        })
      );

      params.setValue(
        (params.value || []).filter(
          ({ budgetSpecialProject }) =>
            budgetSpecialProject?.id !== data.removeBudgetSpecialProjectCostCode?.id
        )
      );
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Removing Cost Code', variant: 'error' }));
    },
  });

  const checked = useMemo(
    () =>
      params?.value
        ?.map(({ budgetSpecialProject }) => budgetSpecialProject?.id)
        .includes(params?.budgetSpecialProjectId),
    [params?.budgetSpecialProjectId, params?.value]
  );

  const loading = useMemo(
    () =>
      [addBudgetSpecialProjectCostCodeLoading, removeBudgetSpecialProjectCostCodeLoading].includes(
        true
      ),
    [addBudgetSpecialProjectCostCodeLoading, removeBudgetSpecialProjectCostCodeLoading]
  );

  const handleChange = async (event) => {
    const {
      target: { checked: _checked },
    } = event;

    if (_checked && params.budgetSpecialProjectId && params.data?.id) {
      await addBudgetSpecialProjectCostCode({
        variables: {
          where: { id: params.budgetSpecialProjectId },
          data: { costCodes: [{ id: params.data?.id }] },
        },
      });
    } else if (!_checked && params.budgetSpecialProjectId && params.data?.id) {
      await removeBudgetSpecialProjectCostCode({
        variables: {
          where: { id: params.budgetSpecialProjectId },
          data: { costCodes: [{ id: params.data?.id }] },
        },
      });
    }
  };

  return (
    <div className="flex justify-center">
      <Switch checked={!!checked} color="success" disabled={loading} onChange={handleChange} />
    </div>
  );
};

export default BudgetSpecialProjectViewScreenCostCodeActiveCellRenderer;
