import { gql } from '@apollo/client';
import { BUDGET_ACTIVATE_DIALOG_BUDGET_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const ACTIVATE_BUDGET = gql`
  mutation ActivateBudget($where: BudgetWhereUniqueInput!, $data: BudgetActivateInput!) {
    activateBudget(where: $where, data: $data) {
      id
      ...BudgetActivateDialogBudgetFragment
    }
  }
  ${BUDGET_ACTIVATE_DIALOG_BUDGET_FRAGMENT}
`;
