import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';

const WorkOrderIssueReCodeDialogTimeoutDialog = ({
  open,
  sessionExpiresAt,
  onEndSession,
  onExtendSession,
}) => {
  const [duration, setDuration] = useState(null);
  const interval = useRef(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    if (open && sessionExpiresAt && moment(sessionExpiresAt).isValid()) {
      interval.current = setInterval(() => {
        const diff = moment(sessionExpiresAt).diff(moment());
        const _duration = moment.duration(diff >= 0 ? diff : 0, 'milliseconds');

        setDuration(_duration);
      }, 1000);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [open, sessionExpiresAt]);

  const handleEndSession = useCallback(() => {
    if (typeof onEndSession === 'function') {
      onEndSession();
    }
  }, [onEndSession]);

  const handleExtendSession = useCallback(() => {
    if (typeof onExtendSession === 'function') {
      onExtendSession();
    }
  }, [onExtendSession]);

  return (
    <Dialog classes={{ paper: 'w-full max-w-320 min-w-320 rounded-8' }} open={!!open}>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}`, textAlign: 'center' }}>
        Re-Code Session Timeout
      </DialogTitle>

      <DialogContent>
        <div className="flex items-center justify-center w-full pt-20">
          <Typography>Your Re-Code Session Will Expire In:</Typography>
        </div>

        <div className="flex items-center justify-center w-full">
          <div className="flex flex-col items-center mx-8 w-68">
            <Typography className="text-48">{duration?.minutes()}</Typography>
            <Typography className="text-12 uppercase">minutes</Typography>
          </div>

          <div className="flex flex-col items-center mx-8 w-68">
            <Typography className="text-48">{duration?.seconds()}</Typography>
            <Typography className="text-12 uppercase">seconds</Typography>
          </div>
        </div>
      </DialogContent>

      <DialogActions
        sx={{ borderTop: `1px solid ${grey[400]}`, justifyContent: 'space-between', padding: 2 }}
      >
        <Button color="primary" disabled={false} variant="contained" onClick={handleEndSession}>
          End Session
        </Button>

        <Button
          color="secondary"
          disabled={false}
          variant="contained"
          onClick={handleExtendSession}
        >
          Extend Session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkOrderIssueReCodeDialogTimeoutDialog;
