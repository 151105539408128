import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BUDGET_VIEW_SCREEN_BUDGET_FRAGMENT = gql`
  fragment BudgetViewScreenBudgetFragment on Budget {
    id
    createdAt
    updatedAt

    divisionName
    divisionNumber
    facilities(skip: 0, take: 5) {
      facility {
        id
        franchiseNumber
        name
      }
    }
    name
    owner {
      id
      name
    }
    projects {
      id
      name
      status {
        id
        enum
        name
      }
    }
    # ROADMAP: Implement
    # segments {
    #   id
    # }
    status {
      id
      enum
      name
    }
  }
`;
