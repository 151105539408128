import { useLazyQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountingProjectViewHeader,
  AccountingProjectViewTabs,
} from 'app/shared-components/AccountingProject';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { FETCH_ACCOUNTING_PROJECT_VIEW_SCREEN_DATA } from './queries';

// TODO:
const schema = yup.object().shape({
  acceptedCommonStandardRateTypes: yup
    .array()
    .test(
      'requireOneAcceptedCommonStandardRateType',
      'One or more standard rate types must be provided.',
      (value) => value && value.filter((el) => el).length
    ),
  account: yup.mixed().when('codingType', {
    is: (option) => ['GL', 'GL_POET'].includes(option?.enum),
    then: yup.string().nullable().required('An account must be provided.'),
  }),
  accountingWorkOrderTypes: yup.array().optional(),
  attachments: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          file: yup.mixed().required(),
          commonStoredUpload: yup.object().shape({ key: yup.string().required() }),
        })
        .required()
    )
    .optional(),
  codingType: yup.object().nullable().required('A coding type must be provided.'),
  name: yup.string().nullable().required('A name must be provided.'),
  number: yup.mixed().when('codingType', {
    is: (option) => ['GL_POET', 'POET'].includes(option?.enum),
    then: yup.string().nullable().required('A number must be provided.'),
  }),
  numberSuffix: yup.mixed().when('codingType', {
    is: (option) => ['GL_POET', 'POET'].includes(option?.enum),
    then: yup.object().nullable().optional(),
  }),
});

const AccountingProjectViewScreen = () => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const params = useParams();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const accountingProjectId = useMemo(
    () => params?.accountingProjectId,
    [params?.accountingProjectId]
  );

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const [
    fetchAccountingProjectViewScreenData,
    {
      data: accountingProjectViewScreenData,
      loading: accountingProjectViewScreenLoading,
      refetch: accountingProjectViewScreenRefetch,
    },
  ] = useLazyQuery(FETCH_ACCOUNTING_PROJECT_VIEW_SCREEN_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Accounting Project Data',
          variant: 'error',
        })
      );
    },
  });

  const accountingProject = useMemo(
    () => accountingProjectViewScreenData?.accountingProject,
    [accountingProjectViewScreenData?.accountingProject]
  );

  const loading = useMemo(
    () => [accountingProjectViewScreenLoading].includes(true),
    [accountingProjectViewScreenLoading]
  );

  useEffect(() => {
    if (methods?.reset && accountingProject) {
      const formData = {
        // TODO:
        acceptedCommonStandardRateTypes: [],
        account: '',
        accountingWorkOrderTypes: [],
        attachments: [],
        codingType: null,
        isEditable: accountingProject.isEditable,
        name: accountingProject.name ?? '',
        number: '',
        numberSuffix: null,
      };

      methods?.reset(formData);
    }
  }, [methods, accountingProject]);

  useEffect(() => {
    if (accountingProjectId) {
      fetchAccountingProjectViewScreenData({
        variables: { where: { id: accountingProjectId } },
      });
    }
  }, [fetchAccountingProjectViewScreenData, accountingProjectId]);

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 10000));

      // TODO:
      // await createAccountingProject({
      //   variables: {
      //     data: {
      //       acceptedCommonStandardRateTypes: data.acceptedCommonStandardRateTypes?.map(
      //         ({ value }) => ({ id: value })
      //       ),
      //       account: data.account,
      //       accountingWorkOrderTypes: data.accountingWorkOrderTypes?.map(({ id }) => ({
      //         id,
      //       })),
      //       attachments: data.attachments?.map((attachment) => ({
      //         commonStoredUpload: { key: attachment?.commonStoredUpload.key },
      //       })),
      //       codingType: { id: data.codingType?.id },
      //       name: data.name,
      //       number: data.number,
      //       numberSuffix: data.numberSuffix?.id ? { id: data.numberSuffix.id } : null,
      //     },
      //   },
      // });
    } catch (err) {
      console.log({ err });
      //
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="w-full h-full"
        id="accounting-project-update-form"
        name="accounting-project-update-form"
        noValidate
        onSubmit={methods?.handleSubmit(onSubmit)}
      >
        <FusePageCarded
          header={
            <>
              {!fetched || loading ? (
                <FuseLoading />
              ) : (
                <div className="flex flex-col w-full">
                  {['tabs/definition'].includes(basePath) && (
                    <AccountingProjectViewHeader basePath={basePath} />
                  )}

                  <AccountingProjectViewTabs basePath={basePath} />

                  {/* TODO: */}
                  {/* {['tabs/definition'].includes(basePath) && (
                    <AccountingProjectDefinitionViewHeader basePath={basePath} />
                  )} */}
                </div>
              )}
            </>
          }
          content={
            <>
              {!fetched || loading ? (
                <FuseLoading className="h-full" />
              ) : (
                <>
                  {basePath === 'tabs/definition' && (
                    // TODO:
                    // <AccountingProjectDefinitionViewForm basePath={basePath} />
                    <div>Definition</div>
                  )}
                </>
              )}
            </>
          }
          scroll={isMobile ? 'normal' : 'content'}
        />
      </form>
    </FormProvider>
  );
};

export default AccountingProjectViewScreen;
