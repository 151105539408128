import { AccountingProjectViewScreen } from 'app/screens/AccountingProjectViewScreen';
import { Navigate, Route, Routes } from 'react-router-dom';

const AccountingProjectsViewPage = () => {
  return (
    <Routes>
      <Route element={<AccountingProjectViewScreen />} path=":sectionName/:viewName" />
      <Route element={<AccountingProjectViewScreen />} path=":sectionName/:viewName/view/:viewId" />

      <Route path="*" element={<Navigate to="tabs/definition" replace />} />
    </Routes>
  );
};

export default AccountingProjectsViewPage;
