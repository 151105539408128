import { useMutation } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { BudgetDeleteDialog } from 'app/shared-components/Budget';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_BUDGET } from '../queries';
import BudgetViewScreenHeader from './BudgetViewScreenHeader';

const defaultValues = {
  divisionName: '',
  divisionNumber: '',
  isDefault: false,
  name: '',
};

const schema = yup.object().shape({
  divisionName: yup.string().nullable().required('A division name must be provided.'),
  divisionNumber: yup.string().nullable().required('A division number must be provided.'),
  isDefault: yup.boolean().required(),
  name: yup.string().nullable().required('A name must be provided.'),
});

const BudgetViewScreenOverviewTab = ({ budget }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const user = useSelector(selectUser);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [updateBudget, { loading: updateBudgetLoading }] = useMutation(UPDATE_BUDGET, {
    onCompleted: (data) => {
      dispatch(
        showMessage({
          message: 'Budget Successfully Updated',
          variant: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Updating Budget', variant: 'error' }));
    },
  });

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const canDeleteBudget = useMemo(
    () =>
      Boolean(
        ['DRAFT'].includes(budget?.status?.enum) &&
          budget?.owner?.id === user?.data?.organization?.id
      ),
    [budget?.owner?.id, budget?.status?.enum, user?.data?.organization?.id]
  );

  const canUpdateBudget = useMemo(
    () =>
      Boolean(
        ['DRAFT', 'INACTIVE'].includes(budget?.status?.enum) &&
          budget?.owner?.id === user?.data?.organization?.id
      ),
    [budget?.owner?.id, budget?.status?.enum, user?.data?.organization?.id]
  );

  const loading = useMemo(() => [updateBudgetLoading].includes(true), [updateBudgetLoading]);

  useEffect(() => {
    if (budget) {
      const formData = {
        divisionName: budget.divisionName,
        divisionNumber: budget.divisionNumber,
        isDefault: budget.isDefault,
        name: budget.name,
      };

      reset(formData);
    }
  }, [reset, budget]);

  const onSubmit = async (data) => {
    try {
      await updateBudget({
        variables: {
          where: { id: budget.id },
          data: {
            divisionName: data.divisionName,
            divisionNumber: data.divisionNumber,
            isDefault: data.isDefault,
            name: data.name,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <FusePageCarded
      content={
        <>
          {!budget ? (
            <FuseLoading className="h-full" />
          ) : (
            <div className="flex flex-col w-full max-w-5xl mx-auto p-24">
              <Grid container spacing={2}>
                <Grid md={8} xs={12}>
                  <FormProvider
                    {...{
                      clearErrors,
                      control,
                      formState,
                      handleSubmit,
                      reset,
                      setValue,
                      watch,
                      ...methods,
                    }}
                  >
                    <form
                      className="w-full"
                      id="budget-view-screen-overview-form"
                      name="budget-view-screen-overview-form"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Paper
                        className="p-24 border-1"
                        sx={{
                          backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
                        }}
                      >
                        <Grid className="mb-24" container spacing={2}>
                          <Grid
                            alignItems="center"
                            display="flex"
                            md={9}
                            order={{ md: 1, xs: 2 }}
                            xs={12}
                          >
                            <Controller
                              control={control}
                              name="name"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className=""
                                  error={!!errors?.name}
                                  fullWidth
                                  helperText={errors?.name?.message}
                                  inputProps={{ readOnly: !canUpdateBudget }}
                                  placeholder="Enter Budget Name..."
                                  required
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid
                            alignItems="center"
                            display="flex"
                            justifyContent="right"
                            md={3}
                            order={{ md: 2, xs: 1 }}
                            xs={12}
                          >
                            <Controller
                              control={control}
                              name="isDefault"
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      {...field}
                                      checked={!!field.value}
                                      color="info"
                                      disabled={!canUpdateBudget}
                                    />
                                  }
                                  label="Default"
                                  labelPlacement="start"
                                  sx={{ ml: 0 }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid xs={12}>
                            <Controller
                              control={control}
                              name="divisionName"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  error={!!errors?.divisionName}
                                  fullWidth
                                  helperText={errors?.divisionName?.message}
                                  inputProps={{ readOnly: !canUpdateBudget }}
                                  placeholder="Enter Budget Division Name..."
                                  required
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid xs={12}>
                            <Controller
                              control={control}
                              name="divisionNumber"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="mb-24"
                                  error={!!errors?.divisionNumber}
                                  fullWidth
                                  helperText={errors?.divisionNumber?.message}
                                  inputProps={{ readOnly: !canUpdateBudget }}
                                  placeholder="Enter Budget Division Number..."
                                  required
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        {(canDeleteBudget || canUpdateBudget) && (
                          <>
                            <Divider className="my-16" />

                            <Grid container spacing={2}>
                              <Grid
                                alignContent="center"
                                display="flex"
                                justifyContent={isMobile ? 'center' : 'left'}
                                md={6}
                                xs={12}
                              >
                                <Button
                                  color="error"
                                  disabled={!canDeleteBudget || loading}
                                  fullWidth={isMobile}
                                  variant="contained"
                                  onClick={() => {
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <BudgetDeleteDialog
                                            budgetId={budget?.id}
                                            onClose={() => dispatch(closeDialog())}
                                            onSuccess={() => navigate('/budgets/search')}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    );
                                  }}
                                >
                                  Delete
                                </Button>
                              </Grid>

                              <Grid
                                alignContent="center"
                                display="flex"
                                justifyContent={isMobile ? 'center' : 'right'}
                                md={3}
                                xs={12}
                              >
                                <Button
                                  color="primary"
                                  disabled={!canUpdateBudget || _.isEmpty(dirtyFields) || loading}
                                  fullWidth
                                  variant="contained"
                                  onClick={() => reset(undefined, { keepDefaultValues: true })}
                                >
                                  Revert
                                </Button>
                              </Grid>

                              <Grid
                                alignContent="center"
                                display="flex"
                                justifyContent={isMobile ? 'center' : 'right'}
                                md={3}
                                xs={12}
                              >
                                <Button
                                  color="secondary"
                                  disabled={
                                    !canUpdateBudget ||
                                    _.isEmpty(dirtyFields) ||
                                    !isValid ||
                                    loading
                                  }
                                  form="budget-view-screen-overview-form"
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                >
                                  Update
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Paper>
                    </form>
                  </FormProvider>
                </Grid>

                <Grid className="sm:pl-24" md={4} xs={12}>
                  <Paper
                    className="mb-24 border-1"
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Typography className="pt-12 px-12 text-18 font-500">
                          Budget Projects
                        </Typography>
                      </Grid>

                      <Grid xs={12}>
                        {!_.isEmpty(budget?.projects) ? (
                          <List className="border-t-1" disablePadding>
                            {budget.projects.slice(0, 5).map((budgetProject) => (
                              <ListItem component="div" dense divider key={budgetProject.id}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <div className="flex flex-1">
                                      <div className="flex flex-col">
                                        <Typography className="mb-1 text-12" variant="body2">
                                          {`${budgetProject.status?.name}`}
                                        </Typography>

                                        <Typography variant="body2">{`${budgetProject.name}`}</Typography>
                                      </div>
                                    </div>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <div className="flex justify-center p-24 border-t-1">
                            No Budget Projects Found
                          </div>
                        )}
                      </Grid>

                      <Grid className="p-16" xs={12}>
                        <Button
                          color="info"
                          fullWidth
                          size="small"
                          variant="contained"
                          onClick={() => navigate('../tabs/budget-projects')}
                        >
                          View Budget Projects
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Paper
                    className="mb-24 border-1"
                    sx={{
                      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#1e293b',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Typography className="pt-12 px-12 text-18 font-500">Facilities</Typography>
                      </Grid>

                      <Grid xs={12}>
                        {!_.isEmpty(budget?.facilities) ? (
                          <List className="border-t-1" disablePadding>
                            {budget.facilities.slice(0, 5).map(({ facility }) => (
                              <ListItem component="div" dense divider key={facility.id}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <div className="flex flex-1">
                                      <div className="flex flex-col">
                                        <Typography className="mb-1 text-12" variant="body2">
                                          {`${facility.franchiseNumber}`}
                                        </Typography>

                                        <Typography variant="body2">{`${facility.name}`}</Typography>
                                      </div>
                                    </div>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <div className="flex justify-center p-24 border-t-1">
                            No Facilities Found
                          </div>
                        )}
                      </Grid>

                      <Grid className="p-16" xs={12}>
                        <Button
                          color="info"
                          fullWidth
                          size="small"
                          variant="contained"
                          onClick={() => navigate('../tabs/facilities')}
                        >
                          View Facilities
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      }
      header={
        <>
          {!budget ? (
            <FuseLoading />
          ) : (
            <BudgetViewScreenHeader basePath={basePath} budget={budget} />
          )}
        </>
      }
      scroll={isMobile ? 'normal' : 'content'}
    />
  );
};

export default BudgetViewScreenOverviewTab;
