import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
// TODO: Uninstall
// import { createUploadLink } from 'apollo-upload-client';
import i18n from 'i18n';
import { jwtService, JWT_SERVICE_TOKEN_TYPES } from 'app/providers/auth/services';

import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';

const {
  REACT_APP_HYVE_CLIENT_VERSION,
  REACT_APP_HYVE_SYSTEM_CLIENT,
  REACT_APP_ULTRAVIOLET_API_BASE_URL,
} = process.env;

const authLink = setContext((_, { headers, cache }) => {
  const { accessToken } = jwtService.getSession();

  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'X-HYVE-Client-Language': i18n.language,
      'X-HYVE-Client-Version': REACT_APP_HYVE_CLIENT_VERSION,
      'X-HYVE-System-Client': REACT_APP_HYVE_SYSTEM_CLIENT,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      // NOTE: Uncomment to Debug GraphQL Errors
      // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      Sentry.captureException(message);
    });

  if (networkError) {
    // NOTE: Uncomment to Debug Apollo/Network Errors
    console.log('[Network error]', { networkError });
  }
});

// FIXME:
const customFetch = async (uri, options) => {
  const start = new Date();

  // let transaction;

  try {
    // const existingTransaction = Sentry.getCurrentHub().getScope()?.getTransaction();

    // Create a transaction if one does not exist in order to work around
    // https://github.com/getsentry/sentry-javascript/issues/3169
    // https://github.com/getsentry/sentry-javascript/issues/4072
    // transaction =
    //   existingTransaction ?? Sentry.startTransaction({ name: 'apollo:client:fetch' });

    // Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

    // console.log('customFetch => START', { transaction, options });

    const response = await fetch(uri, options);

    // console.log('customFetch => END ', { transaction, duration: new Date() - start, response });

    // transaction?.finish();

    return response;
  } catch (err) {
    // console.error({ err });
    Sentry.captureException(err);
    // return request;
  } finally {
    // console.log('customFetch => END ', { duration: new Date() - start });

    // transaction?.finish();
  }
};

const httpLink = new HttpLink({ fetch: customFetch, uri: REACT_APP_ULTRAVIOLET_API_BASE_URL });

// const sentryTransactionLink = new ApolloLink((operation, forward) => {
//   // Called before operation is sent to server
//   console.log({ operation });
//   operation.setContext({ start: new Date() });

//   return forward(operation).map((data) => {
//     // Called after server responds
//     const time = new Date() - operation.getContext().start;
//     console.log(`Operation ${operation.operationName} took ${time} to complete`);
//     return data;
//   });
// });

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN,
  isTokenValidOrUndefined: () => {
    const { accessToken } = jwtService.getSession();

    return jwtService.isTokenValid(accessToken);
  },
  fetchAccessToken: () => {
    return new Promise((resolve, reject) => {
      jwtService
        .refreshSession()
        .then(({ accessToken }) => resolve(accessToken))
        .catch((error) => reject(error));
    });
  },
  handleFetch: (accessToken) => {},
  handleResponse: (operation, accessTokenField) => (response) => {
    return { [accessTokenField]: response };
  },
  handleError: (err) => {},
});

const sentryLink = new SentryLink({
  uri: REACT_APP_ULTRAVIOLET_API_BASE_URL,
});

const apiClient = new ApolloClient({
  cache: new InMemoryCache({}),
  // NOTE: Order Matters
  link: ApolloLink.from([
    tokenRefreshLink,
    authLink,
    // sentryLink,
    errorLink,
    // sentryTransactionLink,
    httpLink,
  ]),
});

export default apiClient;
