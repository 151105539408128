import { gql } from '@apollo/client';
import { BUDGET_PROJECT_ACTIVATE_DIALOG_BUDGET_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const ACTIVATE_BUDGET_PROJECT = gql`
  mutation ActivateBudgetProject($where: BudgetProjectWhereUniqueInput!) {
    activateBudgetProject(where: $where) {
      id
      ...BudgetProjectActivateDialogBudgetProjectFragment
    }
  }
  ${BUDGET_PROJECT_ACTIVATE_DIALOG_BUDGET_PROJECT_FRAGMENT}
`;
