import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PROGRAM_CAMPAIGN_VIEW_SCREEN_PROGRAM_CAMPAIGN_FRAGMENT = gql`
  fragment ProgramCampaignViewScreenProgramCampaignFragment on ProgramCampaign {
    id
    createdAt
    updatedAt

    # TODO:
    analytics {
      id
      createdAt
      accountingExpenseTypes {
        id
        createdAt
        accountingExpenseType {
          id
          name
        }
        accountingExpenseTypeCount
        totalAmountAvg
        totalAmountSum
      }
      costCodes {
        id
        createdAt
        costCode {
          id
          csiClassification {
            id
            name
            number
            title
          }
        }
        costCodeCount
        totalAmountAvg
        totalAmountSum
      }
    }

    # TODO
    name
    serviceTickets {
      id
    }
  }
`;
