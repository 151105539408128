import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Divider, Input, LinearProgress, List, ListItem, ListItemText, Menu } from '@mui/material';
import { useMeilisearch } from 'app/providers/meilisearch';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Configure,
  InstantSearch,
  useInfiniteHits,
  useInstantSearch,
  usePagination,
  useSearchBox,
  useSortBy,
} from 'react-instantsearch';

const getStateWithoutPage = (state) => {
  const { page, ...rest } = state || {};
  return rest;
};

const getInMemoryCache = () => {
  let cachedHits = null;
  let cachedState = null;
  return {
    read({ state }) {
      return _.isEqual(cachedState, getStateWithoutPage(state)) ? cachedHits : null;
    },
    write({ state, hits }) {
      cachedState = getStateWithoutPage(state);
      cachedHits = hits;
    },
    invalidate() {
      cachedHits = null;
      cachedState = null;
    },
  };
};

const cache = getInMemoryCache();

const EntitySearchMenuRoot = ({
  anchorEl,
  indexName,
  ListOption,
  listOptionParams,
  nameField,
  sortString,
  onClose,
}) => {
  const { clear: clearQuery, refine: refineQuery } = useSearchBox();
  const { isFirstPage, isLastPage, items, showMore } = useInfiniteHits({ cache });
  const { refine: refinePage } = usePagination();
  const { refresh, status } = useInstantSearch();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const searchTimer = useRef(null);
  const scrollRef = useRef(null);

  const isSearching = useMemo(() => status === 'stalled', [status]);

  const sortByItems = useMemo(
    () => [
      { label: 'None', value: indexName },
      { label: 'Name (asc)', value: `${indexName}:${nameField}:asc` },
      { label: 'Name (desc)', value: `${indexName}:${nameField}:desc` },
    ],
    [indexName, nameField]
  );

  const { refine: refineSort } = useSortBy({ items: sortByItems });

  useEffect(
    () => refineSort(sortString || sortByItems?.[1]?.value),
    [refineSort, sortByItems, sortString]
  );

  useEffect(() => {
    if (anchorEl) {
      refinePage(0);

      cache.invalidate();
      refresh();
    }
  }, [anchorEl, refresh, refinePage]);

  useEffect(() => {
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }

      if (!searchText.length) {
        clearQuery();
      } else if (searchText.length > 2) {
        refineQuery(searchText);
      }
    }, 300);

    return () => clearTimeout(searchTimer.current);
  }, [clearQuery, refineQuery, searchText]);

  const handleListOptionClick = (params) => {
    if (typeof onClose === 'function') {
      onClose();
    }

    return null;
  };

  const handleSearchTextChange = ({ target }) => setSearchText(target?.value);

  const handleSearchTextClear = () => setSearchText('');

  if (!ListOption) return null;

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: 'w-420',
      }}
      open={Boolean(anchorEl)}
      TransitionProps={{
        onEntered: () => {
          searchInput.current.focus();
        },
        onExited: () => {
          handleSearchTextClear();
        },
      }}
      onClose={onClose}
    >
      <div className="p-16 pt-8">
        <Input
          disableUnderline
          fullWidth
          inputRef={searchInput}
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchTextChange}
        />
      </div>

      <Divider />

      <div
        className="max-h-384 overflow-y-auto"
        id="entity-search-infinite-hits-list"
        ref={scrollRef}
      >
        {isFirstPage && isLastPage && isSearching ? (
          <div className="flex items-center justify-center w-full h-384">
            <FontAwesomeIcon icon={faSpinner} size="xl" spin />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={items?.length}
            endMessage={
              isLastPage &&
              !isSearching && (
                <ListItem className="flex flex-row items-center p-16">
                  <div className="flex flex-1">End of Results</div>
                  <div className="flex">Fetched: {items?.length}</div>
                </ListItem>
              )
            }
            hasMore={!isLastPage}
            loader={
              !isLastPage &&
              isSearching && (
                <ListItem className="flex flex-col px-16 py-32">
                  <LinearProgress className="w-full" color="secondary" />
                </ListItem>
              )
            }
            next={showMore}
            scrollableTarget="entity-search-infinite-hits-list"
          >
            <List>
              {!items?.length && isFirstPage && !isSearching ? (
                <ListItem>
                  <ListItemText primary="No Results Found" />
                </ListItem>
              ) : (
                <>
                  {items.map((hit) => (
                    <ListOption
                      hit={hit}
                      key={hit.id}
                      onClick={handleListOptionClick}
                      {...listOptionParams}
                    />
                  ))}
                </>
              )}
            </List>
          </InfiniteScroll>
        )}
      </div>
    </Menu>
  );
};

const EntitySearchMenu = ({
  filters,
  hitsPerPage = 40,
  indexName,
  paginationTotalHits = 2000,
  ...props
}) => {
  const { instantSearchClient } = useMeilisearch();
  const [searchState, setSearchState] = useState({});

  if (!instantSearchClient) return null;

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={instantSearchClient}
      searchState={searchState}
      onSearchStateChange={setSearchState}
    >
      <Configure
        filters={filters}
        hitsPerPage={hitsPerPage}
        paginationTotalHits={paginationTotalHits}
      />

      <EntitySearchMenuRoot indexName={indexName} {...props} />
    </InstantSearch>
  );
};

export default EntitySearchMenu;
