import {
  faCodeCommit,
  faCodeFork,
  faCodeMerge,
  faCodePullRequest,
  faLeftRight,
  faUpDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Button, ButtonGroup, IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Panel, ReactFlow, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { generateUniqueId, getLayoutedElements } from '../utils';
import WorkOrderIssueReCodeDialogReCodeActionsFlowEditDialog from './WorkOrderIssueReCodeDialogReCodeActionsFlowEditDialog';
import WorkOrderIssueReCodeDialogReCodeActionsFlowSourceNode from './WorkOrderIssueReCodeDialogReCodeActionsFlowSourceNode';
import WorkOrderIssueReCodeDialogReCodeActionsFlowTargetNode from './WorkOrderIssueReCodeDialogReCodeActionsFlowTargetNode';

const ButtonWithTooltip = ({ disabled, TooltipProps, ...ButtonProps }) => {
  const button = useMemo(
    () => <Button disabled={disabled} {...ButtonProps} />,
    [ButtonProps, disabled]
  );

  return disabled ? button : <Tooltip {...TooltipProps}>{button}</Tooltip>;
};

const WorkOrderIssueReCodeDialogReCodeActionsFlow = ({
  facility,
  targetBudgetHasChanged,
  targetBudgetProjectHasChanged,
  targetBudgetSpecialProjectHasChanged,
  workOrderIssue,
  workOrderIssueReCodeActionTypes,
}) => {
  const [direction, setDirection] = useState('LR');
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { fitView } = useReactFlow();
  const mainTheme = useSelector(selectMainTheme);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const reCodeActionsCache = useRef(null);
  const [reCodeActionDialogProps, setReCodeActionDialogProps] = useState(null);
  const [selectedSourceActionItemIds, setSelectedSourceActionItemIds] = useState([]);
  const themeMode = useMemo(() => mainTheme?.palette.mode, [mainTheme?.palette.mode]);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const reCodeActionsFieldArray = useFieldArray({
    control,
    name: 'reCodeActions',
  });

  const watchFields = watch();

  const workOrderIssueReCodeActionTypeAccept = useMemo(
    () =>
      workOrderIssueReCodeActionTypes?.find(
        (workOrderIssueReCodeActionType) => workOrderIssueReCodeActionType.enum === 'ACCEPT'
      ),
    [workOrderIssueReCodeActionTypes]
  );

  const workOrderIssueReCodeActionTypeMerge = useMemo(
    () =>
      workOrderIssueReCodeActionTypes?.find(
        (workOrderIssueReCodeActionType) => workOrderIssueReCodeActionType.enum === 'MERGE'
      ),
    [workOrderIssueReCodeActionTypes]
  );

  const workOrderIssueReCodeActionTypeReplace = useMemo(
    () =>
      workOrderIssueReCodeActionTypes?.find(
        (workOrderIssueReCodeActionType) => workOrderIssueReCodeActionType.enum === 'REPLACE'
      ),
    [workOrderIssueReCodeActionTypes]
  );

  const workOrderIssueReCodeActionTypeSplit = useMemo(
    () =>
      workOrderIssueReCodeActionTypes?.find(
        (workOrderIssueReCodeActionType) => workOrderIssueReCodeActionType.enum === 'SPLIT'
      ),
    [workOrderIssueReCodeActionTypes]
  );

  const handleClearSelectedSourceActionItemIds = useCallback(() => {
    setSelectedSourceActionItemIds([]);
  }, []);

  const handleCloseReCodeActionDialog = useCallback(() => {
    setReCodeActionDialogProps({
      open: false,
    });
  }, []);

  const handleOpenReCodeActionDialog = useCallback(({ reCodeAction }) => {
    setReCodeActionDialogProps({
      open: true,
      reCodeAction,
    });
  }, []);

  const handleCreateReCodeAction = useCallback(
    ({ sourceActionItemIds, type }) => {
      const sourceActionItems = workOrderIssue?.actionItems?.filter(({ id }) =>
        sourceActionItemIds?.includes(id)
      );

      let targetActionItems = [];

      if (type?.enum === workOrderIssueReCodeActionTypeAccept?.enum) {
        const sourceActionItem = _.first(sourceActionItems || []);

        if (sourceActionItem) {
          targetActionItems = [
            ...targetActionItems,
            {
              codingStrings: [],
              commonStandardFlatRate: {
                quantity: sourceActionItem.commonStandardFlatRate?.quantity,
              },
              commonStandardHourlyRate: {
                hours: sourceActionItem.commonStandardHourlyRate?.hours,
                rateAmount: sourceActionItem.commonStandardHourlyRate?.rateAmount,
                technicians: sourceActionItem.commonStandardHourlyRate?.technicians,
              },
              commonStandardRateType: {
                label: sourceActionItem.commonStandardRateType?.name,
                result: sourceActionItem.commonStandardRateType,
                value: sourceActionItem.commonStandardRateType?.id,
              },
              costCode: sourceActionItem.costCode,
              description: sourceActionItem.description,
              id: generateUniqueId(),
              notToExceedAmount: sourceActionItem.notToExceedAmount || null,
              workOrderIssue: { id: workOrderIssue?.id },
            },
          ];
        }
      } else if (type?.enum === workOrderIssueReCodeActionTypeMerge?.enum) {
        if (sourceActionItems.length) {
          targetActionItems = [
            ...targetActionItems,
            {
              codingStrings: [],
              commonStandardFlatRate: {
                quantity: sourceActionItems.reduce(
                  (acc, sourceActionItem) =>
                    acc + sourceActionItem.commonStandardFlatRate?.quantity ?? 0,
                  0
                ),
              },
              // ROADMAP: Handle Hourly Rate
              commonStandardHourlyRate: null,
              commonStandardRateType: {
                label: sourceActionItems[0].commonStandardRateType?.name,
                result: sourceActionItems[0].commonStandardRateType,
                value: sourceActionItems[0].commonStandardRateType?.id,
              },
              costCode: null,
              description: '',
              id: generateUniqueId(),
              notToExceedAmount: null,
              workOrderIssue: { id: workOrderIssue?.id },
            },
          ];
        }
      } else if (type?.enum === workOrderIssueReCodeActionTypeReplace?.enum) {
        const sourceActionItem = _.first(sourceActionItems || []);

        if (sourceActionItem) {
          targetActionItems = [
            ...targetActionItems,
            {
              codingStrings: [],
              commonStandardFlatRate: {
                quantity: sourceActionItem.commonStandardFlatRate?.quantity,
              },
              commonStandardHourlyRate: {
                hours: sourceActionItem.commonStandardHourlyRate?.hours,
                rateAmount: sourceActionItem.commonStandardHourlyRate?.rateAmount,
                technicians: sourceActionItem.commonStandardHourlyRate?.technicians,
              },
              commonStandardRateType: {
                label: sourceActionItem.commonStandardRateType?.name,
                result: sourceActionItem.commonStandardRateType,
                value: sourceActionItem.commonStandardRateType?.id,
              },
              costCode: null,
              description: sourceActionItem.description,
              id: generateUniqueId(),
              notToExceedAmount: sourceActionItem.notToExceedAmount || null,
              workOrderIssue: { id: workOrderIssue?.id },
            },
          ];
        }
      } else if (type?.enum === workOrderIssueReCodeActionTypeSplit?.enum) {
        const splitInto = 2;
        const sourceActionItem = _.first(sourceActionItems || []);

        if (sourceActionItem) {
          targetActionItems = [
            ...targetActionItems,
            ...[...Array(splitInto).keys()].map(() => ({
              codingStrings: [],
              commonStandardFlatRate: {
                quantity: parseFloat(
                  (sourceActionItem.commonStandardFlatRate?.quantity / splitInto).toFixed(2)
                ),
              },
              // ROADMAP: Handle Hourly Rate
              commonStandardHourlyRate: null,
              commonStandardRateType: {
                label: sourceActionItem.commonStandardRateType?.name,
                result: sourceActionItem.commonStandardRateType,
                value: sourceActionItem.commonStandardRateType?.id,
              },
              costCode: null,
              description: '',
              id: generateUniqueId(),
              notToExceedAmount: null,
              workOrderIssue: { id: workOrderIssue?.id },
            })),
          ];
        }
      }

      handleOpenReCodeActionDialog({
        reCodeAction: {
          id: null,
          sourceActionItems,
          targetActionItems,
          type,
        },
      });
    },
    [
      handleOpenReCodeActionDialog,
      workOrderIssue?.actionItems,
      workOrderIssue?.id,
      workOrderIssueReCodeActionTypeAccept?.enum,
      workOrderIssueReCodeActionTypeMerge?.enum,
      workOrderIssueReCodeActionTypeReplace?.enum,
      workOrderIssueReCodeActionTypeSplit?.enum,
    ]
  );

  const handleDeleteReCodeAction = useCallback(
    ({ reCodeAction }) => {
      handleCloseReCodeActionDialog();
      handleClearSelectedSourceActionItemIds();

      const watchReCodeActionsIndex = watchFields?.reCodeActions?.findIndex(
        ({ id }) => id === reCodeAction.id
      );

      if (watchReCodeActionsIndex !== -1) {
        reCodeActionsFieldArray.remove(watchReCodeActionsIndex);
      }
    },
    [
      handleCloseReCodeActionDialog,
      handleClearSelectedSourceActionItemIds,
      reCodeActionsFieldArray,
      watchFields?.reCodeActions,
    ]
  );

  const handleSaveReCodeAction = useCallback(
    ({ reCodeAction }) => {
      handleCloseReCodeActionDialog();
      handleClearSelectedSourceActionItemIds();

      const watchReCodeActionsIndex = watchFields?.reCodeActions?.findIndex(
        ({ id }) => id === reCodeAction.id
      );

      if (watchReCodeActionsIndex !== -1) {
        reCodeActionsFieldArray.update(watchReCodeActionsIndex, reCodeAction);
      } else {
        reCodeActionsFieldArray.append(reCodeAction);
      }
    },
    [
      handleCloseReCodeActionDialog,
      handleClearSelectedSourceActionItemIds,
      reCodeActionsFieldArray,
      watchFields?.reCodeActions,
    ]
  );

  const handleToggleDirection = useCallback(
    () => setDirection(direction === 'LR' ? 'TB' : 'LR'),
    [direction]
  );

  const handleUpdateReCodeAction = useCallback(
    ({ reCodeAction }) => {
      handleOpenReCodeActionDialog({ reCodeAction });
    },
    [handleOpenReCodeActionDialog]
  );

  useEffect(() => {
    if (
      direction &&
      fitView &&
      handleUpdateReCodeAction &&
      setEdges &&
      setNodes &&
      watchFields?.reCodeActions &&
      workOrderIssue?.actionItems?.length
    ) {
      const layouted = getLayoutedElements(
        _.uniqBy(
          [
            ...(workOrderIssue?.actionItems?.map((actionItem) => ({
              data: {
                actionItem,
                handleUpdateReCodeAction,
                reCodeAction: watchFields?.reCodeActions?.find(({ sourceActionItems }) =>
                  sourceActionItems?.map(({ id }) => id)?.includes(actionItem?.id)
                ),
                setSelectedSourceActionItemIds,
              },
              id: actionItem.id,
              position: { x: 0, y: 0 },
              type: 'sourceNode',
            })) ?? []),
            ..._.flatten(
              watchFields?.reCodeActions?.map((reCodeAction) =>
                reCodeAction?.targetActionItems?.map((targetActionItem) => ({
                  data: {
                    handleUpdateReCodeAction,
                    reCodeAction,
                    targetActionItem,
                  },
                  id: targetActionItem.id,
                  position: { x: 0, y: 0 },
                  type: 'targetNode',
                }))
              ) ?? []
            ),
          ],
          'id'
        ),
        _.uniqBy(
          [
            ..._.flattenDeep(
              watchFields?.reCodeActions?.map(({ sourceActionItems, targetActionItems }) =>
                sourceActionItems?.map((sourceActionItem) =>
                  targetActionItems?.map((targetActionItem) => ({
                    id: `e-${sourceActionItem.id}-${targetActionItem.id}`,
                    source: sourceActionItem.id,
                    target: targetActionItem.id,
                    type: 'smoothstep',
                  }))
                )
              ) ?? []
            ),
          ],
          'id'
        ),
        {
          direction,
        }
      );

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      setTimeout(() => {
        let focusNodes = [];

        if (
          fitView &&
          watchFields?.reCodeActions?.length &&
          !_.isEqual(
            watchFields?.reCodeActions?.map(({ id }) => id)?.sort(),
            reCodeActionsCache.current?.map(({ id }) => id)?.sort()
          )
        ) {
          const addedActions = _.differenceBy(
            watchFields?.reCodeActions,
            reCodeActionsCache.current,
            'id'
          );
          const removedActions = _.differenceBy(
            reCodeActionsCache.current,
            watchFields?.reCodeActions,
            'id'
          );

          if (addedActions.length) {
            focusNodes = [
              ..._.flatten(
                addedActions?.map(
                  ({ sourceActionItems }) => sourceActionItems?.map(({ id }) => ({ id })) ?? []
                ) ?? []
              ),
              ..._.flatten(
                addedActions?.map(
                  ({ targetActionItems }) => targetActionItems?.map(({ id }) => ({ id })) ?? []
                ) ?? []
              ),
            ];
          } else if (removedActions.length) {
            // PLACEHOLDER
          } else {
            // PLACEHOLDER
          }

          reCodeActionsCache.current = [...(watchFields?.reCodeActions ?? [])];
        } else if (layouted.edges?.length) {
          focusNodes = [{ id: layouted.edges[0].source }, { id: layouted.edges[0].target }];
        } else if (layouted.nodes?.length) {
          focusNodes = [{ id: layouted.nodes[0].id }];
        }

        window.requestAnimationFrame(() => {
          fitView({ nodes: focusNodes });
        });
      }, 100);
    }
  }, [
    direction,
    fitView,
    handleUpdateReCodeAction,
    setEdges,
    setNodes,
    watchFields?.reCodeActions,
    workOrderIssue?.actionItems,
  ]);

  return (
    <ReactFlow
      colorMode={themeMode}
      edges={edges}
      fitView
      maxZoom={1}
      minZoom={1}
      nodes={nodes}
      nodesConnectable={false}
      nodesDraggable={false}
      nodeTypes={{
        sourceNode: WorkOrderIssueReCodeDialogReCodeActionsFlowSourceNode,
        targetNode: WorkOrderIssueReCodeDialogReCodeActionsFlowTargetNode,
      }}
      panOnDrag={false}
      panOnScroll
      panOnScrollMode="free"
      onEdgesChange={onEdgesChange}
      onNodesChange={onNodesChange}
    >
      <Panel position="top-left">
        <Tooltip title="Toggle Direction">
          <IconButton
            size="large"
            sx={{
              background: themeMode === 'light' ? grey[100] : grey[900],
              borderColor: themeMode === 'light' ? grey[200] : grey[800],
              borderStyle: 'solid',
              borderWidth: '2px',
              padding: '10px',
            }}
            onClick={handleToggleDirection}
          >
            {direction === 'LR' && <FontAwesomeIcon className="w-16 h-16" icon={faLeftRight} />}
            {direction === 'TB' && <FontAwesomeIcon className="w-16 h-16" icon={faUpDown} />}
          </IconButton>
        </Tooltip>
      </Panel>

      <Panel position="top-right">
        <ButtonGroup disableElevation variant="contained">
          <ButtonWithTooltip
            color="secondary"
            disabled={
              selectedSourceActionItemIds?.length !== 1 ||
              targetBudgetHasChanged ||
              targetBudgetProjectHasChanged ||
              targetBudgetSpecialProjectHasChanged
            }
            TooltipProps={{
              title: workOrderIssueReCodeActionTypeAccept?.name,
            }}
            onClick={() =>
              handleCreateReCodeAction({
                sourceActionItemIds: selectedSourceActionItemIds,
                type: workOrderIssueReCodeActionTypeAccept,
              })
            }
          >
            <FontAwesomeIcon className="w-16 h-16" icon={faCodeCommit} />
          </ButtonWithTooltip>

          <ButtonWithTooltip
            color="secondary"
            disabled={selectedSourceActionItemIds?.length < 2}
            TooltipProps={{
              title: workOrderIssueReCodeActionTypeMerge?.name,
            }}
            onClick={() =>
              handleCreateReCodeAction({
                sourceActionItemIds: selectedSourceActionItemIds,
                type: workOrderIssueReCodeActionTypeMerge,
              })
            }
          >
            <FontAwesomeIcon className="w-16 h-16" icon={faCodeMerge} />
          </ButtonWithTooltip>

          <ButtonWithTooltip
            color="secondary"
            disabled={selectedSourceActionItemIds?.length !== 1}
            TooltipProps={{
              title: workOrderIssueReCodeActionTypeReplace?.name,
            }}
            onClick={() =>
              handleCreateReCodeAction({
                sourceActionItemIds: selectedSourceActionItemIds,
                type: workOrderIssueReCodeActionTypeReplace,
              })
            }
          >
            <FontAwesomeIcon className="w-16 h-16" icon={faCodePullRequest} />
          </ButtonWithTooltip>

          <ButtonWithTooltip
            color="secondary"
            disabled={selectedSourceActionItemIds?.length !== 1}
            TooltipProps={{
              title: workOrderIssueReCodeActionTypeSplit?.name,
            }}
            onClick={() =>
              handleCreateReCodeAction({
                sourceActionItemIds: selectedSourceActionItemIds,
                type: workOrderIssueReCodeActionTypeSplit,
              })
            }
          >
            <FontAwesomeIcon className="w-16 h-16" icon={faCodeFork} />
          </ButtonWithTooltip>
        </ButtonGroup>
      </Panel>

      <WorkOrderIssueReCodeDialogReCodeActionsFlowEditDialog
        {...(reCodeActionDialogProps || {})}
        facility={facility}
        targetBudget={watchFields?.targetBudget}
        targetBudgetProject={watchFields?.targetBudgetProject}
        targetBudgetSpecialProject={watchFields?.targetBudgetSpecialProject}
        workOrderIssueReCodeActionTypeAccept={workOrderIssueReCodeActionTypeAccept}
        workOrderIssueReCodeActionTypeMerge={workOrderIssueReCodeActionTypeMerge}
        workOrderIssueReCodeActionTypeReplace={workOrderIssueReCodeActionTypeReplace}
        workOrderIssueReCodeActionTypeSplit={workOrderIssueReCodeActionTypeSplit}
        onClose={handleCloseReCodeActionDialog}
        onDelete={handleDeleteReCodeAction}
        onSave={handleSaveReCodeAction}
      />
    </ReactFlow>
  );
};

export default WorkOrderIssueReCodeDialogReCodeActionsFlow;
