import { gql } from '@apollo/client';
import { COST_CODE_VIEW_DRAWER_COST_CODE_FRAGMENT } from './fragments';

export const FETCH_COST_CODE_VIEW_DRAWER_DATA = gql`
  query FetchCostCodeViewDrawerData($where: CostCodeWhereUniqueInput!) {
    costCode(where: $where) {
      id
      ...CostCodeViewDrawerCostCodeFragment
    }
  }
  ${COST_CODE_VIEW_DRAWER_COST_CODE_FRAGMENT}
`;

export const UPDATE_COST_CODE = gql`
  mutation UpdateCostCode($where: CostCodeWhereUniqueInput!, $data: CostCodeUpdateInput!) {
    updateCostCode(where: $where, data: $data) {
      id
      ...CostCodeViewDrawerCostCodeFragment
    }
  }
  ${COST_CODE_VIEW_DRAWER_COST_CODE_FRAGMENT}
`;
