import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { jwtService } from 'app/providers/auth/services';
import { EntitySearchMenu } from 'app/shared-components/EntitySearch';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { UserMenuTeamHitsListOption, UserMenuThemeModeMenu } from './components';

const UserMenu = () => {
  const [defaultTeam, setDefaultTeam] = useState(null);
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const [teamMenu, setTeamMenu] = useState(null);
  const [themeModeMenu, setThemeModeMenu] = useState(null);
  const user = useSelector(selectUser);
  const [userMenu, setUserMenu] = useState(null);

  const [
    fetchUserMenuData,
    { data: userMenuData, loading: userMenuLoading, refetch: userMenuRefetch },
  ] = useLazyQuery(
    gql`
      query FetchUserMenuData {
        userDefaultTeam {
          id
          isOrganizationAdmin
          name
          owner {
            id
            name
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setFetched(true),
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Fetching User Data',
            variant: 'error',
          })
        );
      },
    }
  );

  const [setUserDefaultTeam, { loading: setUserDefaultTeamLoading }] = useMutation(
    gql`
      mutation SetUserDefaultTeam($data: UserSetDefaultTeamInput!) {
        setUserDefaultTeam(data: $data) {
          id
          isOrganizationAdmin
          name
          owner {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Team Successfully Set As Default',
            variant: 'success',
          })
        );

        if (data.setUserDefaultTeam) {
          setDefaultTeam(data.setUserDefaultTeam);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Setting Team As Default', variant: 'error' }));
      },
    }
  );

  const loading = useMemo(
    () => [setUserDefaultTeamLoading, userMenuLoading].includes(true),
    [setUserDefaultTeamLoading, userMenuLoading]
  );

  useEffect(() => {
    if (userMenuData?.userDefaultTeam) {
      setDefaultTeam(userMenuData?.userDefaultTeam);
    }
  }, [userMenuData?.userDefaultTeam]);

  useEffect(() => {
    if (fetchUserMenuData && userMenu) {
      fetchUserMenuData();
    }
  }, [fetchUserMenuData, userMenu]);

  const handleChangeTeam = ({ teamId }) =>
    setTimeout(() => jwtService.reassignSession({ teamId }), 250);

  const handleCloseTeamMenu = () => setTeamMenu(null);

  const handleCloseThemeModeMenu = () => setThemeModeMenu(null);

  const handleCloseUserMenu = () => setUserMenu(null);

  const handleOpenTeamMenu = (event) => setTeamMenu(event.currentTarget);

  const handleOpenThemeModeMenu = (event) => setThemeModeMenu(event.currentTarget);

  const handleOpenUserMenu = (event) => setUserMenu(event.currentTarget);

  const handleSetDefaultTeam = ({ teamId }) =>
    setUserDefaultTeam({ variables: { data: { team: { id: teamId } } } });

  if (!user) return null;

  return (
    <>
      <Button className="p-0 ml-8 min-h-40 min-w-40" color="inherit" onClick={handleOpenUserMenu}>
        {user.data.photoURL ? (
          <Avatar className="md:mx-4" alt="user photo" src={user.data.photoURL} />
        ) : (
          <Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
        )}
      </Button>

      <Popover
        anchorEl={userMenu}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        classes={{
          paper: 'py-8',
        }}
        open={Boolean(userMenu)}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        onClose={handleCloseUserMenu}
      >
        <div className="w-288">
          {!user.role || user.role.length === 0 ? (
            <>
              <MenuItem component={Link} to="/sign-in" role="button">
                <ListItemText primary="Sign In" />
              </MenuItem>

              <MenuItem component={Link} to="/sign-up" role="button">
                <ListItemText primary="Sign up" />
              </MenuItem>
            </>
          ) : (
            <>
              <div className="mx-16 mt-8">
                <Typography className="text-10 font-500 uppercase">Account</Typography>
              </div>

              <ListItem>
                <ListItemAvatar>
                  {user.data?.photoURL ? (
                    <Avatar className="md:mx-4" alt="user photo" src={user.data?.photoURL} />
                  ) : (
                    <Avatar className="md:mx-4">{user.data?.displayName?.[0]}</Avatar>
                  )}
                </ListItemAvatar>

                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  primary={user.data?.displayName}
                  secondary={user.data?.email}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>

              <MenuItem selected={Boolean(teamMenu)} onClick={handleOpenTeamMenu}>
                <ListItemText
                  className="flex-1 mr-16"
                  primary={user.data?.team?.name}
                  primaryTypographyProps={{ noWrap: true }}
                />

                <FontAwesomeIcon icon={teamMenu ? faChevronDown : faChevronRight} size="sm" />
              </MenuItem>

              <Divider />

              <div className="mx-16 mt-12 mb-8">
                <Typography className="text-10 font-500 uppercase">HYVE FSM</Typography>
              </div>

              {/* ROADMAP: Implement or Remove */}
              <MenuItem disabled>
                <ListItemText primary="Profile" />
              </MenuItem>

              {/* ROADMAP: Implement or Remove */}
              <MenuItem disabled>
                <ListItemText primary="Personal Settings" />
              </MenuItem>

              <MenuItem selected={Boolean(themeModeMenu)} onClick={handleOpenThemeModeMenu}>
                <ListItemText className="flex-1 mr-16" primary="Theme" />

                <FontAwesomeIcon icon={themeModeMenu ? faChevronDown : faChevronRight} size="sm" />
              </MenuItem>

              <Divider />

              <MenuItem component={NavLink} to="/sign-out" onClick={handleCloseUserMenu}>
                <ListItemText primary="Sign Out" />
              </MenuItem>
            </>
          )}
        </div>
      </Popover>

      <EntitySearchMenu
        anchorEl={teamMenu}
        indexName="teams"
        ListOption={UserMenuTeamHitsListOption}
        listOptionParams={{
          defaultTeam,
          loading,
          onChangeTeam: handleChangeTeam,
          onSetDefaultTeam: handleSetDefaultTeam,
        }}
        nameField="name"
        onClose={handleCloseTeamMenu}
      />

      <UserMenuThemeModeMenu anchorEl={themeModeMenu} onClose={handleCloseThemeModeMenu} />
    </>
  );
};

export default UserMenu;
