import { useMutation } from '@apollo/client';
import { Switch } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  ADD_BUDGET_SPECIAL_PROJECT_SERVICE_COMPLETION_ITEM,
  REMOVE_BUDGET_SPECIAL_PROJECT_SERVICE_COMPLETION_ITEM,
} from '../queries';

const BudgetSpecialProjectViewScreenCompletionItemActiveCellRenderer = (params) => {
  const dispatch = useDispatch();

  const [
    addBudgetSpecialProjectServiceCompletionItem,
    { loading: addBudgetSpecialProjectServiceCompletionItemLoading },
  ] = useMutation(ADD_BUDGET_SPECIAL_PROJECT_SERVICE_COMPLETION_ITEM, {
    onCompleted: (data) => {
      dispatch(
        showMessage({
          message: 'Completion Item Successfully Added',
          variant: 'success',
        })
      );

      params.setValue([
        ...(params.value || []),
        {
          budgetSpecialProject: {
            id: data.addBudgetSpecialProjectServiceCompletionItem?.id,
            name: data.addBudgetSpecialProjectServiceCompletionItem?.name,
          },
        },
      ]);
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Adding Completion Item', variant: 'error' }));
    },
  });

  const [
    removeBudgetSpecialProjectServiceCompletionItem,
    { loading: removeBudgetSpecialProjectServiceCompletionItemLoading },
  ] = useMutation(REMOVE_BUDGET_SPECIAL_PROJECT_SERVICE_COMPLETION_ITEM, {
    onCompleted: (data) => {
      dispatch(
        showMessage({
          message: 'Completion Item Successfully Removed',
          variant: 'success',
        })
      );

      params.setValue(
        (params.value || []).filter(
          ({ budgetSpecialProject }) =>
            budgetSpecialProject?.id !== data.removeBudgetSpecialProjectServiceCompletionItem?.id
        )
      );
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Removing Completion Item', variant: 'error' }));
    },
  });

  const checked = useMemo(
    () =>
      params?.value
        ?.map(({ budgetSpecialProject }) => budgetSpecialProject?.id)
        .includes(params?.budgetSpecialProjectId),
    [params?.budgetSpecialProjectId, params?.value]
  );

  const loading = useMemo(
    () =>
      [
        addBudgetSpecialProjectServiceCompletionItemLoading,
        removeBudgetSpecialProjectServiceCompletionItemLoading,
      ].includes(true),
    [
      addBudgetSpecialProjectServiceCompletionItemLoading,
      removeBudgetSpecialProjectServiceCompletionItemLoading,
    ]
  );

  const handleChange = async (event) => {
    const {
      target: { checked: _checked },
    } = event;

    if (_checked && params.budgetSpecialProjectId && params.data?.id) {
      await addBudgetSpecialProjectServiceCompletionItem({
        variables: {
          where: { id: params.budgetSpecialProjectId },
          data: { serviceCompletionItems: [{ id: params.data?.id }] },
        },
      });
    } else if (!_checked && params.budgetSpecialProjectId && params.data?.id) {
      await removeBudgetSpecialProjectServiceCompletionItem({
        variables: {
          where: { id: params.budgetSpecialProjectId },
          data: { serviceCompletionItems: [{ id: params.data?.id }] },
        },
      });
    }
  };

  return (
    <div className="flex justify-center">
      <Switch checked={!!checked} color="success" disabled={loading} onChange={handleChange} />
    </div>
  );
};

export default BudgetSpecialProjectViewScreenCompletionItemActiveCellRenderer;
