import _ from '@lodash';
import { CommonChatChannelListPreviewTemp } from 'app/shared-components/Common/CommonChat';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  ChannelList,
  ChannelListMessenger,
  getChannel,
  useChannelListContext,
  useChatContext,
} from 'stream-chat-react';

const ProgramCampaignViewScreenChannelListMessenger = (props) => {
  const { client, channel, setActiveChannel } = useChatContext();
  const { setChannels } = useChannelListContext();
  const init = useRef(false);
  const { channelType = 'service_ticket', viewId: channelId } = useParams();

  useEffect(() => {
    let subscription;

    if (!channelId || !channelType) {
      setActiveChannel(null);
    } else if (client && (!channel?.id || channel?.id !== channelId)) {
      if (init.current) {
        setActiveChannel(client.channel(channelType, channelId));
      } else {
        subscription = client.on('channels.queried', (event) => {
          const loadedChannelData = event.queriedChannels?.channels.find(
            (response) => response.channel.id === channelId
          );

          if (loadedChannelData) {
            setActiveChannel(client.channel(channelType, channelId));
            subscription?.unsubscribe();
            return;
          }

          // eslint-disable-next-line consistent-return
          return getChannel({ client, id: channelId, type: channelType }).then(
            (newActiveChannel) => {
              setActiveChannel(newActiveChannel);
              setChannels((channels) => {
                return [
                  newActiveChannel,
                  ...channels.filter((ch) => ch.data?.cid !== newActiveChannel.data?.cid),
                ];
              });
            }
          );
        });
      }
    }

    init.current = true;

    return () => {
      subscription?.unsubscribe();
    };
  }, [channel?.id, channelId, channelType, client, setActiveChannel, setChannels]);

  return <ChannelListMessenger {...props} />;
};

const ProgramCampaignViewScreenChannelListWidget = ({ programCampaign }) => {
  // const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [serviceTicketChatChannelType, setServiceTicketChatChannelType] = useState(null);
  const user = useSelector(selectUser);

  const filters = useMemo(
    () => ({
      // TODO:
      // serviceTicketId: !_.isEmpty(programCampaign?.serviceTickets) && {
      //   $in: programCampaign?.serviceTickets?.map(({ id }) => id) || [],
      // },
      type: 'service_ticket',
      members: { $in: [user?.id] },
    }),
    [programCampaign?.serviceTickets, user?.id]
  );

  const options = useMemo(() => ({}), []);

  const sort = useMemo(
    () => [{ has_unread: -1 }, { last_message_at: -1 }, { last_updated: -1 }],
    []
  );

  return (
    <div className="h-384">
      <ChannelList
        // TODO:
        // channelRenderFilterFn={(channels) =>
        //   channels.filter(
        //     (channel) =>
        //       channel.data?.serviceTicketId === serviceTicket?.id &&
        //       channel.data?.type === 'service_ticket'
        //   )
        // }
        filters={filters}
        List={ProgramCampaignViewScreenChannelListMessenger}
        options={options}
        // TODO:
        Preview={CommonChatChannelListPreviewTemp}
        setActiveChannelOnMount={false}
        sort={sort}
      />
    </div>
  );
};

export default ProgramCampaignViewScreenChannelListWidget;
