import { gql, useApolloClient } from '@apollo/client';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { CostCodeHitsListOption } from 'app/shared-components/CostCode';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { selectUser } from 'app/store/userSlice';
import { capitalCase } from 'change-case';
import clsx from 'clsx';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { calculateActionItemsTotal, generateUniqueId } from '../utils';

const defaultValues = {
  id: null,
  sourceActionItems: [],
  targetActionItems: [],
  type: null,
};

const buildSchema = ({ user }) => {
  yup.addMethod(yup.number, 'maxDigitsAfterDecimal', function maxDigitsAfterDecimal() {
    return this.test(
      'maxDigitsAfterDecimalTest',
      'Number field must have 2 digits after decimal or less',
      (number) => (_.isNumber(number) ? /^\d+(\.\d{1,2})?$/.test(number) : true)
    );
  });

  return yup
    .object()
    .shape({
      id: yup.string().nullable().required('An ID must be provided'),
      sourceActionItems: yup
        .array()
        .of(yup.object().nullable().required('A source action item must be provided'))
        .min(1, 'Select at least one source action item')
        .required('Source action items must be provided'),
      targetActionItems: yup
        .array()
        .of(
          yup.object().shape({
            codingStrings: yup
              .array()
              .of(yup.object().nullable().optional())
              .required('Coding strings must be provided'),
            commonStandardRateType: yup
              .object()
              .nullable()
              .required('A standard rate type must be provided.'),
            commonStandardFlatRate: yup.mixed().when('commonStandardRateType', {
              is: (val) => val?.result.enum === 'FLAT_RATE',
              then: yup.object().shape({
                quantity: yup
                  .number()
                  .nullable()
                  .min(0.01, 'A value must be provided.')
                  .maxDigitsAfterDecimal()
                  .required('An amount must be provided.'),
              }),
            }),
            commonStandardHourlyRate: yup.mixed().when('commonStandardRateType', {
              is: (val) => val?.result.enum === 'HOURLY_RATE',
              then: yup.object().shape({
                technicians: yup
                  .number()
                  .nullable()
                  .min(1, 'A value must be provided.')
                  .required('A value must be provided.'),
                hours: yup
                  .number()
                  .nullable()
                  .min(0.01, 'A value must be provided.')
                  .maxDigitsAfterDecimal()
                  .required('A value must be provided.'),
                rateAmount: yup
                  .number()
                  .nullable()
                  .min(0.01, 'A value must be provided.')
                  .maxDigitsAfterDecimal()
                  .required('A value must be provided.'),
              }),
            }),
            costCode: yup
              .object()
              .nullable()
              .when('_user', {
                is: () => ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization.type.enum),
                then: (schema) => schema.required('A cost code must be provided'),
              }),
            description: yup.string().nullable().optional(),
            notToExceedAmount: yup
              .number()
              .nullable()
              .min(0.01, 'A value must be provided.')
              .maxDigitsAfterDecimal()
              .optional(),
          })
        )
        .min(1, 'Select at least one target action item')
        .required('Target action items must be provided'),
      type: yup.object().nullable().required('A type must be provided'),
    })
    .test(
      'testSourceTargetActionItemsTotal',
      'Source and target action items total must match',
      (value) => {
        if (!value) return false;

        const sourceActionItemsTotal = calculateActionItemsTotal(value?.sourceActionItems);
        const targetActionItemsTotal = calculateActionItemsTotal(value?.targetActionItems);

        return sourceActionItemsTotal === targetActionItemsTotal;
      }
    );
};

const WorkOrderIssueReCodeDialogReCodeActionsFlowEditDialog = ({
  facility,
  open,
  reCodeAction,
  targetBudget,
  targetBudgetProject,
  targetBudgetSpecialProject,
  workOrderIssueReCodeActionTypeAccept,
  workOrderIssueReCodeActionTypeMerge,
  workOrderIssueReCodeActionTypeReplace,
  workOrderIssueReCodeActionTypeSplit,
  onClose,
  onDelete,
  onSave,
}) => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const [editingMode, setEditingMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);

  const schema = useMemo(() => buildSchema({ user }), [user]);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const sourceActionItemsFieldArray = useFieldArray({
    control,
    name: 'sourceActionItems',
  });

  const targetActionItemsFieldArray = useFieldArray({
    control,
    name: 'targetActionItems',
  });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const costCodeFilter = useMemo(() => {
    if (!targetBudget?.id || !targetBudgetProject?.id) {
      return null;
    }

    let _costCodeFilter = [`budgetProjects.budgetProject.id = '${targetBudgetProject?.id}'`];

    if (targetBudgetSpecialProject?.id && targetBudgetSpecialProject?._count?.costCodes > 0) {
      _costCodeFilter = [
        ..._costCodeFilter,
        `budgetSpecialProjects.budgetSpecialProject.id = '${targetBudgetSpecialProject?.id}'`,
      ];
    }

    return _costCodeFilter;
  }, [
    targetBudget?.id,
    targetBudgetProject?.id,
    targetBudgetSpecialProject?._count?.costCodes,
    targetBudgetSpecialProject?.id,
  ]);

  const [getCostCodeCodingStringsLoading, setGetCostCodeCodingStringsLoading] = useState(false);
  const getCostCodeCodingStrings = useCallback(
    ({ costCode, totalAmount, onComplete, onData, onError }) => {
      if (
        apolloClient &&
        costCode?.id &&
        facility?.id &&
        targetBudget?.id &&
        targetBudgetProject?.id
      ) {
        setGetCostCodeCodingStringsLoading(true);

        apolloClient
          .query({
            fetchPolicy: 'network-only',
            query: gql`
              query WorkOrderIssueActionItemCodingStrings(
                $where: WorkOrderIssueActionItemCodingStringWhereInput!
              ) {
                workOrderIssueActionItemCodingStrings(where: $where) {
                  typeEnum
                  value
                }
              }
            `,
            variables: {
              where: {
                budget: { id: targetBudget?.id },
                budgetProject: { id: targetBudgetProject?.id },
                costCode: { id: costCode?.id },
                facility: { id: facility?.id },
                totalAmount,
              },
            },
          })
          .then(({ data }) => {
            if (typeof onData === 'function') {
              onData(data);
            }
          })
          .catch((err) => {
            if (typeof onError === 'function') {
              onError(err);
            }
          })
          .finally(() => {
            setGetCostCodeCodingStringsLoading(false);

            if (typeof onComplete === 'function') {
              onComplete();
            }
          });
      }
    },
    [apolloClient, facility?.id, targetBudget?.id, targetBudgetProject?.id]
  );

  const sourceActionItemsTotal = calculateActionItemsTotal(watchFields?.sourceActionItems);

  const targetActionItemsTotal = calculateActionItemsTotal(watchFields?.targetActionItems);

  useEffect(() => {
    if (getCostCodeCodingStrings && reCodeAction && reset && setValue) {
      const formData = {
        id: reCodeAction.id,
        sourceActionItems: reCodeAction.sourceActionItems ?? [],
        targetActionItems: reCodeAction.targetActionItems ?? [],
        type: reCodeAction.type,
      };

      if (!formData.id) {
        formData.id = generateUniqueId();

        setEditingMode('CREATE');
      } else {
        setEditingMode('UPDATE');
      }

      reset(formData);

      formData.targetActionItems.forEach(({ costCode, commonStandardFlatRate }, index) => {
        if (costCode?.id) {
          getCostCodeCodingStrings({
            costCode,
            // ROADMAP: Handle Hourly Rate
            totalAmount: commonStandardFlatRate?.quantity || 0,
            onData: ({ workOrderIssueActionItemCodingStrings }) => {
              if (workOrderIssueActionItemCodingStrings) {
                setValue(
                  `targetActionItems[${index}].codingStrings`,
                  workOrderIssueActionItemCodingStrings,
                  {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  }
                );
              }
            },
          });
        }
      });
    }
  }, [getCostCodeCodingStrings, reCodeAction, reset, setValue]);

  const handleAddTargetActionItem = () => {
    const sampleTargetActionItem = watchFields?.targetActionItems?.[0];

    if (sampleTargetActionItem) {
      targetActionItemsFieldArray.append({
        codingStrings: [],
        commonStandardFlatRate: {
          quantity: null,
        },
        // ROADMAP: Handle Hourly Rate
        commonStandardHourlyRate: null,
        commonStandardRateType: sampleTargetActionItem.commonStandardRateType,
        costCode: null,
        description: '',
        id: generateUniqueId(),
        notToExceedAmount: null,
        workOrderIssue: sampleTargetActionItem.workOrderIssue,
      });
    }
  };

  const handleClose = useCallback(() => {
    reset(defaultValues);

    if (typeof onClose === 'function') {
      onClose();
    }

    return null;
  }, [onClose, reset]);

  const handleDelete = useCallback(() => {
    reset(defaultValues);

    if (typeof onDelete === 'function') {
      onDelete({ reCodeAction });
    }

    return null;
  }, [onDelete, reCodeAction, reset]);

  const handleSave = useCallback(
    (data) => {
      reset(defaultValues);

      if (typeof onSave === 'function') {
        onSave({ reCodeAction: data });
      }

      return null;
    },
    [onSave, reset]
  );

  return (
    <Dialog classes={{ paper: 'w-full max-w-640 min-w-320 rounded-8' }} open={!!open}>
      <DialogTitle>Re-Code Action</DialogTitle>

      <DialogContent>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full"
            id="work-order-issue-re-code-dialog-re-code-actions-flow-edit-form"
            name="work-order-issue-re-code-dialog-re-code-actions-flow-edit-form"
            noValidate
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();

              handleSubmit((data) => handleSave(data))(e);
            }}
          >
            <div>
              <Typography className="text-16 font-500">Source Action Items</Typography>

              <List disablePadding>
                {sourceActionItemsFieldArray.fields.map((item, index) => (
                  <ListItem
                    className="px-0 py-24"
                    divider={index + 1 < sourceActionItemsFieldArray.fields?.length}
                    key={item.id}
                  >
                    <div className="flex flex-1 flex-col pl-6" style={{ minWidth: 174 }}>
                      {item.costCode?.accountingTaskCode ? (
                        <Typography className="text-11" noWrap>
                          {`${item.costCode?.accountingTaskCode.name} (${item.costCode?.accountingTaskCode.number})`}
                        </Typography>
                      ) : (
                        <Typography className="text-11" noWrap>
                          CSI Classification
                        </Typography>
                      )}

                      <Typography className="text-14" noWrap>
                        {item.costCode?.csiClassification
                          ? `${item.costCode?.csiClassification?.name} (${item.costCode?.csiClassification?.number})`
                          : 'Cost Code Not Set'}
                      </Typography>

                      <div className="mt-12">
                        {/* ROADMAP: Handle Multiple Coding Strings */}
                        {item.codingStrings?.[0] ? (
                          <>
                            <Typography className="text-11" noWrap>
                              {capitalCase(item.codingStrings[0].typeEnum)}
                            </Typography>

                            <Typography className="text-14" noWrap>
                              {item.codingStrings[0].value}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography className="text-11" noWrap>
                              Coding String
                            </Typography>

                            <Typography className="text-14" noWrap>
                              Not Set
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col items-end" style={{ minWidth: 84 }}>
                      <Typography className="text-11">Total</Typography>

                      <Typography className="text-14">
                        {numeral(item?.totalAmount).format('$0,0.00')}
                      </Typography>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>

            <Divider className="mb-24" />

            <div>
              <Typography className="mb-24 text-16 font-500">Target Action Items</Typography>

              <div>
                {targetActionItemsFieldArray.fields.map((item, index) => (
                  <div key={item.id}>
                    <div className="flex items-center mb-24">
                      <Controller
                        control={control}
                        name={`targetActionItems[${index}].costCode`}
                        render={({ field }) => (
                          <EntitySearchSelectFieldV2
                            {...field}
                            className="flex-1"
                            components={{
                              Option: CostCodeHitsListOption,
                            }}
                            error={_.get(errors, `targetActionItems[${index}].costCode`)}
                            filter={costCodeFilter}
                            getOptionLabel={(option) =>
                              `${option?.csiClassification?.number} - ${option?.csiClassification?.name}`
                            }
                            getOptionValue={(option) => option?.id}
                            indexName="cost_codes"
                            isClearable
                            isDisabled={
                              !costCodeFilter ||
                              getCostCodeCodingStringsLoading ||
                              ![
                                workOrderIssueReCodeActionTypeMerge?.enum,
                                workOrderIssueReCodeActionTypeReplace?.enum,
                                workOrderIssueReCodeActionTypeSplit?.enum,
                              ].includes(watchFields?.type?.enum)
                            }
                            isMulti={false}
                            placeholder="Select Cost Code..."
                            sort={['csiClassification.number:asc']}
                            onChange={(params) => {
                              if (params) {
                                getCostCodeCodingStrings({
                                  costCode: params,
                                  // ROADMAP: Handle Hourly Rate
                                  totalAmount:
                                    watchFields?.targetActionItems?.[index]?.commonStandardFlatRate
                                      ?.quantity || 0,
                                  onComplete: () => {
                                    field.onChange(params);
                                  },
                                  onData: ({ workOrderIssueActionItemCodingStrings }) => {
                                    if (workOrderIssueActionItemCodingStrings) {
                                      setValue(
                                        `targetActionItems[${index}].codingStrings`,
                                        workOrderIssueActionItemCodingStrings,
                                        {
                                          shouldDirty: true,
                                          shouldTouch: true,
                                          shouldValidate: true,
                                        }
                                      );
                                    }
                                  },
                                });
                              } else {
                                setValue(`targetActionItems[${index}].codingStrings`, [], {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  shouldValidate: true,
                                });

                                field.onChange(params);
                              }
                            }}
                          />
                        )}
                      />

                      {[workOrderIssueReCodeActionTypeSplit?.enum].includes(
                        watchFields?.type?.enum
                      ) &&
                        targetActionItemsFieldArray.fields?.length > 2 && (
                          <div className="ml-12">
                            <Tooltip title="Remove Action Item">
                              <IconButton
                                sx={{ width: 40, height: 40 }}
                                onClick={() => targetActionItemsFieldArray.remove(index)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                    </div>

                    <Controller
                      control={control}
                      name={`targetActionItems[${index}].codingStrings`}
                      render={({ field }) => (
                        <>
                          {watchFields?.targetActionItems?.[index]?.costCode && (
                            <Alert className="mb-24" severity="info">
                              {/* ROADMAP: Handle Multiple Coding Strings */}
                              {field.value?.[0] ? (
                                <>
                                  <Typography className="text-11" noWrap>
                                    {capitalCase(field.value[0].typeEnum)}
                                  </Typography>

                                  <Typography className="text-14" noWrap>
                                    {field.value[0].value}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography className="text-11" noWrap>
                                    Coding String
                                  </Typography>

                                  <Typography className="text-14" noWrap>
                                    Not Set
                                  </Typography>
                                </>
                              )}
                            </Alert>
                          )}
                        </>
                      )}
                    />

                    {/* ROADMAP: Handle Hourly Rate */}
                    {item?.commonStandardRateType?.result?.enum === 'FLAT_RATE' && (
                      <>
                        <Controller
                          control={control}
                          name={`targetActionItems[${index}].commonStandardFlatRate.quantity`}
                          render={({ field }) => (
                            <CommonCurrencyField
                              {...field}
                              className="mb-24"
                              control={control}
                              disabled={false}
                              error={_.get(
                                errors,
                                `targetActionItems[${index}].commonStandardFlatRate.quantity`
                              )}
                              inputProps={{
                                readOnly: ![workOrderIssueReCodeActionTypeSplit?.enum].includes(
                                  watchFields?.type?.enum
                                ),
                              }}
                              placeholder="Enter Amount..."
                            />
                          )}
                        />
                      </>
                    )}

                    {index + 1 < targetActionItemsFieldArray.fields?.length && (
                      <Divider className="mb-24" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <Divider className="mb-24" />

            <div className="flex mb-24">
              <div className="flex flex-1 items-center">
                {[workOrderIssueReCodeActionTypeSplit?.enum].includes(watchFields?.type?.enum) && (
                  <Button
                    color="inherit"
                    disabled={false}
                    variant="outlined"
                    onClick={handleAddTargetActionItem}
                  >
                    Add Action Item
                  </Button>
                )}
              </div>

              <div className="flex items-baseline justify-end">
                <Typography className="mr-12 text-14 font-500">Total:</Typography>

                <Typography
                  className={clsx(
                    { 'text-green': sourceActionItemsTotal === targetActionItemsTotal },
                    { 'text-red': sourceActionItemsTotal !== targetActionItemsTotal },
                    'text-18 font-600'
                  )}
                >
                  {targetActionItemsTotal ? numeral(targetActionItemsTotal).format('$0,0.00') : '-'}
                </Typography>
              </div>
            </div>
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        {editingMode === 'UPDATE' && (
          <div className="flex flex-1 items-center">
            <Button color="error" disabled={false} variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        )}

        <Button color="primary" onClick={handleClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={!isValid || loading}
          form="work-order-issue-re-code-dialog-re-code-actions-flow-edit-form"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkOrderIssueReCodeDialogReCodeActionsFlowEditDialog;
