import { useMutation } from '@apollo/client';
import { Switch } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ADD_BUDGET_FACILITY, REMOVE_BUDGET_FACILITY } from '../queries';

const BudgetViewScreenFacilityActiveCellRenderer = (params) => {
  const dispatch = useDispatch();

  const [addBudgetFacility, { loading: addBudgetFacilityLoading }] = useMutation(
    ADD_BUDGET_FACILITY,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Facility Successfully Added',
            variant: 'success',
          })
        );

        params.setValue([
          ...(params.value || []),
          {
            budget: {
              id: data.addBudgetFacility?.id,
              name: data.addBudgetFacility?.name,
            },
          },
        ]);
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Adding Facility', variant: 'error' }));
      },
    }
  );

  const [removeBudgetFacility, { loading: removeBudgetFacilityLoading }] = useMutation(
    REMOVE_BUDGET_FACILITY,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'Facility Successfully Removed',
            variant: 'success',
          })
        );

        params.setValue(
          (params.value || []).filter(({ budget }) => budget?.id !== data.removeBudgetFacility?.id)
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Removing Facility', variant: 'error' }));
      },
    }
  );

  const checked = useMemo(
    () => params?.value?.map(({ budget }) => budget?.id).includes(params?.budgetId),
    [params?.budgetId, params?.value]
  );

  const loading = useMemo(
    () => [addBudgetFacilityLoading, removeBudgetFacilityLoading].includes(true),
    [addBudgetFacilityLoading, removeBudgetFacilityLoading]
  );

  const handleChange = async (event) => {
    const {
      target: { checked: _checked },
    } = event;

    if (_checked && params.budgetId && params.data?.id) {
      await addBudgetFacility({
        variables: {
          where: { id: params.budgetId },
          data: { facilities: [{ id: params.data?.id }] },
        },
      });
    } else if (!_checked && params.budgetId && params.data?.id) {
      await removeBudgetFacility({
        variables: {
          where: { id: params.budgetId },
          data: { facilities: [{ id: params.data?.id }] },
        },
      });
    }
  };

  return (
    <div className="flex justify-center">
      <Switch checked={!!checked} color="success" disabled={loading} onChange={handleChange} />
    </div>
  );
};

export default BudgetViewScreenFacilityActiveCellRenderer;
