import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_ATTACHMENT_VIEW_APP_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketAttachmentViewAppServiceTicketFragment on ServiceTicket {
    id
    attachments {
      id
      createdAt
      commonStoredCad {
        id
        name
        standardImageUrl
      }
      commonStoredImage {
        id
        name
        originalImageUrl
        standardImageUrl
      }
      commonStoredPdf {
        id
        name
        originalPdfUrl
        standardImageUrl
      }
      commonStoredType {
        id
        enum
        name
      }
      commonStoredVideo {
        id
        name
        standardImageUrl
      }
    }
    chatChannels {
      id
      chatChannel {
        id
        attachments {
          id
          createdAt
          commonStoredCad {
            id
            name
            standardImageUrl
          }
          commonStoredImage {
            id
            name
            originalImageUrl
            standardImageUrl
          }
          commonStoredPdf {
            id
            name
            originalPdfUrl
            standardImageUrl
          }
          commonStoredType {
            id
            enum
            name
          }
          commonStoredVideo {
            id
            name
            standardImageUrl
          }
        }
        name
      }
      isCollaborative
      owner {
        id
        name
      }
    }
    programCampaign {
      id
      name
      program {
        id
        attachments {
          id
          createdAt
          commonStoredCad {
            id
            name
            standardImageUrl
          }
          commonStoredImage {
            id
            name
            originalImageUrl
            standardImageUrl
          }
          commonStoredPdf {
            id
            name
            originalPdfUrl
            standardImageUrl
          }
          commonStoredType {
            id
            enum
            name
          }
          commonStoredVideo {
            id
            name
            standardImageUrl
          }
        }
        name
      }
    }
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
    workOrder {
      id
      issues {
        id
        serviceIssue {
          id
          attachments {
            id
            createdAt
            commonStoredCad {
              id
              name
              standardImageUrl
            }
            commonStoredImage {
              id
              name
              originalImageUrl
              standardImageUrl
            }
            commonStoredPdf {
              id
              name
              originalPdfUrl
              standardImageUrl
            }
            commonStoredType {
              id
              enum
              name
            }
            commonStoredVideo {
              id
              name
              standardImageUrl
            }
            isCompletionAttachment
          }
          name
        }
      }
    }
  }
`;
