// eslint-disable-next-line import/prefer-default-export
export const calendarAppScreenFilterDefs = [
  {
    name: 'Visit',
    refinements: [
      {
        attribute: '__typename',
        label: 'Visit Type',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Facility',
    refinements: [
      {
        attribute: 'facility.businessConsultantContact.name',
        label: 'Business Consultant',
        operator: 'or',
      },
      {
        attribute: 'facility.esrContact.name',
        label: 'ESR',
        operator: 'or',
      },
      {
        attribute: 'facility.fieldConsultantContact.name',
        label: 'Field Consultant',
        operator: 'or',
      },
      {
        attribute: 'facility.address.city',
        label: 'City',
        operator: 'or',
      },
      {
        attribute: 'facility.leadAdvisorContact.name',
        label: 'Lead Advisor',
        operator: 'or',
      },
      {
        attribute: 'facility.marketTeam',
        label: 'Market Team',
        operator: 'or',
      },
      {
        attribute: 'facility.name',
        label: 'Name',
        operator: 'or',
      },
      {
        attribute: 'facility.operatorContact.name',
        label: 'Operator',
        operator: 'or',
      },
      {
        attribute: 'facility.projectAdministratorContact.name',
        label: 'Project Administrator',
        operator: 'or',
      },
      {
        attribute: 'facility.projectCoordinatorContact.name',
        label: 'Project Coordinator',
        operator: 'or',
      },
      {
        attribute: 'facility.regionalCoordinatorContact.name',
        label: 'Regional Coordinator',
        operator: 'or',
      },
      {
        attribute: 'facility.address.region',
        label: 'Region',
        operator: 'or',
      },
      {
        attribute: 'facility.regionalTeam',
        label: 'Regional Team',
        operator: 'or',
      },
    ],
  },
];
