import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BUDGET_PROJECT_ACTIVATE_DIALOG_BUDGET_PROJECT_FRAGMENT = gql`
  fragment BudgetProjectActivateDialogBudgetProjectFragment on BudgetProject {
    id
    status {
      id
      enum
      name
    }
  }
`;
