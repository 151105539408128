import { Paper, Typography } from '@mui/material';
import { useAuth } from 'app/providers/auth';
import { useCallback, useState } from 'react';
// import { Link } from 'react-router-dom';
import { SignInScreenInitializeForm, SignInScreenVerifyForm } from './components';

const SignInScreen = () => {
  const { jwtService } = useAuth();
  const [verificationKey, setVerificationKey] = useState(null);

  const handleSignInCancelled = useCallback(() => {
    setVerificationKey(null);
  }, []);

  const handleSignInInitialized = useCallback(({ key }) => {
    setVerificationKey(key);
  }, []);

  const handleSignInVerified = useCallback(
    async ({ signInToken }) => {
      setVerificationKey(null);

      try {
        await jwtService.completeSignIn({ signInToken });
      } catch (err) {
        // PLACEHOLDER
      }
    },
    [jwtService]
  );

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-48" src="assets/images/logo/logo.svg" alt="logo" />

          <Typography className="mt-32 text-4xl font-600 tracking-tight leading-tight">
            Sign In
          </Typography>

          {/* ROADMAP: Implement */}
          {/* <div className="flex items-baseline mt-2 font-500">
            <Typography>Don't have an account?</Typography>

            <Link className="ml-4" to="/sign-up">
              Sign up
            </Link>
          </div> */}

          {!verificationKey ? (
            <SignInScreenInitializeForm onSignInInitialized={handleSignInInitialized} />
          ) : (
            <SignInScreenVerifyForm
              verificationKey={verificationKey}
              onCancel={handleSignInCancelled}
              onSignInVerified={handleSignInVerified}
            />
          )}
        </div>
      </Paper>
    </div>
  );
};

export default SignInScreen;
