import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildCsiClassificationSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'csiClassification',
    headerName: 'CSI Classification',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'title',
        headerName: 'Title',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'number',
        headerName: 'Number',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'standard.name',
        headerName: 'Standard',
        sortable: true,
      },
    ],
  },
];
