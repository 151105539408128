import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  EntitySearchAppProvider,
  EntitySearchDrawer,
  EntitySearchHeader,
  EntitySearchInfiniteHitsGrid,
  makeEntitySearchCommonClientAdapter,
  useEntitySearchApp,
} from 'app/shared-components/EntitySearch';
import {
  WorkOrderIssueCreateDialog,
  WorkOrderIssueSelectedRowsControl,
} from 'app/shared-components/WorkOrderIssue';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { buildWorkOrderIssueSearchScreenColumnDefs } from './columnDefs';
import { workOrderIssueSearchScreenFilterDefs } from './filterDefs';
import { isWorkOrderIssueRowSelectable } from './utils';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
  },
  '& .FusePageSimple-rightSidebar': { zIndex: 9999 },
  '& .FusePageSimple-sidebarContent': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-toolbar': {},
}));

const WorkOrderIssueSearchScreenRoot = ({ basePath }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(selectUser);
  const { viewMode } = useEntitySearchApp();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  // TODO: Merge Memos' with Other Pages

  const canCreateWorkOrderIssue = useMemo(
    () =>
      Boolean(
        !!user?.data?.team &&
          ['FRANCHISOR', 'OPERATOR', 'VENDOR'].includes(user?.data?.organization?.type.enum)
      ),
    [user]
  );

  const leftSidebarWidth = useMemo(() => 341, []);

  // TODO: Merge Handlers with Other Pages

  const handleIsRowSelectable = useCallback(
    (args) =>
      isWorkOrderIssueRowSelectable(args, {
        organizationTypeEnum: user?.data?.organization?.type.enum,
      }),
    [user?.data?.organization?.type.enum]
  );

  const handleLeftSidebarClose = useCallback(() => setLeftSidebarOpen(false), []);

  const handleLeftSidebarToggle = useCallback(
    () => setLeftSidebarOpen(!leftSidebarOpen),
    [leftSidebarOpen]
  );

  const handleViewEntity = useCallback(
    (data) =>
      data?.workOrder?.serviceTicket?.id &&
      navigate(`/service-tickets/view/${data.workOrder.serviceTicket.id}`),
    [navigate]
  );

  return (
    <Root
      content={
        <SizeMe monitorHeight monitorWidth={false}>
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {viewMode === 'grid' && (
                <EntitySearchInfiniteHitsGrid
                  rowSelection="multiple"
                  SelectedRowsControl={WorkOrderIssueSelectedRowsControl}
                  onIsRowSelectable={handleIsRowSelectable}
                  onRowClicked={handleViewEntity}
                  {...size}
                />
              )}
            </div>
          )}
        </SizeMe>
      }
      header={
        <EntitySearchHeader
          dateRangeAttribute="createdAtTimestamp"
          leftSidebarToggle={handleLeftSidebarToggle}
          rightSidebarToggle={null}
          rightToolbarContent={
            <>
              <Button
                color="success"
                // TODO:
                // disabled={!canCreateWorkOrderIssue}
                disabled
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <WorkOrderIssueCreateDialog
                          onClose={() => dispatch(closeDialog())}
                          onSuccess={handleViewEntity}
                        />
                      ),
                      classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                      onClose: () => null,
                    })
                  )
                }
              >
                Create
              </Button>
            </>
          }
        />
      }
      leftSidebarContent={
        <EntitySearchDrawer
          contentWidth={leftSidebarWidth - 1}
          dateRangeAttribute="createdAtTimestamp"
          filterDefs={workOrderIssueSearchScreenFilterDefs}
          title="Work Order Issues"
          toggleDrawer={handleLeftSidebarToggle}
        />
      }
      leftSidebarOnClose={handleLeftSidebarClose}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={leftSidebarWidth}
      scroll="content"
    />
  );
};

const WorkOrderIssueSearchScreen = ({ basePath }) => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const workOrderIssueSearchScreenColumnDefs = buildWorkOrderIssueSearchScreenColumnDefs({
    theme,
    user,
  });

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="work_order_issues"
      initialColumnDefs={workOrderIssueSearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
      socketIoIncludeSockets={['team', 'user']}
      socketIoRoomId="aggregate:work_order_issues"
    >
      <WorkOrderIssueSearchScreenRoot basePath={basePath} />
    </EntitySearchAppProvider>
  );
};

export default WorkOrderIssueSearchScreen;
