import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderViewAppServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    creatorOrganizationType {
      id
      enum
      name
    }
    draftPhaseEndedAt
    draftPhaseStartedAt
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isRequestForQuote
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    lastActivityAt
    latestCompleteBy
    phase {
      id
      enum
      name
    }
    phaseReviews {
      id
      assignedOrganizationType {
        id
        enum
        name
      }
      isCancelled
      isCompleted
      serviceTicketPhase {
        id
        enum
        name
      }
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    status {
      id
      enum
      name
    }
    workOrder {
      id
      comments
      issues {
        id
        budget {
          id
          divisionName
          divisionNumber
          name
          status {
            id
            enum
            name
          }
        }
        budgetProject {
          id
          attachments {
            id
            createdAt
            commonStoredCad {
              id
              name
            }
            commonStoredImage {
              id
              lightboxImageUrl
              name
              thumbnailImageUrl
            }
            commonStoredPdf {
              id
              name
              originalPdfUrl
              thumbnailImageUrl
            }
            commonStoredType {
              id
              enum
              name
            }
            commonStoredVideo {
              id
              captionsFileUrl
              name
              playlistFileUrl
              standardImageUrl
              thumbnailImageUrl
            }
          }
          name
          status {
            id
            enum
            name
          }
        }
        budgetSpecialProject {
          id
          attachments {
            id
            createdAt
            commonStoredCad {
              id
              name
            }
            commonStoredImage {
              id
              lightboxImageUrl
              name
              thumbnailImageUrl
            }
            commonStoredPdf {
              id
              name
              originalPdfUrl
              thumbnailImageUrl
            }
            commonStoredType {
              id
              enum
              name
            }
            commonStoredVideo {
              id
              captionsFileUrl
              name
              playlistFileUrl
              standardImageUrl
              thumbnailImageUrl
            }
          }
          name
          status {
            id
            enum
            name
          }
        }
        actionItems {
          id
          codingStrings {
            typeEnum
            value
          }
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          costCode {
            id
            accountingExpenseType {
              id
              name
            }
            accountingTaskCode {
              id
              name
              number
            }
            attachments {
              id
              createdAt
              commonStoredCad {
                id
                name
              }
              commonStoredImage {
                id
                lightboxImageUrl
                name
                thumbnailImageUrl
              }
              commonStoredPdf {
                id
                name
                originalPdfUrl
                thumbnailImageUrl
              }
              commonStoredType {
                id
                enum
                name
              }
              commonStoredVideo {
                id
                captionsFileUrl
                name
                playlistFileUrl
                standardImageUrl
                thumbnailImageUrl
              }
            }
            csiClassification {
              id
              name
              number
              title
            }
          }
          description
          isCapitalExpense
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        completeBy
        isLocked
        issueTemplateWorkOrderIssue {
          id
          issueTemplates {
            id
            name
          }
        }
        lockedAt
        notToExceedAmount
        reviews {
          id
          createdAt
          assignedOrganizationType {
            id
            enum
            name
          }
          completor {
            id
            name
          }
          decision {
            id
            enum
            name
          }
          completedAt
          isCancelled
          isCompleted
          type {
            id
            enum
            name
          }
        }
        scheduledResumptions {
          id
          createdAt
          isProcessed
          resumeAt
        }
        serviceInvoice {
          id
          createdAt
          isApproved
          vendorReferenceNumber
        }
        serviceIssue {
          id
          attachments {
            id
            createdAt
            commonStoredCad {
              id
              name
            }
            commonStoredImage {
              id
              commonStoredUpload {
                id
                uploader {
                  id
                  name
                }
              }
              lightboxImageUrl
              name
              thumbnailImageUrl
            }
            commonStoredPdf {
              id
              name
              originalPdfUrl
              thumbnailImageUrl
            }
            commonStoredType {
              id
              enum
              name
            }
            commonStoredVideo {
              id
              captionsFileUrl
              name
              playlistFileUrl
              standardImageUrl
              thumbnailImageUrl
            }
            isCompletionAttachment
          }
          description
          name
          serviceResponseSeverity {
            id
            color
            commonTemporalDurationUnit {
              id
              enum
              name
            }
            ordinal
            name
            value
          }
          tags {
            id
            name
          }
          targetFacilityAreas {
            id
            name
          }
        }
        serviceVisits {
          id
          windowEndAt
          windowStartAt
        }
        shippingAmount
        siteVisits {
          id
          windowEndAt
          windowStartAt
        }
        status {
          id
          enum
          name
        }
        subtotalAmount
        subtotalAmountCheck {
          id
          enum
          name
        }
        taxAmount
        totalAmount
      }
      notToExceedAmount
      shippingAmount
      subtotalAmount
      taxAmount
      totalAmount
    }
  }
`;
