import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, IconButton, Toolbar, Tooltip } from '@mui/material';
import { EntitySearchBox } from 'app/shared-components/EntitySearch/EntitySearchBox';
import {
  EntitySearchHeaderCsvButton,
  EntitySearchHeaderRefreshButton,
  EntitySearchHeaderViewModeButton,
} from './components';

const EntitySearchHeader = ({
  dateRangeAttribute,
  leftSidebarToggle,
  rightSidebarToggle,
  rightToolbarContent,
}) => {
  return (
    <AppBar color="inherit" elevation={0} position="static">
      <Toolbar className="flex-col sm:flex-row py-8 sm:py-0">
        <div className="flex flex-1 items-center">
          <div className="flex-1">
            <Tooltip title="Toggle Menu">
              <IconButton
                color="default"
                edge="start"
                sx={{ mr: 2, width: 40, height: 40 }}
                onClick={leftSidebarToggle}
              >
                <FontAwesomeIcon icon={faBars} size="xs" />
              </IconButton>
            </Tooltip>
          </div>

          <EntitySearchBox />
        </div>

        <div className="flex items-center">
          <EntitySearchHeaderViewModeButton className="ml-16" />

          <EntitySearchHeaderRefreshButton />

          <EntitySearchHeaderCsvButton dateRangeAttribute={dateRangeAttribute} />

          {rightToolbarContent && (
            <>
              <div className="mx-8 w-1 h-24 bg-gray-800" />

              {rightToolbarContent}
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default EntitySearchHeader;
