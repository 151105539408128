import { adaptRequestsForDateRange } from 'app/shared-components/EntitySearch/EntitySearchDateRangeMenu/utils';

// eslint-disable-next-line import/prefer-default-export
export const makeEntitySearchCommonClientAdapter = (instantSearchClient, params) => (requests) => {
  return new Promise((resolveSearch, rejectSearch) => {
    try {
      let adaptedRequests = requests;

      if (params?.dateRangeAttribute) {
        adaptedRequests = adaptRequestsForDateRange(adaptedRequests, {
          dateRangeAttribute: params?.dateRangeAttribute,
        });
      }

      instantSearchClient.search(adaptedRequests).then((results) => {
        resolveSearch(results);
      });
    } catch (err) {
      rejectSearch(err);
    }
  });
};
