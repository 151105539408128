import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { components } from 'react-select';

const ProgramHitsListOption = (props) => {
  // ROADMAP: Switch to `props.data` when `EntitySearchSelectFieldV2` is Fully Deployed
  const program = props.data?.hit || props.data;

  const option = useMemo(() => {
    if (!program) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faBookmark} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{program.name}</Typography>}
          secondary={
            <div className="flex flex-1">
              <div className="flex items-center">
                <Typography variant="body2">
                  {program.description || 'No Description Set'}
                </Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [program]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default ProgramHitsListOption;
