import { gql } from '@apollo/client';
import { BUDGET_SPECIAL_PROJECT_CREATE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BUDGET_SPECIAL_PROJECT = gql`
  mutation CreateBudgetSpecialProject($data: BudgetSpecialProjectCreateInput!) {
    createBudgetSpecialProject(data: $data) {
      id
      ...BudgetSpecialProjectCreateDialogBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_CREATE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;
