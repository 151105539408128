import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const CsiClassificationsSearchPage = lazy(() => import('./CsiClassificationsSearchPage'));
export const csiClassificationsSearchPageBase = '/csi-classifications';

// i18next.addResourceBundle('en', 'csiClassificationsSearchPage', en);
// i18next.addResourceBundle('tr', 'csiClassificationsSearchPage', tr);
// i18next.addResourceBundle('ar', 'csiClassificationsSearchPage', ar);

const csiClassificationsSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${csiClassificationsSearchPageBase}/search`,
      element: <CsiClassificationsSearchPage />,
    },
    {
      path: `${csiClassificationsSearchPageBase}/quick-view/:csiClassificationId`,
      element: <CsiClassificationsSearchPage />,
    },
    {
      path: csiClassificationsSearchPageBase,
      element: <Navigate to={`${csiClassificationsSearchPageBase}/search`} />,
    },
  ],
};

export default csiClassificationsSearchPageConfig;
