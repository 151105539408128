import { gql } from '@apollo/client';

export const SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_SERVICE_INVOICE_FRAGMENT = gql`
  fragment ServiceInvoiceVendorReferenceNumberUpdateDialogServiceInvoiceFragment on ServiceInvoice {
    id
    vendorReferenceNumber
  }
`;

export const SERVICE_INVOICE_VENDOR_REFERENCE_NUMBER_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceInvoiceVendorReferenceNumberUpdateDialogServiceTicketFragment on ServiceTicket {
    id
    serviceInvoices {
      id
      isApproved
      isGenerated
      submittedAt
      vendorReferenceNumber
    }
  }
`;
