import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Checkbox, Tooltip } from '@mui/material';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonAsyncPaginateSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';
import { forwardRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';

const EntitySearchSelectFieldV2Control = ({ children, ...props }) => {
  const selectOptions = useMemo(() => props?.options ?? [], [props?.options]);
  const selectValue = props.getValue();

  const checkboxChecked = useMemo(
    () =>
      selectValue &&
      _.every(selectOptions ?? [], (v) => (selectValue?.map((o) => o.id) ?? []).includes(v.id)),
    [selectOptions, selectValue]
  );

  const checkboxIndeterminate = useMemo(
    () =>
      !checkboxChecked &&
      selectValue &&
      _.some(selectOptions ?? [], (v) => (selectValue?.map((o) => o.id) ?? []).includes(v.id)),
    [checkboxChecked, selectOptions, selectValue]
  );

  const tooltipTitle = useMemo(() => {
    let title;

    if (checkboxChecked) {
      title = 'Deselect All Options';
    } else if (checkboxIndeterminate) {
      title = `Select All ${selectOptions?.length} Loaded Options`;
    } else {
      title = `Select All ${selectOptions?.length} Loaded Options`;
    }

    return title;
  }, [checkboxChecked, checkboxIndeterminate, selectOptions?.length]);

  const handleCheckboxChange = useCallback(() => {
    let options;

    if (checkboxChecked) {
      options = [];
    } else if (checkboxIndeterminate) {
      options = selectOptions;
    } else {
      options = selectOptions;
    }

    if (options && typeof props?.setValue === 'function') {
      props.setValue(options);
    }
  }, [checkboxChecked, checkboxIndeterminate, props, selectOptions]);

  return (
    <div>
      <components.Control {...props}>
        {props.isMulti && (
          <Tooltip title={tooltipTitle}>
            <div>
              <Checkbox
                checked={checkboxChecked}
                indeterminate={checkboxIndeterminate}
                size="small"
                onChange={handleCheckboxChange}
              />
            </div>
          </Tooltip>
        )}

        {children}
      </components.Control>
    </div>
  );
};

const EntitySearchSelectFieldV2MoreSelectedBadge = ({ items }) => {
  const style = {
    background: '#4f46e5',
    borderRadius: '4px',
    fontSize: '85%',
    marginLeft: 'auto',
    order: 99,
    padding: '4px',
  };

  const label = `+ ${items.length} Item${items.length !== 1 ? 's' : ''} Selected`;
  const title = items.join(', ');

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const EntitySearchSelectFieldV2MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = props.selectProps?.maxToShow || 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  // eslint-disable-next-line no-nested-ternary
  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <EntitySearchSelectFieldV2MoreSelectedBadge items={overflow} />
  ) : null;
};

const EntitySearchSelectFieldV2Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        {props.isMulti && (
          <FontAwesomeIcon className="mr-6" icon={props.isSelected ? faSquareCheck : faSquare} />
        )}

        {props.label}
      </components.Option>
    </div>
  );
};

const EntitySearchSelectFieldV2 = forwardRef(
  ({ className, filter, indexName, sort, ...SelectProps }, ref) => {
    const { axiosSearchClient } = useMeilisearch();
    const dispatch = useDispatch();

    const loadOptions = useCallback(
      async (match, loadedOptions) => {
        let output = {
          options: [],
          hasMore: false,
        };

        try {
          const {
            data: { estimatedTotalHits, hits },
          } = await axiosSearchClient.post(`/indexes/${indexName}/search`, {
            filter,
            limit: 20,
            offset: loadedOptions.length,
            q: match,
            sort,
          });

          output = {
            options: hits,
            hasMore: estimatedTotalHits > loadedOptions.length,
          };
        } catch (err) {
          dispatch(showMessage({ message: 'An Unknown Error Occurred!', variant: 'error' }));
        }

        return output;
      },
      [axiosSearchClient, dispatch, filter, indexName, sort]
    );

    return (
      <div className={className}>
        <CommonAsyncPaginateSelect
          {...SelectProps}
          closeMenuOnSelect={!SelectProps.isMulti}
          components={{
            Control: EntitySearchSelectFieldV2Control,
            MultiValue: EntitySearchSelectFieldV2MultiValue,
            Option: EntitySearchSelectFieldV2Option,
            ...(SelectProps.components ?? {}),
          }}
          hideSelectedOptions={!SelectProps.isMulti}
          key={JSON.stringify({ filter, indexName, sort })}
          loadOptions={loadOptions}
          ref={ref}
        />
      </div>
    );
  }
);

export default EntitySearchSelectFieldV2;
