import { gql } from '@apollo/client';

export const WORK_ORDER_ISSUE_RE_CODE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT = gql`
  fragment WorkOrderIssueReCodeDialogWorkOrderIssueFragment on WorkOrderIssue {
    id
    actionItems {
      id
      codingStrings {
        typeEnum
        value
      }
      commonStandardFlatRate {
        id
        quantity
      }
      commonStandardHourlyRate {
        id
        hours
        rateAmount
        technicians
      }
      commonStandardRateType {
        id
        enum
        name
      }
      costCode {
        id
        accountingTaskCode {
          id
          name
          number
        }
        csiClassification {
          id
          name
          number
        }
      }
      description
      isCapitalExpense
      notToExceedAmount
      subtotalAmount
      totalAmount
    }
    budget {
      id
      name
    }
    budgetProject {
      id
      name
      _count {
        costCodes
        serviceCompletionItems
      }
    }
    budgetSpecialProject {
      id
      name
      _count {
        costCodes
        serviceCompletionItems
      }
    }
    isLocked
    lockedAt
    status {
      id
      enum
      name
    }
  }
`;

export const WORK_ORDER_ISSUE_RE_CODE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueReCodeDialogServiceTicketFragment on ServiceTicket {
    id
    facility {
      id
      franchiseNumber
      name
    }
    lastActivityAt
    serviceInvoices {
      id
      isApproved
      isGenerated
      vendorReferenceNumber
    }
    workOrder {
      id
      issues {
        id
        ...WorkOrderIssueReCodeDialogWorkOrderIssueFragment
      }
    }
  }
  ${WORK_ORDER_ISSUE_RE_CODE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT}
`;
