import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Alert, Button, TextField } from '@mui/material';
import { useAuth } from 'app/providers/auth';
import { useCallback, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

const defaultValues = {
  secret: '',
};

const buildSchema = () =>
  yup.object().shape({
    secret: yup
      .string()
      .test('isValidSecretTest', 'You Must Enter A Valid Secret', (val) => val?.length === 6)
      .required('You Must Enter A Secret'),
  });

const SignInScreenVerifyForm = ({ onCancel, onSignInVerified, verificationKey }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { jwtService } = useAuth();
  const [loading, setLoading] = useState(false);
  const schema = useMemo(() => buildSchema(), []);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onSubmit',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const onSubmit = useCallback(
    async (data) => {
      setErrorMessage(null);
      setLoading(true);

      try {
        const { signInToken } = await jwtService.verifySignIn({
          key: verificationKey,
          secret: data.secret,
        });

        if (signInToken) {
          if (typeof onSignInVerified === 'function') {
            onSignInVerified({ signInToken });
          }

          reset(defaultValues);
        } else {
          setErrorMessage('Verification Failed!');
        }
      } catch (err) {
        setErrorMessage(err.response?.data?.error ?? 'An unknown error occurred!');
      } finally {
        setLoading(false);
      }
    },
    [jwtService, onSignInVerified, reset, verificationKey]
  );

  return (
    <FormProvider
      {...{
        clearErrors,
        control,
        formState,
        handleSubmit,
        reset,
        setValue,
        watch,
        ...methods,
      }}
    >
      <form
        className="w-full pt-20"
        id="sign-in-screen-verify-form"
        name="sign-in-screen-verify-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        {errorMessage && (
          <Alert className="mb-24" severity="error" variant="outlined">
            {errorMessage}
          </Alert>
        )}

        <Controller
          control={control}
          name="secret"
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              className="mb-24"
              error={!!errors?.secret}
              fullWidth
              helperText={errors?.secret?.message}
              placeholder="Enter Verification Secret..."
              required
              variant="outlined"
            />
          )}
        />

        <div className="flex items-center w-full mt-16">
          <Button className="flex-1 mr-12" color="primary" onClick={onCancel} variant="contained">
            Cancel
          </Button>

          <Button
            className="flex-1 ml-12"
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid || loading}
            form="sign-in-screen-verify-form"
            size="large"
            type="submit"
            variant="contained"
          >
            Verify
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default SignInScreenVerifyForm;
