import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@mui/material';
import { CommonDatepicker } from 'app/shared-components/Common';
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

const defaultValues = {
  resumeAt: null,
};

const schema = yup.object().shape({
  resumeAt: yup.date().nullable().optional(),
});

const WorkOrderIssueBatchActionDialogPauseIssueForm = ({ onIsValidChanged, onSubmit }) => {
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (typeof onIsValidChanged === 'function') {
      onIsValidChanged(isValid);
    }
  }, [isValid, onIsValidChanged]);

  return (
    <FormProvider
      {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
    >
      <form
        className="w-full pt-20"
        id="work-order-issue-batch-action-form"
        name="work-order-issue-batch-action-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Alert className="mb-24" severity="info">
          Once paused, no further action can be taken on these issues until they are each resumed.
        </Alert>

        <Controller
          control={control}
          name="resumeAt"
          render={({ field }) => (
            <div className="mb-24">
              <CommonDatepicker
                {...field}
                controls={['calendar']}
                disabled={false}
                error={errors?.resumeAt}
                min={moment().add(1, 'day').startOf('day')}
                placeholder="Select Scheduled Resumption Date..."
                select="date"
              />
            </div>
          )}
        />
      </form>
    </FormProvider>
  );
};

export default WorkOrderIssueBatchActionDialogPauseIssueForm;
