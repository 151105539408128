import { faCircle, faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseUtils from '@fuse/utils';
import _ from '@lodash';
import {
  Alert,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import pLimit from 'p-limit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const limit = pLimit(1);

const CommonDownloadZipDialog = ({ files, onClose, onProcessingComplete }) => {
  const [currentFile, setCurrentFile] = useState(null);
  const dispatch = useDispatch();
  const [processed, setProcessed] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processorOutput, setProcessorOutput] = useState([]);

  const zip = useRef(new JSZip());
  const folder = useRef(zip.current.folder('attachments'));

  useEffect(() => {
    const process = async () => {
      try {
        setProcessing(true);

        await Promise.all(
          files?.map((file) =>
            limit(
              () =>
                // eslint-disable-next-line no-async-promise-executor
                new Promise(async (resolveFile) => {
                  setCurrentFile(file);

                  let result;

                  try {
                    const { data } = await axios.get(file.url, { responseType: 'blob' });

                    if (data) {
                      const downloadFile = new File([data], file.name);

                      folder.current.file(file.name, downloadFile);
                    }

                    result = 'SUCCESS';
                  } catch (err) {
                    result = 'ERROR';
                  }

                  await new Promise((awake) => setTimeout(awake, 250));

                  setCurrentFile(null);
                  setProcessorOutput((prev) => [...(prev || []), { id: file.id, result }]);

                  resolveFile();
                })
            )
          )
        );

        setProcessed(true);
      } catch (err) {
        //
      } finally {
        setProcessing(false);
      }
    };

    if (_.isArray(files) && !processed && !processing) {
      process();
    }
  }, [files, processed, processing]);

  useEffect(() => {
    if (processed && !processing && folder.current.files) {
      folder.current.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, FuseUtils.generateGUID());

        if (typeof onProcessingComplete === 'function') {
          onProcessingComplete({ processorOutput });
        }

        dispatch(closeDialog());
      });
    }
  }, [dispatch, onProcessingComplete, processed, processing, processorOutput]);

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>Download Files</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
        <div className="px-24 border-b-1">
          {processing && (
            <div className="py-24">
              <Alert className="mb-24" severity="info">
                Please do not close this window or click the back button on your browser.
              </Alert>

              <div className="flex flex-row items-center mb-12">
                <Typography className="flex-1 text-16 font-500 truncate">
                  {`Processing: ${currentFile?.name} ...`}
                </Typography>

                <Typography className="ml-12 text-16 font-600">
                  {processorOutput?.length} / {files?.length}
                </Typography>
              </div>

              <div>
                <LinearProgress
                  color="info"
                  variant="determinate"
                  value={Math.min((processorOutput?.length / files?.length) * 100, 100)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex-1 overflow-y-auto">
          {files?.length ? (
            <List disablePadding>
              {files?.map((file) => {
                const fileOutput = processorOutput?.find(({ id }) => id === file.id);

                return (
                  <ListItem divider key={file.id} sx={{ pl: '24px' }}>
                    <ListItemText primary={file.name} />

                    <div className="ml-12">
                      {currentFile?.id === file.id ? (
                        <CircularProgress size={24} />
                      ) : (
                        <>
                          {!fileOutput && <FontAwesomeIcon icon={faCircle} size="xl" />}

                          {fileOutput?.result === 'SUCCESS' && (
                            <FontAwesomeIcon
                              className="text-green"
                              icon={faCircleCheck}
                              size="xl"
                            />
                          )}

                          {fileOutput?.result === 'ERROR' && (
                            <FontAwesomeIcon className="text-red" icon={faCircleXmark} size="xl" />
                          )}
                        </>
                      )}
                    </div>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div className="flex items-center justify-center w-full py-24">
              <Typography className="text-24">No Files Found</Typography>
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <>
          <Button color="primary" disabled={false} onClick={onClose} variant="contained">
            Cancel
          </Button>
        </>
      </DialogActions>
    </>
  );
};

export default CommonDownloadZipDialog;
