// eslint-disable-next-line import/prefer-default-export
export const budgetSpecialProjectSearchScreenFilterDefs = [
  {
    name: 'Budget Special Project',
    refinements: [
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
      {
        attribute: 'referenceNumber',
        label: 'Reference Number',
        operator: 'or',
      },
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
    ],
  },
];
