import { gql } from '@apollo/client';
import { ACCOUNTING_TASK_CODE_VIEW_DRAWER_ACCOUNTING_TASK_CODE_FRAGMENT } from './fragments';

export const FETCH_ACCOUNTING_TASK_CODE_VIEW_DRAWER_DATA = gql`
  query FetchAccountingTaskCodeViewDrawerData($where: AccountingTaskCodeWhereUniqueInput!) {
    accountingTaskCode(where: $where) {
      id
      ...AccountingTaskCodeViewDrawerAccountingTaskCodeFragment
    }
  }
  ${ACCOUNTING_TASK_CODE_VIEW_DRAWER_ACCOUNTING_TASK_CODE_FRAGMENT}
`;

export const UPDATE_ACCOUNTING_TASK_CODE = gql`
  mutation UpdateAccountingTaskCode(
    $where: AccountingTaskCodeWhereUniqueInput!
    $data: AccountingTaskCodeUpdateInput!
  ) {
    updateAccountingTaskCode(where: $where, data: $data) {
      id
      ...AccountingTaskCodeViewDrawerAccountingTaskCodeFragment
    }
  }
  ${ACCOUNTING_TASK_CODE_VIEW_DRAWER_ACCOUNTING_TASK_CODE_FRAGMENT}
`;
