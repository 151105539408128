import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COST_CODE_VIEW_DRAWER_COST_CODE_FRAGMENT = gql`
  fragment CostCodeViewDrawerCostCodeFragment on CostCode {
    id
    createdAt
    updatedAt

    accountingExpenseType {
      id
      name
    }
    accountingTaskCode {
      id
      name
      number
    }
    attachments {
      id
      createdAt
      commonStoredImage {
        id
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredType {
        id
        enum
        name
      }
      commonStoredPdf {
        id
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        originalPdfUrl
        standardImageUrl
        thumbnailImageUrl
      }
      commonStoredVideo {
        id
        captionsFileUrl
        commonStoredUpload {
          id
          key
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        playlistFileUrl
        standardImageUrl
        thumbnailImageUrl
      }
    }
    canBeDeleted
    capitalExpenseThreshold
    csiClassification {
      id
      name
      number
      title
    }
    isCapitalExpense
    owner {
      id
      name
    }
  }
`;
