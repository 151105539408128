import { gql } from '@apollo/client';
import { BUDGET_SPECIAL_PROJECT_DEACTIVATE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DEACTIVATE_BUDGET_SPECIAL_PROJECT = gql`
  mutation DeactivateBudgetSpecialProject($where: BudgetSpecialProjectWhereUniqueInput!) {
    deactivateBudgetSpecialProject(where: $where) {
      id
      ...BudgetSpecialProjectDeactivateDialogBudgetSpecialProjectFragment
    }
  }
  ${BUDGET_SPECIAL_PROJECT_DEACTIVATE_DIALOG_BUDGET_SPECIAL_PROJECT_FRAGMENT}
`;
