import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import numeral from 'numeral';
import { useCallback } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch } from 'react-redux';

const WorkOrderViewAppDownloadPdfTemplate = ({
  facility,
  serviceReferenceNumber,
  width,
  workOrder,
}) => {
  return (
    <div
      className="text-black bg-white"
      style={{
        fontFamily: 'arial',
        width,
      }}
    >
      <div className="mb-24">
        <div className="flex mb-12">
          <div className="flex-1">
            <Typography className="flex-1 font-bold text-32">Work Order</Typography>
          </div>

          <div className="flex flex-col items-end">
            <Typography className="mb-2 font-semibold text-14">
              {moment().format('MMM. DD, YYYY @ hh:mm A')}
            </Typography>

            <Typography className="mb-2 font-semibold text-14">{serviceReferenceNumber}</Typography>

            <Typography className="mb-2 font-semibold text-14">{`${facility?.franchiseNumber} - ${facility?.name}`}</Typography>
          </div>
        </div>
      </div>

      <div className="w-full h-1 mb-8 bg-black" />

      <div className="mb-24">
        <Typography className="mb-1 text-12 text-grey-700 font-semibold uppercase">
          Comments
        </Typography>

        <Typography>{workOrder?.comments || 'Not Set'}</Typography>
      </div>

      <div className="w-full h-1 mb-8 bg-black" />

      <div className="mb-24">
        <Typography className="text-20 font-semibold">Service Issues</Typography>
      </div>

      {!_.isEmpty(workOrder.issues) ? (
        <>
          {workOrder.issues?.map((workOrderIssue, workOrderIssueIndex) => (
            <div className="w-full" key={workOrderIssue.id}>
              <div className="mb-24">
                <Typography className="text-16 font-bold">
                  {workOrderIssue.serviceIssue?.name}
                </Typography>

                {workOrderIssue?.serviceIssue?.description && (
                  <Typography className="mt-3">
                    {workOrderIssue?.serviceIssue?.description || 'Not Set'}
                  </Typography>
                )}
              </div>

              <Table className="w-full">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="font-bold text-white text-left bg-black"
                      style={{ padding: '16px 8px' }}
                    >
                      <Typography
                        style={{
                          marginTop: '-12px',
                        }}
                      >
                        Action Item
                      </Typography>
                    </TableCell>

                    <TableCell
                      className="font-bold text-white text-right bg-black"
                      style={{ padding: '16px 8px' }}
                    >
                      <Typography
                        style={{
                          marginTop: '-12px',
                        }}
                      >
                        Qty.
                      </Typography>
                    </TableCell>

                    <TableCell
                      className="font-bold text-white text-right bg-black"
                      style={{ padding: '16px 8px' }}
                    >
                      <Typography
                        style={{
                          marginTop: '-12px',
                        }}
                      >
                        Rate
                      </Typography>
                    </TableCell>

                    <TableCell
                      className="font-bold text-white text-right bg-black"
                      style={{ padding: '16px 8px' }}
                    >
                      <Typography
                        style={{
                          marginTop: '-12px',
                        }}
                      >
                        Total
                      </Typography>
                    </TableCell>

                    <TableCell
                      className="font-bold text-white text-right bg-black"
                      style={{ padding: '16px 8px' }}
                    >
                      <Typography
                        style={{
                          marginTop: '-12px',
                        }}
                      >
                        NTE
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!_.isEmpty(workOrderIssue.actionItems) ? (
                    <>
                      {workOrderIssue.actionItems.map((workOrderIssueActionItem) => {
                        let quantity;
                        let rateAmount;

                        if (workOrderIssueActionItem.commonStandardRateType?.enum === 'FLAT_RATE') {
                          rateAmount = workOrderIssueActionItem.commonStandardFlatRate?.quantity;
                        } else if (
                          workOrderIssueActionItem.commonStandardRateType?.enum === 'HOURLY_RATE'
                        ) {
                          quantity =
                            workOrderIssueActionItem.commonStandardHourlyRate?.hours *
                            workOrderIssueActionItem.commonStandardHourlyRate?.technicians;

                          rateAmount =
                            workOrderIssueActionItem.commonStandardHourlyRate?.rateAmount;
                        }

                        return (
                          <TableRow
                            key={workOrderIssueActionItem.id}
                            style={{
                              borderBottom: '1px solid #000000',
                            }}
                          >
                            <TableCell className="text-left" style={{ padding: '16px 8px' }}>
                              <div style={{ marginTop: '-12px' }}>
                                <Typography className="text-11">
                                  {workOrderIssueActionItem.costCode?.accountingTaskCode
                                    ? `${workOrderIssueActionItem.costCode?.accountingTaskCode.name} (${workOrderIssueActionItem.costCode?.accountingTaskCode.number})`
                                    : 'CSI Classification'}
                                </Typography>

                                <Typography className="text-14">
                                  {workOrderIssueActionItem.costCode?.csiClassification
                                    ? `${workOrderIssueActionItem.costCode?.csiClassification?.name} (${workOrderIssueActionItem.costCode?.csiClassification?.number})`
                                    : 'Cost Code Not Set'}
                                </Typography>

                                {workOrderIssueActionItem.description && (
                                  <Typography className="mt-8 text-14">
                                    {workOrderIssueActionItem.description || 'Not Set'}
                                  </Typography>
                                )}
                              </div>
                            </TableCell>

                            <TableCell className="text-right" style={{ padding: '16px 8px' }}>
                              <Typography
                                style={{
                                  marginTop: '-12px',
                                }}
                              >
                                {quantity || '-'}
                              </Typography>
                            </TableCell>

                            <TableCell className="text-right" style={{ padding: '16px 8px' }}>
                              <Typography
                                style={{
                                  marginTop: '-12px',
                                }}
                              >
                                {quantity && `${quantity} @ `}
                                {rateAmount ? numeral(rateAmount).format('$0,0.00') : '-'}
                              </Typography>
                            </TableCell>

                            <TableCell className="text-right" style={{ padding: '16px 8px' }}>
                              <Typography
                                style={{
                                  marginTop: '-12px',
                                }}
                              >
                                {workOrderIssueActionItem.totalAmount
                                  ? numeral(workOrderIssueActionItem.totalAmount).format('$0,0.00')
                                  : '-'}
                              </Typography>
                            </TableCell>

                            <TableCell className="text-right" style={{ padding: '16px 8px' }}>
                              <Typography
                                style={{
                                  marginTop: '-12px',
                                }}
                              >
                                {workOrderIssueActionItem.notToExceedAmount
                                  ? numeral(workOrderIssueActionItem.notToExceedAmount).format(
                                      '$0,0.00'
                                    )
                                  : '-'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow
                      style={{
                        borderBottom: '1px solid #000000',
                      }}
                    >
                      <TableCell
                        className="text-center"
                        colSpan={5}
                        style={{ padding: '16px 8px' }}
                      >
                        <Typography
                          style={{
                            marginTop: '-12px',
                          }}
                        >
                          No Action Items Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} style={{ padding: '12px 4px 4px' }}>
                      &nbsp;
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '12px 4px 4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        Subtotal
                      </Typography>
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '12px 4px 4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        {workOrderIssue?.subtotalAmount
                          ? numeral(workOrderIssue?.subtotalAmount).format('$0,0.00')
                          : '-'}
                      </Typography>
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '12px 4px 4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        {workOrderIssue?.notToExceedAmount
                          ? numeral(workOrderIssue?.notToExceedAmount).format('$0,0.00')
                          : '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2} style={{ padding: '4px' }}>
                      &nbsp;
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        Shipping
                      </Typography>
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        {workOrderIssue?.shippingAmount
                          ? numeral(workOrderIssue?.shippingAmount).format('$0,0.00')
                          : '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2} style={{ padding: '4px' }}>
                      &nbsp;
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        Tax
                      </Typography>
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        {workOrderIssue?.taxAmount
                          ? numeral(workOrderIssue?.taxAmount).format('$0,0.00')
                          : '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2} style={{ padding: '4px' }}>
                      &nbsp;
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        Total
                      </Typography>
                    </TableCell>

                    <TableCell className="text-right" style={{ padding: '4px' }}>
                      <Typography className="font-500" style={{ marginTop: '-12px' }}>
                        {workOrderIssue?.totalAmount
                          ? numeral(workOrderIssue?.totalAmount).format('$0,0.00')
                          : '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>

              {workOrderIssueIndex < workOrder.issues?.length - 1 && (
                <div className="w-full h-1 mt-24 mb-16 bg-black" />
              )}
            </div>
          ))}
        </>
      ) : (
        <div className="w-full">
          <div className="mb-24">
            <Typography className="text-16 font-semibold">No Service Issues Found</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

const WorkOrderViewAppDownloadPdfButton = ({ facility, serviceReferenceNumber, workOrder }) => {
  const dispatch = useDispatch();
  const margin = 24;

  const handleDownloadPdf = useCallback(() => {
    if (dispatch && facility && serviceReferenceNumber && workOrder) {
      dispatch(
        openDialog({
          children: <div className="p-24 text-16 text-center font-500">Generating PDF...</div>,
          classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
          onClose: () => null,
        })
      );

      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        floatPrecision: 16,
        format: 'letter',
        hotfixes: ['px_scaling'],
        orientation: 'p',
        putOnlyUsedFonts: true,
        unit: 'px',
      });

      doc.html(
        renderToString(
          <WorkOrderViewAppDownloadPdfTemplate
            facility={facility}
            serviceReferenceNumber={serviceReferenceNumber}
            width={doc.internal.pageSize.getWidth() - margin * 2}
            workOrder={workOrder}
          />
        ),
        {
          autoPaging: 'text',
          callback: () => {
            doc.output('save', {
              filename: `work-order-${serviceReferenceNumber}-${moment().unix()}.pdf`,
            });

            dispatch(closeDialog());
          },
          margin,
        }
      );
    }
  }, [dispatch, facility, serviceReferenceNumber, workOrder]);

  return (
    <Tooltip title="Download PDF">
      <span>
        <IconButton
          color="inherit"
          disabled={false}
          size="small"
          sx={{
            ml: 2,
            borderStyle: 'solid',
            borderWidth: '2px',
          }}
          onClick={handleDownloadPdf}
        >
          <FontAwesomeIcon className="w-16 h-16" icon={faDownload} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default WorkOrderViewAppDownloadPdfButton;
