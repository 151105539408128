import _ from '@lodash';
import { makeEntitySearchDateRangeMenuPresets } from './presets';

export const adaptRequestsForDateRange = (requests, params) => {
  const adaptedRequests = requests?.map((request) => {
    let numericFilters;

    const facetFilters = request.params?.facetFilters?.filter((facetFilter) => {
      const value = _.isArray(facetFilter) ? facetFilter[0] : facetFilter;
      const [attribute, dateRangeType, dateRangeString] = value?.split(':');

      if (attribute === params?.dateRangeAttribute) {
        numericFilters = convertDateRangeToNumericFilter({
          attribute,
          dateRangeAttribute: params?.dateRangeAttribute,
          dateRangeType,
          dateRangeString,
        });

        return false;
      }

      return true;
    });

    return { ...request, params: { ...request.params, facetFilters, numericFilters } };
  });

  return adaptedRequests;
};

export const convertDateRangeToNumericFilter = ({
  attribute,
  dateRangeAttribute,
  dateRangeType,
  dateRangeString,
}) => {
  const dateRangePresets = makeEntitySearchDateRangeMenuPresets({ attribute });

  let numericFilter;

  if (dateRangeType === 'dateRangePreset') {
    numericFilter = dateRangePresets
      .find(({ value: _value }) => `${dateRangeType}:${dateRangeString}` === _value)
      ?.getAdaptedValue('meilisearch');
  } else if (dateRangeType === 'dateRangeCustom') {
    const [start, end] = dateRangeString.split('|');

    numericFilter = [`${dateRangeAttribute} >= ${start}`, `${dateRangeAttribute} <= ${end}`];
  } else if (dateRangeType === 'dateRangeCustomMultiple') {
    const entries = dateRangeString.split(',');

    numericFilter = entries
      ?.map((entry) => {
        const [start, end] = entry.split('|');

        return [`${dateRangeAttribute} >= ${start}`, `${dateRangeAttribute} <= ${end}`];
      })
      ?.join(' OR ');
  }

  return numericFilter;
};
