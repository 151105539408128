import _ from '@lodash';
import { AgGridReact } from 'ag-grid-react';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import {
  WorkOrderIssueLineItemGridEntitySelect,
  WorkOrderIssueLineItemGridRateTypeSelect,
  WorkOrderIssueLineItemGridSelectOption,
} from './components';
import numeral from 'numeral';

const WorkOrderIssueLineItemGrid = memo(({ workOrderIssue }) => {
  const containerStyle = useMemo(() => ({ padding: 16, width: "100%", height: "400px" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const actionItems = workOrderIssue?.actionItems.map((actionItem) => _.cloneDeep(actionItem));
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([
    ...(actionItems || []),
    // ...[...Array(10).keys()].map(() => ({ id: _.random(0, 100), totalAmount: 750 })),
  ]);

  // TODO:
  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
      defaultMinWidth: 100,
      columnLimits: [
        {
          colId: "country",
          minWidth: 900,
        },
      ],
    };
  }, []);

  // TODO:
  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) =>
      colDef.headerName
        ? `${colDef.headerName}...`
        : `${colDef.field[0].toUpperCase()}${colDef.field.slice(1)}...`,
    []
  );

  // TODO:
  const isEmptyPinnedCell = useCallback(
    ({ node, value }) => node.rowPinned === 'top' && _.isEmpty(value),
    []
  );

  // TODO:
  const [columnDefs, setColumnDefs] = useState([
    {
      cellDataType: 'object',
      cellEditor: WorkOrderIssueLineItemGridEntitySelect,
      cellEditorParams: {
        components: { Option: WorkOrderIssueLineItemGridSelectOption },
        // TODO:
        filter: undefined,
        getOptionLabel: (option) => `${option?.number} (${option?.name})`,
        getOptionValue: (option) => option?.id,
        indexName: 'csi_classifications',
        sort: ['number:asc'],

        onSomething: (params, node) => {
          node?.setData({
            ...node.data,
            csiClassification: {
              ...(node?.data?.csiClassification || {}),
              accountingTaskCode: params?.accountingTaskCode,
            },
          });
          console.log({ params, node });
        },
      },
      editable: true,
      field: 'csiClassification',
      headerName: 'CSI Classification',
      // TODO:
      minWidth: 150,
      sortable: true,
      // TODO:
      suppressKeyboardEvent: (params) => params.editing && ['Enter'].includes(params.event?.key),
      valueFormatter: (params) => {
        if (isEmptyPinnedCell(params)) {
          return createPinnedCellPlaceholder(params);
        }

        return params.value ? `${params.value.number} (${params.value.name})` : undefined;
      },
    },
    {
      cellDataType: 'object',
      editable: false,
      field: 'csiClassification.accountingTaskCode',
      headerName: 'Task Code',
      // TODO:
      minWidth: 150,
      sortable: true,
      suppressNavigable: true,
      valueFormatter: (params) => {
        if (isEmptyPinnedCell(params)) {
          return createPinnedCellPlaceholder(params);
        }

        return params.value ? `${params.value.number} (${params.value.name})` : undefined;
      },
    },
    {
      cellDataType: 'text',
      editable: true,
      field: 'description',
      headerName: 'Description',
      sortable: true,
    },
    {
      cellDataType: 'object',
      cellEditor: WorkOrderIssueLineItemGridRateTypeSelect,
      cellEditorParams: {
        components: { Option: WorkOrderIssueLineItemGridSelectOption },
        // TODO:
        filters: undefined,
        getOptionLabel: (option) => option?.name,
        getOptionValue: (option) => option?.id,
      },
      editable: true,
      field: 'commonStandardRateType',
      headerName: 'Rate Type',
      sortable: true,
      // TODO:
      suppressKeyboardEvent: (params) => params.editing && ['Enter'].includes(params.event?.key),
      valueFormatter: ({ value }) => value?.name || undefined,
    },

    {
      cellDataType: 'number',
      editable: true,
      field: 'quantity',
      headerName: 'Quantity',
      sortable: true,
      // TODO:
      // valueFormatter
    },

    {
      cellDataType: 'number',
      editable: true,
      field: 'rate',
      headerName: 'Rate',
      sortable: true,
      // TODO:
      valueFormatter: ({ value }) =>
        _.isNumber(value) ? numeral(value).format('$0,0.00') : undefined,
    },

    { field: 'totalAmount' },
    { field: 'notToExceedAmount' },

    {
      cellDataType: 'boolean',
      editable: true,
      field: 'includesParts',
    },

    {
      cellDataType: 'boolean',
      cellRendererParams: { disabled: true },
      editable: false,
      field: 'isCapitalExpense',
    },
  ]);

  // TODO:
  const defaultColDef = useMemo(() => {
    return {
      // editable: true,
      flex: 1,
      // minWidth: 100,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined,
    };
  }, []);

  const onCellEditRequest = useCallback((event) => {
    console.log('onCellEditRequest', { event, newValue: event.newValue });
  }, []);

  const gridRef = useRef(null);
  let inputRow = {};
  const getRowStyle = useCallback(
    ({ node }) => (node.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    []
  );
  const onCellEditingStopped = useCallback((params) => {
    console.log('// TODO: onCellEditingStopped', { params });
    if (isPinnedRowDataCompleted(params)) {
      setLoading(true);

      setTimeout(() => {
        // save data
        setRowData([...rowData, inputRow]);
        //reset pinned row
        setInputRow({});

        setLoading(false);
      }, 1000);
    }
    },
    [inputRow, isPinnedRowDataCompleted, rowData]
  );
  function setInputRow(newData) {
    const pinnedTopRow = gridRef.current.api.getPinnedTopRow();

    if (pinnedTopRow) {
      inputRow = newData;
      pinnedTopRow.setData(inputRow);
    }
    // gridRef.current.api.setPinnedTopRowData([inputRow]);
  }

  function isPinnedRowDataCompleted(params) {
    if (params.rowPinned !== 'top') return;
    return true;
    // return columnDefs.every((def) => inputRow[def.field]);
  }

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          autoSizeStrategy={autoSizeStrategy}
          columnDefs={columnDefs}
          // TODO:
          defaultColDef={defaultColDef}
          editType="fullRow"
          getRowStyle={getRowStyle}
          loading={loading}
          // pagination={false}
          // paginationPageSize={5}
          pinnedTopRowData={[inputRow]}
          // readOnlyEdit
          ref={gridRef}
          rowData={rowData}
          // TODO:
          onCellEditingStopped={onCellEditingStopped}
          onCellValueChanged={(params) => {
            console.log('// TODO: onCellValueChanged', { params });
          }}
          // onRowEd
          // onCellEditRequest={onCellEditRequest}
          // TODO:
          // onGridReady={onGridReady}
        />
      </div>
    </div>
  );
});

export default WorkOrderIssueLineItemGrid;
