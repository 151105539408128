import { yupResolver } from '@hookform/resolvers/yup';
import { WorkOrderIssueReviewDecisionSelectField } from 'app/shared-components/WorkOrderIssueReviewDecision';
import { useEffect } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

const defaultValues = {
  decision: null,
};

const schema = yup.object().shape({
  decision: yup.object().nullable().required('A decision must be provided.'),
});

const WorkOrderIssueBatchActionDialogCompleteReviewForm = ({ onIsValidChanged, onSubmit }) => {
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (typeof onIsValidChanged === 'function') {
      onIsValidChanged(isValid);
    }
  }, [isValid, onIsValidChanged]);

  return (
    <FormProvider
      {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
    >
      <form
        className="w-full pt-20"
        id="work-order-issue-batch-action-form"
        name="work-order-issue-batch-action-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="decision"
          render={({ field }) => (
            <WorkOrderIssueReviewDecisionSelectField
              {...field}
              className="mb-24"
              error={errors?.decision}
              filters={null}
              isDisabled={false}
              isClearable
              isMulti={false}
              placeholder="Select a Decision..."
            />
          )}
        />
      </form>
    </FormProvider>
  );
};

export default WorkOrderIssueBatchActionDialogCompleteReviewForm;
