import { faCircleQuestion, faMessage } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleRight,
  faArrowRightArrowLeft,
  faArrowTrendUp,
  faCheck,
  faCircle,
  faEllipsis,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faInbox,
  faMinus,
  faPaperclip,
  faTags,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import {
  Alert,
  AlertTitle,
  Avatar,
  Badge,
  Button,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { amber, blue, green, grey, lightBlue, purple, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useStreamChat } from 'app/providers/stream/chat';
import { ChatChannelCreateDialog } from 'app/shared-components/ChatChannel';
import { CommonChatChannelListPreviewTemp } from 'app/shared-components/Common/CommonChat';
import { ServiceInvoiceCreateDialog } from 'app/shared-components/ServiceInvoice';
import {
  ServiceIssueAddServiceIssueTagDialog,
  ServiceIssueRemoveServiceIssueTagDialog,
} from 'app/shared-components/ServiceIssue';
import {
  ServiceIssueAttachmentCreateDialog,
  ServiceIssueAttachmentDeleteDialog,
} from 'app/shared-components/ServiceIssueAttachment';
import {
  WorkOrderIssueCancelDialog,
  WorkOrderIssueCompleteDialog,
  WorkOrderIssueDeleteDialog,
  WorkOrderIssueNteUpdateDialog,
  WorkOrderIssuePauseDialog,
  WorkOrderIssueRescheduleServiceVisitDialog,
  WorkOrderIssueRescheduleSiteVisitDialog,
  WorkOrderIssueResponseSeverityUpdateDialog,
  WorkOrderIssueResumeDialog,
  WorkOrderIssueScheduleServiceVisitDialog,
  WorkOrderIssueScheduleSiteVisitDialog,
} from 'app/shared-components/WorkOrderIssue';
import { WorkOrderIssueActionItemCreateDialog } from 'app/shared-components/WorkOrderIssueActionItem';
import {
  WorkOrderIssueReviewCompleteDialog,
  WorkOrderIssueReviewCreateDialog,
  WorkOrderIssueReviewRecallDialog,
} from 'app/shared-components/WorkOrderIssueReview';
import { WorkOrderIssueScheduledResumptionUpdateDialog } from 'app/shared-components/WorkOrderIssueScheduledResumption';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import moment from 'moment';
import numeral from 'numeral';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChannelList } from 'stream-chat-react';
// TODO:
// import { WorkOrderViewAppWorkOrderIssueActionItemTable } from '.';
import { WorkOrderIssueLineItemGrid } from 'app/shared-components/WorkOrderIssueLineItem';

const WORK_ORDER_ISSUE_STATUS_ENUM_COLORS = {
  CANCELLED: red[800],
  CLOSED: grey[800],
  COMPLETED: green[800],
  HELD: amber[800],
  IN_PROGRESS: purple[800],
  INVOICED: lightBlue[600],
  OPEN: blue[800],
  PAUSED: amber[800],
  PENDING: blue[800],
  QUOTED: purple[800],
  SCHEDULED: purple[800],
};

const AmberButton = styled(Button)(({ theme }) => {
  const color = amber[800];
  const hover = amber[900];

  return {
    color: 'white',
    backgroundColor: color,
    '&:disabled': {
      color: 'white',
      backgroundColor: color,
    },
    '&:hover': {
      backgroundColor: hover,
    },
  };
});

const BlueButton = styled(Button)(({ theme }) => {
  const color = blue[800];
  const hover = blue[900];

  return {
    color: 'white',
    backgroundColor: color,
    '&:disabled': {
      color: 'white',
      backgroundColor: color,
    },
    '&:hover': {
      backgroundColor: hover,
    },
  };
});

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? grey[200] : '#0a0e17',
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
  top: 64,
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 220,
  },
}));

const PopoverActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderIssueAttachmentBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: '20%',
    top: '20%',
    padding: '0 2px',
  },
}));

const WorkOrderIssueTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? grey[100] : '#0e131f',
  borderColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  height: 64,
}));

const WorkOrderIssueSummaryTableCell = styled(TableCell)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
  color: 'inherit',
  fontSize: 14,
  fontWeight: 500,
  paddingBottom: 32,
}));

const WorkOrderViewAppWorkOrderIssueTable = ({
  canUpdateWorkOrder,
  consumer,
  // TODO:
  deferredUntil,
  handleOpenImageLightbox,
  handleOpenPdfWindow,
  handleOpenVideoJsPlayer,
  handleToggleTable,
  openTables,
  phase,
  serviceTicketId,
  serviceTicketPhaseReview,
  user,
  vendor,
  workOrderIssue,
}) => {
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const [attachmentsMenuAnchorEl, setAttachmentsMenuAnchorEl] = useState(null);
  const attachmentsMenuOpen = Boolean(attachmentsMenuAnchorEl);
  const [channelsCacheKey, setChannelsCacheKey] = useState(moment().unix());
  const [channelsMenuAnchorEl, setChannelsMenuAnchorEl] = useState(null);
  const channelsMenuOpen = Boolean(channelsMenuAnchorEl);
  const [channelsUnreadCounts, setChannelsUnreadCounts] = useState({});
  const dispatch = useDispatch();
  const [informationMenuAnchorEl, setInformationMenuAnchorEl] = useState(null);
  const informationMenuOpen = Boolean(informationMenuAnchorEl);
  const isMounted = useRef(false);
  const mainTheme = useSelector(selectMainTheme);
  const navigate = useNavigate();
  const [responseSeverityMenuAnchorEl, setResponseSeverityMenuAnchorEl] = useState(null);
  const responseSeverityMenuOpen = Boolean(responseSeverityMenuAnchorEl);
  const [resumeMenuAnchorEl, setResumeMenuAnchorEl] = useState(null);
  const resumeMenuOpen = Boolean(resumeMenuAnchorEl);
  const { streamChatClient } = useStreamChat();
  const [tagsMenuAnchorEl, setTagsMenuAnchorEl] = useState(null);
  const tagsMenuOpen = Boolean(tagsMenuAnchorEl);

  useEffect(() => {
    if (!isMounted.current && typeof handleToggleTable === 'function' && workOrderIssue?.id) {
      handleToggleTable({ tableId: workOrderIssue?.id });

      isMounted.current = true;
    }
  }, [handleToggleTable, workOrderIssue?.id]);

  useEffect(() => {
    let subscriptions = [];

    if (streamChatClient) {
      subscriptions = [
        ...subscriptions,
        streamChatClient.on('notification.added_to_channel', (event) => {
          if (event.channel?.targetWorkOrderIssueId === workOrderIssue?.id) {
            setChannelsCacheKey(moment().unix());
          }
        }),
      ];
    }

    return () => {
      subscriptions?.forEach((subscription) => {
        subscription.unsubscribe();
      });
    };
  }, [streamChatClient, workOrderIssue?.id]);

  useEffect(() => {
    let channels = [];
    let subscriptions = [];

    const getChannels = async () => {
      channels = await streamChatClient.queryChannels(
        {
          members: { $in: [user?.id] },
          serviceTicketId,
          targetWorkOrderIssueId: workOrderIssue?.id,
          type: 'service_ticket',
        },
        [{ has_unread: -1 }, { last_message_at: -1 }, { last_updated: -1 }],
        { watch: true }
      );

      subscriptions = channels?.map((channel) => {
        setChannelsUnreadCounts((prev) => ({
          ...(prev || {}),
          [channel.id]: channel.countUnread(),
        }));

        return channel.on('message.new', (event) => {
          setChannelsUnreadCounts((prev) => ({
            ...(prev || {}),
            [channel.id]: channel.countUnread(),
          }));
        });
      });
    };

    if (channelsCacheKey && serviceTicketId && streamChatClient && user?.id && workOrderIssue?.id) {
      getChannels();
    }

    return () => {
      subscriptions?.forEach((subscription) => {
        subscription.unsubscribe();
      });

      channels?.forEach((channel) => {
        channel.stopWatching();
      });
    };
  }, [channelsCacheKey, serviceTicketId, streamChatClient, user?.id, workOrderIssue?.id]);

  const nextServiceVisit = useMemo(() => {
    if (_.isEmpty(workOrderIssue?.serviceVisits)) {
      return null;
    }

    const orderedServiceVisits = _.orderBy(
      workOrderIssue?.serviceVisits || [],
      ({ windowStartAt }) => moment(windowStartAt).unix(),
      ['asc']
    );

    return (
      _.first(
        _.filter(orderedServiceVisits, ({ windowStartAt }) =>
          moment(windowStartAt).isAfter(moment())
        )
      ) || _.last(orderedServiceVisits)
    );
  }, [workOrderIssue?.serviceVisits]);

  const nextSiteVisit = useMemo(() => {
    if (_.isEmpty(workOrderIssue?.siteVisits)) {
      return null;
    }

    const orderedSiteVisits = _.orderBy(
      workOrderIssue?.siteVisits || [],
      ({ windowStartAt }) => moment(windowStartAt).unix(),
      ['asc']
    );

    return (
      _.first(
        _.filter(orderedSiteVisits, ({ windowStartAt }) => moment(windowStartAt).isAfter(moment()))
      ) || _.last(orderedSiteVisits)
    );
  }, [workOrderIssue?.siteVisits]);

  const openWorkOrderIssueReview = useMemo(
    () =>
      workOrderIssue?.reviews?.find(({ isCancelled, isCompleted }) =>
        Boolean(!isCancelled && !isCompleted)
      ),
    [workOrderIssue?.reviews]
  );

  const scheduledResumption = useMemo(
    () =>
      workOrderIssue?.scheduledResumptions?.find(({ isProcessed, resumeAt }) =>
        Boolean(!isProcessed && moment().isBefore(resumeAt))
      ),
    [workOrderIssue?.scheduledResumptions]
  );

  const handleCloseActionMenu = () => setActionMenuAnchorEl(null);

  const handleOpenActionMenu = (event) => setActionMenuAnchorEl(event.currentTarget);

  const handleCloseAttachmentsMenu = (event) => setAttachmentsMenuAnchorEl(null);

  const handleOpenAttachmentsMenu = (event) => setAttachmentsMenuAnchorEl(event.currentTarget);

  const handleCloseChannelsMenu = (event) => setChannelsMenuAnchorEl(null);

  const handleOpenChannelsMenu = (event) => setChannelsMenuAnchorEl(event.currentTarget);

  const handleCloseInformationMenu = (event) => setInformationMenuAnchorEl(null);

  const handleOpenInformationMenu = (event) => setInformationMenuAnchorEl(event.currentTarget);

  const handleCloseResponseSeverityMenu = (event) => setResponseSeverityMenuAnchorEl(null);

  const handleOpenResponseSeverityMenu = (event) =>
    setResponseSeverityMenuAnchorEl(event.currentTarget);

  const handleCloseResumeMenu = () => setResumeMenuAnchorEl(null);

  const handleOpenResumeMenu = ({ currentTarget, selected }) =>
    setResumeMenuAnchorEl(currentTarget);

  const handleCloseTagsMenu = (event) => setTagsMenuAnchorEl(null);

  const handleOpenTagsMenu = (event) => setTagsMenuAnchorEl(event.currentTarget);

  return (
    <TableContainer component={Paper} elevation={0} square sx={{ overflowX: 'initial' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <WorkOrderIssueTableCell colSpan={7}>
              <div className="flex flex-1 items-center">
                <div className="flex flex-1 flex-start items-center">
                  <IconButton
                    className="mr-12 w-32 h-32"
                    onClick={() => handleToggleTable({ tableId: workOrderIssue?.id })}
                  >
                    <FontAwesomeIcon
                      icon={openTables?.[workOrderIssue?.id] ? faAngleDown : faAngleRight}
                      size="sm"
                    />
                  </IconButton>

                  <div className="flex items-center mr-16">
                    <Tooltip title="Response Severity">
                      <IconButton className="w-32 h-32" onClick={handleOpenResponseSeverityMenu}>
                        <FontAwesomeIcon
                          style={{
                            color: workOrderIssue?.serviceIssue?.serviceResponseSeverity.color,
                          }}
                          icon={faCircle}
                          size="xs"
                        />
                      </IconButton>
                    </Tooltip>

                    <Popover
                      anchorEl={responseSeverityMenuAnchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      open={responseSeverityMenuOpen}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      onClose={handleCloseResponseSeverityMenu}
                    >
                      <div
                        className="flex items-center p-16 h-64"
                        style={{
                          backgroundColor:
                            mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                        }}
                      >
                        <Typography className="text-16 font-500">Response Severity</Typography>
                      </div>

                      <div className="p-16 w-288">
                        <div>
                          <Typography className="text-12">Level</Typography>

                          <Typography className="text-14">
                            {workOrderIssue?.serviceIssue?.serviceResponseSeverity.name}
                          </Typography>
                        </div>

                        <div className="mt-16">
                          <Typography className="text-12">Complete By</Typography>

                          <Typography className="text-14">
                            {workOrderIssue?.completeBy &&
                              moment(workOrderIssue?.completeBy).format('MMMM DD, YYYY')}
                          </Typography>
                        </div>
                      </div>

                      {!['CANCELLED', 'CLOSED', 'COMPLETED', 'INVOICED', 'PAUSED'].includes(
                        workOrderIssue?.status?.enum
                      ) &&
                        consumer?.id === user?.data?.organization?.id && (
                          <div
                            className="flex items-center p-16 h-64"
                            style={{
                              backgroundColor:
                                mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                            }}
                          >
                            <PopoverActionButton
                              disabled={false}
                              fullWidth
                              onClick={() =>
                                dispatch(
                                  openDialog({
                                    children: (
                                      <WorkOrderIssueResponseSeverityUpdateDialog
                                        serviceTicketId={serviceTicketId}
                                        workOrderIssueId={workOrderIssue?.id}
                                        onClose={() => dispatch(closeDialog())}
                                      />
                                    ),
                                    classes: {
                                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                                    },
                                  })
                                )
                              }
                            >
                              Update Response Severity
                            </PopoverActionButton>
                          </div>
                        )}
                    </Popover>

                    <Typography className="ml-8 text-15 font-600">
                      {workOrderIssue?.serviceIssue?.name}
                    </Typography>
                  </div>

                  <Tooltip title="General Information">
                    <IconButton className="mr-12 w-32 h-32" onClick={handleOpenInformationMenu}>
                      <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    anchorEl={informationMenuAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={informationMenuOpen}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={handleCloseInformationMenu}
                  >
                    <div
                      className="flex items-center p-16 h-64"
                      style={{
                        backgroundColor: mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                      }}
                    >
                      <Typography className="text-16 font-500">General Information</Typography>
                    </div>

                    <div className="p-16 w-288">
                      {/* ROADMAP: Handle Other Targets */}
                      <div>
                        <Typography className="text-12">Facility Areas</Typography>

                        <Typography className="text-14">
                          {workOrderIssue?.serviceIssue?.targetFacilityAreas
                            ?.map((targetFacilityArea) => targetFacilityArea.name)
                            .join(', ') || 'Not Set'}
                        </Typography>
                      </div>

                      <div className="mt-16">
                        <div>
                          <Typography className="text-12">Description</Typography>
                        </div>

                        <div style={{ maxHeight: 208, overflowY: 'auto' }}>
                          <Typography className="text-14">
                            {workOrderIssue?.serviceIssue?.description || 'Not Set'}
                          </Typography>
                        </div>
                      </div>

                      <div className="mt-16">
                        <Typography className="text-12">Issue Template</Typography>

                        <Typography className="text-14">
                          {workOrderIssue?.issueTemplateWorkOrderIssue?.issueTemplates[0]?.name ||
                            'Not Set'}
                        </Typography>
                      </div>

                      <div className="mt-16">
                        <Typography className="text-12">Work Order Type</Typography>

                        <Typography className="text-14">
                          {workOrderIssue?.accountingWorkOrderType?.name || 'Not Set'}
                        </Typography>
                      </div>

                      <div className="mt-16">
                        <Typography className="text-12">Budget Organization</Typography>

                        <Typography className="text-14">
                          {workOrderIssue?.accountingWorkOrderType?.accountingBudgetOrganization
                            ?.name
                            ? `${workOrderIssue?.accountingWorkOrderType?.accountingBudgetOrganization?.name} (${workOrderIssue?.accountingWorkOrderType?.accountingBudgetOrganization?.number})`
                            : 'Not Set'}
                        </Typography>
                      </div>
                    </div>
                  </Popover>

                  <Tooltip title="Attachments">
                    <IconButton className="mr-12 w-32 h-32" onClick={handleOpenAttachmentsMenu}>
                      <FontAwesomeIcon icon={faPaperclip} size="sm" />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    anchorEl={attachmentsMenuAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={attachmentsMenuOpen}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={handleCloseAttachmentsMenu}
                  >
                    <div
                      className="flex items-center p-16 h-64"
                      style={{
                        backgroundColor: mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                      }}
                    >
                      <Typography className="text-16 font-500">Attachments</Typography>
                    </div>

                    <div className="p-16 w-288">
                      {!_.isEmpty(workOrderIssue?.serviceIssue?.attachments) ? (
                        <div className="flex flex-wrap items-center">
                          {workOrderIssue?.serviceIssue?.attachments?.map(
                            (serviceIssueAttachment) => (
                              <div key={serviceIssueAttachment.id}>
                                {serviceIssueAttachment.commonStoredType?.enum ===
                                  'COMMON_STORED_IMAGE' && (
                                  <WorkOrderIssueAttachmentBadge
                                    badgeContent={
                                      (![
                                        'CANCELLED',
                                        'CLOSED',
                                        'COMPLETED',
                                        'INVOICED',
                                        'PAUSED',
                                      ].includes(workOrderIssue?.status?.enum) &&
                                        (consumer?.id === user?.data?.organization?.id ||
                                          serviceIssueAttachment?.commonStoredImage
                                            ?.commonStoredUpload?.uploader?.id === user?.id)) ||
                                      (['CLOSED', 'COMPLETED', 'INVOICED'].includes(
                                        workOrderIssue?.status?.enum
                                      ) &&
                                        serviceIssueAttachment?.isCompletionAttachment) ? (
                                        <>
                                          {!['CLOSED', 'COMPLETED', 'INVOICED'].includes(
                                            workOrderIssue?.status?.enum
                                          ) &&
                                            (consumer?.id === user?.data?.organization?.id ||
                                              serviceIssueAttachment?.commonStoredImage
                                                ?.commonStoredUpload?.uploader?.id ===
                                                user?.id) && (
                                              <>
                                                <IconButton
                                                  size="small"
                                                  sx={{ padding: 0 }}
                                                  onClick={() =>
                                                    dispatch(
                                                      openDialog({
                                                        children: (
                                                          <ServiceIssueAttachmentDeleteDialog
                                                            serviceIssueId={
                                                              workOrderIssue?.serviceIssue?.id
                                                            }
                                                            serviceIssueAttachmentId={
                                                              serviceIssueAttachment.id
                                                            }
                                                            onClose={() => dispatch(closeDialog())}
                                                          />
                                                        ),
                                                        classes: {
                                                          paper:
                                                            'w-full max-w-640 min-w-320 rounded-8',
                                                        },
                                                      })
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTimes}
                                                    style={{
                                                      width: 10,
                                                      height: 10,
                                                      color: 'white',
                                                    }}
                                                  />
                                                </IconButton>
                                              </>
                                            )}

                                          {['CLOSED', 'COMPLETED', 'INVOICED'].includes(
                                            workOrderIssue?.status?.enum
                                          ) &&
                                            serviceIssueAttachment?.isCompletionAttachment && (
                                              <FontAwesomeIcon icon={faCheck} size="xs" />
                                            )}
                                        </>
                                      ) : null
                                    }
                                    color="secondary"
                                    overlap="circular"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        handleOpenImageLightbox({
                                          slides: [
                                            {
                                              src: serviceIssueAttachment.commonStoredImage
                                                ?.lightboxImageUrl,
                                            },
                                          ],
                                        });
                                      }}
                                    >
                                      <Avatar
                                        src={
                                          serviceIssueAttachment.commonStoredImage
                                            ?.thumbnailImageUrl
                                        }
                                        sx={{ border: '2px solid', width: 42, height: 42 }}
                                      >
                                        <FontAwesomeIcon icon={faFileImage} />
                                      </Avatar>
                                    </IconButton>
                                  </WorkOrderIssueAttachmentBadge>
                                )}

                                {serviceIssueAttachment.commonStoredType?.enum ===
                                  'COMMON_STORED_PDF' && (
                                  <IconButton
                                    onClick={() =>
                                      handleOpenPdfWindow({
                                        pdfUrl:
                                          serviceIssueAttachment.commonStoredPdf?.originalPdfUrl,
                                      })
                                    }
                                  >
                                    <Avatar
                                      src={
                                        serviceIssueAttachment.commonStoredPdf?.thumbnailImageUrl
                                      }
                                      sx={{ border: '2px solid', width: 42, height: 42 }}
                                    >
                                      <FontAwesomeIcon icon={faFilePdf} />
                                    </Avatar>
                                  </IconButton>
                                )}

                                {serviceIssueAttachment.commonStoredType?.enum ===
                                  'COMMON_STORED_VIDEO' && (
                                  <IconButton
                                    onClick={() => {
                                      handleOpenVideoJsPlayer({
                                        options: {
                                          autoplay: false,
                                          controls: true,
                                          responsive: true,
                                          fluid: true,
                                          poster:
                                            serviceIssueAttachment.commonStoredVideo
                                              ?.standardImageUrl,
                                          sources: [
                                            {
                                              src: serviceIssueAttachment.commonStoredVideo
                                                ?.playlistFileUrl,
                                              type: 'application/x-mpegURL',
                                            },
                                          ],
                                          tracks: [
                                            {
                                              src: serviceIssueAttachment.commonStoredVideo
                                                ?.captionsFileUrl,
                                              kind: 'captions',
                                              srclang: 'en',
                                              label: 'English',
                                            },
                                          ],
                                        },
                                      });
                                    }}
                                  >
                                    <Avatar
                                      src={
                                        serviceIssueAttachment.commonStoredVideo?.thumbnailImageUrl
                                      }
                                      sx={{ border: '2px solid', width: 42, height: 42 }}
                                    >
                                      <FontAwesomeIcon icon={faFileVideo} />
                                    </Avatar>
                                  </IconButton>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <Alert severity="info">No Attachments Found</Alert>
                      )}
                    </div>

                    {!['CANCELLED', 'CLOSED', 'COMPLETED', 'INVOICED', 'PAUSED'].includes(
                      workOrderIssue?.status?.enum
                    ) && (
                      <div
                        className="flex items-center p-16 h-64"
                        style={{
                          backgroundColor:
                            mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                        }}
                      >
                        <PopoverActionButton
                          disabled={false}
                          fullWidth
                          onClick={() =>
                            dispatch(
                              openDialog({
                                children: (
                                  <ServiceIssueAttachmentCreateDialog
                                    serviceIssueId={workOrderIssue?.serviceIssue?.id}
                                    onClose={() => dispatch(closeDialog())}
                                  />
                                ),
                                classes: {
                                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                                },
                              })
                            )
                          }
                        >
                          Add Attachment
                        </PopoverActionButton>
                      </div>
                    )}
                  </Popover>

                  <Tooltip title="Tags">
                    <IconButton className="mr-12 w-32 h-32" onClick={handleOpenTagsMenu}>
                      <FontAwesomeIcon icon={faTags} size="sm" />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    anchorEl={tagsMenuAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={tagsMenuOpen}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={handleCloseTagsMenu}
                  >
                    <div
                      className="flex items-center p-16 h-64"
                      style={{
                        backgroundColor: mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                      }}
                    >
                      <Typography className="text-16 font-500">Tags</Typography>
                    </div>

                    <div className="p-16 w-288">
                      {!_.isEmpty(workOrderIssue?.serviceIssue?.tags) ? (
                        <div className="flex flex-wrap items-center">
                          {workOrderIssue?.serviceIssue?.tags?.map((serviceIssueTag) => {
                            const options = {};

                            if (
                              !['CANCELLED', 'CLOSED', 'COMPLETED', 'INVOICED', 'PAUSED'].includes(
                                workOrderIssue?.status?.enum
                              ) &&
                              consumer?.id === user?.data?.organization?.id
                            ) {
                              options.onDelete = () =>
                                dispatch(
                                  openDialog({
                                    children: (
                                      <ServiceIssueRemoveServiceIssueTagDialog
                                        serviceIssueId={workOrderIssue?.serviceIssue?.id}
                                        serviceIssueTagId={serviceIssueTag.id}
                                        onClose={() => dispatch(closeDialog())}
                                      />
                                    ),
                                    classes: {
                                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                                    },
                                  })
                                );
                            }

                            return (
                              <Chip
                                className="mx-4 my-8"
                                key={serviceIssueTag.id}
                                label={serviceIssueTag.name}
                                {...options}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <Alert severity="info">No Tags Found</Alert>
                      )}
                    </div>

                    {!['CANCELLED', 'CLOSED', 'COMPLETED', 'INVOICED', 'PAUSED'].includes(
                      workOrderIssue?.status?.enum
                    ) &&
                      consumer?.id === user?.data?.organization?.id && (
                        <div
                          className="flex items-center p-16 h-64"
                          style={{
                            backgroundColor:
                              mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                          }}
                        >
                          <PopoverActionButton
                            disabled={false}
                            fullWidth
                            onClick={() =>
                              dispatch(
                                openDialog({
                                  children: (
                                    <ServiceIssueAddServiceIssueTagDialog
                                      serviceIssueId={workOrderIssue?.serviceIssue?.id}
                                      onClose={() => dispatch(closeDialog())}
                                    />
                                  ),
                                  classes: {
                                    paper: 'w-full max-w-640 min-w-320 rounded-8',
                                  },
                                })
                              )
                            }
                          >
                            Add Tag
                          </PopoverActionButton>
                        </div>
                      )}
                  </Popover>

                  <Tooltip title="Channels">
                    <IconButton className="mr-12 w-32 h-32" onClick={handleOpenChannelsMenu}>
                      <Badge
                        color="secondary"
                        badgeContent={Object.keys(channelsUnreadCounts).reduce(
                          (acc, key) => acc + channelsUnreadCounts[key],
                          0
                        )}
                      >
                        <FontAwesomeIcon icon={faMessage} size="sm" />
                      </Badge>
                    </IconButton>
                  </Tooltip>

                  <Popover
                    anchorEl={channelsMenuAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={channelsMenuOpen}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={handleCloseChannelsMenu}
                  >
                    <div
                      className="flex items-center p-16 h-64"
                      style={{
                        backgroundColor: mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                      }}
                    >
                      <Typography className="text-16 font-500">Channels</Typography>
                    </div>

                    <div className="w-320 max-h-256 overflow-y-auto">
                      <ChannelList
                        // TODO:
                        showChannelSearch={false}
                        channelRenderFilterFn={(channels) =>
                          channels.filter(
                            (channel) =>
                              // TODO: Add `workOrderId`
                              channel.data?.serviceTicketId === serviceTicketId &&
                              channel.data?.type === 'service_ticket'
                          )
                        }
                        filters={{
                          members: { $in: [user?.id] },
                          serviceTicketId,
                          targetWorkOrderIssueId: workOrderIssue?.id,
                          type: 'service_ticket',
                        }}
                        // TODO: Implement or Remove
                        options={{
                          // TODO: Auto-Load
                          limit: 20,
                        }}
                        Preview={CommonChatChannelListPreviewTemp}
                        setActiveChannelOnMount={false}
                        sort={[{ has_unread: -1 }, { last_message_at: -1 }, { last_updated: -1 }]}
                      />
                    </div>

                    {!['CANCELLED', 'CLOSED', 'PAUSED'].includes(workOrderIssue?.status?.enum) && (
                      <div
                        className="flex items-center p-12"
                        style={{
                          backgroundColor:
                            mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
                        }}
                      >
                        <PopoverActionButton
                          disabled={false}
                          fullWidth
                          onClick={() =>
                            dispatch(
                              openDialog({
                                children: (
                                  <ChatChannelCreateDialog
                                    serviceTicketId={serviceTicketId}
                                    workOrderIssue={workOrderIssue}
                                    onClose={() => dispatch(closeDialog())}
                                    // TODO: Implement or Remove
                                    // onCreate={handleCreateChatChannel}
                                  />
                                ),
                                classes: {
                                  paper: 'w-full max-w-640 min-w-320 rounded-8',
                                },
                              })
                            )
                          }
                        >
                          Create New Channel
                        </PopoverActionButton>
                      </div>
                    )}
                  </Popover>
                </div>

                {/* ----------------------------------------- */}

                <div className="flex flex-end items-center">
                  {/* TODO: Review */}
                  {['IN_PROGRESS'].includes(phase?.enum) &&
                    ['PAUSED'].includes(workOrderIssue?.status?.enum) && (
                      <>
                        <BlueButton
                          disableElevation
                          disabled={consumer?.id !== user?.data?.organization?.id}
                          size="small"
                          variant="contained"
                          onClick={({ currentTarget }) =>
                            handleOpenResumeMenu({
                              currentTarget,
                              selected: workOrderIssue?.id,
                            })
                          }
                        >
                          {`Resumes: ${
                            scheduledResumption?.resumeAt
                              ? moment(scheduledResumption?.resumeAt).format('MMM. DD, YYYY')
                              : 'Not Set'
                          }`}
                        </BlueButton>

                        <Menu
                          anchorEl={resumeMenuAnchorEl}
                          id="resume-menu"
                          open={resumeMenuOpen}
                          onClose={handleCloseResumeMenu}
                        >
                          {consumer?.id === user?.data?.organization?.id &&
                            ['IN_PROGRESS'].includes(phase?.enum) &&
                            ['PAUSED'].includes(workOrderIssue?.status?.enum) &&
                            scheduledResumption && (
                              <MenuItem
                                disabled={consumer?.id !== user?.data?.organization?.id}
                                onClick={() => {
                                  handleCloseResumeMenu();

                                  // TODO:
                                  dispatch(
                                    openDialog({
                                      children: (
                                        <WorkOrderIssueScheduledResumptionUpdateDialog
                                          serviceTicketId={serviceTicketId}
                                          workOrderIssueId={workOrderIssue?.id}
                                          workOrderIssueScheduledResumptionId={
                                            scheduledResumption?.id
                                          }
                                          onClose={() => dispatch(closeDialog())}
                                        />
                                      ),
                                      classes: {
                                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                                      },
                                    })
                                  );
                                }}
                              >
                                Edit Resumption
                              </MenuItem>
                            )}

                          {consumer?.id === user?.data?.organization?.id &&
                            ['IN_PROGRESS'].includes(phase?.enum) &&
                            ['PAUSED'].includes(workOrderIssue?.status?.enum) && (
                              <MenuItem
                                disabled={consumer?.id !== user?.data?.organization?.id}
                                onClick={() => {
                                  handleCloseResumeMenu();

                                  // TODO:
                                  dispatch(
                                    openDialog({
                                      children: (
                                        <WorkOrderIssueResumeDialog
                                          serviceTicketId={serviceTicketId}
                                          showScheduledResumption={false}
                                          workOrderIssueId={workOrderIssue?.id}
                                          onClose={() => dispatch(closeDialog())}
                                        />
                                      ),
                                      classes: {
                                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                                      },
                                    })
                                  );
                                }}
                              >
                                Resume Now
                              </MenuItem>
                            )}

                          {consumer?.id === user?.data?.organization?.id &&
                            ['IN_PROGRESS'].includes(phase?.enum) &&
                            ['PAUSED'].includes(workOrderIssue?.status?.enum) &&
                            !scheduledResumption && (
                              <MenuItem
                                disabled={consumer?.id !== user?.data?.organization?.id}
                                onClick={() => {
                                  handleCloseResumeMenu();

                                  // TODO:
                                  dispatch(
                                    openDialog({
                                      children: (
                                        <WorkOrderIssueResumeDialog
                                          serviceTicketId={serviceTicketId}
                                          showScheduledResumption
                                          workOrderIssueId={workOrderIssue?.id}
                                          onClose={() => dispatch(closeDialog())}
                                        />
                                      ),
                                      classes: {
                                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                                      },
                                    })
                                  );
                                }}
                              >
                                Schedule Resumption
                              </MenuItem>
                            )}
                        </Menu>
                      </>
                    )}

                  {openWorkOrderIssueReview && (
                    <>
                      <Tooltip title="Pending Work Order Issue Review">
                        <Avatar className="ml-12 w-28 h-28" sx={{ backgroundColor: amber[800] }}>
                          <FontAwesomeIcon className="text-white" icon={faInbox} size="xs" />
                        </Avatar>
                      </Tooltip>
                    </>
                  )}

                  {['IN_PROGRESS', 'QUOTING'].includes(phase?.enum) &&
                    // TODO: Add OPEN / Remove QUOTED?
                    ['IN_PROGRESS', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(workOrderIssue?.status?.enum) && (
                      <>
                        {workOrderIssue?.totalAmountCheck?.enum === 'APPROVED' && (
                          <Tooltip title="Approved">
                            <Avatar
                              className="ml-12 w-28 h-28"
                              sx={{ backgroundColor: green[800] }}
                            >
                              <FontAwesomeIcon className="text-white" icon={faCheck} size="xs" />
                            </Avatar>
                          </Tooltip>
                        )}

                        {workOrderIssue?.totalAmountCheck?.enum === 'OVERAGE' && (
                          <Tooltip title="Total Amount Exceeds NTE">
                            <Avatar className="ml-12 w-28 h-28" sx={{ backgroundColor: red[800] }}>
                              <FontAwesomeIcon
                                className="text-white"
                                icon={faArrowTrendUp}
                                size="xs"
                              />
                            </Avatar>
                          </Tooltip>
                        )}

                        {workOrderIssue?.totalAmountCheck?.enum === 'MANUAL' && (
                          <Avatar className="ml-12 w-28 h-28" sx={{ backgroundColor: amber[800] }}>
                            <Tooltip title="No Prior Approved Amount">
                              <FontAwesomeIcon
                                className="text-white"
                                icon={faArrowRightArrowLeft}
                                size="xs"
                              />
                            </Tooltip>
                          </Avatar>
                        )}

                        {workOrderIssue?.totalAmountCheck?.enum === 'PENDING' && (
                          <Tooltip title="Total Amount Not Set">
                            <Avatar className="ml-12 w-28 h-28" sx={{ backgroundColor: grey[800] }}>
                              <FontAwesomeIcon className="text-white" icon={faMinus} size="xs" />
                            </Avatar>
                          </Tooltip>
                        )}
                      </>
                    )}

                  {/* TODO: Add RELEASE? */}
                  {['QUOTING', 'SITE_VISIT'].includes(phase?.enum) &&
                    // TODO: Add OPEN
                    ['PENDING', 'SCHEDULED'].includes(workOrderIssue?.status?.enum) && (
                      <>
                        {nextSiteVisit ? (
                          <HtmlTooltip
                            title={
                              <>
                                <Typography className="mb-12 text-15 font-500" color="inherit">
                                  Site Visit
                                </Typography>

                                <Typography className="text-10 uppercase" color="inherit">
                                  Arrival Window Start
                                </Typography>

                                <Typography
                                  className="flex items-center mb-6 text-14"
                                  color="inherit"
                                >
                                  <FontAwesomeIcon
                                    className="mr-8 text-grey-800"
                                    icon={faCircle}
                                    size="sm"
                                  />

                                  {moment(nextSiteVisit?.windowStartAt).format(
                                    'MMM. DD, YYYY @ hh:mm A'
                                  )}
                                </Typography>

                                <Typography className="text-10 uppercase" color="inherit">
                                  Arrival Window End
                                </Typography>

                                <Typography
                                  className="flex items-center mb-6 text-14"
                                  color="inherit"
                                >
                                  <FontAwesomeIcon
                                    className="mr-8 text-grey-800"
                                    icon={faCircle}
                                    size="sm"
                                  />

                                  {moment(nextSiteVisit?.windowEndAt).format(
                                    'MMM. DD, YYYY @ hh:mm A'
                                  )}
                                </Typography>
                              </>
                            }
                          >
                            <div className="ml-12">
                              <BlueButton
                                disableElevation
                                disabled={!canUpdateWorkOrder}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  dispatch(
                                    openDialog({
                                      children: (
                                        <WorkOrderIssueRescheduleSiteVisitDialog
                                          removeFromSiteVisitId={nextSiteVisit?.id}
                                          serviceTicketId={serviceTicketId}
                                          workOrderIssueId={workOrderIssue?.id}
                                          onClose={() => dispatch(closeDialog())}
                                        />
                                      ),
                                      classes: {
                                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                                      },
                                    })
                                  )
                                }
                              >
                                {moment(nextSiteVisit?.windowStartAt).format('MMM. DD, YYYY')}
                              </BlueButton>
                            </div>
                          </HtmlTooltip>
                        ) : (
                          <div className="ml-12">
                            <AmberButton
                              disableElevation
                              disabled={!canUpdateWorkOrder}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                dispatch(
                                  openDialog({
                                    children: (
                                      <WorkOrderIssueScheduleSiteVisitDialog
                                        defaultWorkOrderIssueId={workOrderIssue?.id}
                                        serviceTicketId={serviceTicketId}
                                        onClose={() => dispatch(closeDialog())}
                                      />
                                    ),
                                    classes: {
                                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                                    },
                                  })
                                )
                              }
                            >
                              Not Scheduled
                            </AmberButton>
                          </div>
                        )}
                      </>
                    )}

                  {/* TODO: */}
                  {((phase?.enum === 'SCHEDULING' &&
                    workOrderIssue?.status?.enum !== 'CANCELLED') ||
                    (phase?.enum === 'IN_PROGRESS' &&
                      ['SCHEDULED', 'IN_PROGRESS'].includes(workOrderIssue?.status?.enum))) && (
                    <>
                      {nextServiceVisit ? (
                        <HtmlTooltip
                          title={
                            <>
                              <Typography className="mb-12 text-15 font-500" color="inherit">
                                Service Visit
                              </Typography>

                              <Typography className="text-10 uppercase" color="inherit">
                                Arrival Window Start
                              </Typography>

                              <Typography
                                className="flex items-center mb-6 text-14"
                                color="inherit"
                              >
                                <FontAwesomeIcon
                                  className="mr-8 text-grey-800"
                                  icon={faCircle}
                                  size="sm"
                                />

                                {moment(nextServiceVisit?.windowStartAt).format(
                                  'MMM. DD, YYYY @ hh:mm A'
                                )}
                              </Typography>

                              <Typography className="text-10 uppercase" color="inherit">
                                Arrival Window End
                              </Typography>

                              <Typography
                                className="flex items-center mb-6 text-14"
                                color="inherit"
                              >
                                <FontAwesomeIcon
                                  className="mr-8 text-grey-800"
                                  icon={faCircle}
                                  size="sm"
                                />

                                {moment(nextServiceVisit?.windowEndAt).format(
                                  'MMM. DD, YYYY @ hh:mm A'
                                )}
                              </Typography>
                            </>
                          }
                        >
                          <div className="ml-12">
                            <BlueButton
                              disableElevation
                              disabled={!canUpdateWorkOrder}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                dispatch(
                                  openDialog({
                                    children: (
                                      <WorkOrderIssueRescheduleServiceVisitDialog
                                        removeFromServiceVisitId={nextServiceVisit?.id}
                                        serviceTicketId={serviceTicketId}
                                        workOrderIssueId={workOrderIssue?.id}
                                        onClose={() => dispatch(closeDialog())}
                                      />
                                    ),
                                    classes: {
                                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                                    },
                                  })
                                )
                              }
                            >
                              {moment(nextServiceVisit?.windowStartAt).format('MMM. DD, YYYY')}
                            </BlueButton>
                          </div>
                        </HtmlTooltip>
                      ) : (
                        <div className="ml-12">
                          <AmberButton
                            disableElevation
                            disabled={!canUpdateWorkOrder}
                            size="small"
                            variant="contained"
                            onClick={() =>
                              dispatch(
                                openDialog({
                                  children: (
                                    <WorkOrderIssueScheduleServiceVisitDialog
                                      defaultWorkOrderIssueId={workOrderIssue?.id}
                                      serviceTicketId={serviceTicketId}
                                      onClose={() => dispatch(closeDialog())}
                                    />
                                  ),
                                  classes: {
                                    paper: 'w-full max-w-640 min-w-320 rounded-8',
                                  },
                                })
                              )
                            }
                          >
                            Not Scheduled
                          </AmberButton>
                        </div>
                      )}
                    </>
                  )}

                  {phase?.enum === 'IN_PROGRESS' &&
                    ['CLOSED', 'COMPLETED', 'INVOICED'].includes(workOrderIssue?.status?.enum) && (
                      <>
                        {workOrderIssue?.serviceInvoice ? (
                          <div className="ml-12">
                            <BlueButton
                              disableElevation
                              disabled={false}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                navigate(
                                  `../activity/service-invoices/view/${workOrderIssue?.serviceInvoice?.id}`
                                )
                              }
                            >
                              {`VRN: ${_.truncate(
                                workOrderIssue?.serviceInvoice.vendorReferenceNumber,
                                { length: 12 }
                              )}`}
                            </BlueButton>
                          </div>
                        ) : (
                          <div className="ml-12">
                            <AmberButton
                              disableElevation
                              disabled={!canUpdateWorkOrder}
                              size="small"
                              variant="contained"
                              onClick={() =>
                                dispatch(
                                  openDialog({
                                    children: (
                                      <ServiceInvoiceCreateDialog
                                        defaultWorkOrderIssueId={workOrderIssue?.id}
                                        serviceTicketId={serviceTicketId}
                                        onClose={() => dispatch(closeDialog())}
                                      />
                                    ),
                                    classes: {
                                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                                    },
                                  })
                                )
                              }
                            >
                              Not Invoiced
                            </AmberButton>
                          </div>
                        )}
                      </>
                    )}

                  <Chip
                    className="ml-12"
                    label={workOrderIssue?.status?.name}
                    sx={{
                      color: 'white',
                      backgroundColor:
                        WORK_ORDER_ISSUE_STATUS_ENUM_COLORS[workOrderIssue?.status?.enum] ||
                        grey[800],
                    }}
                  />

                  {!serviceTicketPhaseReview &&
                    !openWorkOrderIssueReview &&
                    // TODO: Whole Block
                    ['HELD', 'IN_PROGRESS', 'OPEN', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(
                      workOrderIssue?.status?.enum
                    ) &&
                    ((canUpdateWorkOrder && ['DRAFT', 'IN_PROGRESS'].includes(phase?.enum)) ||
                      (consumer?.id === user?.data?.organization?.id &&
                        // TODO: RELEASE?
                        ['IN_PROGRESS', 'QUOTING', 'RELEASE', 'SITE_VISIT'].includes(phase?.enum))) && (
                      <>
                        <IconButton
                          className="ml-12 w-32 h-32"
                          onClick={({ currentTarget }) =>
                            handleOpenActionMenu({
                              currentTarget,
                              selected: workOrderIssue?.id,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faEllipsis} size="sm" />
                        </IconButton>

                        <Menu
                          anchorEl={actionMenuAnchorEl}
                          id="action-menu"
                          open={actionMenuOpen}
                          onClose={handleCloseActionMenu}
                        >
                          {canUpdateWorkOrder &&
                            phase?.enum === 'DRAFT' &&
                            _.isEmpty(workOrderIssue?.actionItems) && (
                              <MenuItem
                                disabled={!canUpdateWorkOrder}
                                onClick={() => {
                                  handleCloseActionMenu();

                                  dispatch(
                                    openDialog({
                                      children: (
                                        <WorkOrderIssueNteUpdateDialog
                                          serviceTicketId={serviceTicketId}
                                          workOrderIssueId={workOrderIssue?.id}
                                          onClose={() => dispatch(closeDialog())}
                                        />
                                      ),
                                      classes: {
                                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                                      },
                                    })
                                  );
                                }}
                              >
                                Set Not to Exceed
                              </MenuItem>
                            )}

                          {canUpdateWorkOrder && phase?.enum === 'DRAFT' && (
                            <MenuItem
                              disabled={!canUpdateWorkOrder}
                              onClick={() => {
                                handleCloseActionMenu();

                                dispatch(
                                  openDialog({
                                    children: (
                                      <WorkOrderIssueDeleteDialog
                                        serviceTicketId={serviceTicketId}
                                        workOrderIssueId={workOrderIssue?.id}
                                        onClose={() => dispatch(closeDialog())}
                                      />
                                    ),
                                    classes: {
                                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                                    },
                                  })
                                );
                              }}
                            >
                              Delete Work Order Issue
                            </MenuItem>
                          )}

                          {consumer?.id === user?.data?.organization?.id &&
                            // TODO: RELEASE?
                            ['IN_PROGRESS', 'QUOTING', 'RELEASE', 'SITE_VISIT'].includes(phase?.enum) && (
                              <MenuItem
                                disabled={consumer?.id !== user?.data?.organization?.id}
                                onClick={() => {
                                  handleCloseActionMenu();

                                  dispatch(
                                    openDialog({
                                      children: (
                                        <WorkOrderIssueCancelDialog
                                          serviceTicketId={serviceTicketId}
                                          workOrderIssueId={workOrderIssue?.id}
                                          onClose={() => dispatch(closeDialog())}
                                        />
                                      ),
                                      classes: {
                                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                                      },
                                    })
                                  );
                                }}
                              >
                                Cancel Work Order Issue
                              </MenuItem>
                            )}

                          {/* ROADMAP: Implement Vendor Request Cancellation Process */}

                          {/* TODO:  */}
                          {consumer?.id === user?.data?.organization?.id &&
                            // TODO: Add Status Check for IN_PROGRESS SCHEDULED
                            ['IN_PROGRESS'].includes(phase?.enum) && (
                              <MenuItem
                                disabled={consumer?.id !== user?.data?.organization?.id}
                                onClick={() => {
                                  handleCloseActionMenu();

                                  dispatch(
                                    openDialog({
                                      children: (
                                        <WorkOrderIssuePauseDialog
                                          serviceTicketId={serviceTicketId}
                                          workOrderIssueId={workOrderIssue?.id}
                                          onClose={() => dispatch(closeDialog())}
                                        />
                                      ),
                                      classes: {
                                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                                      },
                                    })
                                  );
                                }}
                              >
                                Pause Work Order Issue
                              </MenuItem>
                            )}
                        </Menu>
                      </>
                    )}
                </div>
              </div>
            </WorkOrderIssueTableCell>
          </TableRow>

          <TableRow>
            <HeaderTableCell sx={{ width: '34%' }}>Action Item</HeaderTableCell>

            <HeaderTableCell sx={{ width: '12%' }} align="right">
              Qty.
            </HeaderTableCell>

            <HeaderTableCell sx={{ width: '12%' }} align="right">
              Rate
            </HeaderTableCell>

            <HeaderTableCell sx={{ width: '12%' }} align="right">
              Disc.
            </HeaderTableCell>

            <HeaderTableCell sx={{ width: '12%' }} align="right">
              Total
            </HeaderTableCell>

            <HeaderTableCell sx={{ width: '12%' }} align="right">
              NTE
            </HeaderTableCell>

            <HeaderTableCell sx={{ width: '6%' }} />
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell colSpan={7} sx={{ padding: 0 }}>
              <Collapse in={openTables?.[workOrderIssue?.id]} timeout="auto" unmountOnExit>
                <>
                  {openWorkOrderIssueReview ? (
                    <>
                      {/* TODO: Review */}
                      {['IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                        consumer?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={
                                <Button
                                  className="mr-8"
                                  disabled={false}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <WorkOrderIssueReviewCompleteDialog
                                            defaultWorkOrderIssueId={workOrderIssue?.id}
                                            serviceTicketId={serviceTicketId}
                                            onClose={() => dispatch(closeDialog())}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    )
                                  }
                                >
                                  Complete Review
                                </Button>
                              }
                              classes={{ action: 'items-center' }}
                              severity="warning"
                            >
                              <AlertTitle>Complete Service Issue Review</AlertTitle>
                              Review service issue and provide an outcome decision.
                            </Alert>

                            <StyledDivider />
                          </>
                        )}

                      {/* TODO: Review */}
                      {['IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                        vendor?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={
                                <Button
                                  className="mr-8"
                                  disabled={false}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <WorkOrderIssueReviewRecallDialog
                                            defaultWorkOrderIssueId={workOrderIssue?.id}
                                            serviceTicketId={serviceTicketId}
                                            onClose={() => dispatch(closeDialog())}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    )
                                  }
                                >
                                  Recall Review
                                </Button>
                              }
                              classes={{ action: 'items-center' }}
                              severity="info"
                            >
                              <AlertTitle>Recall Request For Review</AlertTitle>
                              Cancel complete service issue review task and reopen issue for
                              editing.
                            </Alert>

                            <StyledDivider />
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {/* TODO: Add to Header / Expand to Include Empty */}
                      {['QUOTING', 'IN_PROGRESS'].includes(phase.enum) &&
                        // TODO: OPEN
                        ['IN_PROGRESS', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(
                          workOrderIssue?.status?.enum
                        ) &&
                        // TODO: Review
                        // workOrderIssue.actionItems &&
                        (_.isEmpty(workOrderIssue.actionItems) ||
                          _.some(
                            workOrderIssue.actionItems || [],
                            ({ totalAmount }) => !totalAmount
                          )) &&
                        vendor?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={null}
                              classes={{ action: 'items-center' }}
                              severity="warning"
                            >
                              {/* TODO: Expand for Empty State */}
                              <AlertTitle>Total Amount Required</AlertTitle>
                              One or more action items require a total amount to be entered.
                            </Alert>

                            <StyledDivider />
                          </>
                        )}

                      {/* TODO: Remove */}
                      {/* {phase.enum === 'QUOTING' &&
                        ['APPROVED'].includes(workOrderIssue?.totalAmountCheck?.enum) &&
                        // TODO:
                        ['PENDING', 'SCHEDULED'].includes(workOrderIssue?.status?.enum) &&
                        workOrderIssue.actionItems &&
                        !_.some(
                          workOrderIssue.actionItems || [],
                          ({ totalAmount }) => !totalAmount
                        ) &&
                        vendor?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={
                                <Button
                                  className="mr-8"
                                  disabled={false}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <WorkOrderIssueCompleteDialog
                                            defaultWorkOrderIssueId={workOrderIssue?.id}
                                            serviceTicketId={serviceTicketId}
                                            onClose={() => dispatch(closeDialog())}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    )
                                  }
                                >
                                  Complete Quote
                                </Button>
                              }
                              classes={{ action: 'items-center' }}
                              severity="warning"
                            >
                              <AlertTitle>Eligible for Completion</AlertTitle>
                              Service issue quote is eligible for completion.
                            </Alert>

                            <StyledDivider />
                          </>
                        )} */}

                      {/* TODO: */}
                      {phase.enum === 'IN_PROGRESS' &&
                        ['APPROVED'].includes(workOrderIssue?.totalAmountCheck?.enum) &&
                        // TODO: OPEN
                        ['IN_PROGRESS', 'SCHEDULED'].includes(workOrderIssue?.status?.enum) &&
                        workOrderIssue.actionItems &&
                        !_.some(
                          workOrderIssue.actionItems || [],
                          ({ totalAmount }) => !totalAmount
                        ) &&
                        vendor?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={
                                <Button
                                  className="mr-8"
                                  disabled={false}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <WorkOrderIssueCompleteDialog
                                            defaultWorkOrderIssueId={workOrderIssue?.id}
                                            serviceTicketId={serviceTicketId}
                                            onClose={() => dispatch(closeDialog())}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    )
                                  }
                                >
                                  Complete Issue
                                </Button>
                              }
                              classes={{ action: 'items-center' }}
                              severity="warning"
                            >
                              <AlertTitle>Eligible for Completion</AlertTitle>
                              Service issue is eligible for completion upon on-site work completion.
                            </Alert>

                            <StyledDivider />
                          </>
                        )}

                      {/* TODO: */}
                      {['IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                        ['MANUAL', 'OVERAGE'].includes(workOrderIssue?.totalAmountCheck?.enum) &&
                        // TODO: OPEN
                        ['IN_PROGRESS', 'PENDING', 'SCHEDULED'].includes(workOrderIssue?.status?.enum) &&
                        workOrderIssue.actionItems &&
                        !_.some(
                          workOrderIssue.actionItems || [],
                          ({ totalAmount }) => !totalAmount
                        ) &&
                        vendor?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={
                                <Button
                                  className="mr-8"
                                  disabled={false}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <WorkOrderIssueReviewCreateDialog
                                            defaultWorkOrderIssueId={workOrderIssue?.id}
                                            serviceTicketId={serviceTicketId}
                                            onClose={() => dispatch(closeDialog())}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    )
                                  }
                                >
                                  Submit Review
                                </Button>
                              }
                              classes={{ action: 'items-center' }}
                              severity="warning"
                            >
                              <AlertTitle>Submit Service Issue for Review</AlertTitle>
                              Service issue exceeds its approved amount and requires review.
                            </Alert>

                            <StyledDivider />
                          </>
                        )}

                      {phase.enum === 'IN_PROGRESS' &&
                        ['COMPLETED'].includes(workOrderIssue?.status?.enum) &&
                        !workOrderIssue.serviceInvoice &&
                        vendor?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={
                                <Button
                                  className="mr-8"
                                  disabled={false}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    dispatch(
                                      openDialog({
                                        children: (
                                          <ServiceInvoiceCreateDialog
                                            defaultWorkOrderIssueId={workOrderIssue?.id}
                                            serviceTicketId={serviceTicketId}
                                            onClose={() => dispatch(closeDialog())}
                                          />
                                        ),
                                        classes: {
                                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                                        },
                                      })
                                    )
                                  }
                                >
                                  Create Invoice
                                </Button>
                              }
                              classes={{ action: 'items-center' }}
                              severity="warning"
                            >
                              <AlertTitle>Generate/Submit Invoice</AlertTitle>
                              Provide vendor reference number and generate invoice for submission.
                            </Alert>

                            <StyledDivider />
                          </>
                        )}

                      {phase.enum === 'IN_PROGRESS' &&
                        ['INVOICED'].includes(workOrderIssue?.status?.enum) &&
                        workOrderIssue.serviceInvoice &&
                        consumer?.id === user?.data?.organization?.id && (
                          <>
                            <Alert
                              square
                              action={
                                <Button
                                  className="mr-8"
                                  disabled={false}
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    navigate(
                                      `../activity/service-invoices/view/${workOrderIssue?.serviceInvoice?.id}`
                                    )
                                  }
                                >
                                  View Invoice
                                </Button>
                              }
                              classes={{ action: 'items-center' }}
                              severity="warning"
                            >
                              <AlertTitle>Confirm Invoice</AlertTitle>
                              Confirm invoice payment and close service issue.
                            </Alert>

                            <StyledDivider />
                          </>
                        )}
                    </>
                  )}

                  {/* TODO: */}
                  {/* <WorkOrderViewAppWorkOrderIssueActionItemTable
                    canUpdateWorkOrder={canUpdateWorkOrder}
                    handleOpenImageLightbox={handleOpenImageLightbox}
                    handleOpenPdfWindow={handleOpenPdfWindow}
                    handleOpenVideoJsPlayer={handleOpenVideoJsPlayer}
                    openWorkOrderIssueReview={openWorkOrderIssueReview}
                    phase={phase}
                    serviceTicketId={serviceTicketId}
                    workOrderIssue={workOrderIssue}
                  /> */}
                  <WorkOrderIssueLineItemGrid workOrderIssue={workOrderIssue} />
                </>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>

        <TableFooter>
          <TableRow>
            <WorkOrderIssueSummaryTableCell colSpan={3}>
              {canUpdateWorkOrder &&
                !openWorkOrderIssueReview &&
                ['DRAFT', 'IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                ['IN_PROGRESS', 'OPEN', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(
                  workOrderIssue?.status?.enum
                ) && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      dispatch(
                        openDialog({
                          children: (
                            <WorkOrderIssueActionItemCreateDialog
                              serviceTicketId={serviceTicketId}
                              workOrderIssueId={workOrderIssue?.id}
                              onClose={() => dispatch(closeDialog())}
                            />
                          ),
                          classes: {
                            paper: 'w-full max-w-640 min-w-320 rounded-8',
                          },
                        })
                      )
                    }
                  >
                    Add Action Item
                  </Button>
                )}
            </WorkOrderIssueSummaryTableCell>

            <WorkOrderIssueSummaryTableCell align="right">
              {workOrderIssue?.discountAmount
                ? numeral(workOrderIssue?.discountAmount).format('$0,0.00')
                : '-'}
            </WorkOrderIssueSummaryTableCell>

            <WorkOrderIssueSummaryTableCell align="right">
              {workOrderIssue?.totalAmount
                ? numeral(workOrderIssue?.totalAmount).format('$0,0.00')
                : '-'}
            </WorkOrderIssueSummaryTableCell>

            <WorkOrderIssueSummaryTableCell align="right">
              {workOrderIssue?.notToExceedAmount
                ? numeral(workOrderIssue?.notToExceedAmount).format('$0,0.00')
                : '-'}
            </WorkOrderIssueSummaryTableCell>

            <WorkOrderIssueSummaryTableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default WorkOrderViewAppWorkOrderIssueTable;
