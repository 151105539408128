import { gql } from '@apollo/client';
import { COST_CODE_DELETE_DIALOG_COST_CODE_FRAGMENT } from './fragments';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_COST_CODE = gql`
  mutation DeleteCostCode($where: CostCodeWhereUniqueInput!) {
    deleteCostCode(where: $where) {
      id
      ...CostCodeDeleteDialogCostCodeFragment
    }
  }
  ${COST_CODE_DELETE_DIALOG_COST_CODE_FRAGMENT}
`;
