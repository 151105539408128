// eslint-disable-next-line import/prefer-default-export
export const accountingTaskCodeSearchScreenFilterDefs = [
  {
    name: 'Accounting Task Code',
    refinements: [
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
    ],
  },
];
