import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSocketIo } from 'app/providers/socketIo';
import { AccountingExpenseTypeCreateDialog } from 'app/shared-components/AccountingExpenseType';
import {
  EntitySearchAppProvider,
  EntitySearchDrawer,
  EntitySearchHeader,
  EntitySearchInfiniteHitsGrid,
  makeEntitySearchCommonClientAdapter,
  useEntitySearchApp,
} from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { buildAccountingExpenseTypeSearchScreenColumnDefs } from './columnDefs';
import { accountingExpenseTypeSearchScreenFilterDefs } from './filterDefs';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
  },
  '& .FusePageSimple-sidebarContent': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-toolbar': {},
}));

const AccountingExpenseTypeSearchScreenRoot = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();
  const { setRefreshSearch, viewMode } = useEntitySearchApp();

  const { userSocket } = useSocketIo();
  useEffect(
    () => console.log('// socket.io => connection changed', { connected: userSocket?.connected }),
    [userSocket?.connected]
  );
  useEffect(() => {
    const onConnect = () => {
      console.log('// SOCKET.IO => Connected');
    };

    const onDisconnect = () => {
      console.log('// SOCKET.IO => Disconnected');
    };

    const onDocumentUpsert = (value) => {
      console.log('// SOCKET.IO => document.upsert', { value });
      setRefreshSearch(true);
    };

    if (userSocket) {
      userSocket.on('connect', onConnect);
      userSocket.on('disconnect', onDisconnect);
      userSocket.on('document.upsert', onDocumentUpsert);
    }

    return () => {
      userSocket?.off('connect', onConnect);
      userSocket?.off('disconnect', onDisconnect);
      userSocket?.off('document.upsert', onDocumentUpsert);
    };
  }, [setRefreshSearch, userSocket]);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <Root
      content={
        <SizeMe monitorHeight monitorWidth={false}>
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {viewMode === 'grid' && (
                <EntitySearchInfiniteHitsGrid
                  onRowClicked={(data) =>
                    data?.id && navigate(`/accounting-expense-types/quick-view/${data.id}`)
                  }
                  {...size}
                />
              )}
            </div>
          )}
        </SizeMe>
      }
      header={
        <EntitySearchHeader
          dateRangeAttribute="createdAtTimestamp"
          leftSidebarToggle={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
          rightSidebarToggle={null}
          rightToolbarContent={
            <>
              <Button
                color="success"
                // TODO: Consumer + Admin ONLY
                disabled={false}
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <AccountingExpenseTypeCreateDialog
                          onClose={() => dispatch(closeDialog())}
                          onSuccess={(data) =>
                            data?.id && navigate(`/accounting-expense-types/quick-view/${data.id}`)
                          }
                        />
                      ),
                      classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                      onClose: () => null,
                    })
                  )
                }
              >
                Create
              </Button>
            </>
          }
        />
      }
      leftSidebarContent={
        <EntitySearchDrawer
          dateRangeAttribute="createdAtTimestamp"
          filterDefs={accountingExpenseTypeSearchScreenFilterDefs}
          title="Expense Types"
          toggleDrawer={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
        />
      }
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={341}
      // TODO:
      rightSidebarContent={<div>{params?.accountingExpenseTypeId}</div>}
      // TODO: Order
      rightSidebarOnClose={() => {
        navigate('/accounting-expense-types/search');
      }}
      // TODO:
      rightSidebarOpen={!!params?.accountingExpenseTypeId}
      // TODO:
      rightSidebarWidth={480}
      scroll="content"
    />
  );
};

const AccountingExpenseTypeSearchScreen = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const accountingExpenseTypeSearchScreenColumnDefs =
    buildAccountingExpenseTypeSearchScreenColumnDefs({
      theme,
      user,
    });

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="accounting_expense_types"
      initialColumnDefs={accountingExpenseTypeSearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
    >
      <AccountingExpenseTypeSearchScreenRoot />
    </EntitySearchAppProvider>
  );
};

export default AccountingExpenseTypeSearchScreen;
