import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  budgetProjectSearchScreenFilterDefs,
  buildBudgetProjectSearchScreenColumnDefs,
} from 'app/screens/BudgetProjectSearchScreen';
import { BudgetProjectCreateDialog } from 'app/shared-components/BudgetProject';
import {
  EntitySearchAppProvider,
  EntitySearchDrawer,
  EntitySearchHeader,
  EntitySearchInfiniteHitsGrid,
  makeEntitySearchCommonClientAdapter,
} from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import BudgetViewScreenHeader from './BudgetViewScreenHeader';

const BudgetViewScreenBudgetProjectsTabRoot = ({ budget }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(selectUser);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const canCreateBudgetProject = useMemo(
    () =>
      Boolean(
        user?.data?.team?.isOrganizationAdmin === true &&
          ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum)
      ),
    [user]
  );

  const leftSidebarWidth = useMemo(() => 341, []);

  const handleLeftSidebarClose = useCallback(() => setLeftSidebarOpen(false), []);

  const handleLeftSidebarToggle = useCallback(
    () => setLeftSidebarOpen(!leftSidebarOpen),
    [leftSidebarOpen]
  );

  const handleViewEntity = useCallback(
    (data) => data?.id && navigate(`/budget-projects/view/${data.id}`),
    [navigate]
  );

  return (
    <FusePageCarded
      content={
        <>
          {!budget ? (
            <FuseLoading className="h-full" />
          ) : (
            <div className="flex flex-col w-full">
              <EntitySearchHeader
                dateRangeAttribute="createdAtTimestamp"
                leftSidebarToggle={handleLeftSidebarToggle}
                rightSidebarToggle={null}
                rightToolbarContent={
                  <>
                    <Button
                      color="success"
                      disabled={!canCreateBudgetProject}
                      size="small"
                      variant="contained"
                      onClick={() =>
                        dispatch(
                          openDialog({
                            children: (
                              <BudgetProjectCreateDialog
                                budget={budget}
                                onClose={() => dispatch(closeDialog())}
                                onSuccess={handleViewEntity}
                              />
                            ),
                            classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                            onClose: () => null,
                          })
                        )
                      }
                    >
                      Create
                    </Button>
                  </>
                }
              />

              <SizeMe monitorHeight monitorWidth={false}>
                {({ size }) => (
                  <div
                    style={{
                      // NOTE: NavBar + ScreenHeader + SearchHeader
                      height: `calc(100vh - ${isMobile ? 48 + 256 + 103 : 64 + 160 + 64}px)`,
                    }}
                  >
                    <EntitySearchInfiniteHitsGrid onRowClicked={handleViewEntity} {...size} />
                  </div>
                )}
              </SizeMe>
            </div>
          )}
        </>
      }
      header={
        <>
          {!budget ? (
            <FuseLoading />
          ) : (
            <BudgetViewScreenHeader basePath={basePath} budget={budget} />
          )}
        </>
      }
      leftSidebarContent={
        <>
          {!budget ? (
            <FuseLoading />
          ) : (
            <>
              <EntitySearchDrawer
                contentWidth={leftSidebarWidth - 1}
                dateRangeAttribute="createdAtTimestamp"
                disableUserSearchViewControl
                filterDefs={budgetProjectSearchScreenFilterDefs}
                offsetHeight={isMobile ? 0 : 160}
                title="Budget Projects"
                toggleDrawer={handleLeftSidebarToggle}
              />
            </>
          )}
        </>
      }
      leftSidebarOnClose={handleLeftSidebarClose}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={leftSidebarWidth}
      scroll={isMobile ? 'normal' : 'content'}
    />
  );
};

const BudgetViewScreenBudgetProjectsTab = (props) => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const budgetProjectSearchScreenColumnDefs = buildBudgetProjectSearchScreenColumnDefs({
    theme,
    user,
  });

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      filters={[`budget.id = '${props.budget?.id}'`]}
      indexName="budget_projects"
      initialColumnDefs={budgetProjectSearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
    >
      <BudgetViewScreenBudgetProjectsTabRoot {...props} />
    </EntitySearchAppProvider>
  );
};

export default BudgetViewScreenBudgetProjectsTab;
