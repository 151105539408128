import Dagre from '@dagrejs/dagre';
import { Position } from '@xyflow/react';

export const calculateActionItemsTotal = (actionItems) =>
  actionItems?.reduce(
    (acc, { commonStandardFlatRate }) => acc + (commonStandardFlatRate?.quantity ?? 0),
    0
  ) ?? 0;

const NODE_WIDTH = 320;
const NODE_HEIGHT = 112;

export const generateUniqueId = () => {
  const uniqueId = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);

  return [
    uniqueId.substr(0, 8),
    uniqueId.substr(8, 4),
    `4000-8${uniqueId.substr(13, 3)}`,
    uniqueId.substr(16, 12),
  ].join('-');
};

export const getLayoutedElements = (nodes, edges, options) => {
  const isHorizontal = options.direction === 'LR';

  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: options.direction });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? NODE_WIDTH,
      height: node.measured?.height ?? NODE_HEIGHT,
    })
  );

  Dagre.layout(g);

  return {
    edges,
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - (node.measured?.width ?? NODE_WIDTH) / 2;
      const y = position.y - (node.measured?.height ?? NODE_HEIGHT) / 2;

      return {
        ...node,
        position: { x, y },
        sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        targetPosition: isHorizontal ? Position.Left : Position.Top,
      };
    }),
  };
};
