import { useLazyQuery, useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CsiClassificationDeleteDialog } from 'app/shared-components/CsiClassification/CsiClassificationDeleteDialog';
import { CsiClassificationStandardSelectField } from 'app/shared-components/CsiClassificationStandard';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { FETCH_CSI_CLASSIFICATION_VIEW_DRAWER_DATA, UPDATE_CSI_CLASSIFICATION } from './queries';

const DRAWER_APP_BAR_HEIGHT = 64;

const defaultValues = {
  name: '',
  number: '',
  standard: null,
  title: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('A name must be provided.'),
  number: yup.string().nullable().required('A number must be provided.'),
  standard: yup.object().nullable().required('A standard must be provided.'),
  title: yup.string().nullable().required('A title must be provided.'),
});

const CsiClassificationViewDrawer = forwardRef(({ basePath, contentWidth, onCloseDrawer }, ref) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const rootRef = useRef(null);
  const { refreshSearch } = useEntitySearchApp();
  const user = useSelector(selectUser);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [
    fetchCsiClassificationViewDrawerData,
    {
      data: csiClassificationViewDrawerData,
      loading: csiClassificationViewDrawerLoading,
      refetch: csiClassificationViewDrawerRefetch,
    },
  ] = useLazyQuery(FETCH_CSI_CLASSIFICATION_VIEW_DRAWER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching CSI Classification Data',
          variant: 'error',
        })
      );
    },
  });

  const [updateCsiClassification, { loading: updateCsiClassificationLoading }] = useMutation(
    UPDATE_CSI_CLASSIFICATION,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'CSI Classification Successfully Updated',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Updating CSI Classification', variant: 'error' }));
      },
    }
  );

  const csiClassification = useMemo(
    () => csiClassificationViewDrawerData?.csiClassification,
    [csiClassificationViewDrawerData?.csiClassification]
  );

  const loading = useMemo(
    () => [csiClassificationViewDrawerLoading, updateCsiClassificationLoading].includes(true),
    [csiClassificationViewDrawerLoading, updateCsiClassificationLoading]
  );

  const canUpdateCsiClassification = useMemo(
    () => Boolean(csiClassification?.canBeUpdated === true && user?.role === 'SYSTEM_ADMIN'),
    [csiClassification?.canBeUpdated, user?.role]
  );

  useImperativeHandle(ref, () => ({
    rootRef,
    refetchAll: () => {
      [csiClassificationViewDrawerRefetch].forEach(() => csiClassificationViewDrawerRefetch());
    },
  }));

  useEffect(() => {
    if (csiClassification) {
      const formData = {
        name: csiClassification?.name,
        number: csiClassification?.number,
        // ROADMAP: Use `getOptionValue` Instead
        standard: {
          label: csiClassification.standard?.name,
          result: csiClassification,
          value: csiClassification.standard?.id,
        },
        title: csiClassification?.title,
      };

      reset(formData);
    }
  }, [reset, csiClassification]);

  useEffect(() => {
    if (csiClassificationViewDrawerRefetch && fetched && refreshSearch) {
      csiClassificationViewDrawerRefetch();
    }
  }, [csiClassificationViewDrawerRefetch, fetched, refreshSearch]);

  useEffect(() => {
    if (params?.csiClassificationId) {
      setFetched(false);
      fetchCsiClassificationViewDrawerData({
        variables: { where: { id: params?.csiClassificationId } },
      });
    }
  }, [fetchCsiClassificationViewDrawerData, params?.csiClassificationId]);

  const handleCloseDrawer = useCallback(() => {
    if (typeof onCloseDrawer === 'function') {
      onCloseDrawer();
    }

    setFetched(false);
  }, [onCloseDrawer]);

  useEffect(() => {
    if (fetched && !csiClassification) {
      handleCloseDrawer();
    }
  }, [csiClassification, fetched, handleCloseDrawer]);

  const onSubmit = async (data) => {
    try {
      await updateCsiClassification({
        variables: {
          where: {
            id: csiClassification.id,
          },
          data: {
            name: data.name,
            number: data.number,
            standard: { id: data.standard?.value },
            title: data.title,
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <div className="flex-1" style={{ width: contentWidth }}>
      <AppBar color="inherit" elevation={0} position="static">
        <Toolbar>
          <div className="flex flex-1">
            <Typography component="div" noWrap variant="h6">
              CSI Classification Details
            </Typography>
          </div>

          <IconButton sx={{ width: 40, height: 40 }} onClick={handleCloseDrawer}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper
        className="flex flex-col p-24 overflow-auto border-t-1"
        elevation={0}
        square
        style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
      >
        {(loading && !fetched) || !csiClassification ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <FormProvider
            {...{
              clearErrors,
              control,
              formState,
              handleSubmit,
              reset,
              setValue,
              watch,
              ...methods,
            }}
          >
            <form
              className="w-full"
              id="csi-classification-view-form"
              name="csi-classification-view-form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="title"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.title}
                        fullWidth
                        helperText={errors?.title?.message}
                        inputProps={{ readOnly: !canUpdateCsiClassification }}
                        required
                        variant="outlined"
                        placeholder="Enter CSI Classification Title..."
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.name}
                        fullWidth
                        helperText={errors?.name?.message}
                        inputProps={{ readOnly: !canUpdateCsiClassification }}
                        required
                        variant="outlined"
                        placeholder="Enter CSI Classification Name..."
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="number"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.number}
                        fullWidth
                        helperText={errors?.number?.message}
                        inputProps={{ readOnly: !canUpdateCsiClassification }}
                        required
                        variant="outlined"
                        placeholder="Enter CSI Classification Number..."
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12}>
                  <Controller
                    control={control}
                    name="standard"
                    render={({ field }) => (
                      <CsiClassificationStandardSelectField
                        {...field}
                        className="mb-24"
                        error={errors?.standard}
                        filters={null}
                        isDisabled={!canUpdateCsiClassification}
                        isClearable
                        isMulti={false}
                        placeholder="Select a CSI Classification Standard..."
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {canUpdateCsiClassification && (
                <>
                  <Divider className="my-16" />

                  <Grid container spacing={2}>
                    <Grid
                      alignContent="center"
                      display="flex"
                      justifyContent={isMobile ? 'center' : 'left'}
                      md={6}
                      xs={12}
                    >
                      <Button
                        color="error"
                        disabled={loading}
                        fullWidth={isMobile}
                        variant="contained"
                        onClick={() => {
                          dispatch(
                            openDialog({
                              children: (
                                <CsiClassificationDeleteDialog
                                  csiClassificationId={csiClassification?.id}
                                  onClose={() => dispatch(closeDialog())}
                                  onSuccess={handleCloseDrawer}
                                />
                              ),
                              classes: {
                                paper: 'w-full max-w-640 min-w-320 rounded-8',
                              },
                            })
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>

                    <Grid
                      alignContent="center"
                      display="flex"
                      justifyContent={isMobile ? 'center' : 'right'}
                      md={3}
                      xs={12}
                    >
                      <Button
                        color="primary"
                        disabled={_.isEmpty(dirtyFields) || loading}
                        fullWidth
                        variant="contained"
                        onClick={() => reset(undefined, { keepDefaultValues: true })}
                      >
                        Revert
                      </Button>
                    </Grid>

                    <Grid
                      alignContent="center"
                      display="flex"
                      justifyContent={isMobile ? 'center' : 'right'}
                      md={3}
                      xs={12}
                    >
                      <Button
                        color="secondary"
                        disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                        form="csi-classification-view-form"
                        fullWidth
                        type="submit"
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </form>
          </FormProvider>
        )}
      </Paper>
    </div>
  );
});

export default CsiClassificationViewDrawer;
